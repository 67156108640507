import styled from 'styled-components';
import {
  CardCvcElement as CardCVC,
} from '@stripe/react-stripe-js';

export const CardCVCElement = styled(CardCVC)`
  padding: 1.1rem 1.6rem;
  border-radius: .5rem;
  border: solid .1rem #caccd8;
`;
