import { createAction } from 'redux-actions';

const baseURL = process.env.REACT_APP_SOAR_API_HOST;
const errorMessage = Error('Oops! Something went wrong!');

export const setFieldGivenName = createAction('PRICE_PLANS/SET_FIELD_GIVEN_NAME', value => value);
export const setFieldFamilyName = createAction('PRICE_PLANS/SET_FIELD_FAMILY_NAME', value => value);

export const setFieldEmail = createAction('PRICE_PLANS/SET_FIELD_EMAIL', value => value);
export const setFieldEmailIsNewUser = createAction('PRICE_PLANS/SET_FIELD_EMAIL_IS_NEW_USER', value => value);
export const setFieldEmailWasVerified = createAction('PRICE_PLANS/SET_FIELD_EMAIL_WAS_VERIFIED', value => value);
export const setFieldEmailIsValid = createAction('PRICE_PLANS/SET_FIELD_EMAIL_IS_VALID', value => value);

export const setFieldPassword = createAction('PRICE_PLANS/SET_FIELD_PASSWORD', value => value);
export const setFieldPasswordWasVerified = createAction('PRICE_PLANS/SET_FIELD_PASSWORD_WAS_VERIFIED', value => value);
export const setFieldPasswordIsValid = createAction('PRICE_PLANS/SET_FIELD_PASSWORD_IS_VALID', value => value);

export const setFieldCardIsValid = createAction('PRICE_PLANS/SET_FIELD_CARD_IS_VALID', value => value);

export const setIsLoadingSubscription = createAction('SUBSCRIPTION/SET_IS_LOADING', value => value);
export const setErrorMessageSubscription = createAction('SUBSCRIPTION/SET_ERROR_MESSAGE', value => value);
export const setSuccessMessageSubscription = createAction('SUBSCRIPTION/SET_SUCCESS_MESSAGE', value => value);

export const getUserByEmail = email =>
  new Promise((resolve, reject) => {
    fetch(`${baseURL}/user/byEmail/clean?email=${email.replace('+', '%2B')}`)
      .then(res => {
        if (String(res.status)[0] === 5) throw errorMessage;
        return res.ok ? res.json() : [{ id: 0 }]
      })
      .then(res => {
        resolve(res[0]);
      })
      .catch(err => {
        reject(err)
      });
  });

export const getPlansUser = userId =>
  new Promise((resolve, reject) => {
    fetch(`${baseURL}/Subscription/${userId}`, {
      headers: { Authorization: `Bearer ${sessionStorage.getItem('token_API_audience_TMP')}` }
    })
      .then(res => {
        if (res.ok) return res.json();
        throw errorMessage;
      })
      .then(res => resolve(res))
      .catch(err => reject(err));
  });

export const postUser = email =>
  new Promise((resolve, reject) => {
    const data = {
      emails: [{ emailAddress: email, type: 'Main' }],
      signupCode: 10,
      gsnStatus: 110,
      isCoach: true,
    };
    fetch(`${baseURL}/user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${sessionStorage.getItem('token_API_audience_TMP')}`
      },
      body: JSON.stringify(data),
    })
      .then(res => {
        if (res.ok) return res.json();
        throw errorMessage;
      })
      .then(res => resolve(res))
      .catch(err => reject(err));
  });

export const alterPlan = ({ cardToken, userId, coachPlans, newPlanId }) =>
  new Promise(async (resove, reject) => {
    try {
      const stripeId = coachPlans.length ? coachPlans[0].stripeSubscriptionId : null;
      const cancelledOn = coachPlans.length ? coachPlans[0].cancelledOn : null;
      let res = '';

      if (stripeId && cancelledOn === null) {
        res = await fetch(`${baseURL}/Subscription/updatePlan/${userId}/${stripeId}/${newPlanId}`, {
          method: "PATCH",
          headers: { "Authorization": `Bearer ${sessionStorage.getItem('token_API_audience_TMP')}` }
        }
        );
      } else {
        if (stripeId) {
          await fetch(`${baseURL}/Subscription/cancel/${stripeId}/now`, {
            method: "PATCH",
            headers: { "Authorization": `Bearer ${sessionStorage.getItem('token_API_audience_TMP')}` }
          });
        }
        res = await fetch(`${baseURL}/Subscription/${userId}/${newPlanId}/${cardToken}`, {
          method: "POST",
          headers: { "Authorization": `Bearer ${sessionStorage.getItem('token_API_audience_TMP')}` }
        });
      }

      if (res.status !== 200) throw new Error();

      resove();
    } catch {
      reject();
    }
  });
