import styled from 'styled-components';
import { breakpoints } from '../../../styles/breakpoints';

export const StyledLogo = styled.img`
  width: auto;
  height: 4.4rem;

  @media ${breakpoints.sm} { 
    height: 5.2rem;
  }

  @media ${breakpoints.md} {
    height: 6rem;
  }

  @media ${breakpoints.xl} { 
    width: auto;
    height: 8.7rem;
  }
`;
