import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Title } from '../styles';
import { PLANS } from '../../../../constants';
import {
  FormGroup,
  Label,
  Select,
  Option,
  Total,
  Value
} from './styles';
import { setPeriod } from '../../../../actions/premium-actions';
import Channel from './Channel';
import { NEW_PLANS } from '../../../../constants';

const Subscription = ({ plan, period }) => {
  const [newPeriod, setNewPeriod] = useState(period);

  function searchPlan() {
    const [selectedPlan] = NEW_PLANS.filter(item => {
      return item.link.toLowerCase() === plan.toLowerCase()
        && item.period.toLocaleLowerCase() === newPeriod.toLowerCase();
    });

    return selectedPlan;
  }

  const [planInfo, setPlanInfo] = useState(searchPlan());
  const dispatch = useDispatch();
  const { MONTHLY, YEARLY } = PLANS.SINGLE;
  const [price, setPrice] = useState(planInfo.price);

  useEffect(() => {
    setPlanInfo(searchPlan());
  }, [searchPlan]);

  function onChange(event) {
    if (event.target.value === "MONTHLY") {
      setNewPeriod('Monthly');
      dispatch(setPeriod('MONTHLY'));
      setPrice(MONTHLY.price);
    } else {
      setNewPeriod('Yearly');
      dispatch(setPeriod('YEARLY'));
      setPrice(YEARLY.price);
    }

    setPlanInfo(searchPlan());
  };


  return (
    <>
      <Title>SUBSCRIPTION CHECKOUT</Title>

      <FormGroup>
        <Channel {...planInfo} />
      </FormGroup>

      {/* <FormGroup>
        <Label>Channel</Label>
        <Channel {...CHANNELS[0]} />
      </FormGroup> */}

      <FormGroup>
        <Label>Billed</Label>
        <Select name="billed" data-cy="billed-field" onChange={onChange}>
          <Option value="MONTHLY">Monthly</Option>
          <Option value="YEARLY">Yearly</Option>
        </Select>
      </FormGroup>

      <Title secondary>TOTAL</Title>
      <Total>
        <Value>{price}</Value>per {newPeriod === 'Monthly' ? 'month' : 'year'}
      </Total>
    </>
  );
};

Subscription.propTypes = {
  plan: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
};

export default Subscription;