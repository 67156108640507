import styled from 'styled-components';
import { background, black, lightGreyBlue, white } from '../../../../styles/variables';

export const Div = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  padding: 22px 25px 24px 20px;
  border: solid 1px ${lightGreyBlue};
  background-color: ${white};
  font-family: Work Sans;

  &:first-child{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:not(:first-child){
    border-top: unset;
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  & span.name {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.1px;
    color: ${black};
  }

  & span.amount {
    margin-left: auto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.09px;
    color: ${background};
  }
`;