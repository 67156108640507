import styled from "styled-components";

export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 1.3rem;
  width: 1.3rem;
  background-color: #eee;
  border-radius: 50%;
  cursor: inherit;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

export const Input = styled.input.attrs(() => ({
  type: 'radio',
}))`
  position: absolute;
  cursor: inherit;
  opacity: 0;  
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
`;

export const Container = styled.div`
  display: block;
  position: relative;
  padding-left: calc(1.3rem + 1rem);
  user-select: none;
  white-space: nowrap; 

  &:hover ${Input}:enabled:not(:checked) ~ ${Checkmark} {
    background-color: #ccc;
  }

  & ${Input}:checked ~ ${Checkmark} {
    background-color: #ff7654;
  }

  & ${Input}:checked ~ ${Checkmark}:after {
    display: block;
  }

  & ${Checkmark}:after {
    top: auto;
    bottom: auto;
    right: auto;
    left: auto;
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    background: white;
  }
`;

export const Label = styled.label`
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`;
