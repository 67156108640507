import { createGlobalStyle } from 'styled-components';
import { PELE_GREY } from '../../src/styles/variables'

const GlobalStyle = createGlobalStyle`
  :root {
    font-size: 10px;
    font-family: 'Work Sans', sans-serif;
  }​
  
  *, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    background-color: ${PELE_GREY};
    background-size: 120%;
    background-repeat: no-repeat;
    background-position: top right;
    width: 100%;
  }

  .tag-editor-mirror {
    position: absolute;
    top: -9999px;
    left: -9999px;
    width: auto;
    visibility: hidden;
  }
`;

export default GlobalStyle;
