import styled from 'styled-components'
import {white, melon, darkGrey, lightGrey} from '../../styles/variables'

const small = 'screen and (max-width: 600px)';
const small1 = 'screen and (max-width: 769px)';
const mobile = 'screen and (max-width: 1000px)';
const normalScreen = 'screen and (min-width: 2000px)';
const tooBigScreen = 'screen and (min-width: 2500px)';

export const Container = styled.div`
  max-width: 156rem;
  margin: 0 auto;
  padding: 0 4rem 1rem 4rem;

  @media ${mobile} {
    padding: 0 2rem 1rem 2rem;
  }
`;

export const StyledLanding = styled.div`
  width: 100%;
  z-index: 1;
`;

export const SignupButton = styled.a`
  margin: 25px auto;
  color: ${white} !important;
  text-decoration: none !important;
  background-color: ${melon};
  padding: 1rem 2rem;
  border-radius: 4rem;
  border: 0.2rem solid ${melon};
  font-family: Work Sans;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  display: inline-block;

  &:hover {
    color: ${melon} !important;
    border: 0.2rem solid ${melon};
    background-color: ${white};
  }
`;

export const CTAButton = styled.a`
  margin: 25px auto;
  color: ${white} !important;
  text-decoration: none !important;
  background-color: ${melon};
  padding: 1rem 2rem;
  border-radius: 4rem;
  border: 0.2rem solid ${melon};
  font-family: Work Sans;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  display: inline-block;

  &:hover {
    color: ${melon} !important;
    border: 0.2rem solid ${melon};
    background-color: ${white};
  }
`;

export const LogoContainer = styled.div`
 img {
  width: 30rem;
  height: auto;
 }

 @media ${mobile} {
  img {
    max-width: 15rem;
  }
 }
`;


export const OverflowImage = styled.div`
  right: 0;

  img {
    position: absolute;
    right: 0;
    max-width: 50%;
  }

  @media ${normalScreen} {
    img {
      right: 10vw;
    }
  }

  @media ${tooBigScreen} {
    img {
      right: 15vw;
    }
  }
`;

export const ForBox = styled.div`
position: absolute;
  right: 15rem;
  max-width: 50%;
  border: 3px solid ${darkGrey};
  border-radius: 25px;
  background:  ${lightGrey};
  padding-top: ${props => props.pt || 1}rem;
  padding-bottom: ${props => props.pb || 1}rem;
  padding-right: ${props => props.pt || 1}rem;
  padding-left: ${props => props.pb || 1}rem;
  text-align: center;
  
  
  @media ${mobile} {
    font-size:14px;
    padding: 2rem ;
  }
  
`;

export const Title2 = styled.div`
  font-size: 2rem;
  font-weight: 600;
  padding-top: ${props => props.pt || 0}rem;
  padding-bottom: ${props => props.pb || 0}rem;

  @media ${mobile} {
    font-size: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;

export const TopBlock = styled.div`
  display: grid;
  /* margin-left: 13rem; */
  padding-top: ${props => props.pt || 0}rem;
  
  @media ${mobile} {
    margin-left: 0rem;
  }
`;

export const Title1 = styled.div`
  font-size: 4.6rem;
  margin-top: 2rem;
  font-weight: 600;
  padding-top: ${props => props.pt || 0}rem;
  padding-bottom: ${props => props.pb || 0}rem;
  width: 55%;

  @media ${mobile} {
    font-size: 3rem;
    margin-top: 4rem;
    padding-bottom: 1rem;
  }
`;

export const SubText1 = styled.div`
  color: #28323b;
  font-size: 2.4rem;
  font-weight: 400;
  text-align: ${props => props.align || ''}; 
  max-width: ${props => props.width || 10}rem;
  padding-top: ${props => props.pt || 0}rem;
  padding-bottom: ${props => props.pb || 0}rem;
  @media ${mobile} {
    font-size:14px;
    padding: 2rem ;
  }
`;

export const SubText2 = styled.div`
  color: #28323b;
  font-size: 2rem;
  font-weight: 400;
  text-align: ${props => props.align || ''}; 
  /* max-width: ${props => props.width || 10}rem; */
  padding-top: ${props => props.pt || 0}rem;
  padding-bottom: ${props => props.pb || 0}rem;
  max-width: 50rem;
  margin: auto;

  @media ${mobile} {
    font-size:14px;
    padding: 2rem 0;
  }
`;

export const StyledContainer = styled(Container)`
  & > img {
    margin-top: 2rem;
  }
`;

export const OpacityView = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background: transparent linear-gradient(185deg, var(---ee785a-primary) 0%, var(---7f5faa-secondary) 100%) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(185deg, #EE785A 0%, #7F5FAA 100%) 0% 0% no-repeat padding-box;
  mix-blend-mode: overlay;
  opacity: 0.7;
`;

export const ContentView = styled.div`
  z-index: 2;
  overflow: auto;
  margin-left: 2.9rem;
  margin-right: 2.9rem;

  @media ${small} {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;

export const ContentContainer = styled.div`
  /* width: 56%; */
  padding: 7vh 0 0;
  
  & > p:first-child {
    margin-bottom: 8rem;
  }

  & > button {
    margin: 8rem auto 4rem;
  }

  @media ${mobile} {
    padding: 4vh 0 0 0;
    width: auto;
    
    div {
      /* width: 90%; */
      min-width: 25rem;
    }

    input {
      height: 4rem;
      font-size: 14px;
      padding-right: 1rem;
    }

    button {
      height: 4rem;
      max-width: 50%;
      padding: 0 12px;
      p {
      font-size: 14px;
      }
    }
  }
`;

export const SpanQuestion = styled.span`
  font-size: 1.4rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.34px;
  text-align: center;
  color: #666b87;
`;

export const CenterTitle = styled.span`
  max-width: 63%;
  margin: 2rem 0 3rem;
  font-size: 2.8rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.18px;
  text-align: center;
  color: #28323b;
  
  @media ${mobile} {
    font-size:20px;
    margin: 1rem 0;
    max-width: 90%;
  }
`;

export const CaptureContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  justify-content: center;
  align-items: center;
  margin: 10rem 0 1rem;
  
  @media ${mobile} {
    margin: 1rem 0 1rem;
  }
`;

export const CenterBlock = styled.div`
  
`;

export const AppButtons = styled.div`
  /* display: grid; */
  grid-template-columns: 60% 20% 20%;
  max-width: 520px;
  margin: auto;

  @media ${mobile} {
    grid-template-columns: auto;
    max-width: 100%;
  }
`;

export const AppButton = styled.div`
  text-align: center;
  margin: 25px auto;
`;

export const MediaContainer = styled.div`
  display: flex;
  grid-gap: 3rem;
  flex-direction: column;
  padding-left: 10rem;
  margin-bottom: 10rem;
`;

export const MiddleForm = styled.div`
  margin-bottom: 10rem;
  @media ${mobile} {
    margin-bottom: 3rem;
  }
`;
export const MiddleFormInput = styled.div`
  
  @media ${mobile} {
    margin-bottom: 3rem;

    div{
      width: 90%;
      min-width: 30rem;
    }

    input {
      height: 4rem;
      font-size: 14px;
      padding-right: 2rem;
      max-width: 90%;
    }

    button {
      height: 4rem;
      max-width: 50%;
      padding: 0 12px;
      p {
      font-size: 14px;
      }
    }
  }
`;

export const JoinImage = styled.div`
 text-align: right;

 img {
    margin: 0;
    width: 100%;
 }

 @media ${mobile} {
  img {
    max-width: 90%;
  }
}
`;

export const JoinTheRevolution = styled.div`
  max-width: 1275px;
  display: grid;
  grid-template-columns: 50% 45%;
  margin: 2rem auto;
  border-radius: 79px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  background-image: linear-gradient(to bottom, #e1e3ef 1%, #f3f3f8 99%);
  grid-gap: 5%;

  @media ${mobile} {
    grid-template-columns: auto;
    border-radius: 35px;
    margin-left: 2.9rem;
    margin-right: 2.9rem;
    padding: 2rem;
  }

  @media ${small} {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  @media ${small1} {
    grid-template-columns: 100%;
    grid-gap: 0;
  }
`;

export const CenterTitle2 = styled.span`
  max-width: 80%;
  margin: 2rem 0 3rem;
  font-size: 2.8rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.18px;
  text-align: right;
  color: #28323b;
  
  @media ${mobile} {
    font-size:20px;
    margin: 1rem 0;
    max-width: 90%;
  }
`;

export const CaptureContainer2 = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  justify-content: center;
  align-items: center;
  margin: 1rem 0 1rem;
  
  @media ${mobile} {
    margin: 1rem 0 1rem;
  }
`;
