import styled from 'styled-components';
import { black } from '../../../../../../styles/variables';

export const Wrapper = styled.div`
  display: block;
  width: 100%;
`;

export const SessionTitle = styled.div`
  margin: 4rem 0 2rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: ${black};
`;