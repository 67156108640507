import { handleActions } from 'redux-actions';
import {
  setAuthorizedUser,
  setProfilePictUrl,
  userReceiveData,
  receiveUpdateAuthorizedUser,
} from '../actions/authorized-user-actions';
import { CoachRecord } from '../records/coach-record';

export const initialState = new CoachRecord();

export default handleActions(
  {
    [setAuthorizedUser]: (state, action) => action.payload,
    [setProfilePictUrl]: (state, action) => state.set('profilePictUrl', action.payload),
    [receiveUpdateAuthorizedUser]: (state, action) => action.payload,
    [userReceiveData]: (state, action) => action.payload,
  },
  initialState
);
