import React from 'react';
import withLayout from '../../shared/layout/hocs/withLayout';
import Container from '../../shared/layout/Container';
import { Title, SubTitle, Paragraph } from '../../shared/layout/CustomContent/styles';

const Terms = () => (
  <Container>
    <Title>Terms of Service</Title>
    <Paragraph>
      These Terms of Service (these “Terms”) apply to the services (the “Services”) provided by Strengths, Inc.
      dba Soar Uplift (“Soar,” “our,” or “we”) via https://www.soaraudio.com (the “Site”).
    </Paragraph>
    <Paragraph>
      BY REGISTERING TO USE OR ACCESS THE SERVICES AND CHECKING THE BOX TO AGREE TO THESE TERMS OF SERVICE OR
      OTHERWISE USING OR ACCESSING THE SITE AND/OR SERVICES, YOU ACKNOWLEDGE AND AGREE THAT: (A) YOU HAVE READ
      THESE TERMS; (B) YOU UNDERSTAND ALL OF THE TERMS AND CONDITIONS OF THESE TERMS; AND (C) YOU AGREE TO BE
      LEGALLY BOUND BY ALL OF THE TERMS AND CONDITIONS SET FORTH IN THESE TERMS.
    </Paragraph>
    <Paragraph>
      Soar reserves the right to change or modify any of the terms and conditions contained in these Terms
      (or any policy or guideline of Soar) at any time and in its sole discretion. If Soar makes changes to
      these Terms, we will provide notice of such changes, such as by sending you a notification, by providing
      notice through the Services, and/or by updating the “Last Updated” date at the top of these Terms and
      posting the revised Terms to our Services. Your continued use of any of the Services will constitute
      your acceptance of such changes or modifications. Therefore, you should review these Terms whenever you
      access the Services to make sure that you understand the terms and conditions that will apply to your
      use of the Services.
    </Paragraph>
    <Paragraph>
      For purposes of these Terms, “Soar” refers to Soar and Soar’s investors, directors, officers, employees,
      agents, representatives, and assigns. ICF refers to the International Coaching Federation, its officers,
      directors, employees, volunteers, partners, contractors and authorized agents..
    </Paragraph>
    <Paragraph>
      Soar and ICF have come together in response to Covid-19, offering pro bono coaching services to those
      affected by the pandemic.  Your voluntary participation in this pro-bono program is subject to your
      agreement to the following terms and conditions:.
    </Paragraph>
    <SubTitle>1. ELIGIBILITY</SubTitle>
    <Paragraph>
      You represent and warrant that you (a) are at least 18 years of age, and (b) have not been previously
      suspended or removed from using the Services.
    </Paragraph>
    <Paragraph>
      For ICF Coaches for Good Program - You affirm that you are willing and available to donate three (3)
      hours of pro bono services each week to the Covid-19 Response Initiative.
    </Paragraph>
    <SubTitle>2. ACCOUNT</SubTitle>
    <Paragraph>
      To access certain areas and features within the Services, you must create an account. You agree to (a)
      provide accurate, truthful, current and complete information when creating an account; (b) maintain and
      promptly update your account information; (c) maintain the security of your account by not sharing your
      password with others and restricting access to your account and your computer; (d) promptly notify Soar
      if you discover or otherwise suspect any security breaches related to the Site; and (e) take responsibility
      for all activities that occur under your account and accept all risks of unauthorized access.
    </Paragraph>
    <Paragraph>
      For ICF Coaches for Good Program - The Soar “Business Plus” account, which includes Zoom video conferencing,
      integrated billing, appointment scheduling, and simple CRM (Customer Relationship Manager), is free for ICF
      coaches participating in the Covid-19 pro bono initiative for a period of 3 months, not ending before August
      12th of 2020. At that point, all coaches are invited to continue using their “Business Plus” account as a
      paid subscription service, or they can choose to stay on the platform free of charge, using Soar’s “Business
      Basic” account. The free account does not include Zoom and calendar integration and other features.
    </Paragraph>
    <Paragraph>
      Soar reserves the right to revoke access to any coach that is not fulfilling their commitment of three (3)
      pro bono hours each week. If access is revoked, the Zoom license associated to the account of the Coach
      will be reassigned.
    </Paragraph>
    <SubTitle>3. ICF CREDENTIAL</SubTitle>
    <Paragraph>
      Applies to Participants in the ICF Coaches for Good Program
    </Paragraph>
    <Paragraph>
      1. All participants in the Covid-19 Pro Bono Initiative need to have a current ICF Credential (ACC, PCC,
      or MCC).
    </Paragraph>
    <Paragraph>
      2. Before a Coach may join and participate in  the Covid-19 Response Initiative he/she must become an
      approved user. In order to get approved, the Coach must  have a digital badge issued by Credly/Acclaim
      on behalf of ICF.
    </Paragraph>
    <Paragraph>
      3. All pro bono coaches are expected to adhere to the Codes of Ethics set out by the ICF and to coach
      according to the ICF Core Competencies to the best of their ability at all times in interaction with
      initiative clients.
    </Paragraph>
    <Paragraph>
      4. Those offering pro bono coaching are encouraged, as always, to maintain their own professional
      insurance. ICF, Soar and their respective officers, directors, employees, volunteers, partners,
      authorized agents or contractors will not be held liable for the coaching provided by any of the
      coaches on the Soar  platform.
    </Paragraph>
    <Paragraph>
      5. Review ICF Code of Ethics.
    </Paragraph>
    <Paragraph>
      6. Review When and How to Refer a Client to Therapy or Other Helping Professionals document.
    </Paragraph>
    <SubTitle>4. ICF EXTENDED COACH CLIENT RELATIONSHIP</SubTitle>
    <Paragraph>
      Applies to Participants in the ICF Coaches for Good Program
    </Paragraph>
    <Paragraph>
      Client will receive a code, redeemable for two 60-minute, pro bono coaching sessions. At the end of
      the two sessions, the relationship between Coach and Client is terminated, unless the Client initiates
      talk of extending the relationship into paid sessions; at which point Coach can engage client in
      talks of regular scheduling and fees, as necessary.
    </Paragraph>
    <Paragraph>
      At no point will a participating Coach use the pro bono site for building a recruitment list.
      Individuals identified  in violation of this term and condition will have their Soar Platform license
      immediately revoked and are subject to disciplinary action by the  ICF.
    </Paragraph>
    <SubTitle>5. PRIVACY</SubTitle>
    <Paragraph>
      Please see Soar’s privacy policy at https://www.soaraudio.com/privacy for information about how Soar
      collects, uses and discloses information about users of the Services.
    </Paragraph>
    <SubTitle>6. SERVICES</SubTitle>
    <Paragraph>
      Access to Services. On the condition that you comply with these Terms, Soar hereby grants you a
      non-exclusive, non-transferable, revocable license to access the Services (including any Soar Material
      therein) solely for the purpose of enabling you to use the Services in the manner permitted by these
      Terms. If Soar, in its sole discretion, elects to provide any upgrade that replaces or supplements the
      original Services, this license will govern any such upgrade unless such upgrade is accompanied by a
      separate license, in which case the terms of that license will govern.
    </Paragraph>
    <Paragraph>
      Modification of the Services. Soar may review, improve, change, or discontinue (temporarily or permanently)
      the Services or any features, information, materials, or content on the Services at any time, with or without
      notice to you. If you no longer wish to use the Services following any such modifications, you may terminate
      these Terms.
    </Paragraph>
    <Paragraph>
      Third Party Services. The Services require the use of certain third-party products and services (“Third Party Services”) identified on the Site. Use of any Third Party Services is at your sole risk and will be governed by separate terms and conditions, separate privacy policies relating to usage of data you may share through the Third Party Services in the course of using the Services, other applicable policies, and may include separate fees and charges. Soar may display content from third parties through the Services or may provide information about or links to Third Party Services. Your interactions with any such third parties, and any terms, conditions, warranties, or representations associated with such interactions, are solely between you and the applicable third parties. Soar is not responsible or liable for any loss or damage of any sort incurred as the result of any such interactions or as the result of the presence of such third-party information made available through the Services. If you are not able to access or use the Third-Party Services for any reason, you will not be able to use the Services, and your sole remedy will be to terminate these Terms.
    </Paragraph>
    <SubTitle>7. RESTRICTIONS ON USE OF SERVICE</SubTitle>
    <Paragraph>
      You are solely responsible for your conduct (and the conduct of anyone who uses the Services on your behalf) with respect to the Services, which you use at your own risk. Except as expressly permitted in writing by Soar, you will not do, and will not permit any third party to do, any of the following:
    </Paragraph>
    <Paragraph>
      rent, lease, lend, sell, sublicense, or create derivative works of the Services;
      distribute the Services or make the Services available over a network where it could be used by multiple devices at the same time;
      modify, disclose, decompile, reverse engineer, disassemble, attempt to derive the source code, underlying ideas, or algorithms of the Services or any part of the Services, except to the extent required by applicable law;
      remove, alter, or obscure any copyright, trademark, or other proprietary rights notice or labels on or in the Services;
      use the Services in any manner that could interfere with, disrupt, negatively affect, or inhibit Soar’s other users from fully enjoying the Services or that could damage, disable, overburden, or impair the functioning of the Services;
      use the Services for any illegal or unauthorized purpose or engage in, encourage, or promote any illegal activity, or any activity that violates these Terms;
      attempt to gain unauthorized access to the Services or their related systems or networks;
      use the Services to store or transmit infringing, libelous, or otherwise unlawful or tortious material, or to store or transmit material in violation of the rights of Soar or any third party;
      impersonate or use the Services by misrepresenting your affiliation with a person or entity;
      build a competitive product or service, or copy any features or functions of the Services;
      disclose to any third party any performance information or analysis relating to the Services; or
      cause or permit any third party to do any of the foregoing.
    </Paragraph>
    <Paragraph>
      If you violate (or are alleged to have violated) any of the foregoing restrictions, your license to use of the Services will immediately and automatically terminate, and you may have infringed the rights of Soar, which may subject you to prosecution and damages.

    </Paragraph>
    <SubTitle>8. ACCEPTABLE USE POLICY</SubTitle>
    <Paragraph>
      In using the Services, including in any video chat or one-on-one interaction with other users or our personal skills trainers or professional trainers (“Trainers”), regardless of whether you believe you have been prompted by the Services, Trainers, or other users to do any of the following, you may not upload, use, create, transmit, store, display, distribute, share or otherwise make available any content, descriptions, or information that:

    </Paragraph>
    <Paragraph>
      is illegal or fraudulent or that would encourage, solicit, foster, glorify or provide instructions for any criminal or civil offense;
      may create a risk of harm, loss, physical or mental injury, emotional distress, death, disability, disfigurement, or physical or mental illness to you, to any other person, to any animal or to any property;
      is obscene, pornographic, indecent or sexually explicit;
      depicts graphic, excessive or gratuitous violence, or make any threats to commit violent acts;
      is derogatory, demeaning, inflammatory, malicious, defamatory, abusive, offensive, hateful, harassing, humiliating to other people (publicly or otherwise), libelous or otherwise objectionable;
      you do not have a right to make available under any law or under contractual or fiduciary relationships;
      you know is untruthful; or
      infringes, misappropriates or violates the intellectual property or proprietary rights of others (including, but not limited to, rights of privacy and rights of publicity).
      Soar reserves the right, but is not obligated, to investigate any violation of this Section 7 or misuse of the Services or the Site. Enforcement of this Section 7 is solely at Soar’s discretion, and failure to enforce this Policy in some instances does not constitute a waiver of Soar’s right to enforce this Section 7 in other instances. In addition, these rules do not create any private right of action on the part of any third party or any reasonable expectation that the Services or the Site will be free from conduct or content that violates this Section 7. Soar may, in its sole discretion, remove or disable access to any content for any reason or for no reason, including if Soar believes that such content violates these Terms or any other agreement we have with you for use of the Services or the Site.
    </Paragraph>

    <SubTitle>9. OWNERSHIP AND RESERVATION OF RIGHTS</SubTitle>
    <Paragraph>
      Soar reserves all rights not expressly granted in these Terms. Except for the license expressly stated in these Terms, you are not granted any rights in or to the Services by implication, estoppel, or other legal theory, and all rights in and to the Services not expressly granted in these Terms are hereby reserved and retained by Soar. Soar reserves all right, title, and interest to and in all information, materials, and content (including text, graphics, data, formatting, graphs, designs, HTML, look and feel, photographs, music, sounds, images, software, videos, designs, typefaces, source and object code, format, queries, algorithms, and other content) (collectively “Soar Material”). Soar Material includes any information, videos, or images delivered by Soar via a Third-Party Service in the course of providing the Services. Soar Material is confidential, and you may not disclose Soar Material that you have received in the course of the Services or otherwise. Soar Material is protected in all forms, media, and technologies now known or later developed.
    </Paragraph>

    <SubTitle>10. TRADEMARKS</SubTitle>
    <Paragraph>
      Soar’s name, Soar’s trademarks, Soar’s logos, and any other Soar product, service name, or slogan included in the Services are property of Soar and may not be copied, imitated, or used (in whole or in part) without Soar’s prior written consent. The look and feel of the Services, including all custom graphics, button icons, and scripts constitute service marks, trademarks, or trade dress of Soar and may not be copied, imitated, or used (in whole or in part) without Soar’s prior written consent. All other trademarks, registered trademarks, product names, and company names or logos mentioned in the Services (“Third Party Trademarks”) are the property of their respective owners, and the use of such Third-Party Trademarks inures to the benefit of each owner. The use of such Third- Party Trademarks is intended to denote interoperability and does not constitute an affiliation by Soar and its licencors with such company or an endorsement or approval by such company of Soar or its licencors or their respective products or services.
    </Paragraph>

    <SubTitle>11. FEEDBACK</SubTitle>
    <Paragraph>
      Soar will own exclusive rights, including all intellectual property rights, to any feedback, suggestions, ideas, or other information or materials that you provide regarding Soar or the Services, whether by email, posting through the Services, or otherwise (“Feedback”). Any Feedback you submit is non-confidential and will become the sole property of Soar. Soar will be entitled to the unrestricted use and dissemination of such Feedback for any purpose, commercial or otherwise, without acknowledgment or compensation to you. You waive any rights you may have to the Feedback (including any copyrights or moral rights). Do not send Feedback to Soar if you expect to be paid or want to continue to own or claim rights in your Feedback. Soar also has the right to disclose your identity to any third party who is claiming that any content posted by you constitutes a violation of their intellectual property rights, or of their right to privacy. Further, you agree not to submit any Feedback that is defamatory, illegal, offensive, or otherwise violates any right of any third party, or breaches any agreement between you and any third party.
    </Paragraph>

    <SubTitle>12. SUSPENSION AND TERMINATION</SubTitle>
    <Paragraph>
      Soar may suspend your license to access or use the Services or terminate these Terms at any time, with or without cause, immediately upon notice to you. You may terminate these Terms by cancelling your account through the Services. Soar reserves the right, but does not undertake any duty, to take appropriate legal action including the pursuit of civil, criminal, or injunctive redress against you for continuing to use the Services during suspension or after termination, including by creation of a different account. Soar may recover its reasonable attorneys’ fees and court costs from you for such actions. These Terms will remain enforceable against you while your license to access or use the Services is suspended.
    </Paragraph>

    <SubTitle>13. DISCLAIMER</SubTitle>
    <Paragraph>
      YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. SOAR DISCLAIMS ALL WARRANTIES AND REPRESENTATIONS (EXPRESS OR IMPLIED, ORAL OR WRITTEN) WITH RESPECT TO THESE TERMS, THE SERVICES, THE Soar MATERIAL, AND THIRD PARTY TRADEMARKS, WHETHER ALLEGED TO ARISE BY OPERATION OF LAW, BY REASON OF CUSTOM OR USAGE IN THE TRADE, BY COURSE OF DEALING OR OTHERWISE, INCLUDING ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR ANY PURPOSE, NON-INFRINGEMENT AND CONDITION OF TITLE.
    </Paragraph>
    <Paragraph>

      TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, SOAR DOES NOT WARRANT, AND DISCLAIMS ALL LIABILITY FOR (A) THE COMPLETENESS, ACCURACY, AVAILABILITY, TIMELINESS, SECURITY OR RELIABILITY OF THE SERVICE OR ANY Soar MATERIAL; (B) ANY HARM TO YOUR COMPUTER SYSTEM, LOSS OF DATA, OR OTHER HARM THAT RESULTS FROM YOUR ACCESS TO OR USE OF THE SERVICE OR ANY Soar MATERIAL; (C) THE DELETION OF, OR THE FAILURE TO STORE OR TO TRANSMIT, ANY COMMUNICATIONS MAINTAINED BY THE SERVICE; (D) WHETHER THE SERVICE WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS; AND (E) THE CONTENT AND NATURE OF ANY INFORMATION OR ADVICE YOU RECEIVE FROM TRAINERS VIA THE SERVICES. YOU ACKNOWLEDGE THAT YOU ARE RESPONSIBLE FOR YOUR USE OF ANY INFORMATION OR ADVICE RECEIVED IN THE COURSE OF USING THE SERVICES FROM SOAR AND/OR ITS TRAINERS. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM SOAR OR THROUGH THE SERVICE, WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE IN THESE TERMS.
    </Paragraph>

    <SubTitle>14. NO MEDICAL ADVICE</SubTitle>
    <Paragraph>
      THE CONTENT AND/OR INFORMATION PROVIDED VIA THE SERVICES AND OUR TRAINERS DO NOT CONTAIN OR CONSTITUTE AND SHOULD NOT BE INTERPRETED AS THERAPY OR AS MEDICAL ADVICE, MEDICAL OPINIONS OR MEDICAL SERVICES, AND THERE IS NO DOCTOR-PATIENT RELATIONSHIP OR THERAPIST-PATIENT RELATIONSHIP BETWEEN Soar AND YOU OR OUR TRAINERS AND YOU. NEITHER Soar NOR OUR TRAINERS PROVIDE MEDICAL SERVICES OR RENDER MEDICAL ADVICE TO YOU. THE SERVICES ARE NOT A SUBSTITUTE FOR THE ADVICE OF A MEDICAL PROFESSIONAL OR THERAPIST, AND THE INFORMATION MADE AVAILABLE ON OR THROUGH THE SERVICES OR FROM OUR TRAINERS SHOULD NOT BE RELIED UPON WHEN MAKING MEDICAL DECISIONS, OR TO DIAGNOSE OR TREAT A MEDICAL OR HEALTH CONDITION. SOAR AND ITS TRAINERS DO NOT DIAGNOSE OR IDENTIFY ILLNESSES OR CONDITIONS, AND NO STATEMENT FROM SOAR OR ITS TRAINERS SHOULD BE CONSTRUED AS A DIAGNOSIS OR AS CONFIRMATION THAT YOU DO NOT HAVE ANY PARTICULAR MEDICAL CONDITION. IF YOU REQUIRE MEDICAL ADVICE, MEDICAL SERVICES, OR THERAPY, YOU SHOULD CONSULT A MEDICAL PROFESSIONAL DIRECTLY AND NOT THROUGH SOAR.
    </Paragraph>

    <SubTitle>15. INDEMNIFICATION</SubTitle>
    <Paragraph>
      At your sole expense, you will defend, indemnify and hold Soar and its Trainers, as well as ICF, its officers, directors, employees, volunteers, partners and contractors harmless from and against any and all actual or threatened suits, actions, proceedings (at law or in equity), claims, damages, payments, deficiencies, fines, judgments, settlements, liabilities, losses, costs and expenses (including, but not limited to, reasonable attorney fees, costs, penalties, interest and disbursements) caused by, arising out of or resulting from (a) your use of the Services or the use of the Services by a third party accessing your account, including any infringement of rights of third parties, or (b) your breach of these Terms.
    </Paragraph>

    16. LIMITATION OF LIABILITY
    <SubTitle>1. ELIGIBILITY</SubTitle>
    <Paragraph>
      IN NO EVENT WILL SOAR OR ITS TRAINERS OR ICF AND ITS PARTNERS BE LIABLE TO YOU FOR ANY INCIDENTAL, SPECIAL, INDIRECT, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES WHATSOEVER, ARISING OUT OF OR RELATED TO THESE TERMS OR THE SOAR IP, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED, OR EMOTIONAL DISTRESS), PRODUCT LIABILITY, STRICT LIABILITY OR OTHER THEORY), EVEN IF SOAR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the limitations of this section may not apply to you.
    </Paragraph>
    <Paragraph>

      FURTHER, IN NO EVENT WILL SOAR OR ITS TRAINERS’ OR ICF AND ITS PARTNERS’s TOTAL LIABILITY ARISING OUT OF OR RELATED TO THESE TERMS EXCEED THE GREATER OF US$100 OR THE AMOUNT YOU PAID TO SOAR DURING THE SUBSCRIPTION PERIOD WITHIN WHICH THE DAMAGES AROSE.
    </Paragraph>

    <SubTitle>17. ARBITRATION</SubTitle>
    <Paragraph>
      PLEASE READ THE FOLLOWING PROVISIONS CAREFULLY BECAUSE THEY REQUIRE YOU AND SOAR TO ARBITRATE DISPUTES AND LIMITS THE MANNER IN WHICH BOTH PARTIES CAN SEEK RELIEF.
    </Paragraph>
    <Paragraph>

      You and Soar will arbitrate any dispute, claim, or controversy arising from or related to these Terms or the Services, except that you nor Soar is required to arbitrate any dispute in which either party seeks equitable and/or other relief for the alleged unlawful use of copyrights, trademarks, trade names, logos, trade names, or patents. ARBITRATION PREVENTS YOU AND SOAR FROM SUING IN COURT OR FROM HAVING A JURY TRIAL. Arbitration will be conducted confidentially in Provo, Utah, by a single arbitrator in accordance with the rules of JAMS (or its successor). Except as expressly provided in these Terms, the arbitrator has the authority to grant any remedy that would otherwise be available in court. The arbitrator will render a written opinion including findings of fact and law, and the award and determination of the arbitrator will bind the parties and will not be subject to appeal. Judgment may be entered upon the award of the arbitrator in any court of competent jurisdiction. The parties will equally share the expenses of the arbitration unless the arbitrator determines to apportion fees and expenses differently. To the fullest extent permitted by applicable law, WHETHER THE DISPUTE IS HEARD IN ARBITRATION OR IN COURT, NO ARBITRATION OR CLAIM UNDER THESE TERMS WILL BE JOINED TO ANY OTHER ARBITRATION OR CLAIM, INCLUDING ANY ARBITRATION OR CLAIM INVOLVING ANY OTHER CURRENT OR FORMER USER OF THE SERVICES, AND NEITHER YOU NOR SOAR WILL COMMENCE AGAINST THE OTHER A CLASS ACTION, CLASS ARBITRATION, OR OTHER REPRESENTATIVE ACTION OR PROCEEDING.
    </Paragraph>

    <SubTitle>18. MISCELLANEOUS</SubTitle>
    <Paragraph>
      Governing Law and Dispute Resolution. These Terms will be governed by and construed in accordance with the laws of the State of Utah, without resort to its conflict of law provisions. The state and federal courts located in Utah County, Utah, will have exclusive jurisdiction over any suit in connection with the Services or these Terms that is not subject to arbitration, and you and Soar hereby irrevocably and unconditionally consent and submit to the exclusive jurisdiction of such courts. These Terms and your access to or use of the Services will be governed by, construed in accordance with, and enforced under the laws of the State of Utah without resort to its conflict of law provisions. If a party initiates any proceeding regarding these Terms, the prevailing party to such proceeding is entitled to reasonable attorneys’ fees and costs for claims arising out of these Terms.
    </Paragraph>
    <Paragraph>

      Assignment. You may not assign, delegate or transfer these Terms or any right, title, interest or obligation hereunder without the prior written consent of Soar. Any attempted or purported assignment, delegation or transfer in violation of the foregoing will be null and void and without effect. Soar may assign these Terms without your prior written consent. These Terms will be binding and inure to the benefit of such assignee, transferees and other successors in interest of the parties in the event of an assignment or other transfer made consistent with the provisions of these Terms.
    </Paragraph>
    <Paragraph>

      Third Party Beneficiaries. All current and former Trainers are third party beneficiaries of Sections 12-17 hereunder.
    </Paragraph>
    <Paragraph>

      Electronic Communications. You agree to receive electronically all current and future notices, disclosures, communications and information and that the aforementioned electronic communications satisfy any legal requirement that such communications be in writing. An electronic notice will be deemed to have been received on the day of receipt as evidenced by such email.
    </Paragraph>
    <Paragraph>

      Force Majeure. Soar will not be liable for any loss or damage arising from any event beyond its reasonable control, including, but not limited to, flood, extraordinary weather conditions, earthquake, or other act of God, fire, war, insurrection, riot, labor dispute, accident, action of government, communications, power failure, or equipment or software malfunction.
    </Paragraph>
    <Paragraph>

      Headings. Headings of sections are for convenience only and will not be used to limit or construe such sections.
    </Paragraph>
    <Paragraph>

      Severability. If any provision of these Terms is invalid, illegal or incapable of being enforced by any rule of law or public policy, all other provisions of these Terms will nonetheless remain in full force and effect so long as the economic and legal substance of the transactions contemplated by these Terms is not affected in any manner adverse to any party. Upon such determination that any provision is invalid, illegal or incapable of being enforced, the parties will negotiate in good faith to modify these Terms so as to effect the original intent of the parties as closely as possible in an acceptable manner to the end that the transactions contemplated hereby are fulfilled.
    </Paragraph>
    <Paragraph>

      Survival. Notwithstanding any terms to the contrary in these Terms, Sections 7-9 and 11-15 will survive any termination of these Terms.
    </Paragraph>
  </Container>
);

export default withLayout(Terms);
