import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import Icon from '../../../../../../shared/basic/IconFontAnswer';
import { kellyGreen, melon, white } from '../../../../../../styles/variables';
import {
  FormFile,
  FormGroup,
  Label,
  Input,
  FormBody,
  AudioInfo,
  FormHeader,
  ModalTitle,
  Helper,
  Textarea,
  FormFooter,
  SubmitButton,
  CancelButton,
} from './styles'
import Loader from '../../../../../../shared/basic/ContentLoader';
import ErrorMessage from '../../../../../../shared/basic/BoxMessage';
import { titleByContent, uploadType } from '../../../../constants';
import { Keywords, GlobalStyles } from '../../../../../MyLibrary/components/audioForm/styles';
import { AudioRecord } from '../../../../../../redux/records/audio-record';
import './styles.css';

const Form = props => {
  const {
    closeModal,
    fileInfo,
    sendUserFile,
    isSedingFile,
    sendFileError,
    sendFileInfo,
    isFileInfoLoading,
    fileInfoError,
    textToTranscription,
    contentType,
    sourceUrl,
    fetchAudios,
    refreshAudios,
    setNewAudio,
    audio,
  } = props;

  const [formError, setFormError] = useState('');
  const {
    title,
    description,
    source,
    tags,
    voicePhrase,
  } = audio;

  useEffect(() => {
    contentType === uploadType.FILE && sendUserFile();
  }, [sendUserFile]);

  async function submitForm() {
    if (!title) {
      setFormError('The Title field is required.');
      return;
    }
    if (!source) {
      setFormError('The Speaker field is required.');
      return;
    }
    if (!description) {
      setFormError('The Description field is required.');
      return;
    }
    if (!voicePhrase) {
      setFormError('The Quick voice phrase field is required.');
      return;
    }

    const callback = async () => {
      closeModal()
      refreshAudios && await fetchAudios();
    };

    await sendFileInfo(callback);
  };

  function changeValue(field, value) {
    setNewAudio(audio.set(field, value));
  }

  function onChangeTags(tagsChanged, allTags, action) {
    setNewAudio(audio.set('tags', allTags));
  }

  function fileExtractInfo() {
    const { name, size } = fileInfo[0];
    let sizeUnity = 'B';
    let newSize = size

    if (!name) {
      return '';
    }

    if (size > 1024) {
      sizeUnity = 'Kb';
      newSize = (size / 1024).toFixed(1);

      if ((newSize / 1024) > 1024) {
        sizeUnity = 'Mb';
        newSize = (size / 1024).toFixed(1);
      };
    }
    return `${name.substr(0, 30)} - ${newSize}${sizeUnity}`;
  };

  function showTextByType() {
    let content = '';
    switch (contentType) {
      case uploadType.TEXT:
        content = textToTranscription.substr(0, 30);
        break;

      case uploadType.URL:
        content = sourceUrl.substr(0, 30);
        break;

      default:
        content = fileExtractInfo();
        break;
    }

    return content;
  }

  function renderHeader() {
    return (
      <FormHeader>
        <ModalTitle>
          {titleByContent[contentType].title}
          <Icon unicode={'f00d'} color={white} onClick={closeModal} />
        </ModalTitle>
        <AudioInfo>
          <Icon
            unicode={titleByContent[contentType].icon}
            isBrand={contentType === uploadType.URL}
            color={melon}
            className="left"
          />
          {showTextByType()}
          <Icon unicode={'f00c'}  color={kellyGreen} className="right" />
        </AudioInfo>
      </FormHeader>
    );
  };

  return isSedingFile || isFileInfoLoading
    ? <Loader />
    : (
      <FormFile>
        <GlobalStyles />
        {renderHeader()}
        <FormBody>
          {formError ? <ErrorMessage message={formError} /> : null}
          {sendFileError ? <ErrorMessage message={sendFileError} /> : null}
          {fileInfoError ? <ErrorMessage message={fileInfoError} /> : null}
          <FormGroup>
            <Label>Title<span>*</span></Label>
            <Input defaulValue={title} onChange={event => changeValue('title', event.target.value)} />
            <Helper>This will help to identify your content, also to index your audio on our search engine.</Helper>
          </FormGroup>
          <FormGroup>
            <Label>Description<span>*</span></Label>
            <Textarea defaultValue={description} rows="2" onChange={event => changeValue('description', event.target.value)} />
            <Helper>This will help you describing the contents of your audio.</Helper>
          </FormGroup>
          <FormGroup>
            <Label>Speaker<span>*</span></Label>
            <Input defaultValue={source} onChange={event => changeValue('source', event.target.value)} />
            <Helper>Who is the source or author of this content.</Helper>
          </FormGroup>
          <FormGroup>
            <Label>Keywords</Label>
            <Keywords
              tags={tags}
              delimiters={[13, ',']}
              onChange={onChangeTags}
            />
            <Helper>Keywords you help finding your audio on our search engine.</Helper>
          </FormGroup>
          <FormGroup>
            <Label>Quick voice phrase<span>*</span></Label>
            <Input defaultValue={voicePhrase} onChange={event => changeValue('voicePhrase', event.target.value)} />
            <Helper>Enter 2 or 3 words to quickly retrieve this item by saying "GET...." followed by the quick phrase you enter here.</Helper>
          </FormGroup>
        </FormBody>
        <FormFooter>
          <SubmitButton type="button" onClick={submitForm}>Submit</SubmitButton>
          <CancelButton type="button" onClick={closeModal}>Cancel</CancelButton>
        </FormFooter>
      </FormFile>
    );
};

Form.propTypes = {
  refreshAudios: PropTypes.bool.isRequired,
  isFileInfoLoading: PropTypes.bool.isRequired,
  isSedingFile: PropTypes.bool.isRequired,
  contentType: PropTypes.string.isRequired,
  fileInfoError: PropTypes.string.isRequired,
  sendFileError: PropTypes.string.isRequired,
  textToTranscription: PropTypes.string,
  sourceUrl: PropTypes.string,
  audio: PropTypes.instanceOf(AudioRecord).isRequired,
  fileInfo: PropTypes.any,
  closeModal: PropTypes.func.isRequired,
  sendUserFile: PropTypes.func.isRequired,
  sendFileInfo: PropTypes.func.isRequired,
  fetchAudios: PropTypes.func.isRequired,
  setNewAudio: PropTypes.func.isRequired,
}

Form.defaultProps = {
  fileInfo: [{}],
  textToTranscription: '',
  sourceUrl: '',
}

export default Form;
