import { handleActions } from 'redux-actions';
import { Record } from 'immutable';

import AudioRecord from '../records/audio-record';
import {
  setIsLoading,
  setErrorMessage,
  setAudioInfo,
  setLoadionPlaylist,
  setPlaylistData,
} from '../actions/audio-actions';

export const AudioPage = Record({
  isLoading: true,
  isLoadingList: true,
  errorMessage: '',
  audio: new AudioRecord(),
  playlistList: [],
});

export const initialState = new AudioPage();

export default handleActions(
  {
    [setIsLoading]: (state, action) => state.set('isLoading', action.payload),
    [setErrorMessage]: (state, action) => state.set('errorMessage', action.payload),
    [setLoadionPlaylist]: (state, action) => state.set('isLoadingList', action.payload),
    [setPlaylistData]: (state, action) => state.set('playlistList', action.payload),
    [setAudioInfo]: (state, action) => state.set('audio', action.payload),
  },
  initialState
);
