import styled from 'styled-components'

export const BtnShort = styled.div`
  width: ${props => props.disabled && '3.0rem'};
  height: ${props => props.disabled && '3.0rem'};
  border: ${props => props.disabled && 'none'};
  border-radius: ${props => props.disabled && '50%'};
  background-color: ${props => props.disabled && '#fff'};  
  color: ${props => props.color || '#7909ea'};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'} !important;
  opacity: ${props => props.disabled && '0.3'};

  &:hover {
    opacity: ${props => !props.disabled && '0.6'};
  }
`;
