import React from 'react';
import PropTypes from 'prop-types';

import { Time } from './styles';
import { secondFormat } from '../../../../../../../../shared/helper/Time';

const AudioTime = ({ audioSeconds }) => (
  <Time>{secondFormat(audioSeconds)}</Time>
);

AudioTime.propTypes = {
  audioSeconds: PropTypes.string,
};

AudioTime.defaultProps = {
  audioSeconds: 0,
};

export default AudioTime;
