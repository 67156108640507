import { connect } from 'react-redux';
import {
  fetchPlaylistItem,
  setPlaylist,
  updatePlaylistItem,
  fetchPlaylistAudios,
  fetchPlaylistPermissions,
  setDeleteOpts,
} from '../../actions/my-library-actions';
import PlaylistForm from './';

const mapStateToProps = state => ({
  formSubmitSuccess: state.library.formSubmitSuccess,
  playlist: state.library.playlist,
  playlistFormError: state.library.playlistFormError,
  isLoadingPlaylist: state.library.isLoadingPlaylist,
  isLoadingPlaylistPermissions: state.library.isLoadingPlaylistPermissions,
  playlistPermissions: state.library.playlistPermissions,
});

const mapDispatchToProps = {
  fetchPlaylistItem: playlistId => fetchPlaylistItem(playlistId),
  fetchPlaylistAudios: playlistId => fetchPlaylistAudios(playlistId),
  fetchPlaylistPermissions: playlistId => fetchPlaylistPermissions(playlistId),
  setPlaylist: playlist => setPlaylist(playlist),
  updatePlaylistItem: () => updatePlaylistItem(),
  setDeleteOpts: opts => setDeleteOpts(opts),
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistForm);