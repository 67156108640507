import styled from 'styled-components'
import media from 'styled-media-query';
import IconVolume from '../../../../../../shared/icon/Volume';

export const VolumeIcon = styled(IconVolume).attrs(() => ({
  color: '#666b88',
}))`
  min-width: 4.452rem;
  grid-column: 1 / span 2;
  display: flex;
  justify-content: center;  
  font-size: 1.5rem;

  ${media.lessThan("medium")`font-size: 1.3rem;`}
`;
