import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Set from './Form/set';
import Form from './Form';
import Options from './Options';
import { PAY_OPTIONS } from '../../../../constants.js';
import { Title } from '../styles';
import { WrapperOptions, WrapperForms } from './styles';
import { withRouter } from 'react-router-dom';
import {
  fetchCoachPlans,
  fetchCreditCards,
  setCardHolderName,
  setDisabledOptions,
  setSelectedPaymentOption,
} from '../../../../actions/premium-actions';
import { fetchCountries } from '../../../../../../redux/actions/general-actions';
import Footer from './Footer';
import FormGroup from '../../../../../../shared/basic/Form';

const Payment = ({ history }) => {
  const dispatch = useDispatch();

  const authorizedUserStripeId = useSelector(state => state.authorizedUser.stripeId);
  const givenName = useSelector(state => state.authorizedUser.givenName);
  const familyName = useSelector(state => state.authorizedUser.familyName);
  const savedCreditCards = useSelector(state => state.accountSettings.creditCards);
  const successMessage = useSelector(state => state.accountSettings.successMessage);
  const selectedPaymentOption = useSelector(state => state.accountSettings.selectedPaymentOption);
  const disabledOptions = useSelector(state => state.accountSettings.disabledOptions);

  useEffect(() => { dispatch(fetchCreditCards()) }, [dispatch]);
  useEffect(() => { dispatch(fetchCoachPlans()) }, [dispatch]);
  useEffect(() => { dispatch(fetchCountries()) }, [dispatch]);
  useEffect(() => {
    dispatch(setCardHolderName(`${givenName} ${familyName}`.toUpperCase()));
  }, [dispatch, givenName, familyName]);
  useEffect(() => { if (successMessage) history.push('/subscribe/thank-you') },
    [successMessage, history]);
  useEffect(() => {
    if (!savedCreditCards.length && !authorizedUserStripeId) {
      dispatch(setSelectedPaymentOption('newCard'));
      dispatch(setDisabledOptions(['existingCard']));
    }
  }, [dispatch, authorizedUserStripeId, savedCreditCards]);

  return (
    <>
      <Title>PAYMENT</Title>
      <WrapperForms>
        <FormGroup label="Select a payment method:">
          <WrapperOptions>
            {PAY_OPTIONS.map(({ paymentOption: option, paymentForm: form }) => {
              const isDisabled = disabledOptions.some(item => item === option.value);
              return (
                <Options
                  key={option.id}
                  {...option}
                  checked={selectedPaymentOption === option.value}
                  disabled={isDisabled}
                >
                  <Set {...form} />
                </Options>
              );
            })}
          </WrapperOptions>
        </FormGroup>

        <Form />

        <Footer />
      </WrapperForms>
    </>
  );
};

Payment.propTypes = {
  history: PropTypes.object,
};

Payment.defaultProps = {
  history: {},
};

export default withRouter(Payment);
