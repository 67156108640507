import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Box } from './styles';

const DefaultMessage = ({ message }) => <Wrapper><Box>{message}</Box></Wrapper>;

DefaultMessage.propTypes = {
  message: PropTypes.string,
};

DefaultMessage.defaultProps = {
  message: '',
};

export default DefaultMessage;