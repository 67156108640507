import { connect } from 'react-redux';
import Form from './';
import {
  sendUserFile,
  sendFileInfo,
  setNewAudio,
} from '../../../../actions/dashboard-actions';
import { fetchAudios } from '../../../../../MyLibrary/actions/my-library-actions';

const mapStateToProps = state => ({
  fileInfo: state.dashboard.fileInfo,
  isSedingFile: state.dashboard.isSedingFile,
  sendFileError: state.dashboard.sendFileError,
  isFileInfoLoading: state.dashboard.isFileInfoLoading,
  fileInfoError: state.dashboard.fileInfoError,
  textToTranscription: state.dashboard.textToTranscription,
  contentType: state.dashboard.contentType,
  sourceUrl: state.dashboard.sourceUrl,
  audio: state.dashboard.audio,
});

const mapDispatchToProps = {
  sendUserFile: () => sendUserFile(),
  sendFileInfo: callback => sendFileInfo(callback),
  fetchAudios: () => fetchAudios(),
  setNewAudio: audio => setNewAudio(audio),
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);