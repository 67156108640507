export const PricingContent = {
  freePlan: {
    monthly: {
      id: '0',
      name: '',
      title: 'Soar Audio Starter ($0)',
      description: 'totally free',
      price: null,
      priceTotal: '',
      priceCurrency: '',
    },
    annually: {
      id: '0',
      name: '',
      title: 'Soar Audio Starter ($0)',
      description: 'totally free',
      price: null,
      priceTotal: '',
      priceCurrency: '',
    },
  },
  premiumPlan: {
    monthly: {
      id: process.env.REACT_APP_PLAN_AUDIO_PLUS_MONTLY,
      title: 'Soar Audio for TEAMS ($99)',
      description: 'per month',
      name: 'Soar Audio for TEAMS',
      price: process.env.REACT_APP_PLAN_AUDIO_PLUS_MONTLY_PRICE,
      priceTotal: process.env.REACT_APP_PLAN_AUDIO_PLUS_MONTLY_PRICE,
      priceCurrency: 'USD',
    },
    annually: {
      id: process.env.REACT_APP_PLAN_AUDIO_PLUS_ANUAL,
      name: 'Soar Audio for TEAMS',
      title: 'Soar Audio for TEAMS ($399)',
      description: '66% savings for Annual Plan',
      price: process.env.REACT_APP_PLAN_AUDIO_PLUS_ANUAL_PRICE,
      priceTotal: process.env.REACT_APP_PLAN_AUDIO_PLUS_ANUAL_PRICE,
      priceCurrency: 'USD',
    },
  },
  pricingTable: {
    blocks: [
      {
        title: "Listener Benefits",
        items: [
          {
            Text: "NEW! - Automatically access your Zoom recordings",
            Free: "Yes (individual only)",
            Premium: "Yes (individual or entire team)",
          },
          {
            Text: "Upload your content (audio, video, text) and stream on any device",
            Free: true,
            Premium: true,
          },
          {
            Text: "Get searchable transcripts of your content",
            Free: "Yes, limits apply",
            Premium: true,
          },
          {
            Text: "Create Playlists",
            Free: true,
            Premium: true,
          },
          {
            Text: "Mobile App Access (iOS, Android)",
            Free: true,
            Premium: true,
          },
          {
            Text: "Voice Assistant Access (Alexa, Google)",
            Free: true,
            Premium: true,
          },
        ]
      },
      {
        title: "Sharing Your Content",
        items: [
          {
            Text: "Publicly",
            Free: true,
            Premium: true,
          },
          {
            Text: "Private (1:1)",
            Free: false,
            Premium: true,
          },
          {
            Text: "Create Private Groups",
            Free: false,
            Premium: "Unlimited Groups, Unlimited Size",
          },
          {
            Text: "Share Privately to Groups",
            Free: "By invite only",
            Premium: "Unlimited",
          }
        ]
      },
      {
        title: "Storage Limits",
        items: [
          {
            Text: "Uploads Included",
            Free: "100 Items per month",
            Premium: "10,000 Items per month",
          },
        ]
      },
      {
        title: "Data Limits",
        items: [
          {
            Text: "Storage",
            Free: "1GB",
            Premium: "100GB",
          },
          {
            Text: "Content Uploading",
            Free: "Yes, limits apply",
            Premium: "Unlimited",
          },
          {
            Text: "*Zoom automatic uploads do not count against upload limits",
            Free: "",
            Premium: "",
          },
        ]
      },
      {
        title: "Access to Others' Content",
        items: [
          {
            Text: "Public Content",
            Free: true,
            Premium: true,
          },
          {
            Text: "Private (personal) Content",
            Free: "Only by Invite",
            Premium: true,
          },
          {
            Text: "Private Group Content",
            Free: "Only by Invite",
            Premium: true,
          },
        ]
      },
    ]
  }
};
