import React from 'react';

const Loader = () => (
  <div className="css3-spinner">
    <div className="css3-spinner-bounce1" />
    <div className="css3-spinner-bounce2" />
    <div className="css3-spinner-bounce3" />
  </div>
);

export default Loader;
