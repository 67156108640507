import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../dashboard/components/upload/components/modal';
import {
  Close,
  Header,
  Content,
  Footer,
  Input,
  TextArea,
} from './styles';
import DefaultButton from '../../../../shared/basic/DefaultButton';
import FormGroup from '../../../../shared/basic/FormGroup';
import { Keywords, GlobalStyles } from '../audioForm/styles';
import { helperText } from '../../constants';
import { PlaylistRecord } from '../../../../redux/records/playlist-record';
import ContentLoader from '../../../../shared/basic/ContentLoader';
import BoxMessage from '../../../../shared/basic/BoxMessage';

const PlaylistForm = props => {
  const {
    showModal,
    isCreating,
    closeForm,
    playlistErrorMessage,
    newPlaylist,
    closeModal,
    pushNewPlaylist,
    setNewPlaylist,
  } = props;
  const { title, description } = newPlaylist;
  // const [selected] = useState(["ACA", "speech"]);

  // function onChangeTags(tagsChanged, allTags, action) {
  //   console.log('onChangeTags', tagsChanged, allTags, action);
  // }
  useEffect(() => {
    if (closeForm) {
      closeModal();
    }
  }, [closeForm]);

  function changeValue(field, value) {
    setNewPlaylist(newPlaylist.set(field, value));
  }

  async function handlerSubmit() {
    await pushNewPlaylist();
  }


  return (
    <Modal showModal={showModal}>
      <GlobalStyles />
      <Header>
        CREATE NEW PLAYLIST
        <Close unicode="f00d" onClick={closeModal} />
      </Header>
      <Content>
        {isCreating ? <ContentLoader /> : (
          <>
            {playlistErrorMessage && <BoxMessage message={playlistErrorMessage} />}
            <FormGroup title="Playlist name">
              <Input defaultValue={title} onChange={event => changeValue('title', event.target.value)} />
            </FormGroup>
            <FormGroup title="Description">
              <TextArea row="2" defaultValue={description} onChange={event => changeValue('description', event.target.value)} />
            </FormGroup>
            {/* <FormGroup title="Keywords" helper={helperText.keywords}>
              <Keywords tags={selected} delimiters={[13, ',']} onChange={onChangeTags} />
            </FormGroup> */}
          </>
        )}
      </Content>
      {!isCreating && (
        <Footer>
          <DefaultButton click={() => handlerSubmit()}>CREATE PLAYLIST</DefaultButton>
          <DefaultButton onClick={closeModal} inverted>CANCEL</DefaultButton>
        </Footer>
      )}
    </Modal>
  );
};

PlaylistForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  isCreating: PropTypes.bool.isRequired,
  closeForm: PropTypes.bool.isRequired,
  playlistErrorMessage: PropTypes.string.isRequired,
  newPlaylist: PropTypes.instanceOf(PlaylistRecord).isRequired,
  closeModal: PropTypes.func.isRequired,
  pushNewPlaylist: PropTypes.func.isRequired,
  setNewPlaylist: PropTypes.func.isRequired,
}

export default PlaylistForm;
