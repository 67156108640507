import styled from 'styled-components';

export const FontAwesomeIcon = styled.div`
  line-height: 1;
  &::after {
    font-weight: ${props => props.iconSolid && 900};
    color: ${props => props.color || '#a0a6cc'};
    font-family: '${props => props.isBrand ? 'Font Awesome 5 Brands' : 'Font Awesome 5 Pro'}';
    content: ${props => `'\\${props.unicode}'`};
  }
`;
