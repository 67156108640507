import React, { memo } from 'react';
import PropTypes from 'prop-types';

import CopyField from '..';
import IconLink from '../../../../icon/IconLink';

const CopyGoogle = ({ url }) => (
  <CopyField url={url} service="soar">
    <IconLink fontSize="1.6rem" color="#a0a6cc" />
  </CopyField>
);


CopyGoogle.propTypes = {
  url: PropTypes.string,
};

CopyGoogle.defaultProps = {
  url: '',
};

export default memo(CopyGoogle);
