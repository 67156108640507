import React from 'react';
import PropTypes from 'prop-types';

import LoaderBubble from '../../../../../../shared/basic/LoaderBubble';
import { PLAYLIST_LIST_ITEMS_PER_PAGE as ITEMS_PER_PAGE } from '../../../constants/page';
import { Wrapper } from './styles'

const Loading = ({ isList, ...props }) => (
  <Wrapper>
    <LoaderBubble />
    {isList
      ? Array.from({ length: ITEMS_PER_PAGE }).map((_, index) => <props.component key={index} isLoading />)
      : <props.component isLoading />
    }
  </Wrapper>
);

Loading.propTypes = {
  component: PropTypes.any,
  isList: PropTypes.bool,
};

Loading.defaultProps = {
  component: <div />,
  isList: false,
};


export default Loading;