import { Record } from 'immutable';
import { DateTime } from 'luxon';

export const GroupRecord = Record({
  id: '',
  description: '',
  title: '',
  dateCreated: '',
  ownerUserId: '',
  coachUserId: '',
  sharing: '',
  memberCount: '',
});

GroupRecord.parse = groupObject => {
  const {
    id,
    description,
    title,
    dateCreated: date,
    ownerUserId,
    coachUserId,
    sharing,
    memberCount,
  } = groupObject;

  const dateCreated = DateTime.fromISO(date);

  return new GroupRecord({
    id,
    description,
    title,
    dateCreated,
    ownerUserId,
    coachUserId,
    sharing,
    memberCount,
  });
};
