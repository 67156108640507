import React from 'react';
import PropTypes from 'prop-types'
import ReactModal from 'react-modal';

import { customStyles } from './styles';

const Modal = ({ showModal, children }) => (
  <ReactModal 
    isOpen={showModal}
    style={customStyles}
    ariaHideApp={false}
  >
    {children}
  </ReactModal>
);

Modal.propTypes = {
  children: PropTypes.any,
  showModal: PropTypes.bool.isRequired,
};

Modal.defaultProps = {
  children: null,
}

export default Modal;
