import React from "react";
import PropTypes from 'prop-types';

import { Div } from './styles';

const Title = ({ text, ...props }) => <Div {...props}>{text}</Div>;

Title.propTypes = {
  text: PropTypes.string,
};

Title.defaultProps = {
  text: '',
};

export default Title;
