import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Wrapper, Flags, Img } from './styles';
import { setSelectedPaymentOption, setCvcElement } from '../../../../../actions/premium-actions';
import Radio2 from '../../../../../../../shared/basic/InputRadio';

const Options = ({ id, name, value, checked, title, disabled, flags, children }) => {
  const dispatch = useDispatch();

  const changePaymentOption = event => {
    dispatch(setSelectedPaymentOption(event.target.value));
    dispatch(setCvcElement(false));
  };

  return (
    <Wrapper disabled={disabled} checked={checked}>
      <Radio2
        id={id}
        checked={checked}
        name={name}
        value={value}
        onChange={changePaymentOption}
        disabled={disabled}
        label={title}
      />
      {flags && (
        <Flags nItems={flags.length}>
          {flags.map(({ src }) => (<Img src={src} key={src} alt="" />))}
        </Flags>
      )}
      {checked && children}
    </Wrapper>
  );
};

Options.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.element.isRequired,
  disabled: PropTypes.bool,
  flags: PropTypes.array,
};

Options.defaultProps = {
  id: null,
  name: null,
  value: null,
  checked: false,
  title: null,
  disabled: false,
  flags: [],
};

export default Options;
