import React from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import Form from './Form';
import { Wrapper, Title } from './styles';
import { useSelector } from 'react-redux';

const stripeKey = process.env.REACT_APP_STRIPE_API_KEY;
const stripePromise = loadStripe(stripeKey);

const PaymentForm = () => {
  const { id: planId } = useSelector(state => state.pricePlans.planObj);
  return planId && (
    <Wrapper id="form-subscription">
      <Title>Payment Information</Title>
      <Elements stripe={stripePromise}><Form /></Elements>
    </Wrapper>
  );
};

export default PaymentForm;
