import { DateTime } from 'luxon';
import styled from 'styled-components';
import Icon from '../../../../../../../../shared/basic/IconFontAnswer';
import { secondFormat } from '../../../../../../../../shared/helper/Time';
import { melon } from '../../../../../../../../styles/variables';


export const Row = styled.tr``;

export const Title = styled.td`
  display: flex;
`;

export const GripIcon = styled(Icon).attrs(() => ({
  unicode: 'f58e',
  iconSolid: true,
}))`
  :after {
    margin-right: 1.6rem;
    font-size: 1.5rem;
    color: #999999;
    cursor: pointer;
  }
`;

export const Duration = styled.td.attrs(props => ({
  children: secondFormat(props.children)
}))``;

export const Date = styled.td.attrs(props => ({
  children: DateTime.fromISO(props.children).toISODate()
}))``;

export const Action = styled.td`
  display: flex;
`;

export const CommonIcon = styled(Icon)`
  :after {
    margin-right: 1.5rem;
    color: ${melon};
    cursor: pointer;
  }
`;

export const EyeIcon = styled(CommonIcon).attrs(() => ({
  unicode: 'f06e',
}))``;

export const PencilIcon = styled(CommonIcon).attrs(() => ({
  unicode: 'f044',
}))``;

export const TrashIcon = styled(CommonIcon).attrs(() => ({
  unicode: 'f2ed',
}))``;
