import styled from 'styled-components';
import { dashboard } from '../../../../assets';
import { black, slateGrey, neonBlue, melon, white } from '../../../../styles/variables';
import { pixelRatio } from '../../../../shared/helper/screen';
import { FontAwesomeIcon } from '../../../../shared/basic/IconFontAnswer/styles';

export const SpacedDiv = styled.div`
  margin: 0 2rem;
`;

export const Div = styled.div`
  display: block;
  padding: 2rem 0;
`;

export const About = styled.div`
  display: flex;
  justify-content: center;
`;

export const PlanDescrition = styled.div`
  display: block;
`;

export const Cover = styled.img.attrs(() => {
  const ratio = pixelRatio();
  let img = dashboard.premiumCover;
  if (ratio == '@2x') {
    img = dashboard.premiumCover2x;
  } else if (ratio == '@3x') {
    img = dashboard.premiumCover3x;
  }

  return ({
    src: img,
  });
})`
  margin: 1.9rem 0;
  height: auto;
  width: 100%;
`;

export const Gem = styled(FontAwesomeIcon)`
  margin: 0 18px;
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  &:after {
    color: ${neonBlue};
  }
`;

export const PlanTitle = styled.div`
  margin: 0 0 5px 0;
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

export const PlanSubTitle = styled.div`
  margin: 5px 0 10px 0;
  font-family: Work Sans;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${slateGrey};
`;

export const PlanPrice = styled.div`
  margin: 20px 0px 0 0;
  font-family: Work Sans;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  & span {
    font-size: 18px;
  }
`;

export const SubText = styled.div`
  margin: .9rem 0 0 2rem;
  font-family: Work Sans;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${slateGrey};
`;

export const Title = styled(SpacedDiv)`
  margin-left: 2rem;
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.34px;
  color: ${black};
`;

export const Button = styled.button`
  margin: 12px 0 0 0;
  width: 100%;
  padding: 13px 141px 12px 143px;
  border-radius: 5px;
  background-color: ${melon};
  font-family: Work Sans;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.32px;
  text-align: center;
  border: unset;
  color: ${white};
`;