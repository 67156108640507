import React from 'react';
import { FormGroup, Label } from './styles';

const FormInput = ({ children, label, inputId, marginBottom }) => (
  <FormGroup marginBottom={marginBottom}>
    {label ? (<Label htmlFor={inputId}>{label}</Label>) : null}
    {children}
  </FormGroup>
);

export default FormInput;