import styled from 'styled-components'
import { white, black, melon } from '../../styles/variables'

const mobile = 'screen and (max-width: 1000px)';

export const Tile = styled.div`
  text-align: center;
  width: 80%;
  padding: 0 16px;
  min-height: 160px;
  padding: 20px 5%;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  background-color: ${white};
  margin: 30px auto 0px auto;

  a {
    text-decoration: none;
    color: ${black}
  }

  @media ${mobile} {
    margin: 2rem;
  }
`;

export const Price = styled.div`
  padding: 10px;
  font-size: 32px;
  color: ${black};
`;

export const Button = styled.div`
  width: 50%;
  font-size: 2.4rem;
  padding: 10px;
  border-radius: 25px;
  margin: 10px auto;
  color: ${white};
  background-color: ${melon};
`;

export const Tiles = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;

  @media ${mobile} {
    grid-template-columns: auto;
  }
`;
