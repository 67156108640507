import React from 'react';
import PropTypes from 'prop-types';
import { Item, Div, Img, Title, Content } from './styles';

const ItemList = ({ src, content, title }) => (
  <Item>
    <Div>
      <Img src={src} />
    </Div>
    <Div className="align-items-center">
      <Title>
        {title}
      </Title>
      <Content>
        {content}
      </Content>
    </Div>
  </Item>
);

ItemList.propTypes = {
  src: PropTypes.any.isRequired,
  content: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ItemList;
