import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchUserData } from '../../../../redux/actions/authorized-user-actions';
import Callback from './';

const mapDispatchToProps = {
  fetchUserData: userId => fetchUserData(userId),
};

export default withRouter(connect(null, mapDispatchToProps)(Callback));
