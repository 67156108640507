import React from 'react';
import PropTypes from 'prop-types';

import { Title } from './styles';
import { goTo } from '../../../../../../../../shared/helper/goTo';

const PlaylistTitle = ({ title, id }) => (
  <Title onClick={() => goTo(`/playlist/detail/${id}`)}>{title}</Title>
);

PlaylistTitle.propTypes = {
  id: PropTypes.any,
  title: PropTypes.string,
};

PlaylistTitle.defaultProps = {
  id: null,
  title: '',
};

export default PlaylistTitle;
