import React from "react";

import { Icon } from "./styles";

const IconAlexa = props => (
  <Icon viewBox='0 0 11261.99 11309.22' {...props}>
    <path fill="currentColor" d="M5607.5 0c-3104.37,25.35 -5607.5,2549.88 -5607.5,5654.29 0,2808.94 2062.71,5192.65 4841.97,5595.8l0 -1148.57c0,-323.95 -206.15,-618.46 -500.66,-706.81 -1600.24,-543.48 -2677.53,-2046.66 -2677.53,-3736.79 0,-2179.13 1767.21,-3946.35 3946.35,-3946.35 2179.14,0 3946.36,1767.21 3946.36,3946.35 0,47.7 -0.96,96.36 -2.41,143.58l0 29.45c-0.07,24.18 0.44,49.52 0.44,73.86 0,24.33 -0.51,49.68 -0.51,73.51l0 29.45c-0.33,13.94 0.12,29.79 0.12,44.29 0,14.5 -0.45,30.35 -0.45,44.4 0.17,18.96 0.64,39.05 0.64,58.68 0,19.63 -0.47,39.73 -0.47,58.89l0 58.9c-530.09,3180.66 -4682.6,4977.14 -4712.06,4977.14 252.98,36.13 509.67,57.06 765.66,59.15 3122.41,-0.26 5654.53,-2532.38 5654.53,-5654.74 0,-3122.36 -2532.12,-5654.48 -5654.48,-5654.48z" />
  </Icon>
);

export default IconAlexa;