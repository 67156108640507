import React from 'react';
import PropTypes from 'prop-types';
import { Title } from './styles';

const AudioTitle = ({ title }) => (
  <Title>{title}</Title>
);

AudioTitle.propTypes = {
  id: PropTypes.any,
  title: PropTypes.string,
};

AudioTitle.defaultProps = {
  id: null,
  title: '',
};

export default AudioTitle;
