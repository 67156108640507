import styled from 'styled-components';
import { slateGrey } from '../../../../../../styles/variables';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 9.8rem;
`;

export const WrapperItems = styled.div`
  padding: 0;
  margin: 0 0 2rem 0;
`;

export const WrapperTitle = styled.div`
  margin: 0 0 27px 0;
  font-family: Work Sans;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.08px;
  color: ${slateGrey};
`;