import styled from 'styled-components';
import { white, black, melon, lightBlueGrey } from '../../../styles/variables';

export const TagInput = styled.div`
  padding: .8rem .7rem;
  border-radius: .5rem;
  border: solid 1px ${lightBlueGrey};
  background-color: ${white};
  font-size: 1.1rem;
  font-family: Work Sans;
`;

export const Tag = styled.div`
  display: inline-block;
  margin-right: .5rem;
  position: relative;
  border: 0;
  box-sizing: content-box;
  color: ${white};
  background-color: ${melon};
  min-width: 1em;
  border-radius: 4px;
  padding: .5rem 1rem;
  line-height: 1.8;
`;
