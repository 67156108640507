import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactTooltip from 'react-tooltip';

import { Wrapper, CopyContainer, Link, Tooltip } from './styles';
import IconCopy from '../../../icon/Copy';

const CopyField = ({ url, children }) => {
  const copyRef = useRef(null);
  const onCopy = () => ReactTooltip.show(copyRef.current);

  return (
    <Wrapper>
      {children}
      <CopyToClipboard text={url} onCopy={onCopy} >
        <CopyContainer>
          <Link ref={copyRef}>{url}</Link>
          <IconCopy fontSize="1.4rem" color="#ff7654" />
        </CopyContainer>
      </CopyToClipboard>
      <Tooltip />
    </Wrapper>
  );
};

CopyField.propTypes = {
  url: PropTypes.string,
};

CopyField.defaultProps = {
  id: null,
  service: 'soar',
};

export default memo(CopyField);
