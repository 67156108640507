import { createAction } from 'redux-actions';
import { sortAudios } from '../../shared/helpers/sortAudios';
import AuthorRecord from '../records/author-record';
import { setCurrentyPlaylist, setErrorMessage, setIsLoading } from './library-actions';

const apiHost = process.env.REACT_APP_UPLIFT_API_HOST;

export const setPlaylistItems = createAction('LIBRARY/SET_PLAYLIST_ITEMS', value => value);

export const setPlaylistItemIds = createAction('LIBRARY/SET_PLAYLIST_ITEM_IDS', value => value);

export const setOwnerUserObj = createAction('LIBRARY/SET_PLAYLIST_OWNER', value => AuthorRecord.parse(value));

export const fetchCurrentPlaylist = id => async dispatch => {
  dispatch(setIsLoading(true));
  let playlist = { id: null, ownerUserId: null };

  const params = ['expandAll=true'];
  try {
    await fetch(`${apiHost}SoarUpliftPlaylist/public/${id}`)
      .then(res => res.ok ? res.json() : dispatch(setErrorMessage('error')))
      .then( res => { playlist = {...res}; dispatch(setCurrentyPlaylist(res)); });

    await fetch(`${apiHost}user/${playlist.ownerUserId}`)
      .then(res => res.ok ? res.json() : dispatch(setErrorMessage('error')))
      .then(res => dispatch(setOwnerUserObj(res)));
  
    await fetch(`${apiHost}SoarUpliftPlaylist/items/public/${playlist.id}?${params.join('&')}`)
      .then(res => res.ok ? res.json() : dispatch(setErrorMessage('error')))
      .then(res => {
        dispatch(setPlaylistItems(sortAudios(res.itemIds, res.items)));
        dispatch(setPlaylistItemIds(res.itemIds))
      });
    
  } catch(error) {
    dispatch(setErrorMessage(error))
  } finally {
    dispatch(setIsLoading(false));
  }
};
