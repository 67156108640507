import styled from 'styled-components'

export const Bubbles = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: white;
  opacity: 0.9;
  -webkit-perspective: 1000;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

  div:first-child {
    margin-left: -31px;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  div:nth-child(2) {
    margin-left: -9px;
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-top: -9px;
    margin-left: 13px;
    background-color: gray;
    border-radius: 100%;
    -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
    animation: bouncedelay 1.4s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @-webkit-keyframes bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }

    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;
