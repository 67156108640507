import * as palette from "../variables";

export const light = {
  sectionBgColorPrimary: palette.PELE_GREY,
  containerBgColorPrimary: 'transparent',

  primary: palette.primary,
  primaryDark: palette.primaryDark,
  secondary: palette.secondary,
  secondaryTones: palette.secondaryTones,
  econdaryTones: palette.secondary,
  tertiary: palette.tertiary,
  font: palette.font,
  background: palette.background,
  white: palette.white,
  black: palette.black,
  danger: palette.danger,
};
