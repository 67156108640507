import React, { useCallback, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';
import cookies from 'browser-cookies';

import auth0Client, { getSoarId } from './shared/helper/auth0';
import { refreshToken } from './shared/helper/account';
import * as theme from './styles/themes';
import GlobalStyle from './styles/global';
import Sign from './shared/sign';
import Home from './components/home';
import PrivacyPolicy from './components/PrivacyPolicy';
import Terms from './components/Terms/index';
import Contact from './components/Contact';
import Subscriptions from './components/Subscriptions';
import { AudioContainer } from './components/PublicLibrary/components/Audio/Detail';
import AudioList from './components/PublicLibrary/components/Audio/List';
import PlaylistList from './components/PublicLibrary/components/Playlist/List';
import PlaylistDetail from './components/PublicLibrary/components/Playlist/Detail';
import Premium from './components/premium';
import Dashboard from './components/dashboard';
import { CallbackContainer } from './shared/sign/components/callback';
import SecureRoute from './shared/basic/SecureRoute';
import { checkParameter, validateRoute } from './shared/helper/general';
import Pricing from './components/Pricing';
import { LibraryContainer } from './components/MyLibrary';
import { AudioFormContainer } from './components/MyLibrary/components/audioForm';
import { PlaylistFormDetailContainer } from './components/MyLibrary/components/playlistDetailForm';
import { GroupFormDetailContainer } from './components/MyLibrary/components/groupDetailForm';
import ZoomIntegrationAdmin from './components/Tutorial/ZoomIntegrationAdmin';
import ZoomIntegrationIndividual from './components/Tutorial/ZoomIntegrationIndividual';

const zoomIndividualAppUrl = process.env.REACT_APP_ZOOM_INDV_APP;
const zoomAdminAppUrl = process.env.REACT_APP_ZOOM_ADMIN_APP;

const App = ({ fetchUserData, unsetIsLoading }) => {
  const handleStatusChange = useCallback(async () => {
    await auth0Client.setProfile(true);
    const userAuth0 = auth0Client.getProfile();
    if (userAuth0 || sessionStorage.getItem('SoarID')) {
      const userId = getSoarId(userAuth0);
      if (userId) {
        await fetchUserData(userId);
      } else {
        await fetchUserData(sessionStorage.getItem('SoarID'));
      }
    }
    unsetIsLoading();
  }, [fetchUserData, unsetIsLoading]);

  useEffect(() => {
    const tryRefreshingToken = async () => {
      if (!validateRoute()) {
        unsetIsLoading();
        return;
      }
      const refreshTokenSuccess = await refreshToken(!!cookies.get('socialMedia'));
      if (refreshTokenSuccess) {
        handleStatusChange();
      } else {
        await auth0Client.signOut();
        unsetIsLoading();
      }
    };

    if (sessionStorage.getItem('token')) {
      handleStatusChange();
    } else {
      tryRefreshingToken();
    }
  }, [handleStatusChange, unsetIsLoading]);

  return (
    <ThemeProvider theme={theme.light}>
      <>
        <GlobalStyle />
        <Router>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/new" exact component={Home} />
            <Route path="/adminzoomintegration" exact component={ZoomIntegrationAdmin} />
            <Route path="/individualzoomintegration" exact component={ZoomIntegrationIndividual} />
            <Route path="/pricing" exact component={Pricing} />
            <Route path="/zoom-mkp" exact component={() => {
              const state = checkParameter('state');

              if (!state) {
                return null;
              }

              window.location.href = `${zoomIndividualAppUrl}&state=${state}`;
              return null;
            }} />
            <Route path="/zoom-mkp-teams" exact component={() => { 
              const state = checkParameter('state');

              if (!state) {
                return null;
              }

              window.location.href = `${zoomAdminAppUrl}&state=${state}`;
              return null;
            }} />
            <Route path="/privacy-policy" exact component={PrivacyPolicy} />
            <Route path="/terms" exact component={Terms} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/auth/callback" exact component={CallbackContainer} />
            <Route path="/signup" exact component={Sign} />
            <Route path="/subscriptions" extact component={Subscriptions} />
            <SecureRoute path="/subscribe" component={Premium} />
            <SecureRoute path="/dashboard" exact component={Dashboard} />
            <SecureRoute path="/my-library" exact component={LibraryContainer} />
            <SecureRoute path="/audio/view/:id" exact component={AudioFormContainer} />
            <SecureRoute path="/audio/edit/:id" exact component={AudioFormContainer} />
            <SecureRoute path="/playlist/view/:id" exact component={PlaylistFormDetailContainer} />
            <SecureRoute path="/playlist/edit/:id" exact component={PlaylistFormDetailContainer} />
            <SecureRoute path="/group/edit/:id" exact component={GroupFormDetailContainer} />
            <Route path="/playlist/list" exact component={PlaylistList} />
            <Route path="/audios/list" exact component={AudioList} />
            <Route path="/playlist/detail/:playlistId" exact component={PlaylistDetail} />
            <Route path="/audio/:id" exact component={AudioContainer} />
            <Redirect to="/" />
          </Switch>
        </Router>
      </>
    </ThemeProvider>
  );
};

App.propTypes = {
  fetchUserData: PropTypes.func.isRequired,
  unsetIsLoading: PropTypes.func.isRequired,
};

export default App;
