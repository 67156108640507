import styled from 'styled-components'
import media from 'styled-media-query';
import { Button } from '../../shared/basic/DefaultButton/styles';
import { Box } from '../../shared/basic/BoxMessage/styles';
import { black, lightBlueGrey, melon, white } from '../../styles/variables';
import {
  Tab as OriginalTab,
  TabList as OriginalTabList,
} from 'react-tabs';

export const ZoomLinks = styled.div `
  font-size: 14px;
  margin: 4px;
  display: block;
  a, span {
    color: ${melon};
    text-decoration: none;
    font-weight: bold;
  }
`;

export const ContentContainer = styled.div `
  padding: 2.9rem;
  width: 100%;
  display: flex;
  border-radius: 5px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  background-color: ${white};
  margin-top: 14px;
  display: block;
`;

export const CreateButton = styled(Button)`
  display: flex;
  margin: auto 0;
  right: 0;
  position: absolute;

  div::after {
    margin-right: 1rem;
    color: ${white}
  }

  ${media.lessThan('medium')`
    right: unset;
  `}
`;

export const Container = styled.div`
  font-family: Work Sans, sans-serif;
  display: flex;
  justify-content: center;
`;

export const Section = styled.div`
  width: 79.16vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 2.9rem 0 12.2rem;

  ${media.lessThan('medium')`
    width: 100vw;
    padding: 2.9rem 0 1rem;
  `}
`;

export const TabList = styled(OriginalTabList)`
  display: flex;
  border-bottom: solid 1px ${lightBlueGrey};
  padding: 0;
  height: 5rem;
  position: relative;
`;

export const Tab = styled(OriginalTab)`
  padding: 1.5rem 3.5rem;
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.34px;
  color: ${black};
  list-style-type: none;
  cursor: default;

  &:hover, &.react-tabs__tab--selected {
    border-bottom: solid 5px ${melon};
  }

  ${media.lessThan("small")`
  align-items: center;
  padding: 1.5rem 1rem;
  `}
`;

export const DivPlanRequired = styled(Box)`
  padding: 2rem 3rem;
  margin-top: 3rem;
  font-family: Work Sans;
  font-size: 18px;

  a {
    text-decoration: none;
    color: ${black};
    font-weight: 500;
  }
`;

export const ScrollDiv = styled.div`
  overflow-x: auto;
`;

export const WrapperPlayer = styled.div`
  opacity: ${props => props.opacity};

  display: flex;
  flex-direction: column;
  padding: 2rem 0 0 0;
  align-items: center;
  
  ${media.lessThan("small")`
    align-items: center;
    padding: .5rem 0;
  `}
  
`;

export const HighlighterPlayer = styled.div`
  font-size: 1.3rem;
  font-weight: 500;
  color: #5c5c5e;
  margin-bottom: 1rem;  
`;

export const TitlePlayer = styled.div`
  min-height: 2.4rem;
  font-size: 2rem;
  font-weight: 600;
  color: #000000;
  margin-bottom: 4.7rem;

  ${media.lessThan("small")`
    margin-bottom: 2.9rem;
    font-size: 1.6rem;
  `}
`;
