import React from 'react';
import auth0Client from '../../../../../shared/helper/auth0';

import { goTo } from '../../../../../shared/helper/goTo';
import Container from '../../../../../shared/layout/Container';
import Section from '../../../../../shared/layout/Section';

import ImgAppGoogleButton from '../../../../../assets/images/app-buttons/google-play-orig.png';
import ImgAppAppleButton from '../../../../../assets/images/app-buttons/apple-store-orig.png';

import { 
  Wrapper,
} from './styles';

const AppStoreLinks = () => {
  const isAuthenticated = auth0Client.isAuthenticated();
  return !isAuthenticated && (

    <Section>
      <Container paddingTop="1rem">
        <Wrapper>
          <div>
                <a href="https://apps.apple.com/us/app/soar-audio/id1502457902"
                  alt="Soar Uplift Google Play">
                  <img src={ImgAppAppleButton} alt="Soar Uplift Apple Store" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.soar.soarlibrary"
                  alt="Soar Uplift Google Play">
                  <img src={ImgAppGoogleButton} alt="Soar Uplift Google Play" />
                </a>
              </div>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default AppStoreLinks;