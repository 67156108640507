import { createAction } from 'redux-actions';
import AudioRecord from '../records/audio-record';
import PlaylistRecord from '../records/playlist-record';

const apiHost = process.env.REACT_APP_UPLIFT_API_HOST;

export const setIsLoading = createAction('LIBRARY/SET_IS_LOADING', value => value);

export const setSuccessMessage = createAction('LIBRARY/SET_SUCCESS_MESSAGE', value => value);

export const setErrorMessage = createAction('LIBRARY/SET_ERROR_MESSAGE', value => value);

export const setCurrentyAudio = createAction('LIBRARY/SET_PLAYLIST_CURRENTY_AUDIO', value => AudioRecord.parse(value));

export const setCurrentyPlaylist = createAction('LIBRARY/SET_PLAYLIST_CURRENTY_PLAYLIST', value => PlaylistRecord.parse(value));

export const setPlaylists = createAction('LIBRARY/SET_PLAYLISTS', value => value);

export const setItemsPlaylists = createAction('LIBRARY/SET_ITEMS_PLAYLISTS', value => value);

export const fetchPlaylistList = () => async (dispatch, getState) => 
  new Promise((res, rej) => {
    const { publicLibrary: { listPlaylists: {
      pageNum,
      itemsPerPage,
      sort,
      items
    }},
    publicLibrary: { listPlaylists }
    } = getState();

    if (pageNum > 1) dispatch(setPlaylists(listPlaylists.set('isLoadingLoadMore', true)));
    else dispatch(setIsLoading(true));

    const params = [`pageNum=${pageNum}`, `itemsPerPage=${itemsPerPage}`, `sort=${sort}`];

    fetch(`${apiHost}SoarUpliftPlaylist/list/public?${params.join('&')}`)
      .then(res => res.json())
      .then(
        response => {          
          dispatch(setPlaylists(listPlaylists
            .set('items', items.concat(response.items))
            .set('totalCount', response.totalCount)
            .set('returnedCount', response.returnedCount)
          ));
          dispatch(setIsLoading(false));
          res();
        },
        error => {
          dispatch(setErrorMessage(error));
          dispatch(setIsLoading(false));
          dispatch(setPlaylists(listPlaylists.set('isLoadingLoadMore', false)));
          rej();
        }
      );
  });

export const setAudios = createAction('LIBRARY/SET_AUDIOS', value => value);

export const setItemsAudios = createAction('LIBRARY/SET_ITEMS_AUDIOS', value => value);

export const fetchAudioList = () => async (dispatch, getState) => 
  new Promise((res, rej) => {
    const {
      publicLibrary: {
        listAudios: {
          pageNum,
          itemsPerPage,
          sort,
          items
        }
      },
      publicLibrary: { listAudios }
    } = getState();

    if (pageNum > 1) {
      dispatch(setAudios(listAudios.set('isLoadingLoadMore', true)));
    } else {
      dispatch(setIsLoading(true));
    }

    // const params = [`pageNum=${pageNum}`, `itemsPerPage=${itemsPerPage}`, `sort=${sort}`];
    const params = [`pageNum=${pageNum}`, `itemsPerPage=${itemsPerPage}`];

    fetch(`${apiHost}SoarUplift/list/public?${params.join('&')}`)
      .then(res => res.json())
      .then(
        response => {
          dispatch(setAudios(listAudios
            .set('items', items.concat(response.items))
            .set('totalCount', response.totalItems)
            .set('returnedCount', response.itemsReturned)
          ));
          dispatch(setIsLoading(false));
          res();
        },
        error => {
          dispatch(setErrorMessage(error));
          dispatch(setIsLoading(false));
          dispatch(setAudios(listAudios.set('isLoadingLoadMore', false)));
          rej();
        }
      );
  });
