import { createAction } from 'redux-actions';
import axios from 'axios';
import httpClient from '../../../shared/helper/request';
import { guid } from '../../../shared/helper/general';
import { uploadType } from '../constants';
import { AudioRecord } from '../../../redux/records/audio-record';

export const requestUserPath = createAction('DASHBOAR/REQUEST_USER_PATH_INFO');
export const failUserPath = createAction(
  'DASHBOAR/FAIL_USER_PATH_INFO',
  error => error
);
export const receiveUserPath = createAction(
  'DASHBOAR/RECEIVE_USER_PATH_INFO',
  userInfo => userInfo
);

export const setFileId = createAction(
  'DASHBOAR/FILE_ID',
  fileId => fileId
);

export const receiveFile = createAction(
  'DASHBOAR/RECEIVE_FILE',
  fileInfo => fileInfo
);

export const fetchUserPathInfo = () => async (dispatch, getState) => {
  dispatch(requestUserPath());
  const fileId = guid();

  try {
    dispatch(setFileId(fileId));
    const url = `/SoarUplift/library/${fileId}/upload`;
    const { data } = await httpClient.get(url);

    dispatch(receiveUserPath(data));
  } catch (error) {
    dispatch(setFileId(null));
    dispatch(failUserPath(error));
  }
};

export const requestSendFile = createAction('DASHBOAR/REQUEST_SEND_FILE');
export const receiveSendFile = createAction('DASHBOAR/RECEIVE_SEND_FILE');
export const failSendFile = createAction(
  'DASHBOAR/FAIL_REQUEST_SEND_FILE',
  error => error
);

export const sendUserFile = () => async (dispatch, getState) => {
  dispatch(requestSendFile());

  try {
    const { dashboard } = getState();
    const { userPathInfo, fileInfo } = dashboard;
    const [file] = fileInfo;

    const options = {
      method: 'PUT',
      headers: { 'content-type': 'binary/octet-stream' },
      data: file,
      url: userPathInfo,
    };
    await axios(options);

    dispatch(receiveSendFile());
  } catch (error) {
    dispatch(failSendFile(error));
  }
};

export const requestFileInfo = createAction('DASHBOAR/REQUEST_FILE_INFO');
export const receiveFileInfo = createAction('DASHBOAR/RECEIVE_FILE_INFO');
export const failFileInfo = createAction(
  'DASHBOAR/FAIL_REQUEST_FILE_INFO',
  error => error
);

export const sendFileInfo = callback => async (dispatch, getState) => {
  dispatch(requestFileInfo());

  try {
    const { dashboard, authorizedUser } = getState();
    const {
      fileId,
      fileInfo,
      textToTranscription,
      sourceUrl,
      contentType: type,
      scope,
      audio,
    } = dashboard;
    const {
      title,
      description,
      source,
      tags,
      voicePhrase,
    } = audio;

    const [file] = fileInfo;
    const { name } = file;
    const extension = name ? `.${name.split('.').pop()}` : null;

    let contentType = type;
    
    if (contentType == uploadType.FILE) {
      switch (extension) {
        case '.txt':
        case '.md':
          contentType = 'Text';
          break;
        case '.mp3':
        case '.aif':
        case '.m4a':
          contentType = 'Audio';
          break;
        case '.mp4':
        case '.mov':
          contentType = 'Video';
          break;
      };
    }

    const newData = {
      title,
      description,
      source,
      tags: tags.join(','),
      voicePhrase,
      ownerUserId: authorizedUser.id,
      scope,
      contentType,
      transcription: textToTranscription,
      lang : "en-US",
      uploadFileExt : extension,
      originalFilename: name,
      sourceUrl,
      globalVoicePhrase: voicePhrase.trim(),
    };
    const url = `/SoarUplift/library/add/${fileId}`;
    await httpClient.post(url, newData);

    dispatch(receiveFileInfo());
    callback();
  } catch (error) {
    let errorMessage = error;
    if (error.response) {
      errorMessage = {message: error.response.data};
    }
    dispatch(failFileInfo(errorMessage));
  }
};

export const setTextData = createAction(
  'UPLOAD/SET_TEXT_TRANSCRIPTION',
  text => text,
);

export const setScopeData = createAction(
  'UPLOAD/SET_SCOPE',
  scope => scope,
);

export const setTextToTranscript = (data, callback) => dispatch => {
  const { privacy, text } = data;

  dispatch(setTextData(text));
  dispatch(setScopeData(privacy ? 2 : 0));

  callback();
}

export const setUrlData = createAction(
  'UPLOAD/SET_URL_VIDEO',
  url => url,
);

export const setUrlToTranscript = (data, callback) => dispatch => {
  const { privacy, url } = data;

  dispatch(setUrlData(url));
  dispatch(setScopeData(privacy ? 2 : 0));

  callback();
}

export const setNewAudio = createAction('DASHBOARD/SET_NEW_AUDIO', audioObject => AudioRecord.parse(audioObject));
export const requestTopics = createAction('DASHBOAR/REQUEST_TOPICS');
export const receiveTopics = createAction(
  'DASHBOAR/RECEIVE_TOPICS',
  topics => topics
);
export const failTopics = createAction(
  'DASHBOAR/FAIL_REQUEST_TOPICS',
  error => error
);

export const fetchTopics = () => async dispatch => {
  dispatch(requestTopics());

  try {
    const {status, data} = await httpClient.get('/SoarUplift/taxonomy/full');

    if (status !== 200) {
      throw 'Something went wrong with Topics request.';
    }

    const rootTopics = data.filter(topic => {
      return topic.parentId === 0;
    });

    dispatch(receiveTopics(rootTopics));
  } catch (error) {
    dispatch(failTopics(error));
  }
};
