import { connect } from 'react-redux';
import { deleteAction } from '../../actions/my-library-actions';
import Dialog from './';

const mapStateToProps = state => ({
  deleteRowLabel: state.library.deleteRowLabel,
  formSubmitSuccess: state.library.modalSubmitSuccess,
  isDeleting: state.library.isDeleting,
  deleteErrorMessage: state.library.deleteErrorMessage,
});

const mapDispatchToProps = {
  deleteAction: () => deleteAction(),
};

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);