import { Record } from 'immutable';
import { PLAYLIST_LIST_ITEMS_PER_PAGE } from '../../shared/constants/page';

const AudioListRecord = Record({
  pageNum: 1,
  itemsPerPage: PLAYLIST_LIST_ITEMS_PER_PAGE,
  isLoadingLoadMore: false,
  totalCount: null,
  returnedCount: null,
  sort: 'datedesc',
  items: []
});

AudioListRecord.parse = values => {
  return new AudioListRecord({ ...values });
};

export default AudioListRecord;
