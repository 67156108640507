import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  Wrapper,
  Highlighter,
  WrapperTitle,
  Title,
  MobileGoBack,
  DesktopGoBack,
  ArrowLeft,
  WrapperSubtitle,
  Subtitle,
  Author,
  WrapperShare
} from './styles';
import { goTo } from '../../../../../../shared/helper/goTo';
import SharePlaylist from './Buttons/Share';
import Section from '../../../../../../shared/layout/Section';
import Container from '../../../../../../shared/layout/Container';
import { SMALL } from '../../../../../../shared/constants/breakpoint-constants';
import withWindowResize from '../../../../../../shared/hocs/withWindowResize';

const PlaylistDetailHeader = ({ width }) => {
  const style = width.lessThan(SMALL)
    ? { paddingTop: '1.7rem', paddingBottom: '0' }
    : { paddingBottom: '0' };
  const {
    title,
    ownerUserObj: { familyName, givenName }
  } = useSelector(state => state.publicLibrary.currentyPlaylist);
  const fullName = familyName && givenName && `${familyName} ${givenName}`;

  const handleGoBackClick = () => goTo('/playlist/list');

  return (
    <Section>
      <Container {...style}>
        <Wrapper>
          <MobileGoBack onClick={handleGoBackClick}><ArrowLeft /> Back to Playlists</MobileGoBack>

          <Highlighter>PLAYLIST</Highlighter>

          <WrapperTitle>
            <Title>{title || '...'}</Title>
            <DesktopGoBack onClick={handleGoBackClick}><ArrowLeft /> Back to Playlists</DesktopGoBack>
          </WrapperTitle>

          <WrapperSubtitle>
            <Subtitle><span>by</span> <Author>{fullName || '...'}</Author></Subtitle>
            <WrapperShare>Share this Playlist: <SharePlaylist /></WrapperShare>
          </WrapperSubtitle>
        </Wrapper>
      </Container>
    </Section>
  )
};

PlaylistDetailHeader.propTypes = {
  width: PropTypes.object.isRequired,
};

export default withWindowResize(PlaylistDetailHeader);