import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../dashboard/components/upload/components/modal';
import {
  Close,
  Header,
  Content,
  Footer,
  Input,
  TextArea,
} from './styles';
import DefaultButton from '../../../../shared/basic/DefaultButton';
import FormGroup from '../../../../shared/basic/FormGroup';
import { GroupRecord } from '../../../../redux/records/group-record';
import BoxMessage from '../../../../shared/basic/BoxMessage';
import ContentLoader from '../../../../shared/basic/ContentLoader';

const GroupForm = props => {
  const {
    showModal,
    isCreating,
    groupFormError,
    newGroupDetail,
    closeModal,
    pushNewGroup,
    closeForm,
    setNewGroupDetail,
  } = props;
  const { title, description } = newGroupDetail;

  useEffect(() => {
    if (closeForm) {
      closeModal();
    }
  }, [closeForm]);

  function changeValue(field, value) {
    setNewGroupDetail(newGroupDetail.set(field, value));
  }

  async function handlerSubmit() {
    await pushNewGroup();
  }

  return (
    <Modal showModal={showModal}>
      <Header>
        CREATE NEW GROUP
        <Close unicode="f00d" onClick={closeModal} />
      </Header>
      <Content>
        {isCreating ? <ContentLoader /> : (
          <>
            {groupFormError && <BoxMessage message={groupFormError} />}
            <FormGroup title="Group name">
              <Input defaultValue={title} onChange={event => changeValue('title', event.target.value)} />
            </FormGroup>
            <FormGroup title="Description">
              <TextArea row="2" defaultValue={description} onChange={event => changeValue('description', event.target.value)} />
            </FormGroup>
          </>
        )}
      </Content>
      {!isCreating && (
        <Footer>
          <DefaultButton click={() => handlerSubmit()}>CREATE GROUP</DefaultButton>
          <DefaultButton onClick={closeModal} inverted>CANCEL</DefaultButton>
        </Footer>
      )}
    </Modal>
  );
};

GroupForm.propTypes = {
  closeForm: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  isCreating: PropTypes.bool.isRequired,
  groupFormError: PropTypes.string.isRequired,
  newGroupDetail: PropTypes.instanceOf(GroupRecord).isRequired,
  closeModal: PropTypes.func.isRequired,
  pushNewGroup: PropTypes.func.isRequired,
  setNewGroupDetail: PropTypes.func.isRequired,
}

export default GroupForm;
