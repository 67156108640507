import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { goTo } from '../../../../shared/helper/goTo';

import { Wrapper, Box, H1, H2, Img, Description, Timer, Button } from './styles';

const ThankYou = () => {
  const link = `/my-library`;
  const ref = useRef(null);
  const [timer, setTimer] = useState(15);
  const clear = () => window.clearInterval(ref.current);
  
  useEffect(() => {
    ref.current = setInterval(() => setTimer(t => t - 1), 1000)
    return () => clear();
  }, []);

  useEffect(() => {
    if (timer === 0) {
      clear();
      goTo(link);
    }
  }, [timer, link])

  return (
    <Wrapper>
      <Box>
        <H1>You’re all set!</H1>
        <H2>Thanks for subscribing.</H2>
        <Img src="/img/pricing/thank-you-page.png" alt="Thank you" />
        <Description>
          Start exploring the playlists and audios.
        </Description>
        <Button href={link}>
          Go to your Dashboard
        </Button>
        <Timer>This page will redirect in {timer} seconds.</Timer>
      </Box>
    </Wrapper>
  );
};

export default ThankYou;
