import { imgs } from '../../../assets';

export const Constants = {
  items: [
    {
      index: 0,
      src: imgs.arrowup,
      title: 'Free content upload',
      content: 'Upload your content, and share it with your family and friends.'
    },
    {
      index: 1,
      src: imgs.headphone,
      title: 'Playlists',
      content: 'Organize your content and share your playlists. You save your playlist and get a direct unique link, no logins necessary.'
    },
    {
      index: 2,
      src: imgs.stripes,
      title: 'Stream it on any device',
      content: 'Stream your content on Alexa and Google Assistant devices, without complicated steps, it\'s just a click to “cast” your content to your device. Also available on iPhone, Android and most web browsers.'
    },
    {
      index: 3,
      src: imgs.diamond,
      title: 'Access to Premium Content',
      content: 'Leadership, Entrepreneurship, Human Resources, Sales, and more.'
    },
  ],
  form: {
    login: {
      formTitle: 'Log in to your account',
      buttonText: 'Login',
      question: 'Don’t have an account?',
      questionLink: '/signup?login=false',
      link: 'Sign up!',
      linkUrl: '#',
    },
    signup: {
      formTitle: 'Create your account with email',
      buttonText: 'Sign up',
      question: 'Already have an account?',
      questionLink: '/signup?login=true',
      link: 'Log in',
      linkUrl: '#',
      extraData: true,
    }
  }
}