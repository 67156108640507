import { DateTime } from 'luxon';

export const columns = [
  {
    key: 0,
    type: 'Audio',
    names: ['Title', 'Content Type', 'Scope', 'Voice Phrase', 'Date'],
    rowsRef: ['title', 'contentType', 'scope', 'voicePhrase', 'dateCreated'],
    parsers: {
      scope: function(scope) {
        return scopes[scope];
      },
      viewUrl: function(defaultUrl, audio) {
        return `/audio/${audio.globalVoicePhrase.trim().replaceAll(' ', '-')}`;
      },
      dateCreated: function(dateTime) {
        return DateTime.fromISO(dateTime).toISODate();
      }
    },
    deleteDescription: 'title',
    rowKey: 'id',
  },
  {
    key: 1,
    type: 'Playlist',
    names: ['Title', 'Date'],
    rowsRef: ['title', 'dateCreated'],
    parsers: {
      dateCreated: function(dateTime) {
        return DateTime.fromISO(dateTime).toISODate();
      }
    },
    deleteDescription: 'title',
    rowKey: 'id',
  },
  {
    key: 2,
    type: 'Group',
    names: ['Title', 'Members'],
    rowsRef: ['title', 'memberCount'],
    deleteDescription: 'title',
    rowKey: 'id',
  },
  {
    key: 3,
    type: 'Audio Shared With Me',
    names: ['Title', 'Content Type', 'Scope', 'Voice Phrase', 'Date'],
    rowsRef: ['title', 'contentType', 'scope', 'voicePhrase', 'dateCreated'],
    parsers: {
      scope: function (scope) {
        return scopes[scope];
      },
      viewUrl: function (defaultUrl, audio) {
        return audio.scope === 2 ? `/audio/${audio.globalVoicePhrase}` : null;
      },
      dateCreated: function (dateTime) {
        return DateTime.fromISO(dateTime).toISODate();
      }
    },
    deleteDescription: 'title',
    rowKey: 'id',
  },
];

export const playlistRows = [
  {
    key: 0,
    title: 'Inspirational movie speeches',
    date: '2020-09-24',
  },
  {
    key: 1,
    title: 'Classic motivational speakers',
    date: '2020-09-24',
  },
  {
    key: 2,
    title: 'Afternoon commute',
    date: '2020-09-24',
  },
  {
    key: 3,
    title: 'Inspirational movie speeches',
    date: '2020-09-24',
  },
];

export const helperText = [
  {
    form: 'audio',
    title: 'This will help to identify your content, also to index your audio on our search engine.',
    description: 'This will help you describing the contents of your audio.',
    speaker: 'Who is the source or author of this content.',
    keywords: 'Keywords you help finding your audio on our search engine.',
    transcription: 'Add an audio transcription here.',
    privacy: 'Select a private or public scope for your audio.',
    quickVoicePhrase: 'Enter 2 or 3 words to quickly retrieve this item by saying "GET...." followed by the quick phrase you enter here.',
  }
];

export const actions = [
  ['view', 'playlist', 'edit', 'delete', 'play', 'share'],
  ['edit', 'delete'],
  ['edit', 'delete'],
  ['view', 'play', 'share'],
];

export const scopes = {
  0: 'Private',
  1: 'Team/Group',
  2: 'Public',
};

export const widgetHelperText = 'Audio clips less than 4 minutes long give the best listening experience and play inside the Soar UPLIFT Library.';