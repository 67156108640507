import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FieldsNewCard from './FormNewCard';
import FieldsExistingCard from './FormExistingCard';

import {
  setCvcElement,
  setPromotionalCode,
} from '../../../../../actions/premium-actions';
import { FormGroup, Label } from './styles';

import { PromotionalCodeElement } from './styles';

const Form = () => {
  const dispatch = useDispatch();

  const mode = useSelector(state => state.accountSettings.mode);
  const promotionalCode = useSelector(state => state.accountSettings.promotionalCode);

  const Component = mode === 'existing' ? FieldsExistingCard : FieldsNewCard;

  const handleCreditCardCVCChange = element => {
    if (!element.empty && element.complete) {
      dispatch(setCvcElement(true));
    } else dispatch(setCvcElement(false));
  };

  const handlePromotionalCodeChange = event => {
    dispatch(setPromotionalCode(event.target.value));
  };

  return (
    <>
      <Component {...{ handleCreditCardCVCChange }} />

      <FormGroup>
        <Label>ADD PROMO CODE</Label>
        <PromotionalCodeElement
          value={promotionalCode}
          onChange={handlePromotionalCodeChange}
        />
      </FormGroup>
    </>
  );
};

export default memo(Form);
