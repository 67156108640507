import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import FormGroup from '../../../../shared/basic/FormGroup';
import TitlePage from '../../../../shared/basic/TitlePage';
import withLayout from '../../../../shared/layout/hocs/withLayout';
import DefaultButton from '../../../../shared/basic/DefaultButton';
import {
  Container,
  Section,
} from '../../styles';
import {
  Input,
  Content,
  Back,
  TextArea,
  DivFlex,
  SubTitle,
  TrashIcon,
  Table,
  NewContentContainer,
  DivBlock,
  DivBack,
  PlusIcon,
  Button,
  DivTitle,
} from './styles';
import { GroupRecord } from '../../../../redux/records/group-record';
import ContentLoader from '../../../../shared/basic/ContentLoader';
import BoxMessage from '../../../../shared/basic/BoxMessage';
import { InvitationDialogContainer } from '../invitationDialog';
import { DialogContainer } from '../dialog';

const Tr = ({ children }) => (
  <tr>{children}</tr>
);

const GroupDetailForm = props => {
  const {
    isLoadingGroup,
    formSubmitSuccess,
    groupFormError,
    groupDetail,
    match,
    history,
    fetchGroupDetail,
    updateGroup,
    setNewGroupDetail,
    groupMembers,
    fetchGroupMembers,
    isLoadingMembers,
    newGroupDetail,
    setDeleteOpts,
  } = props;
  const { title, description } = groupDetail;
  const [showInvitationDialog, setShowInvitationDialog] = useState(false);
  const [showDialogDeleteInvitation, setShowDialogDeleteInvitation] = useState(false)

  useEffect(() => {
    if (formSubmitSuccess) {
      history.push('/my-library');
    }
  }, [formSubmitSuccess]);

  useEffect(() => {
    async function fetchGroupInfo() {
      const { params } = match;
      await fetchGroupDetail(params.id);
    }

    async function fetchMembers() {
      const { params } = match;
      await fetchGroupMembers(params.id);
    }

    fetchGroupInfo();
    fetchMembers();
  }, [fetchGroupDetail, fetchGroupMembers]);

  function changeValue(field, value) {
    setNewGroupDetail(newGroupDetail.set(field, value));
  }

  function deleteInvitationHandle(event) {
    setDeleteOpts({
      key: event.target.dataset.key,
      label: event.target.dataset.label,
      type: 'invitation',
    });

    setShowDialogDeleteInvitation(true);
  }

  async function handlerSubmit() {
    await updateGroup();
  }

  return (
    <Container>
      <Section>
        <TitlePage iconCode="f07c">MY LIBRARY</TitlePage>
        <NewContentContainer>
          <DivBlock>
            <DivBack>
              <Link to="/my-library" className="back">
                <Back unicode="f053" /> BACK TO AUDIO LIST
              </Link>
            </DivBack>
            {groupFormError && <BoxMessage message={groupFormError} />}
            <DivFlex>
              <Content>
                {isLoadingGroup ? <ContentLoader /> : (
                  <>
                    <DivBlock>
                      <SubTitle><DivTitle>GROUP INFORMATION</DivTitle></SubTitle>
                      <FormGroup title="Title">
                        <Input onChange={event => changeValue('title', event.target.value)} defaultValue={title} />
                      </FormGroup>
                      <FormGroup title="Description">
                        <TextArea rows="2" defaultValue={description} onChange={event => changeValue('description', event.target.value)} />
                      </FormGroup>
                    </DivBlock>
                    <DivFlex>
                      <DefaultButton onClick={handlerSubmit}>SAVE CHANGES</DefaultButton>
                      <Link to="/my-library">CANCEL</Link>
                    </DivFlex>
                  </>
                )}
              </Content>
              <Content>
                {isLoadingMembers ? <ContentLoader /> : (
                  <>
                    <SubTitle>
                      <DivTitle>
                        GROUP MEMBERS ({groupMembers.length})
                      </DivTitle>
                      <Button click={() => setShowInvitationDialog(true)}><PlusIcon unicode="f067" /> SEND INVITATION</Button>
                    </SubTitle>
                    <Table width="100%">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {groupMembers.map(group => (
                          <Tr key={group.userId}>
                            <td>{group.givenName} {group.familyName}</td>
                            <td>{decodeURIComponent(group.email)}</td>
                            <td>
                              <TrashIcon
                                key={`trash-${group.userId}`}
                                onClick={deleteInvitationHandle}
                                data-key={group.userId}
                                data-label={group.email}
                                unicode="f2ed"
                              />
                            </td>
                          </Tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                )}
              </Content>
            </DivFlex>
          </DivBlock>
        </NewContentContainer>
      </Section>
      <DialogContainer showModal={showDialogDeleteInvitation} closeModal={() => setShowDialogDeleteInvitation(false)} label="invitation" />
      <InvitationDialogContainer showModal={showInvitationDialog} closeModal={() => setShowInvitationDialog(false)} />
    </Container>
  );
};

GroupDetailForm.propTypes = {
  isLoadingMembers: PropTypes.bool.isRequired,
  isLoadingGroup: PropTypes.bool.isRequired,
  formSubmitSuccess: PropTypes.bool.isRequired,
  groupFormError: PropTypes.string.isRequired,
  groupDetail: PropTypes.instanceOf(GroupRecord).isRequired,
  newGroupDetail: PropTypes.instanceOf(GroupRecord).isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  groupMembers: PropTypes.array.isRequired,
  fetchGroupDetail: PropTypes.func.isRequired,
  setNewGroupDetail: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired,
  fetchGroupMembers: PropTypes.func.isRequired,
  setDeleteOpts: PropTypes.func.isRequired,
};

GroupDetailForm.defaultProps = {
  history: {},
}

export default withLayout(GroupDetailForm);
