import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AudioRecord } from '../../../../redux/records/audio-record';
import FormGroup from '../../../../shared/basic/FormGroup';
import ContentLoader from '../../../../shared/basic/ContentLoader';
import { helperText } from '../../constants';
import TitlePage from '../../../../shared/basic/TitlePage';
import withLayout from '../../../../shared/layout/hocs/withLayout';
import DefaultButton from '../../../../shared/basic/DefaultButton';
import TagView from '../../../../shared/basic/TagView';
import ErrorMessage from '../../../../shared/basic/BoxMessage';
import {
  Container,
  Section,
  ContentContainer,
} from '../../styles';
import {
  Input,
  Content,
  Back,
  TextArea,
  Select,
  DivFlex,
  Keywords,
  GlobalStyles,
} from './styles';

const AudioForm = props => {
  const {
    isLoadingAudio,
    audioFormError,
    audio,
    match,
    fetchAudio,
    updateAudio,
    setNewAudio,
    formSubmitSuccess,
    history,
    newAudio,
  } = props;
  const [texts] = helperText;
  const { tags, title, description, source: speaker, transcription, scope, voicePhrase } = audio;
  const isReadOnly = /\/(view)\//g.test(match.path);

  useEffect(() => {
    if (formSubmitSuccess) {
      history.push('/my-library');
    }
  }, [formSubmitSuccess]);

  useEffect(() => {
    async function fetchAudioInfo() {
      const { params } = match;
      await fetchAudio(params.id);
    }

    fetchAudioInfo();
  }, [fetchAudio]);

  function changeValue(field, value) {
    setNewAudio(newAudio.set(field, value));
  }

  function onChangeTags(tagsChanged, allTags, action) {
    audio.set('tags', allTags);
  }

  async function handlerSubmit() {
    await updateAudio();
  }

  return isLoadingAudio ? <ContentLoader /> : (
    <Container>
      <GlobalStyles />
      <Section>
        <TitlePage iconCode="f07c">MY LIBRARY</TitlePage>
        <ContentContainer>
          <Content>
            <Link to="/my-library" className="back">
              <Back unicode="f053" /> BACK TO AUDIO LIST
            </Link>
            {audioFormError && <ErrorMessage message={audioFormError} />}
            <div>
              <FormGroup title="Title" helper={texts.title}>
                <Input
                  readOnly={isReadOnly}
                  defaultValue={title}
                  onChange={event => changeValue('title', event.target.value)}
                />
              </FormGroup>
              <FormGroup title="Description" helper={texts.description}>
                <TextArea
                  readOnly={isReadOnly}
                  rows="2"
                  defaultValue={description}
                  onChange={event => changeValue('description', event.target.value)}
                />
              </FormGroup>
              <FormGroup title="Speaker" helper={texts.speaker}>
                <Input
                  readOnly={isReadOnly}
                  defaultValue={speaker}
                  onChange={event => changeValue('source', event.target.value)}
                />
              </FormGroup>
              <FormGroup title="Keywords" helper={texts.keywords}>
                {isReadOnly ? <TagView elements={tags} /> : (
                  <Keywords
                    tags={tags}
                    delimiters={[13, ',']}
                    onChange={onChangeTags}
                  />
                )}
              </FormGroup>
              <FormGroup title="Transcription" helper={texts.transcription}>
                <TextArea
                  readOnly={isReadOnly}
                  rows="2"
                  defaultValue={transcription}
                  onChange={event => changeValue('transcription', event.target.value)}
                />
              </FormGroup>
              <FormGroup title="Privacy" helper={texts.privacy}>
                <Select
                  disabled={isReadOnly}
                  defaultValue={scope}
                  onChange={event => changeValue('scope', event.target.value)}
                >
                  <option value="2">Public</option>
                  <option value="0">Private</option>
                </Select>
              </FormGroup>
              <FormGroup title="Quick voice phrase" helper={texts.quickVoicePhrase}>
                <Input
                  readOnly={isReadOnly}
                  defaultValue={voicePhrase}
                  onChange={event => changeValue('voicePhrase', event.target.value)}
                />
              </FormGroup>
            </div>
            <DivFlex>
              {!isReadOnly && <DefaultButton onClick={() => handlerSubmit()}>SAVE CHANGES</DefaultButton>}
              {!isReadOnly && <Link to="/my-library">CANCEL</Link>}
            </DivFlex>
          </Content>
        </ContentContainer>
      </Section>
    </Container>
  );
};

AudioForm.propTypes = {
  formSubmitSuccess: PropTypes.bool.isRequired,
  isLoadingAudio: PropTypes.bool.isRequired,
  audioFormError: PropTypes.string.isRequired,
  audio: PropTypes.instanceOf(AudioRecord).isRequired,
  newAudio: PropTypes.instanceOf(AudioRecord).isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  fetchAudio: PropTypes.func.isRequired,
  updateAudio: PropTypes.func.isRequired,
  setNewAudio: PropTypes.func.isRequired,
};

AudioForm.defaultProps = {
  history: {},
}

export default withLayout(AudioForm);
