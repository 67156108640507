import styled from 'styled-components';
import { pixelRatio } from '../../helper/screen';

const density = pixelRatio();

export const Img = styled.img.attrs(({ src }) => {
  const [path, type] = src.split(/\.(?=[^\\.]+$)/);
  return ({
    src: `${path}${density}.${type}`,
  })
})``;
