import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, LinksDesktop, LinksMobile, GapDetails } from './styles';
import ButtonAlexa from './Buttons/Alexa';
import ButtonGoogle from './Buttons/Google';
import ButtonPlay from './Buttons/Play';
import ButtonPlayMobile from './Buttons/PlayMobile';
import PlaylistTitle from './Labels/Title';
import PlaylistDate from './Labels/Date';
import PlaylistCount from './Labels/Count';
import Tooltip from '../../../../../../shared/basic/Tooltip';

const PlaylistListItem = ({ title, dateCreated, audioCount, id, backgroundColor, isLoading }) => (
  <Wrapper backgroundColor={backgroundColor}>

    <PlaylistTitle id={id} title={title} />

    <GapDetails>
      <PlaylistDate dateCreated={dateCreated} />
      <PlaylistCount audioCount={audioCount} />
    </GapDetails>

    <LinksDesktop>
      <Tooltip effect='solid' /> {/* This solves the problem in toggle the icon */}
      <ButtonAlexa id={!!Number(audioCount) && id} isLoading={isLoading} />
      <ButtonGoogle id={!!Number(audioCount) && id} isLoading={isLoading} />
      <ButtonPlay id={!!Number(audioCount) && id} isLoading={isLoading} />
    </LinksDesktop>
    
    <LinksMobile>
      <ButtonPlayMobile id={!!Number(audioCount) && id} isLoading={isLoading} />
    </LinksMobile>

  </Wrapper>
);

PlaylistListItem.propTypes = {
  id: PropTypes.any,
  children: PropTypes.any,
  title: PropTypes.string,
  dateCreated: PropTypes.string,
  audioCount: PropTypes.string,
  backgroundColor: PropTypes.string,
  isLoading: PropTypes.bool,
};

PlaylistListItem.defaultProps = {
  id: null,
  children: '',
  title: '',
  dateCreated: '',
  audioCount: '',
  backgroundColor: '#FFFFFF',
  isLoading: false,
};

export default PlaylistListItem;
