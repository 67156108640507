import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4.8rem 0;
  
  ${media.lessThan("small")`
    align-items: center;
    padding: 3.7rem 0;
  `}
`;

export const Highlighter = styled.div`
  font-size: 1.3rem;
  font-weight: 500;
  color: #5c5c5e;
  margin-bottom: 1rem;
`;

export const Title = styled.div`
  min-height: 2.4rem;
  font-size: 2rem;
  font-weight: 600;
  color: #000000;
  margin-bottom: 4.7rem;

  ${media.lessThan("small")`
    margin-bottom: 2.9rem;
    font-size: 1.6rem;
  `}
`;

