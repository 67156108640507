import styled from 'styled-components';
import { lightBlueGrey } from '../../../../../../../styles/variables';

export const Table = styled.table`
  border-collapse: collapse;
  margin-top: 2rem;
  width: 100%;

  thead {
    background-color: ${lightBlueGrey};
    font-size: 1.4rem;

    th {
      padding: 1.6rem 1.4rem;
      text-align: left;
    }
  }

  tr {
    height: 4rem;
  }

  tbody {
    font-size: 1.5rem;

    tr {
      border: 1px solid ${lightBlueGrey};

      td {
        padding: 1.6rem 1.4rem;
      }
    }
  }
`;
