import React, { useState } from 'react';
import PropTypes from 'prop-types';
import auth0Client from '../../../shared/helper/auth0';
import { Link, withRouter } from 'react-router-dom';
import {
  TopNav,
  Logo,
  DivLeft,
  DivRight,
  Signup,
  DivMobileRight,
  LinkList,
} from './styles';

export const Header = ({ history }) => {
  const isAuthenticated = auth0Client.isAuthenticated();
  const [showMenu, setShowMenu] = useState(false);
  const { location: { pathname } } = history;

  async function handleLogoutButtonClick(event) {
    event.preventDefault();
    await auth0Client.signOut();
    history.replace('/');
  };

  const UnloggedOptions = (
    <>
      <Link to="/signup?login=true">Login</Link>
      {pathname !== '/pricing' && <Signup href="/pricing">Sign Up</Signup>}
    </>
  );
  
  const LoggedMenu = (
    <>
      <a href="#" onClick={handleLogoutButtonClick}>Logout</a>
    </>
  );

  return (
    <TopNav id="myTopnav">
      <div>
        <Logo />
        <DivLeft>
          {isAuthenticated && <Link to="/my-library">My Library</Link>}
          <Link to="/playlist/list">Playlists</Link>
          <Link to="/audios/list">Audios</Link>
          {/* {isAuthenticated && <Link to="/dashboard">Topics</Link>} */}
        </DivLeft>
        <DivRight>
          {!isAuthenticated
            ? (UnloggedOptions)
            : (LoggedMenu)
          }
        </DivRight>
        <DivMobileRight>
          {!isAuthenticated && (
            pathname !== '/pricing' && <Signup href="/pricing">Sign Up</Signup>
          )}  
          <button onClick={() => setShowMenu(!showMenu)}>
            <i className="fa fa-bars"></i>
          </button>
        </DivMobileRight>
      </div>
      <div>
        <LinkList showItems={showMenu}>
          <li>
            <Link to="/playlist/list">Playlists</Link>
          </li>
          <li>
            <Link to="/audios/list">Audios</Link>
          </li>
          {!isAuthenticated ? (
            <li>
              <a href="/signup?login=true">Login</a>
            </li>
          ) : (
            <>
              {/* <li>
                <Link to="/dashboard">Topics</Link>
              </li> */}
              <li>
                <Link to="/my-library">My Library</Link>
              </li>
              <li>
                <a href="#" onClick={handleLogoutButtonClick}>Logout</a>
              </li>
            </>
          )}
        </LinkList>
      </div>
    </TopNav>
  );
};

Header.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Header);
