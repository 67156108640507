import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../dashboard/components/upload/components/modal';
import {
  Close,
  Header,
  Content,
  Footer,
} from './styles'
import DefaultButton from '../../../../shared/basic/DefaultButton';
import ContentLoader from '../../../../shared/basic/ContentLoader';
import BoxMessage from '../../../../shared/basic/BoxMessage';

const Dialog = props => {
  const {
    showModal,
    formSubmitSuccess,
    isDeleting,
    label,
    closeModal,
    deleteRowLabel,
    deleteErrorMessage,
    deleteAction,
  } = props;

  useEffect(() => {
    if (formSubmitSuccess) {
      closeModal();
    }
  }, [formSubmitSuccess]);

  return (
    <Modal showModal={showModal}>
      <Header>
        DELETE {label}
        <Close unicode="f00d" onClick={closeModal} />
      </Header>
      {isDeleting ? <ContentLoader /> : (
        <Content>
          {deleteErrorMessage && (<BoxMessage message={deleteErrorMessage} />)}
          Are you sure you want to delete the following {label}:<br/><span>{deleteRowLabel}</span>
        </Content>
      )}
      {!isDeleting && (
        <Footer>
          <DefaultButton click={deleteAction}>DELETE {label}</DefaultButton>
          <DefaultButton click={closeModal} inverted>CANCEL</DefaultButton>
        </Footer>
      )}
    </Modal>
  );
};

Dialog.propTypes = {
  showModal: PropTypes.bool.isRequired,
  formSubmitSuccess: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  deleteRowLabel: PropTypes.string.isRequired,
  deleteErrorMessage: PropTypes.string.isRequired,
  deleteAction: PropTypes.func.isRequired,
};

export default Dialog;
