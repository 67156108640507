import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { PlaylistListContainer } from "./List";
import Header from '../../../shared/components/Header';
import Player from '../../../shared/components/Player';
import JoinSoar from "../../../shared/components/JoinSoar";
import AppStoreLinks from "../../../shared/components/AppStoreLinks";
import withLayout from "../../../../../shared/layout/hocs/withLayout";
import AudioRecord from "../../../redux/records/audio-record";
import ContentLoader from "../../../../../shared/basic/ContentLoader";
import { urlAudioGenerator } from "../../../../../shared/helper/openPlaylistLink";
import BoxMessage from "../../../../../shared/basic/BoxMessage";
import Section from "../../../../../shared/layout/Section";
import Container from "../../../../../shared/layout/Container";
import auth0Client from "../../../../../shared/helper/auth0";

const Audio = props => {
  const {
    match,
    isLoading,
    errorMessage,
    audio,
    fetchAudioData,
  } = props;
  useEffect(() => {
    async function fetchAudioInfo(voicePhrase) {
      await fetchAudioData(voicePhrase);
    };

    const { params } = match;
    fetchAudioInfo(params.id);
  }, [match]);
  const { title, source, voicePhrase, id } = audio;

  const shareProps = {
    isLoading,
    id,
    voicePhrase,
    errorMessage,
    urlGenerator: urlAudioGenerator,
  };
  const content = !isLoading && errorMessage ? (
    <Section>
      <Container>
        <BoxMessage message={errorMessage} />
      </Container>
    </Section>
  ) : (
    <>
      <Header
        showButton={false}
        title={title}
        author={source}
        label="AUDIO"
        shareLabel="Content"
        shareProps={shareProps}
      />
      <Player audio={audio} />
      {auth0Client.isAuthenticated() && <PlaylistListContainer />}
    </>
  );

  return (
    <>
      {isLoading ? <ContentLoader /> : content}
      <AppStoreLinks />
    </>
  );
};

Audio.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  audio: PropTypes.instanceOf(AudioRecord).isRequired,
  fetchAudioData: PropTypes.func.isRequired,
}

export default withLayout(Audio);