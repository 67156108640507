import { Record } from 'immutable';

const AudioRecord = Record({
  id: null,
  ownerUserId: 0,
  description: '',
  source: '',
  lang: '',
  contentType: '',
  tags: [],
  ext: '',
  dateCreated: '',
  title: '',
  transcription: '',
  contentCategory: '',
  scope: 0,
  voicePhrase: '',
  globalVoicePhrase: '',
  audioSeconds: 0,
  ownerInfo: { vanityUrl: '', profilePictUrl: '' },
  sourceUrl: '',
  autoTags: '',
  subscriptions: '',
  groups: '',
  externalId: '',
  hasAudioTranscriptionEnabled: true,
  metadata: '',
  alexaAudioUrl: ''
});

AudioRecord.parse = values => {
  return new AudioRecord({ ...values });
};

export default AudioRecord;
