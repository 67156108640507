import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Buttons from './Buttons';
import SecureMark from './SecureMark';
import {
  Wrapper,
  WrapperFooter,
  ErrorMessage,
  P,
  A,
} from './styles';

const Footer = () => {
  const errorMessage = useSelector(state => state.accountSettings.errorMessage);
  const errorMessageLocalToken = useSelector(state => state.accountSettings.errorMessageLocalToken);
  const isSubscriptionPlan = useSelector(state => state.accountSettings.isSubscriptionPlan);
 
  const preErrorMessage = 'Something went wrong. Try the operation again.';
  const displayedErrorMessage = `${preErrorMessage} ${errorMessage || errorMessageLocalToken}`;

  return (
    <Wrapper>
      {(errorMessageLocalToken || errorMessage) && (
        <ErrorMessage>{displayedErrorMessage}</ErrorMessage>
      )}
      <Buttons />
      <WrapperFooter>
        <P>
          {!isSubscriptionPlan
            ? ' By starting this subscription you agree to these '
            : ' By editing your payment method you agree to these '}
          <A href="https://www.soar.com/soar-terms-of-use">Terms of Service.</A>
        </P>
        <SecureMark>Secure Checkout Guaranteed.</SecureMark>
      </WrapperFooter>
    </Wrapper>
  );
};

export default withRouter(Footer);
