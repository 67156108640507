import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  font-family: Work Sans, sans-serif;
  font-size: 1.4rem;
  color: #000000;
  display: flex;
  justify-content: center;
`;

export const Section = styled.div`
  width: 34.66vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 2.9rem 0 12.2rem;

  ${media.lessThan('large')`
    width: 55vw;
  `}

  ${media.lessThan('medium')`
    width: 100vw;
  `}
`;
