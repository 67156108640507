import { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
  setIsSubscriptionPlan,
  setMode,
} from '../../../../../actions/premium-actions';

const Set = props => {
  const dispatch = useDispatch();
  const { mode, isSubscriptionPlan } = props;

  useEffect(() => { dispatch(setMode(mode)) }, [dispatch, mode]);
  useEffect(() => { dispatch(setIsSubscriptionPlan(isSubscriptionPlan)) },
    [dispatch, isSubscriptionPlan]);

  return null;
};

Set.propTypes = {
  mode: PropTypes.string.isRequired,
  isSubscriptionPlan: PropTypes.bool,
};

Set.defaultProps = {
  isSubscriptionPlan: false,
};

export default memo(Set);
