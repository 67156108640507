import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;  
`
export const Title = styled.div`
  margin-bottom: 1.5rem;
  font-size: 12px;
  font-weight: 500;
  color: #5c5c5e;
`

export const Form = styled.form``

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.marginBottom || '2rem'};
`;

export const Label = styled.label`
  font-size: 1.3rem;
  font-weight: 600;
  display: inline-block;
  line-height: 1;
  color: #ff7654;
`;

export const PromotionalCodeElement = styled.input.attrs(() => ({
  type: 'text',
  required: true,
}))`
  padding: 1.1rem;
  border: none;
  border-bottom: solid .1rem #caccd8;

  &:focus {
    outline: none;
  }
`;
