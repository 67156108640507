import { Record } from 'immutable';
import AudioListRecord from './audio-list-record';
import AudioRecord from './audio-record';
import PlaylistListRecord from './playlist-list-record';
import PlaylistRecord from './playlist-record';

const LibraryRecord = Record({
  isLoading: false,
  errorMessage: '',
  successMessage: '',
  currentyAudio: new AudioRecord(),
  currentyPlaylist: new PlaylistRecord(),
  listPlaylists: new PlaylistListRecord(),
  listAudios: new AudioListRecord(),
});

export default LibraryRecord;
