import styled from 'styled-components';
import { melon } from '../../../styles/variables';

export const Content = styled.div`
  text-align: center;
  font-size: 1.3rem;
  font-weight: 600;
  margin: 3rem auto;
`;

export const Loader = styled.div`
  border: 1rem solid #f3f3f3;
  border-radius: 50%;
  border-top: 1rem solid ${melon};
  width: 3rem;
  height: 3rem;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 1rem auto;

  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
