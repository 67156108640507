import React from 'react';
import { useSelector } from 'react-redux';

import Container from '../../../../../../shared/layout/Container';
import Section from '../../../../../../shared/layout/Section';
import AudioPlayer from './AudioPlayer';

import { Wrapper, Highlighter, Title } from './styles';

const PlaylistDetailPlayer = () => {
  const { id, voicePhrase, alexaAudioUrl } = useSelector(state => state.publicLibrary.currentyAudio);
  const style = { paddingTop: '0', paddingBottom: '0' };

  return id && (
    <Section marginBottom="3rem" backgroundColor="white">
      <Container {...style}>
        <Wrapper>
          <Highlighter>YOU ARE LISTENING TO:</Highlighter>
          <Title>{voicePhrase || '...'}</Title>
          <AudioPlayer url={alexaAudioUrl} />
        </Wrapper>
      </Container>
    </Section>
  )
};

export default PlaylistDetailPlayer;