import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.5rem;
`;

export const H1 = styled.div`
  font-size: 3.5rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
  font-weight: 700;
  line-height: 1.2;
  align-self: center;
`;

export const H2 = styled.div`
  font-size: 2.5rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-weight: 700;
  line-height: 1.2;
`;

export const Img = styled.img`
  margin: 2rem 0;
  max-width: 75vw;
`;

export const Ol = styled.ol`
  padding-inline-start: 1.2rem;
`;
