import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../dashboard/components/upload/components/modal';
import {
  Close,
  Header,
  Content,
  Footer,
  Select,
} from './styles';
import DefaultButton from '../../../../shared/basic/DefaultButton';
import FormGroup from '../../../../shared/basic/FormGroup';
import ContentLoader from '../../../../shared/basic/ContentLoader';
import BoxMessage from '../../../../shared/basic/BoxMessage';
import { useDispatch } from 'react-redux';

const Option = ({id, children}) => (
  <option value={id}>{children}</option>
);

const PermissionDialog = props => {
  const {
    isLoadingGroupPermission,
    showModal,
    modalSubmitSuccess,
    groups,
    fetchGroups,
    updateGroupPermission,
    closeModal,
    groupErrorMessage,
    failGroup,
  } = props;
  const dispatch = useDispatch();
  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    if (modalSubmitSuccess) {
      closeModal();
    }
  }, [modalSubmitSuccess]);

  useEffect(() => {
    async function fetchPermissions() {
      await fetchGroups();
    }
    fetchPermissions();
  }, [fetchGroups]);

  async function handlerSubmit() {
    if (!selectedGroup) {
      dispatch(failGroup({message: 'Select a group!'}));
      return;
    }
    await updateGroupPermission(selectedGroup);
  }

  return (
    <Modal showModal={showModal}>
      <Header>
        ADD PERMISSION
        <Close unicode="f00d" onClick={closeModal} />
      </Header>
      <Content>
        {isLoadingGroupPermission ? <ContentLoader /> : (
          <>
            {groupErrorMessage && <BoxMessage message={groupErrorMessage} />}
            <FormGroup title="Playlist name">
              <Select
                defaultValue={selectedGroup}
                onChange={event => setSelectedGroup(event.target.value)}
              >
                <option>Select a group</option>
                {groups.map(group => (
                  <Option key={group.id} id={group.id}>{group.title}</Option>
                ))}
              </Select>
            </FormGroup>
          </>
        )}
      </Content>
      {!isLoadingGroupPermission && (
        <Footer>
          <DefaultButton click={() => handlerSubmit()}>ADD PERMISSION</DefaultButton>
          <DefaultButton onClick={closeModal} inverted>CANCEL</DefaultButton>
        </Footer>
      )}
    </Modal>
  );
};

PermissionDialog.propTypes = {
  isLoadingGroupPermission: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  modalSubmitSuccess: PropTypes.bool.isRequired,
  groupErrorMessage: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  fetchGroups: PropTypes.func.isRequired,
  updateGroupPermission: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  failGroup: PropTypes.func.isRequired,
};

export default PermissionDialog;
