export const black = '#000000';
export const white = '#ffffff';
export const brightOrange = '#fa6400';
export const darkGrey = '#28323b';
export const greyTitle = '#666b87';
export const PELE_GREY = '#f8f8f9';
export const BLUE_VIOLET = '#7909ea';
export const darkViolet = '#2f005e';
export const darkGrey2 = '#616060';
export const lightBlueGrey = '#caccd8';
export const lightGreyBlue = '#a0a6cc';
export const lightGrey = '#e8e8ec';
export const slateGrey = '#5c5c5e';
export const melon = '#ff7654';
export const greyAlpha = 'rgb(128 128 128 / 0.4)';
export const kellyGreen = '#00b634';
export const neonBlue = '#06dcf3';

export const primary = '#ee785a';
export const primaryDark = '#da6c4b';
export const secondary = '#7f5faa';
export const tertiary = '#2299a3';
export const font = '#464646';
export const background = '#666666';
export const secondaryTones = '#53A5A6';
export const secondaryTones2 = '#876FA7';
export const danger = '#ff0000';

export const alertColor = '#721c24';
export const alertBackgroundColor = '#f8d7da';
export const alertBorderColor = '#f5c6cb';
export const successColor = '#155724';
export const successBackgroundColor = '#d4edda';
export const successBorderColor = '#c3e6cb';
export const infoColor = '#0c5460';
export const infoBackgroundColor = '#d1ecf1';
export const infoBorderColor = '#bee5eb';