import React, { useState } from 'react';
import RegularIcon from '../../../../shared/basic/IconFontAnswer';
import PropTypes from 'prop-types';
import { melon, white } from '../../../../styles/variables';
import {
  Div,
  Title,
  Content,
  Icon,
  ConvertText,
  Button,
  DivButtons,
  FileInput,
  Dropzone,
} from './styles';
import Modal from './components/modal';
import { FormContainer } from './components/form';
import { FormTextContainer } from './components/formText';
import { FormUrlContainer } from './components/formUrl';
import Loader from '../../../../shared/basic/ContentLoader';
import ErrorMessage from '../../../../shared/basic/BoxMessage';

const fileInputRef = React.createRef();

const Upload = props => {
  const {
    isUploadinUserPath,
    userPathErrorMessage,
    fetchUserPathInfo,
    receiveFileInfo,
    isMyLibrary,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [showTextModal, setShowTextModal] = useState(false);
  const [showUrlModal, setShowUrlModal] = useState(false);
  const [highlight, setHighlight] = useState([]);

  function openFileDialog() {
    fileInputRef.current.click();
  }

  async function onFilesAdded(event) {
    const loadedFiles = event.target.files;
    await fetchUserPathInfo();
    if (!userPathErrorMessage) {
      receiveFileInfo(loadedFiles);
      setShowModal(true);
    }
  }

  function onDragOver(event) {
    event.preventDefault();
    setHighlight(true);
  }
  
  function onDragLeave() {
    setHighlight(false);
  }

  async function onDrop(event) {
    event.preventDefault();
    const loadedFiles = event.dataTransfer.files;
    setHighlight(false);
    await fetchUserPathInfo();
    if (!userPathErrorMessage) {
      receiveFileInfo(loadedFiles);
      setShowModal(true);
    }
  }

  return isUploadinUserPath
    ? <Loader />
    : (
      <>
        <Div>
          {userPathErrorMessage ? <ErrorMessage message={userPathErrorMessage} /> : null}
          <Dropzone
            className={`${highlight ? 'highlight' : ''}`}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            onClick={openFileDialog}
            cursor="pointer"
          >
            <Title>DROP FILES HERE <span>OR</span> CLICK TO UPLOAD</Title>
            <Content>
              Audio files (.mp3, .mp4, .txt, .m4a, .aif etc) Text to speech (.txt, .doc, .pdf, etc)
            </Content>
            <Icon unicode={'f33d'} color={melon} />
            <ConvertText>YOU CAN ALSO CONVERT:</ConvertText>
          </Dropzone>
          <DivButtons>
            <Button onClick={() => setShowTextModal(true)}>
              <RegularIcon unicode={'f893'} color={white} /> TYPE OR PASTE YOUR TEXT
            </Button>
            <Button onClick={() => setShowUrlModal(true)}>
              <i className="fab fa-youtube"></i> YOUTUBE URLs
            </Button>
          </DivButtons>
          <FileInput onChange={onFilesAdded} ref={fileInputRef} />
        </Div>
        <Modal showModal={showModal}>
          <FormContainer closeModal={() => setShowModal(false)} refreshAudios={isMyLibrary} />
        </Modal>
        <Modal showModal={showTextModal}>
          <FormTextContainer
            closeModal={() => setShowTextModal(false)}
            openAudioDetail={() => setShowModal(true)}
          />
        </Modal>
        <Modal showModal={showUrlModal}>
          <FormUrlContainer
            closeModal={() => setShowUrlModal(false)}
            openAudioDetail={() => setShowModal(true)}
          />
        </Modal>
      </>
    );
};

Upload.propTypes = {
  isMyLibrary: PropTypes.bool,
  isUploadinUserPath: PropTypes.bool.isRequired,
  userPathErrorMessage: PropTypes.string.isRequired,
  fetchUserPathInfo: PropTypes.func.isRequired,
  receiveFileInfo: PropTypes.func.isRequired,
};

Upload.defaultValues = {
  isMyLibrary: false,
};

export default Upload;
