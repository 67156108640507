import React from 'react';
import withLayout from '../../shared/layout/hocs/withLayout';
import Container from '../../shared/layout/Container';
import { Title, SubTitle, SubTitle3, Paragraph } from '../../shared/layout/CustomContent/styles';

const PrivacyPolicy = () => {
  return (
    <Container>
      <Title>Privacy Policy</Title>
      <Paragraph>
        This Privacy Policy describes how Strengths, Inc. dba Soar (“we,” “us” or “our”) collects, uses, and shares information about you and the choices you have regarding this information. This Privacy Policy applies to the information we collect when you access or use our products, services, and technology platforms (including, without limitation, our website(s), mobile applications, and other Soar-controlled properties that link to this Privacy Policy) (collectively, the “Services”). Please note that this Privacy Policy does not apply to your use of third-party sites, services, or applications you may access through our Services. We encourage you to review the privacy policies of those third parties for more information regarding their privacy practices.
      </Paragraph>
      <SubTitle>CHANGES TO THIS PRIVACY POLICY</SubTitle>
      <Paragraph>
        We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy. In some cases, we may provide you with additional notice, such as adding a statement to our homepage or sending you an email notification. We encourage you to review the Privacy Policy whenever you access the Services to stay informed about our information practices and the ways you can help protect your privacy.
      </Paragraph>
      <SubTitle>COLLECTION OF INFORMATION</SubTitle>
      <SubTitle3>Information You Provide to Us</SubTitle3>
      <Paragraph>
        We collect information you provide directly to us when using the Services. For example, we collect information when you download our mobile application(s), register for our Services, sign up for an account, complete a quiz or questionnaire or other form, participate in any interactive features of the Services including any video or audio call features we provide, apply for a job, request customer support, or otherwise communicate with us. The types of information we may collect include your name, email address, mailing and billing address and payment information, and any other information you choose to provide.
      </Paragraph>
      <SubTitle3>Information We Collect Automatically When You Use the Services</SubTitle3>
      <Paragraph>
        When you access or use the Services, we automatically collect information about you, including the following:
      </Paragraph>
      <Paragraph>
        Log Information: We log information about your use of the Services, including the type of browser you use, access times, pages viewed, your IP address, and the page you visited before navigating to the Services. Publicly available tools can sometimes provide the approximate location for IP addresses.
        Device Information: We collect information about the computer or mobile device you use to access our Services, including the hardware model, operating system and version, unique device identifiers, and mobile network information.
        Information Collected by Cookies and Other Tracking Technologies: We use various technologies to collect information, and this may include sending cookies to your computer or mobile device. Cookies are small data files stored in device memory that help us to improve the Services and your experience, see which areas and features of the Services are popular, and count visits. We may also collect information using web beacons (also known as “tracking pixels”). Web beacons are electronic images that may be used in the Services or emails and help deliver cookies, count visits, understand usage and campaign effectiveness, and determine whether an email has been opened and acted upon.For more information about cookies, and how to disable them, please see “Your Choices” below.
      </Paragraph>
      <SubTitle3>Information We Collect From Other Sources</SubTitle3>
      <Paragraph>
        We may also obtain information from other sources and combine that with information we collect through the Services. For example, we may collect information about you from third parties who provide services on our behalf, such as maintaining and monitoring usage of the Services and processing payment transactions. We also may receive information about you from the coaches with whom you interact through the Services.
      </Paragraph>
      <SubTitle>USE OF INFORMATION</SubTitle>
      <Paragraph>
        We may use information about you for various purposes, including to do the following:
      </Paragraph>
      <Paragraph>
        Provide, maintain, and improve the Services, including the quality of the coaching;
        Provide and deliver the products and services you request, process transactions, and send you related information;
        Verify your identity and, if applicable, authorization for you to use the Services (such as from your employer);
        Respond to your comments, questions, and requests;
        Send you technical notices and other administrative messages;
        Communicate with you about products, services, offers, promotions, rewards, and events offered by us or others, and provide news and information we think will be of interest to you;
        Monitor and analyze trends, usage, and activities in connection with the Services;
        Conduct research, analysis, and surveys;
        Personalize and improve the Services and provide content or features that match user profiles or interests;
        Link or combine with information we get from others in connection with the Services; and
        Carry out any other purpose for which the information was collected.
      </Paragraph>
      <SubTitle>SHARING OF INFORMATION</SubTitle>
      <Paragraph>
        We may share or transfer information about you as follows or as otherwise described in this Privacy Policy:
      </Paragraph>
      <Paragraph>
        With coaches in connection with providing the Services;
        With vendors, consultants, and other service providers who need access to such information to carry out work on our behalf;
        In response to a request for information if we believe disclosure is in accordance with any applicable law, regulation, or legal process, or as otherwise required by any applicable law, rule, or regulation;
        If we believe your actions are inconsistent with the spirit or language of our user agreements or policies, or to protect the rights, property, and safety of you, us, or others;
        In connection with, or during negotiations of, any merger, sale of our assets, financing, or acquisition of all or a portion of our business to another company;
        With a third party, such as your employer, if the third party is paying for your use of the Services or if you are using the Services as part of a third-party-sponsored deployment; in these circumstances we may share with the third party the name of the professional development program you have selected within the Services, the nature of your use of the Services (such as how many sessions you attend), and aggregated or de-identified information; and
        With your consent or at your direction, including if we notify you through the Services that certain information you provide will be shared in a particular manner and you provide this information.
        We may also share aggregated or de-identified information, which cannot reasonably be used to identify you.
      </Paragraph>
      <SubTitle>ANALYTICS SERVICES</SubTitle>
      <Paragraph>
        We may allow others to provide analytics services in connection with the Services. These entities may use cookies, web beacons, and other technologies to collect information about your use of the Services and other websites, including your IP address, web browser, pages viewed, time spent on pages, links clicked, and conversion information. We and others may use this information to, among other things, analyze and track data, determine the popularity of certain content, personalize the user experience, and better understand your activity.
      </Paragraph>
      <SubTitle>SECURITY</SubTitle>
      <Paragraph>
        We take reasonable measures to help protect information about you from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction.
      </Paragraph>
      <SubTitle>TRANSFER OF INFORMATION TO THE U.S. AND OTHER COUNTRIES</SubTitle>
      <Paragraph>
        We are based in the United States and the information we collect is governed by U.S. law. By accessing or using the Services or otherwise providing information to us, you consent to the processing and transfer of information in and to the United States and other countries, where you might not have the same rights and protections as you do under local law.
      </Paragraph>
      <Paragraph>
        In connection with the processing of personal data we receive from the EU (“EU Data”), we adhere to the EU-U.S. Privacy Shield Framework Principles issued by the U.S. Department of Commerce (the “Principles”). For more information about the Principles, please visit the Department of Commerce’s Privacy Shield Website.
      </Paragraph>
      <Paragraph>
        Please direct any inquiries or complaints regarding our compliance with the Principles to the point of contact listed in the “Contact Us” section below. Under certain conditions specified by the Principles, you may also be able to invoke binding arbitration to resolve your complaint. We are subject to the investigatory and enforcement powers of the U.S. Federal Trade Commission. If we share EU Data with a third-party service provider that processes the data solely on our behalf, then we will be liable for that third party’s processing of EU Data in violation of the Principles, unless we can prove that we are not responsible for the event giving rise to the damage.
      </Paragraph>
      <Paragraph>
        We take reasonable measures to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction.
      </Paragraph>
      <SubTitle>YOUR CHOICES</SubTitle>
      <SubTitle3>Your Information</SubTitle3>
      <Paragraph>
        You may update, correct or delete information about you at any time by contacting us at support@soar.com. Please note that we may retain certain information as required by law or for legitimate business purposes. We may also retain cached or archived copies of information about you for a certain period.
      </Paragraph>
      <SubTitle3>Promotional Communications</SubTitle3>
      <Paragraph>
        You may opt out of receiving promotional communications from us by following the instructions in those communications or by emailing us support@soar.com. If you opt out, we may still send you non-promotional communications, such as messages about your account or our ongoing business relations.
      </Paragraph>
      <SubTitle3>Contact Information</SubTitle3>
      <Paragraph>
        If you have questions or comments about our Privacy Policy, please email us at support@soar.com.
      </Paragraph>
      <Paragraph>
        These Terms of Service (these “Terms”) apply to the services (the “Services”) provided by Strengths, Inc.
      </Paragraph>
      <Paragraph>
        dba Soar Uplift (“Soar,” “our,” or “we”) via https://www.soaraudio.com (the “Site”).
      </Paragraph>
    </Container>
  );
};

export default withLayout(PrivacyPolicy);