import React from 'react';
import PropTypes from 'prop-types';
import { Speaker } from './styles';

const AudioSpeaker = ({ speaker }) => (
  <Speaker>{speaker}</Speaker>
);

AudioSpeaker.propTypes = {
  speaker: PropTypes.string,
};

AudioSpeaker.defaultProps = {
  speaker: '',
};

export default AudioSpeaker;
