import React from 'react';
import IconCloud from '../../../../assets/images/icon-cloud.png';
import IconInt from '../../../../assets/images/icon-integration.png';
import ImgAGAS from '../../../../assets/images/AGAS2.png';
import ImgAI from '../../../../assets/images/AI.png';
import ImgAppGoogleButton from '../../../../assets/images/app-buttons/google-play-orig.png';
import ImgAppAppleButton from '../../../../assets/images/app-buttons/apple-store-orig.png';
import {Title2} from '../../../home/styles';
import {
  TileContainer,
  Tile,
  TileImage,
  TileTitle,
  TileText,
} from './styles';

const tiles = [
  {
    icon: IconCloud,
    title: 'Transcription',
    text: 'Soar automatically transcribes, indexes, tags, and organizes your content, enabling full-text search on mobile devices.',
    btn: true,
  },
  {
    icon: ImgAI,
    title: 'Powered by Soar AI',
    text: "Soar's AI helps your listeners find the best content for them in any given moment."
  },
  {
    icon: ImgAGAS,
    title: 'Access on any device',
    text: 'Your content can easily be searched and streamed on any voice-assistant, while you walk, drive or exercise.'
  },
  {
    icon: IconInt,
    title: '',
    text: "",
    for: true
  },
];

const Tiles = () => {

  return (
    <TileContainer>
      {tiles.map((item, index) => (
        <Tile key={`benefit-${index}`}>
          <TileImage>
            {item.btn && (
              <div>
                <a href="https://apps.apple.com/us/app/soar-audio/id1502457902"
                  alt="Soar Uplift Google Play">
                  <img src={ImgAppAppleButton} alt="Soar Uplift Apple Store" />
                </a><br />
                <a href="https://play.google.com/store/apps/details?id=com.soar.soarlibrary"
                  alt="Soar Uplift Google Play">
                  <img src={ImgAppGoogleButton} alt="Soar Uplift Google Play" />
                </a>
              </div>
            )}
            {!item.btn && <img src={item.icon} alt={item.title} />}
          </TileImage>
          <TileTitle>{item.title}</TileTitle>
          {!item.for && <TileText>{item.text}</TileText>}
          {item.for && (
            <>
              <Title2 pt="0" pb="0" width="73">Professional Associations</Title2>
              <Title2 pt="0" pb="0" width="73">Conference Organizers</Title2>
              <Title2 pt="0" pb="0" width="73">Membership Organizations</Title2>
            </>
          )}
        </Tile>
      ))}
    </TileContainer>
  );
};

export default Tiles;