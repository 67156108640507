import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../../../../shared/basic/Button';
import IconPlay from '../../../../../../../shared/icon/IconPlay';
import { GapBetweenIcons } from '../../styles';
import { VolumeIcon } from '../styles';


const LargePlay = ({ id, isActive, setAudio, isLoading, bgColor, color, ...props }) => (
  <Button
    tip="Play audio"
    disabled={!id}
    onClick={setAudio}
    bgColor={bgColor || (isActive ? '#d0d4f0' : '')}
    color={isLoading ? 'transparent' : color || ''}
    {...props}
  >
    <GapBetweenIcons>{isActive ? <VolumeIcon /> : <><IconPlay />Play</>}</GapBetweenIcons>
  </Button>
);

LargePlay.propTypes = {
  id: PropTypes.number,
  isLoading: PropTypes.bool,
  isActive: PropTypes.bool,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  setAudio: PropTypes.func.isRequired,
};

LargePlay.defaultProps = {
  id: null,
  isLoading: false,
  isActive: false,
  bgColor: '',
  color: '',
};

export default memo(LargePlay);
