import styled from 'styled-components';
import { white } from '../../../../styles/variables';
import { customMedia } from '../../../../styles/breakpoints';

export const Item = styled.div`
  display: flex;
  padding-bottom: 3rem;
  width: 90vw;

  &:first-child {
    padding-top: 4rem;
  }

  ${customMedia.greaterThan('sm')`
    width: 60vw;
  `}

  ${customMedia.greaterThan('lg')`
    max-width: 50rem;
  `}

  ${customMedia.greaterThan('xl')`
    padding-bottom: 4rem;
  `}
`;

export const Div = styled.div`
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  & img {
    vertical-align: middle;
  }
`;

export const Content = styled(Div)`
  margin: 0.5rem 0 0.9rem 1.5rem;
  font-family: Work Sans;
  font-size: 1.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  color: ${white};

  ${customMedia.greaterThan('sm')`
    margin: 0.5rem 0 0.9rem 3rem;
    font-size: 1.5rem;
  `}
`;

export const Title = styled(Content)`
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: normal;

  ${customMedia.greaterThan('sm')`
    font-size: 1.4rem;
  `}
`;

export const Img = styled.img`
  width: 5rem;
  height: 5rem;
  object-fit: contain;
  margin: 0 0.5rem 0 0;

  ${customMedia.greaterThan('sm')`
    width: 8.2rem;
    height: 8.2rem;
  `}
`;