import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { LinksTooltip } from './styles';
import Tooltip from '../../../../../../../../shared/basic/Tooltip';
import IconPlayCircle from '../../../../../../../../shared/icon/IconPlayCircle';
import IconChevronCircleDown from '../../../../../../../../shared/icon/IconChevronCircleDown';
import ButtonAlexa from '../Alexa';
import ButtonGoogle from '../Google';
import ButtonPlay from '../Play';
import ButtonShort from '../../../../../../../../shared/basic/ButtonShort';

const AudioListItem = ({ id, isLoading, globalVoicePhrase }) => {
  const [tooltipisShow, setTooltipisShow] = useState(false);
  const toogleTooltip = () => { setTooltipisShow(!tooltipisShow) };

  const configTolltip = {
    bgColor: "#7909ea",
    id: `${id}-playTip`,
    place: "top",
    effect: "solid",
    // globalEventOff: 'click',
    arrowColor: "#7909ea",
    afterShow: toogleTooltip,
    afterHide: toogleTooltip,
    opacity: 1,
    padding: '0.3rem',
    borderRadius: '2rem',
    boxShadow: '0 0 0.4rem 0 rgba(0, 0, 0, 0.5)',
    clickable: true,
  };

  return (
    <>
      <Tooltip {...configTolltip} >
        <LinksTooltip>
          <Tooltip effect='solid' />
          <ButtonGoogle id={id} isLoading={isLoading} isMobile />
          <ButtonAlexa id={id} isLoading={isLoading} isMobile />
          <ButtonPlay id={globalVoicePhrase} isLoading={isLoading} bgColor="white" color="#7909ea" isMobile />
        </LinksTooltip>
      </Tooltip>

      <ButtonShort
        dataFor={`${id}-playTip`}
        data-event-off="blur"
        disabled={!id}
        color={isLoading ? 'transparent' : ''}>
        {tooltipisShow ? <IconChevronCircleDown /> : <IconPlayCircle />}
      </ButtonShort>
    </>
  );
};

AudioListItem.propTypes = {
  globalVoicePhrase: PropTypes.any,
  id: PropTypes.any,
  isLoading: PropTypes.bool,
};

AudioListItem.defaultProps = {
  id: null,
  globalVoicePhrase: '',
  isLoading: false,
};

export default AudioListItem;
