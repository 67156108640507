import React from "react";
import PropTypes from 'prop-types';
import { H1 } from './styles';

const PageTitle = ({ title }) => <H1>{title}</H1>;

PageTitle.propTypes = {
  title: PropTypes.string
};

PageTitle.defaultProps = {
  title: '',
};

export default PageTitle;