import styled from 'styled-components';
import media from 'styled-media-query';
import IconFontAnswer from '../IconFontAnswer';

export const Wrapper = styled.div`
  align-self: start;
  display: grid;
  grid-template-columns: auto auto;
  gap: 0.5rem;
  color: #5b6185;

  ${media.lessThan('medium')`
    margin-left: 1rem;
  `}
`;

export const Icon = styled(IconFontAnswer)`
  font-size: 1.6rem;
  font-weight: ${props => props.secondary && 900};
  cursor: ${props => props.secondary && 'pointer'};
  &::after {
    color: ${props => props.secondary && '#5b6185'};
  }
`;

export const Text = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
`;
