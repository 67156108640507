import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../shared/basic/IconFontAnswer';
import { BLUE_VIOLET } from '../../../../styles/variables';
import {
  Widget as Div,
  Title,
  Footer,
} from './styles';

const Widget = props => {
  const { children, title, footer } = props;
  return (
    <>
      {title ? <Title>{title}</Title> : null}
      <Div {...props}>
        {children}
      </Div>
      {footer ? <Footer><Icon unicode="f05a" color={BLUE_VIOLET} /> {footer}</Footer> : null}
    </>
  );
};

Widget.propTypes = {
  dashed: PropTypes.bool,
  border: PropTypes.bool,
  shadow: PropTypes.bool,
  title: PropTypes.string,
  footer: PropTypes.string,
  children: PropTypes.any,
};

Widget.defaultProps = {
  border: false,
  dashed: false,
  shadow: false,
  title: null,
  footer: null,
  children: null,
};

export default Widget;