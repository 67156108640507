import React, { memo } from 'react';
import PropTypes from 'prop-types';

import CopyField from '..';
import IconGoogle from '../../../../icon/IconGoogle';

const CopyGoogle = ({ url }) =>  (
  <CopyField url={url} service="googleAssistant">
    <IconGoogle height="2.1rem" color="#4c8bf5" />
  </CopyField>
);


CopyGoogle.propTypes = {
  url: PropTypes.string,
};

CopyGoogle.defaultProps = {
  url: '',
};

export default memo(CopyGoogle);
