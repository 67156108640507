import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import SecuredRoute from '../../shared/basic/SecureRoute';
import Main from './components/Main';
import ThankYou from './components/ThankYou';

const Premium = ({ match }) => {
  return (
    <>
      <Switch>
        <SecuredRoute
          path={`${match.path}/thank-you`}
          exact
          component={ThankYou}
        />
        <SecuredRoute
          path={`${match.path}/:plan/:period`}
          exact
          component={Main}
        />
      </Switch>
    </>
  );
};

Premium.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Premium;