import styled from 'styled-components';

export const Wrapper = styled.div``;

export const ErrorMessage = styled.div`
  color: #721c24;
  background-color: #f8d7da;
  border: solid .1rem #f5c6cb;
  padding: 1.1rem 1.6rem;
  font-size: 1.3rem;
  margin-bottom: 2rem;
  border-radius: .5rem;
`;

export const WrapperFooter = styled.div`
  font-size: 1.2rem;
  color: #5c5c5e;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
`;

export const P = styled.p`
  margin: 0;
`;

export const A = styled.a`
  text-decoration: none;
  color: #ff7654;
`;

