import styled from 'styled-components';
import { customMedia } from '../../../styles/breakpoints';
import { white, black, melon, BLUE_VIOLET } from '../../../styles/variables';
import { imgs } from '../../../assets';

export const Logo = styled.img.attrs(() => ({
  src: imgs.logoWithoutText
}))`
    float: left;
    display: block;
    margin: 1.9rem 2.8rem;
    margin-right: 0;
`;

export const DivLeft = styled.div`
  height: inherit;
  float: left;
  display: none;
  align-items: center;
  height: 100%;

  ${customMedia.greaterThan('md')`
    display: flex;
  `}
`;

export const DivRight = styled.div`
  height: inherit;
  float: right;
  display: none;
  align-items: center;
  height: 100%;

  ${customMedia.greaterThan('md')`
    display: flex;
  `}
`;

export const DivMobileRight = styled(DivRight)`
  display: flex;
  margin: auto 1.9rem;

  button {
    background: transparent;
    border: none;
    font-size: 2.1rem;
  }

  ${customMedia.greaterThan('md')`
    display: none;
  `}
`;

export const LinkList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: ${props => props.showItems ? 'grid' : 'none'};

  li {
    border-bottom: 1px solid #ecedf3;
    padding: 1.5rem 2.9rem;

    &:first-child {
      border-top: 1px solid #ecedf3;
    }

    a {
      margin: 0;
    }
  }
`;

export const TopNav = styled.header`
  overflow: hidden;
  background-color: ${white};
  min-height: 8rem;
  display: grid;

  & a {
    margin: 0.8rem 2.8rem;
    font-family: Work Sans;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    float: left;
    display: block;
    color: ${black};
    text-align: center;
    text-decoration: none;

    &:hover {
      border-bottom: 0.2rem solid ${melon};
    }

    &.active {
      color: ${black};
      border-bottom: 0.2rem solid ${melon};
    }
  }

  & .icon {
    display: none;
  }

  ${customMedia.greaterThan('md')`
    .topnav a:not(:first-child) {display: none;}
    .topnav a.icon {
      float: right;
      display: block;
    }

    .topnav.responsive {position: relative;}
    .topnav.responsive .icon {
      position: absolute;
      right: 0;
      top: 0;
    }
    .topnav.responsive a {
      float: none;
      display: block;
      text-align: left;
    }
  `}
`;

export const Signup = styled.a`
  color: ${white} !important;
  text-decoration: none !important;
  background-color: ${melon};
  padding: 1rem 2rem;
  border-radius: 4rem;
  border: 0.2rem solid ${melon};

  &:hover {
    color: ${melon} !important;
    border: 0.2rem solid ${melon};
    background-color: ${white};
  }
`;
