import { connect } from 'react-redux';
import {
  fetchGroupDetail,
  updateGroup,
  setNewGroupDetail,
  fetchGroupMembers,
  setDeleteOpts,
} from '../../actions/my-library-actions';
import GroupForm from './';

const mapStateToProps = state => ({
  groupDetail: state.library.groupDetail,
  newGroupDetail: state.library.newGroupDetail,
  isLoadingGroup: state.library.isLoadingGroup,
  isLoadingMembers: state.library.isLoadingMembers,
  groupFormError: state.library.groupFormError,
  formSubmitSuccess: state.library.formSubmitSuccess,
  groupMembers: state.library.groupMembers,
});

const mapDispatchToProps = {
  fetchGroupDetail: groupId => fetchGroupDetail(groupId),
  updateGroup: () => updateGroup(),
  setNewGroupDetail: group => setNewGroupDetail(group),
  fetchGroupMembers: groupId => fetchGroupMembers(groupId),
  setDeleteOpts: opts => setDeleteOpts(opts),
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupForm);