import styled from 'styled-components'
import ReactTooltip from 'react-tooltip';

export const StyledTooltip = styled(ReactTooltip)`
  color: ${props => `${props.color} !important`};
  background-color: ${props => `${props.bgColor} !important`};
  width: ${props => `${props.width} !important`};
  height: ${props => `${props.height} !important`};
  padding: ${props => props.padding};
  border-radius: ${props => props.borderRadius};
  border: ${props => props.border};
  box-shadow: ${props => props.boxShadow};
  opacity: ${props => `${props.opacity} !important`};
  cursor: default !important;
`;
