export const PLANS = {
  SINGLE: {
    MONTHLY: {
      id: process.env.REACT_APP_PLAN_ID_SINGLE_MONTHLY,
      price: process.env.REACT_APP_PLAN_PRICE_SINGLE_MONTHLY,
    },
    YEARLY: {
      id: process.env.REACT_APP_PLAN_ID_SINGLE_YEARLY,
      price: process.env.REACT_APP_PLAN_PRICE_SINGLE_YEARLY,
    }
  },
  UNLIMITED: {
    MONTHLY: {
      id: process.env.REACT_APP_PLAN_ID_UNLIMITTER_MONTHLY,
      price: process.env.REACT_APP_PLAN_PRICE_UNLIMITTER_MONTHLY,
    },
    YEARLY: {
      id: process.env.REACT_APP_PLAN_ID_UNLIMITTER_YEARLY,
      price: process.env.REACT_APP_PLAN_PRICE_UNLIMITTER_YEARLY,
    }
  }
};

export const NEW_PLANS = [
  {
    index: 0,
    name: 'Single Channel',
    period: 'Monthly',
    key: 'price_HMuZqsGB7zeb2i',
    price: 4.99,
    link: 'zoom-single-user',
  },
  {
    index: 1,
    name: 'Unlimited Channels',
    period: 'Monthly',
    key: 'price_HMuZKnxPqvnP3v',
    price: 9.99,
    link: 'zoom-unlimited-user',
  },
  {
    index: 2,
    name: 'Single Channel',
    period: 'Yearly',
    key: 'price_HN01mSFl2JBtRO',
    price: 29.94,
    link: 'zoom-single-user',
  },
  {
    index: 3,
    name: 'Unlimited Channels',
    period: 'Yearly',
    key: 'price_HN026aJyvfhxo7',
    price: 59.94,
    link: 'zoom-unlimited-user',
  }
];

export const CARD_TYPES = [
  {
    src: '/img/creditCardFlags/visa.png',
  },
  {
    src: '/img/creditCardFlags/master.png',
  },
  {
    src: '/img/creditCardFlags/american_express.png',
  },
  {
    src: '/img/creditCardFlags/discover.png',
  },
];

export const STRIPE_FLAG = [
  {
    src: '/img/stripe/powered_stripe.png',
  },
]

export const PAY_OPTIONS = [
  {
    paymentOption: {
      id: 'existing-payment-option',
      name: 'paymentOption',
      value: 'existingCard',
      title: 'Use an existing payment card',
      flags: STRIPE_FLAG,
    },
    paymentForm: {
      mode: 'existing',
    },
  },
  {
    paymentOption: {
      id: 'new-payment-option',
      name: 'paymentOption',
      value: 'newCard',
      title: 'New payment card',
      flags: CARD_TYPES,
    },
    paymentForm: {
      mode: 'new',
    },
  },
];

export const CHANNELS = [
  {
    id: 0,
    title: 'SHRM',
    description: '​​​​​​​​​​​​​​​Join 300,000+ professionals from​ around the world who rely on SHRM for their HR solutions.​​​​​',
    value: 4.99,
    period: 'monthly',
    img: '/img/checkout/shrm-logo.png'
  },
];
