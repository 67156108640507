import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { Wrapper, LinksDesktop, LinksMobile, Audio } from './styles';
import ButtonAlexa from '../../../../Playlist/List/Item/Buttons/Alexa';
import ButtonGoogle from '../../../../Playlist/List/Item/Buttons/Google';
import ButtonPlay from '../../../../Playlist/List/Item/Buttons/Play';
import ButtonPlayMobile from '../../../../Playlist/List/Item/Buttons/PlayMobile';
import Title from './Labels/Title';
import Time from './Labels/Time';

const timeFormat = date => 
  moment.utc(date).format('yyyy-mm-DD')

const PlaylistListItem = ({ id, title, date, isLoading, audioCount }) => {
  const { id: currentyAudioId } = useSelector(state => state.publicLibrary.currentyAudio);
  const isActive = currentyAudioId === id;

  return (
    <Wrapper backgroundColor={isActive ? '#eef0ff' : '#fff'}>

      <Title gridArea='title' text={title} isActive={isActive} />

      <Time gridArea='time' text={timeFormat(date)} />

      <Audio gridArea='audio'>audios {audioCount}</Audio>

      <LinksDesktop gridArea='links'>
        <ButtonAlexa id={id} isLoading={isLoading} />
        <ButtonGoogle id={id} isLoading={isLoading} />
        <ButtonPlay id={id} isLoading={isLoading} />
      </LinksDesktop>

      <LinksMobile gridArea='links'>
        <ButtonPlayMobile id={id} isLoading={isLoading} />
      </LinksMobile>

    </Wrapper>
  );
};

PlaylistListItem.propTypes = {
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  date: PropTypes.string,
  audioCount: PropTypes.number,
  id: PropTypes.any,
};

PlaylistListItem.defaultProps = {
  id: '',
  title: '',
  date: '',
  isLoading: false,
  audioCount: 0,
};

export default PlaylistListItem;
