import { connect } from "react-redux";
import Audio from './';
import { fetchAudioData } from '../../../redux/actions/audio-actions';

const mapStateToProps = state => ({
  isLoading: state.publicAudio.isLoading,
  isLoadingList: state.publicAudio.isLoadingList,
  errorMessage: state.publicAudio.errorMessage,
  audio: state.publicAudio.audio,
  playlistList: state.publicAudio.playlistList,
});

const mapDispatchToProps = {
  fetchAudioData: voicePharse => fetchAudioData(voicePharse),
};

export default connect(mapStateToProps, mapDispatchToProps)(Audio);