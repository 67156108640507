import React from 'react';
import { useSelector } from 'react-redux';
import Header from '../Header';
import Footer from '../Footer';
import LoaderBubble from '../../basic/LoaderBubble';

const withLayout = (Page) => props => {
  const isLoadingPage = useSelector(state => state.generalInfo.isLoadingPage);

  return isLoadingPage ? <LoaderBubble /> : (
    <>
      <Header />
      <Page {...props} />
      <Footer />
    </>
  );
};

export default withLayout;
