/* eslint-disable default-case */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { melon } from '../../../../styles/variables';
import { Table as TableTag, Icon } from './styles';
import { Link } from 'react-router-dom';
import ButtonPlay from './Button/Play';
import { urlAudioGenerator } from '../../../../shared/helper/openPlaylistLink';
import ButtonShare from '../../../../shared/basic/Share';

const Th = prosp => {
  return <th style={{ whiteSpace: 'nowrap' }}>{prosp.children}</th>;
};

const Td = prosp => {
  return <td style={{ whiteSpace: 'nowrap' }}>{prosp.children}</td>;
};

const Tr = prosp => {
  return <tr>{prosp.children}</tr>;
};

const makeAction = (
  actions = [],
  handlePlaylist,
  viewUrl,
  editUrl,
  rows,
  rowKey,
  rowLabel,
  type,
  handleDelete,
  voicePhrase,
  noActions
) => {
  let button = null;

  const copySoar = urlAudioGenerator({ id: rowKey, voicePhrase }, 'soar');
  const shareConfig = {
    copyGoogle: urlAudioGenerator({ id: rowKey, voicePhrase }, 'googleAssistant'),
    copyAlexa: urlAudioGenerator({ id: rowKey, voicePhrase }, 'googleAssistant'),
    copySoar: copySoar,
    facebook: copySoar,
    twitter: copySoar,
    linkedin: copySoar,
    email: copySoar,
  };

  if (noActions && type === 'audio') {
    return (
      <>
        Converting ...&nbsp;
        <Icon
          key={rowKey}
          unicode="f2ed"
          color={melon}
          data-key={rowKey}
          data-label={rowLabel}
          data-type={type}
          onClick={event => handleDelete(event)}
        />
      </>
    );
  }

  return actions.map(action => {
    const key = () => (Math.random()*(100*rowKey)).toFixed();

    switch(action) {
      case 'view':
        button = !viewUrl ? null : (
          <Link key={key()} to={`${viewUrl}`}><Icon key={key()} unicode="f06e" color={melon} /></Link>
        );
        break;
      case 'playlist':
        button = <Icon
          key={key()}
          data-key={rowKey}
          unicode="f550"
          color={melon}
          onClick={handlePlaylist}
        />
        break;
      case 'edit':
        button = <Link key={key()} to={`${editUrl}/${rowKey}`}><Icon unicode="f044" color={melon} /></Link>
        break;
      case 'delete':
        button = <Icon
          key={key()}
          unicode="f2ed"
          color={melon}
          data-key={rowKey}
          data-label={rowLabel}
          data-type={type}
          onClick={event => handleDelete(event)}
        />
        break;
      case 'share':
        button = <ButtonShare
          isLoading={false}
          margin=' 0 0 0 2rem'
          {...shareConfig}
        />
        break;
      case 'play':
        button = <ButtonPlay
          key={key()}
          margin=' 0 0 0 2rem'
          color="#ffffff"
          id={rowKey}
          isLoading={false}
          items={rows}
        />
        break;
    }
    return button;
  });
};

const Table = props => {
  const {
    columns,
    rows,
    actions,
    handlePlaylist,
    viewUrl,
    editUrl,
    handleDelete,
    recordType,
  } = props;

  const defaultParser = data => data;
  const titles = columns.names.map((column, idx) => {
    const tdKey = `${column.replace(' ', '')}-${idx}`;
    return <Th key={tdKey}>{column}</Th>;
  });

  const allRows = rows.map((row, index) => {
    const rowKey = columns?.rowKey ? row[columns.rowKey] : '0';
    const key = columns?.rowKey ? row[columns.rowKey] : index;
    const label = columns?.deleteDescription ? row[columns.deleteDescription] : 'the record';
    const viewParserUrl = columns?.parsers && columns.parsers.viewUrl
      ? columns.parsers.viewUrl
      : value => `${value}/${rowKey}`;
    const noActions = row.audioSeconds <= 0;

    return (
      <Tr key={key}>
        {columns.rowsRef.map((rowRef, idx) => {
          const tdKey = `${key}-${idx}`;
          const parser = columns?.parsers && columns.parsers[rowRef]
            ? columns.parsers[rowRef]
            : defaultParser;

          return <Td key={tdKey}>{parser(row[rowRef])}</Td>;
        })}
        <td>
          {makeAction(
            actions,
            handlePlaylist,
            viewParserUrl(viewUrl, row),
            editUrl,
            rows,
            rowKey,
            label,
            recordType,
            handleDelete,
            row.voicePhrase,
            noActions
          )}
        </td>
      </Tr>
    );
  });

  return (
    <TableTag border="0">
      <thead>
        <tr>
          {titles}
          <th />
        </tr>
      </thead>
      <tbody>
        {allRows}
      </tbody>
    </TableTag>
  );
};

Table.propTypes = {
  label: PropTypes.string,
  recordType: PropTypes.string,
  viewUrl: PropTypes.string,
  editUrl: PropTypes.string,
  columns: PropTypes.object.isRequired,
  actions: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  handlePlaylist: PropTypes.func,
  handleDelete: PropTypes.func,
};

Table.defaultProps = {
  handlePlaylist: () => {},
  handleDelete: callback => callback(),
  viewUrl: '#',
  editUrl: '',
  recordType: 'audio',
};

export default memo(Table);