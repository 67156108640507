import React, { memo } from "react";
import PropTypes from 'prop-types';

import { GapBetweenIcons } from "../styles";
import Button from "../../../../../../../../../shared/basic/Button";
import IconPlay from "../../../../../../../../../shared/icon/IconPlay";
import IconAlexa from "../../../../../../../../../shared/icon/IconAlexa";
import { openAudioLink } from "../../../../../../../../../shared/helper/openPlaylistLink";

const ButtonAlexa = ({ id, isLoading, bgColor, color, ...props }) => (
  <Button
    tip="Open playlist on Alexa Skills"
    disabled={!id}
    onClick={() => openAudioLink("alexaSkills", id)}
    bgColor={bgColor || '#57b4ef'}
    color={isLoading ? 'transparent' : color || ''}
    {...props}
  >
    <GapBetweenIcons><IconPlay /><IconAlexa /></GapBetweenIcons>
  </Button>
);

ButtonAlexa.propTypes = {
  id: PropTypes.any,
  isLoading: PropTypes.bool,
};

ButtonAlexa.defaultProps = {
  id: null,
  isLoading: false,
};

export default memo(ButtonAlexa);
