import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import LargePlay from '../../../../../../../shared/components/Button/Play/Large'
import { setCurrentyAudio } from '../../../../../../../redux/actions/library-actions';

const ButtonPlay = ({ id, isLoading, bgColor, color, ...props }) => {
  const dispatch = useDispatch();
  const { id: currentyAudioId } = useSelector(state => state.publicLibrary.currentyAudio);
  const { items } = useSelector(state => state.publicLibrary.currentyPlaylist);
  const attributes = {
    id,
    isActive: currentyAudioId === id,
    setAudio: () => dispatch(setCurrentyAudio(items.find(item => item.id === id))),
    isLoading,
    bgColor,
    color,
    ...props
  };

  return <LargePlay {...attributes} />
};

ButtonPlay.propTypes = {
  id: PropTypes.any,
  isLoading: PropTypes.bool,
};

ButtonPlay.defaultProps = {
  id: null,
  isLoading: false,
};

export default ButtonPlay;
