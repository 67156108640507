import { connect } from 'react-redux';
import {
  fetchPlaylist,
  setNewPlaylist,
  pushAudioToPlaylist,
  pushNewPlaylistWithAudio,
  failPlaylistCreate,
} from '../../actions/my-library-actions';
import AddPlaylistForm from './';

const mapStateToProps = state => ({
  newPlaylist: state.library.newPlaylist,
  playlistErrorMessage: state.library.playlistErrorMessage,
  isLoadingPlaylist: state.library.isLoadingPlaylist,
  playlists: state.library.playlists,
  isCreatingPlaylistAudio: state.library.isCreatingPlaylistAudio,
  modalSubmitSuccess: state.library.modalSubmitSuccess,
});

const mapDispatchToProps = {
  fetchPlaylist: () => fetchPlaylist(),
  setNewPlaylist: playlist => setNewPlaylist(playlist),
  pushAudioToPlaylist: playlist => pushAudioToPlaylist(playlist),
  pushNewPlaylistWithAudio: () => pushNewPlaylistWithAudio(),
  failPlaylistCreate: (message) => failPlaylistCreate(message),
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPlaylistForm);