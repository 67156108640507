import styled from 'styled-components';
import { Tabs as BootstrapTabs, Tab as BootstrapTab } from 'react-bootstrap';
import media from 'styled-media-query';
import ReactSwitch from 'react-switch';
import { melon, white, lightBlueGrey, lightGrey } from '../../../../styles/variables';

// Start - Components mobile
export const Tabs = styled(BootstrapTabs)`
    justify-content: center;
`;

export const TabTitle = styled.div`
    padding: 1rem 2.6rem;
    font-size: 1.7rem;
`;

export const Tab = styled(BootstrapTab)`
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
`;
// End - Components mobile

export const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-top: ${props => props.paddingTop};
  padding-bottom: ${props => props.paddingBottom};
  width: 68.06vw;
  background-color: ${props => props.backgroundColor};

  ${media.lessThan('large')`width: 93vw;`}
  ${media.lessThan('medium')`width: 100vw;`}
`;

export const HeaderLine = styled.div`
    display: grid;
    gap: 0px;
    grid-template-columns: 47fr 64fr;

    ${media.lessThan('medium')`
        grid-template-columns: 1fr;
    `}
`

export const Title = styled.h1`
    margin: 0 auto;
    padding: 1rem 0;

    ${media.lessThan('medium')`
        padding: 2rem;
    `}
`;


export const HeaderSwitch = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    ${media.lessThan('medium')`
        grid-column: span 2;
        padding: 2rem 0
    `}
`;

export const Switch = styled(ReactSwitch)`
  margin: 0 1.5rem 0.2rem 1.5rem;
`;

export const SwitchLabel = styled.div`
  font-size: 1.5rem;
  color: #000000;
  font-weight: ${props => props.fontWeight};
`;

export const HeaderPlan = styled.div`
    background-color: ${white};
    text-align: center;
    font-size: 2rem;
    border-radius: 1rem 1rem 0px 0px ;
    border-top: 1px solid ${lightBlueGrey};
    border-left: 1px solid ${lightBlueGrey};
    border-right: 1px solid ${lightBlueGrey};
    padding: 2rem 2.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    a, span {
        font-size: 1.3rem;
        padding: 1.2rem 1.8rem;
        background-color: ${melon};
        border-radius: 0.5rem;
        margin: 2rem;
        display: inline-block;
        color: ${white};
        cursor: pointer;
    }
    

    ${media.lessThan('medium')`
        border: none;
    `}
    
`

export const HeaderPlanLarge = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    ${media.lessThan('medium')`
        display: none;
    `}
`

export const HeaderPlanMedium = styled.div`
        display: none;
    ${media.lessThan('medium')`
        display: unset;
    `}
`

export const Description = styled.div`
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  padding: 2rem 0;
`;

export const Block = styled.div`
    color: #555;
    background-color: ${white};
    font-size: 14px;
    i{
        color: ${melon};
        font-size: 21px;
    }
`

export const BlockLine = styled.div`
    display: grid;
    justify-items: stretch;
    gap: 0px;
    grid-template-columns: 47fr 32fr 32fr;
    border-bottom: 1px solid ${lightBlueGrey};
    border-right: 1px solid ${lightBlueGrey};

    ${media.lessThan('medium')`
        grid-template-columns: 47fr 32fr;
    `}
`
export const BlockCell = styled.div`
    padding: 2rem 2.2rem;
    border-left: 1px solid ${lightBlueGrey};
`

export const BlockCellValue = styled.div`
    padding: 2rem 2.2rem;
    border-left: 1px solid ${lightBlueGrey};
    text-align: center;
`

export const BlockTitleLine = styled.div`
    grid-column: 1 / span 3;
    display: flex;
    position: relative;
    height: 100%;
    background-color: ${lightGrey};
    padding: 2rem 2.2rem;
    border: 1px solid ${lightBlueGrey};
    font-size: 1.7rem;
    font-weight: 500;
`