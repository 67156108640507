import React from 'react';
import ReactPlayer from "react-player";
import {
  VideoContainer,
  VideoPlayers,
  VideoPlayer,
  VideoTextTitle,
  VideoText,
  BenefitsContainer,
  BenefitIndex,
  BenefitItem,
  BenefitText,
} from './styles';
import {
  CTAButton,
} from '../../styles';

const benefits = [
  "Shake the dust off your conference content and bring it back to life",
  "Create shareable playlists of conference highights, can't miss moments, and key takeaways" ,
  "Give your community access to content anytime, anywhere on any device",
  "Let Soar's AI-powered learning system deliver ongoing and sustained learning moments over time",
  "Full-text search of all content available right in your pocket(iPhone and Android)",
  "Let your community learn through voice-interaction while walking, exercising or even driving"
];

const Video = () => {

  return (
    <VideoContainer>
      <VideoPlayers>
      <VideoPlayer>
          <ReactPlayer url="https://youtu.be/GQk6FiQ8pL0" width="100%" height="100%" />
        </VideoPlayer>
      </VideoPlayers>
      <VideoText>
        <VideoTextTitle>Key Benefits</VideoTextTitle>
        <BenefitsContainer>
          {benefits.map((item, index) => (
            <BenefitItem key={`benefit-${index}`}>
              <BenefitIndex>{index+1}.</BenefitIndex>
              <BenefitText>{item}</BenefitText>
            </BenefitItem>
          ))}
          <CTAButton href="https://app.soar.com/clint?isSchedule=true">Schedule a Call to Learn More</CTAButton>
        </BenefitsContainer>
      </VideoText>
    </VideoContainer>
  );
};

export default Video;