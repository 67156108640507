import React from 'react';
import withLayout from '../../shared/layout/hocs/withLayout';
import Container from '../../shared/layout/Container';
import { Title, Paragraph } from '../../shared/layout/CustomContent/styles';

const Contact = () => {
  return (
    <Container>
      <Title>Contact Us</Title>
      <div>
      <script type="text/javascript" src="https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js"></script>
      <style type="text/css" media="screen, projection">
        @import url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css); 
      </style> 
      <iframe title="Feedback Form" className="freshwidget-embedded-form" id="freshwidget-embedded-form"
        src="https://soarhelp.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&formTitle=Soar+Audio+Support&submitTitle=Contact+Us&searchArea=no"
        scrolling="no" height="550px" width="100%" frameborder="0" >
      </iframe>
      </div>
    </Container>
  );
};

export default withLayout(Contact);