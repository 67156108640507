import React from 'react';
import Container from '../../../shared/layout/Container';
import withLayout from '../../../shared/layout/hocs/withLayout';

import { Wrapper, H1, H2, Img, Ol } from './styles';

const ZoomIntegrationIndividual = () => (
  <Container>
    <Wrapper>
      <H1>Soar - for individual accounts Zoom Marketplace App</H1>

      <H2>Overview</H2>
      <p>
        This documentation will guide you through the steps of installing and uninstalling Soar -
        for individual accounts in the Zoom Marketplace. When you install the Soar Zoom
        integration, all of your recordings will be saved for future search of the content of that
        call. The search functionality is available in the Soar Mobile app and the Soar Audio
        Alexa and Google Assistant apps.
      </p>
      <p>To download the Soar Mobile App, visit the store of your choice:</p>
      <a href='https://play.google.com/store/apps/details?id=com.soar.soarlibrary'>
        Google’s Play Store
      </a>
      <a href='https://apps.apple.com/us/app/soar-audio/id1502457902'>
        Apple’s App Store
      </a>
      <p>To activate the Soar Audio Voice App, visit the store of your choice:</p>
      <a href='https://alexa-skills.amazon.com/apis/custom/skills/amzn1.ask.skill.fb16296e-0805-4565-a16f-d5768de17112/launch'>
        Alexa
      </a>
      <a href='https://assistant.google.com/services/a/uid/000000850f4057ae?hl=en_us'>
        Google Assistant
      </a>


      <H2>Prerequisites</H2>
      <ul>
        <li>A Soar Audio account.</li>
        <li>A Zoom User account on a Pro/Business/Education/Enterprise level.</li>
      </ul>

      <H1>How to install Soar - for individual accounts</H1>

      <Ol>
        <li>
          Go to <a href='https://my.soar.com/join'>https://my.soar.com/join</a> and log in with
          your Soar account or sign up for one.
          <br/>
          <Img src="/img/tutorial/zoomIntegration/Individual/01.jpg" alt="Join SOAR Audio" />
        </li>
        <li>
          At the top of the page, click on the <b>Go to personal library</b> button.
          <br/>
          <Img src="/img/tutorial/zoomIntegration/Individual/02.jpg" alt="SOAR Universal Library" />
        </li>
        <li>
          At the top of the page, click on the <b>Connect with zoom</b> button.
          <br />
          <Img src="/img/tutorial/zoomIntegration/Individual/03.jpg" alt="Person Library" />
        </li>
        <li>
          You will be redirected to the Zoom Authorization page. Click on the <b>Authorize</b> button.
          <br />
          <Img src="/img/tutorial/zoomIntegration/Individual/04.jpg" alt="Your Zoom" />
        </li>
        <li>
          Once authorized, you will be redirected back to your Soar Library, and the Zoom
          connection will be complete. The <b>Connected to zoom</b> label will appear.
          <br />
          <Img src="/img/tutorial/zoomIntegration/Individual/05.jpg" alt="Shared with me" />
        </li>
      </Ol>
      <p>
        <b>IMPORTANT</b>: When you create your Soar Audio account, use the same email address
        of your Zoom account. This will make sure your recordings are associated with your
        same account in Soar Audio.
      </p>

      <H2>Usage</H2>

      <p>
        Check out this video demonstration of how the Zoom Integration for Soar Audio works using
        your Soar mobile app and Google Assistant voice app.
      </p>
      <p>
        <a href='https://www.youtube.com/watch?v=qaQF40FQM2I'>
          https://www.youtube.com/watch?v=qaQF40FQM2I
        </a>
      </p>
      <p>
        <b>IMPORTANT</b>: With the Soar - for individual accounts integration, you will be able to search
        content in calls longer than 10 minutes.
      </p>

      <H1>How to uninstall Soar - for individual accounts</H1>

      <Ol>
        <li>
          Go to your installed apps in your Zoom account following
          this <a href='https://marketplace.zoom.us/user/installed'>link</a>.
          Find the Soar Zoom Integration and click on the Uninstall button.
          <br/>
          <Img src="/img/tutorial/zoomIntegration/Individual/06.jpg" alt="Installed Apps" />
        </li>
        <li>
          A dialog will show up with the option to grant the app consent to retain data. As
          explained in the box, you may want to allow us to retain your information in case you
          want to come back in the future and find your recordings to search content using the
          Mobile App or the assistant apps. If you don’t check this box, we will proceed with the
          deletion of your recordings from our system.
          <br />
          <Img src="/img/tutorial/zoomIntegration/Individual/07.jpg" alt="Uninstall App" />
        </li>
      </Ol>

    </Wrapper>
  </Container>
);

export default withLayout(ZoomIntegrationIndividual);

