import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Icon, Span } from './styles';
class SecureMark extends Component {
  static propTypes = {
    children: PropTypes.string.isRequired,
  };

  render() {
    const { children } = this.props;

    return (
      <Wrapper>
        <Icon unicode="f2f7" />
        <Span>{children}</Span>
      </Wrapper>
    );
  }
}

export default SecureMark;
