import { Record } from 'immutable';

const AuthorRecord = Record({
  id: null,
  familyName: '',
  givenName: '',
});

AuthorRecord.parse = values => {
  return new AuthorRecord({ ...values });
};

export default AuthorRecord;
