import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup as Div,
  Label,
  Helper,
} from './styles';

const FormGroup = ({ children, title, helper }) => (
  <Div>
    <Label>{title}</Label>
    {children}
    {helper && <Helper>{helper}</Helper>}
  </Div>
);

FormGroup.propTypes = {
  title: PropTypes.string.isRequired,
  helper: PropTypes.string,
  children: PropTypes.any,
};

FormGroup.defaultProps = {
  children: null,
  helper: '',
}

export default FormGroup;