import React from 'react';
import PropTypes from 'prop-types';

import {
  Wrapper,
  Highlighter,
  WrapperTitle,
  Title,
  MobileGoBack,
  DesktopGoBack,
  ArrowLeft,
  WrapperSubtitle,
  Subtitle,
  Author,
  WrapperShare
} from './styles';
import { goTo } from '../../../../../shared/helper/goTo';
import SharePlaylist from './Buttons/Share';
import Section from '../../../../../shared/layout/Section';
import Container from '../../../../../shared/layout/Container';
import { SMALL } from '../../../../../shared/constants/breakpoint-constants';
import withWindowResize from '../../../../../shared/hocs/withWindowResize';

const PlaylistDetailHeader = props => {
  const {
    width,
    showButton,
    urlBackButton,
    label,
    textButton,
    shareLabel,
    title,
    author,
    shareProps,
  } = props;
  const style = width.lessThan(SMALL)
    ? { paddingTop: '1rem', paddingBottom: '0' }
    : { paddingBottom: '0' };
  const handleGoBackClick = () => goTo(urlBackButton);

  return (
    <Section>
      <Container {...style}>
        <Wrapper>
          {showButton && <MobileGoBack onClick={handleGoBackClick}><ArrowLeft /> {textButton}</MobileGoBack>}

          {/* <Highlighter>{label}</Highlighter> */}

          <WrapperTitle>
            <Title>{title || '...'}</Title> 
            {/* <SharePlaylist {...shareProps} /> */}
            {showButton && <DesktopGoBack onClick={handleGoBackClick}><ArrowLeft /> {textButton}</DesktopGoBack>}
          </WrapperTitle>

          <WrapperSubtitle>
            <Subtitle><span>by</span> <Author>{author || '...'}</Author></Subtitle>
            <WrapperShare>Share this {shareLabel}: <SharePlaylist {...shareProps} /></WrapperShare>
          </WrapperSubtitle>
        </Wrapper>
      </Container>
    </Section>
  )
};

PlaylistDetailHeader.propTypes = {
  showButton: PropTypes.bool,
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  label: PropTypes.string,
  textButton: PropTypes.string,
  shareLabel: PropTypes.string,
  urlBackButton: PropTypes.string,
  width: PropTypes.object.isRequired,
  shareProps: PropTypes.object.isRequired,
};

PlaylistDetailHeader.defaultValues = {
  showButton: true,
  label: 'PLAYLIST',
  urlBackButton: '/playlist/list',
  textButton: 'Back to Playlists',
  shareLabel: 'Playlist',
};

export default withWindowResize(PlaylistDetailHeader);