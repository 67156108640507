import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import {
  setErrorMessageSubscription,
  setIsLoadingSubscription,
  setFieldGivenName,
  setFieldFamilyName,
  setFieldEmail,
  setFieldCardIsValid,
  setFieldEmailWasVerified,
  setFieldEmailIsValid,
  getUserByEmail,
  getPlansUser,
  alterPlan,
  setSuccessMessageSubscription,
  setFieldEmailIsNewUser,
  setFieldPassword,
  setFieldPasswordWasVerified,
  setFieldPasswordIsValid,
} from '../../../Redux/actions/subscription-actions';
import {
  Form,
  WrapperDescription,
  PlanName,
  Billed,
  Price,
  Label,
  InputName,
  InputEmail,
  InputPassword,
  WrapperInput,
  Danger,
  Button
} from './styles';
import LoaderBubble from '../../../../../shared/basic/LoaderBubble';
import { isEmail } from '../../../../../shared/helper/ValidationField';
import { login, signUp } from '../../../../../shared/helper/sign';

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#a0a6cc',
      color: '#000000',
      fontFamily: 'inherit',
      fontSize: '15px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#000000' },
      '::placeholder': { color: '#5c5c5e' },
    },
    invalid: {
      iconColor: '#842029',
      color: '#842029',
    },
  },
};

const FormNewCard = () => {
  const dispatch = useDispatch();
  const { id: planId, priceTotal, ...planObj } = useSelector(state => state.pricePlans.planObj);
  const fieldGivenName = useSelector(state => state.pricePlans.fieldGivenName);
  const fieldFamilyName = useSelector(state => state.pricePlans.fieldFamilyName);
  const fieldEmail = useSelector(state => state.pricePlans.fieldEmail);
  const fieldPassword = useSelector(state => state.pricePlans.fieldPassword);
  
  const fieldCardIsValid = useSelector(state => state.pricePlans.fieldCardIsValid);
  const errorMessageSubscription = useSelector(state => state.pricePlans.errorMessageSubscription);
  const isLoadingSubscription = useSelector(state => state.pricePlans.isLoadingSubscription);
  const period = useSelector(state => state.pricePlans.period);

  const stripe = useStripe();
  const elements = useElements();

  const handleChangeGivenName = ({ target: { value } }) => {
    dispatch(setFieldGivenName(value));
  };

  const handleChangeFamilyName = ({ target: { value } }) => {
    dispatch(setFieldFamilyName(value));
  };
  const handleChangeEmail = ({ target: { value } }) => {
    dispatch(setErrorMessageSubscription(''));
    dispatch(setFieldEmail(value));
    dispatch(setFieldEmailWasVerified(false));
    dispatch(setFieldEmailIsValid(false));
  };
  const handleChangePassword = ({ target: { value } }) => {
    dispatch(setErrorMessageSubscription(''));
    dispatch(setFieldPassword(value));
    dispatch(setFieldPasswordWasVerified(false));
    dispatch(setFieldPasswordIsValid(false));
  };
  const handleChangeCard = e => {
    dispatch(setErrorMessageSubscription(''));
    dispatch(setFieldCardIsValid(e.complete));
  }

  const onKeyDown = event => {
    if (event.key === 'Enter') {
      debugger;
      event.preventDefault();
      event.stopPropagation();
      if(!buttonDisable && fieldCardIsValid){
        handleSubmit(event);
      }
    }
  }
  const handleSubmit = async e => {
    e.preventDefault();
    if(buttonDisable && !fieldCardIsValid){
      return;
    }
    dispatch(setIsLoadingSubscription(true));
    dispatch(setErrorMessageSubscription(''));

    if (isEmail(fieldEmail)) {
      try {
        const data = { user: {}, coachPlans: {} };

        sessionStorage.removeItem('userTMP');
        sessionStorage.removeItem('tokenTMP');
        sessionStorage.removeItem('token_API_audience_TMP');
        sessionStorage.removeItem('refreshToken');
        sessionStorage.removeItem('idAuth0TMP');

        try { // Step 1/4: get user
          const dataUser = await getUserByEmail(fieldEmail);
          if (dataUser.id) {
            data.user = dataUser;
            await login({ email: fieldEmail, password: fieldPassword, redirect: false }); 
          } else data.user = await signUp({ 
            isLogin: false,
            email: fieldEmail,
            password: fieldPassword,
            givenName:fieldGivenName,
            familyName: fieldFamilyName,
            redirect: false,
          });
          dispatch(setFieldEmailIsNewUser(!data.user.auth0Id));
        } catch (e) { throw new Error('Server error'); }

        try { // Step 2/4: get user plans
          data.coachPlans = await getPlansUser(data.user.id);
        } catch (e) { throw new Error('Server error'); }

        try { // Step 3/4: get stripe token
          const element = elements.getElement(CardElement);
          const { token } = await stripe.createToken(element, { type: 'card' });
          const { coachPlans } = data;
          const { user: { id: userId } } = data;
          if (token && token.id && token.card) { // Step 4/4: set user plan
            await alterPlan({ cardToken: token.id, userId, coachPlans, newPlanId: planId })
              .then(() => {
                dispatch(setSuccessMessageSubscription('Thank you!'));
              });
          } else throw new Error('Stripe token error');
        } catch (e) { throw new Error(e.message); }
      } catch (e) {
        dispatch(setErrorMessageSubscription(e.message));
      }
    } else {
      dispatch(setFieldEmailWasVerified(true));
      dispatch(setFieldEmailIsValid(false));
    }
    dispatch(setIsLoadingSubscription(false));
  };

  const buttonDisable = !isEmail(fieldEmail) || !fieldCardIsValid || isLoadingSubscription;

  return (
    <Form onSubmit={handleSubmit}>

      {isLoadingSubscription && <LoaderBubble />}

      <WrapperDescription>
        <PlanName>{planObj.name}</PlanName>
        <Billed>Billed {period}</Billed>
        <Price>${priceTotal} {planObj.priceCurrency}</Price>
      </WrapperDescription>

      <WrapperInput>
        <Label>
          First Name
          <InputName name="givenName" value={fieldGivenName} onChange={handleChangeGivenName} onKeyDown={e => onKeyDown(e)} />
        </Label>
      </WrapperInput>
      <WrapperInput>
        <Label>
          Last Name
          <InputName name="familyName" value={fieldFamilyName} onChange={handleChangeFamilyName} onKeyDown={e => onKeyDown(e)} />
        </Label>
      </WrapperInput>

      <WrapperInput>
        <Label>
          Email
          <InputEmail name="email" value={fieldEmail} onChange={handleChangeEmail} onKeyDown={e => onKeyDown(e)} />
        </Label>
      </WrapperInput>

      <WrapperInput>
        <Label>
          Password
          <InputPassword name="password" value={fieldPassword} onChange={handleChangePassword} onKeyDown={e => onKeyDown(e)} />
        </Label>
      </WrapperInput>

      <WrapperInput>
        <CardElement
          onChange={handleChangeCard}
          options={CARD_OPTIONS}
          onKeyDown={e => onKeyDown(e)}
        />
      </WrapperInput>

      {errorMessageSubscription && (
        <Danger>
          Ops! Something went wrong, please reload this page or contact our support team.
        </Danger>
      )}

      <Button disabled={buttonDisable}>Pay ${priceTotal}</Button>
    </Form>
  );
};

export default FormNewCard;
