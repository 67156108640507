import { handleActions } from 'redux-actions';
import { uploadType } from '../constants';
import { AudioRecord } from '../../../redux/records/audio-record';
import { DashboardRecord } from '../../../redux/records/dashboard-record';
import {
  requestUserPath,
  failUserPath,
  receiveUserPath,
  receiveFile,
  requestSendFile,
  receiveSendFile,
  failSendFile,
  setFileId,
  requestFileInfo,
  receiveFileInfo,
  failFileInfo,
  setTextData,
  setUrlData,
  setScopeData,
  setNewAudio,
  requestTopics,
  receiveTopics,
  failTopics,
} from '../actions/dashboard-actions';

export const initialState = new DashboardRecord();

export default handleActions(
  {
    [setFileId]: (state, action) => state.set('fileId', action.payload),
    [receiveFile]: (state, action) => state.set('fileInfo', action.payload),
    [requestUserPath]: state => state.set('isUploadinUserPath', true),
    [failUserPath]: (state, action) => state
      .set('userPathErrorMessage', action.payload.message)
      .set('isUploadinUserPath', false),
    [receiveUserPath]: (state, action) =>
      state
        .set('userPathInfo', action.payload)
        .set('isUploadinUserPath', false)
        .delete('userPathErrorMessage'),
    [requestSendFile]: state => state
      .delete('sendFileError')
      .set('isSedingFile', true),
    [receiveSendFile]: state => state.set('isSedingFile', false),
    [failSendFile]: (state, action) => state
      .set('isSedingFile', false)
      .set('sendFileError', action.payload.message),
    [requestFileInfo]: state => state
      .delete('fileInfoError')
      .set('isFileInfoLoading', true),
    [receiveFileInfo]: state => state
      .delete('audio')
      .delete('textToTranscription')
      .delete('contentType')
      .delete('scope')
      .set('isFileInfoLoading', false),
    [failFileInfo]: (state, action) => state
      .set('isFileInfoLoading', false)
      .set('fileInfoError', action.payload.message),
    [setTextData]: (state, action) => state
      .set('textToTranscription', action.payload)
      .set('contentType', uploadType.TEXT),
    [setUrlData]: (state, action) => state
      .set('sourceUrl', action.payload)
      .set('contentType', uploadType.URL),
    [setScopeData]: (state, action) => state.set('scope', action.payload),
    [setNewAudio]: (state, action) => state.set('audio', action.payload),
    [requestTopics]: state => state
      .delete('topicsError')
      .set('isLoadingTopics', true),
    [receiveTopics]: (state, action) => state
      .set('topics', action.payload)
      .set('isLoadingTopics', false),
    [failTopics]: (state, action) => state
      .set('isLoadingTopics', false)
      .set('topicsError', action.payload.message),
  },
  initialState
);
