import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 9fr 14fr;
  height: 14rem;
  border-radius: .5rem;
  box-shadow: 0 0 .4rem 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;

  ${media.lessThan('medium')`
    height: unset;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  `}
`
export const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.7rem;
  background-color: ${props => props.bgColor || '#ffffff'};
`

export const Img = styled.img`
  height: 6rem;
`;

export const Title = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  align-self: flex-start;
  margin-bottom: .5rem;
`

export const Description = styled.div`
  font-size: 1.3rem;
  color: #5c5c5e;
  margin-bottom: .5rem;
`

export const Price = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: baseline;
  font-size: 1.7rem;
  font-weight: 500;
`

export const Value = styled.div`
  &::before {
    content: '$';
    margin-right: .5rem;
  }
  margin-right: .5rem;
`

export const Period = styled.div`
  font-size: 1.3rem;
`
