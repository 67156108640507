import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import withWindowResize from '../../../../shared/hocs/withWindowResize';
import { PricingContent } from '../../constants';
import PaymentForm from '../PaymentForm';
import { setPeriod, setPlan, setPlanObj } from '../../Redux/actions/price-plans-actions';
import {
  Block,
  BlockLine,
  BlockTitleLine,
  BlockCell,
  BlockCellValue,
  HeaderLine,
  HeaderSwitch,
  SwitchLabel,
  Switch,
  HeaderPlanLarge,
  HeaderPlanMedium,
  HeaderPlan,
  Description,
  Container,
  Title,
  Tabs,
  TabTitle,
  Tab,
} from './styles';
import { goTo } from '../../../../shared/helper/goTo';

const Grid = ({ width }) => {
  const dispatch = useDispatch();

  const isLoadingSubscription = useSelector(state => state.pricePlans.isLoadingSubscription);
  const period = useSelector(state => state.pricePlans.period);
  const plan = useSelector(state => state.pricePlans.plan);
  const { id: planId } = useSelector(state => state.pricePlans.planObj);

  const { premiumPlan, freePlan } = PricingContent;
  const premium = premiumPlan[period];
  const free = freePlan[period];

  const handleTogglePeriod = () => {
    const currentyPeriod = period === 'monthly' ? 'annually' : 'monthly';
    dispatch(setPeriod(currentyPeriod));
    if (planId) {
      const planCurrenty = plan === 'free'
        ? freePlan[currentyPeriod]
        : premiumPlan[currentyPeriod];
      dispatch(setPlanObj({ ...planCurrenty }));
    }
  };

  const handleSetPlan = () => {
    dispatch(setPlan(plan === 'free' ? 'premium' : 'free'));
  };

  const handleSignUp = plan => {
    if (isLoadingSubscription) return;
    dispatch(setPlanObj({ ...plan }));
  };

  return (
    <Container>
      <Title>Pricing</Title>

      <PaymentForm />

      <div>
        <HeaderLine>
          <HeaderSwitch>
            <SwitchLabel fontWeight={period === 'monthly' ? '500' : ''}>Billed Monthly</SwitchLabel>
            <Switch
              onChange={handleTogglePeriod}
              checked={period === 'annually'}
              onColor="#7909ea"
              offColor="#7909ea"
              height={18}
              width={33}
              uncheckedIcon={false}
              checkedIcon={false}
            />
            <SwitchLabel fontWeight={period === 'annually' ? '500' : ''}>Billed Annually</SwitchLabel>
          </HeaderSwitch>

          <HeaderPlanMedium>
            <Tabs activeKey={plan} onSelect={handleSetPlan} id="plans">
              <Tab eventKey="free" title={<TabTitle>Starter</TabTitle>}>
                <HeaderPlan>
                  {free.title}
                  {free.description && <Description>{free.description}</Description>}
                  <div><span onClick={() => goTo('/signup?login=false')}>SIGN UP</span></div>
                </HeaderPlan>
              </Tab>
              <Tab eventKey="premium" title={<TabTitle>Premium</TabTitle>}>
                <HeaderPlan>
                  {premium.title}
                  {premium.description && <Description>{premium.description}</Description>}
                  <div><span onClick={() => handleSignUp(premium)}>SIGN UP</span></div>
                </HeaderPlan>
              </Tab>
            </Tabs>
          </HeaderPlanMedium>

          <HeaderPlanLarge>
            <HeaderPlan>
              {free.title}
              {free.description &&
                <Description>{free.description}</Description>
              }
              <div><span onClick={() => goTo('/signup?login=false')}>SIGN UP</span></div>
            </HeaderPlan>
            <HeaderPlan>
              {premium.title}
              {premium.description &&
                <Description>{premium.description}</Description>
              }
              <div><span onClick={() => handleSignUp(premium)}>SIGN UP</span></div>
            </HeaderPlan>
          </HeaderPlanLarge>

        </HeaderLine>
        {PricingContent.pricingTable.blocks.map(function (block, key) {
          return (
            <Block key={"priceBlock" + key.toString()}>
              <BlockTitleLine>{block.title}</BlockTitleLine>
              {block.items.map(function (item, itemKey) {
                return (
                  <BlockLine key={"priceItem" + key.toString() + '' + itemKey.toString()}>
                    <BlockCell>{item.Text}</BlockCell>

                    {(plan === 'free' || width.greaterThan('769')) && (
                      <BlockCellValue>
                        {item.Free === true
                          ? <i className="fas fa-check"></i>
                          : item.Free === false
                            ? <i className="fas fa-times"></i>
                            : item.Free}
                      </BlockCellValue>
                    )}

                    {(plan === 'premium' || width.greaterThan('769')) && (
                      <BlockCellValue>
                        {item.Premium === true
                          ? <i className="fas fa-check"></i>
                          : item.Premium === false
                            ? <i className="fas fa-times"></i>
                            : item.Premium}
                      </BlockCellValue>
                    )}
                  </BlockLine>
                );
              })}
            </Block>
          );
        })}
      </div>
    </Container>
  );
};

Grid.propTypes = {
  width: PropTypes.object.isRequired,
};

export default withWindowResize(Grid);
