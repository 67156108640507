import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../dashboard/components/upload/components/modal';
import {
  Close,
  Header,
  Content,
  Footer,
  Input,
  TextArea,
  CenterDiv,
  Line,
  TextDiv,
  Select,
  SubTitle,
} from './styles';
import DefaultButton from '../../../../shared/basic/DefaultButton';
import FormGroup from '../../../../shared/basic/FormGroup';
import { PlaylistRecord } from '../../../../redux/records/playlist-record';
import BoxMessage from '../../../../shared/basic/BoxMessage';
import ContentLoader from '../../../../shared/basic/ContentLoader';
import { useDispatch } from 'react-redux';

const Option = ({ value, children }) => (
  <option value={value}>{children}</option>
);

const AddPlaylistForm = props => {
  const {
    showModal,
    isLoadingPlaylist,
    isCreatingPlaylistAudio,
    modalSubmitSuccess,
    playlistErrorMessage,
    newPlaylist,
    playlists,
    closeModal,
    fetchPlaylist,
    setNewPlaylist,
    pushAudioToPlaylist,
    pushNewPlaylistWithAudio,
    failPlaylistCreate,
  } = props;
  const { title, description } = newPlaylist;
  const [selectedPlaylist, setSelectedPlaylist] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (modalSubmitSuccess) {
      closeModal();
    }
  }, [modalSubmitSuccess]);

  useEffect(() => {
    async function fetchPlaylists() {
      await fetchPlaylist();
    }

    fetchPlaylists();
  }, [fetchPlaylist]);

  function changeValue(field, value) {
    setNewPlaylist(newPlaylist.set(field, value));
  }

  async function handlerNewPlaylist() {
    await pushNewPlaylistWithAudio();
  }

  async function handleExistingPlaylist() {
    if (!selectedPlaylist) {
      dispatch(failPlaylistCreate({message: "Select a playlist"}));
      return;
    }

    await pushAudioToPlaylist(selectedPlaylist);
  }

  return (
    <Modal showModal={showModal}>
      <Header>
        ADD TO PLAYLIST
        <Close unicode="f00d" onClick={closeModal} />
      </Header>
      {isCreatingPlaylistAudio || isLoadingPlaylist ? <ContentLoader /> : (
        <>
          <Content>
            {playlistErrorMessage && <BoxMessage message={playlistErrorMessage} />}
            <SubTitle>CREATE NEW PLAYLIST</SubTitle>
            <FormGroup title="Playlist name">
              <Input defaultValue={title} onChange={event => changeValue('title', event.target.value)} />
            </FormGroup>
            <FormGroup title="Description">
              <TextArea row="2" defaultValue={description} onChange={event => changeValue('description', event.target.value)} />
            </FormGroup>
            <DefaultButton click={handlerNewPlaylist}>CREATE PLAYLIST AND ADD AUDIO</DefaultButton>
          </Content>
          <CenterDiv>
            <Line />
            <TextDiv>OR</TextDiv>
            <Line />
          </CenterDiv>
          <Content>
            <SubTitle>CHOOSE AN EXISTING ONE:</SubTitle>
            <FormGroup title="Playlist name">
              <Select defaultValue={selectedPlaylist} onChange={event => setSelectedPlaylist(event.target.value)}>
                <option>Select an existing playlist</option>
                {playlists.map(item => (
                  <Option key={item.id} value={item.id}>{item.title}</Option>
                ))}
              </Select>
            </FormGroup>
            <DefaultButton click={handleExistingPlaylist}>ADD TO PLAYLIST</DefaultButton>
          </Content>
        </>
      )}
    </Modal>
  );
};

AddPlaylistForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  isLoadingPlaylist: PropTypes.bool.isRequired,
  isCreatingPlaylistAudio: PropTypes.bool.isRequired,
  modalSubmitSuccess: PropTypes.bool.isRequired,
  playlistErrorMessage: PropTypes.string.isRequired,
  newPlaylist: PropTypes.instanceOf(PlaylistRecord).isRequired,
  playlists: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  fetchPlaylist: PropTypes.func.isRequired,
  setNewPlaylist: PropTypes.func.isRequired,
  pushAudioToPlaylist: PropTypes.func.isRequired,
  pushNewPlaylistWithAudio: PropTypes.func.isRequired,
  failPlaylistCreate: PropTypes.func.isRequired,
}

export default AddPlaylistForm;
