import React from 'react';
import PropTypes from 'prop-types';
import Player from 'react-h5-audio-player';
import ReactPlayer from 'react-player';
import 'react-h5-audio-player/lib/styles.css';
import './styles.css';

const VideoPlayer = ({ url }) => {
  return (
    <ReactPlayer
      url={url}
      controls="true"
      playing="true"
      className='react-player'
      config={{ file: { attributes: { controlsList: 'nodownload' } } }}
      onContextMenu={e => e.preventDefault()}
    />
  )
};

VideoPlayer.propTypes = {
  url: PropTypes.string,
};

VideoPlayer.defaultProps = {
  url: '',
};

export default VideoPlayer;