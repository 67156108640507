import styled from 'styled-components'

export const CommonLabel = styled.div`
  font-size: 1.5rem;
  letter-spacing: -.01rem;
  color: #000000;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
