import { connect } from 'react-redux';
import App from '../../App';
import { fetchUserData } from '../actions/authorized-user-actions';
import { unsetIsLoading } from '../actions/page-actions';

const mapDispatchToProps = {
  fetchUserData: userId => fetchUserData(userId),
  unsetIsLoading: () => unsetIsLoading(),
};

export default connect(null, mapDispatchToProps)(App);
