import styled from 'styled-components';
import Icon from '../../../../shared/basic/IconFontAnswer';
import { black, lightBlueGrey, slateGrey, white } from '../../../../styles/variables';

export const Header = styled.div`
  display: flex;
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.34px;
  color: ${black};
  border-bottom: 1px solid ${lightBlueGrey};
  padding: 2rem 3rem;

  div {
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
    right: 3rem;
  }
`;

export const Close = styled(Icon)`
  cursor: pointer;

  &::after {
    color: ${black};
  }
`;

export const Content = styled.div`
  font-family: Work Sans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.34px;
  color: ${black};
  padding: 3rem;
`;

export const Footer = styled.div`
  padding: 0 3rem 3rem 3rem;
  margin-top: 3rem;
  display: flex;
`;

export const Input = styled.input.attrs(() => ({
  type: "text",
}))`
  margin: 5px 0 0;
  border-radius: 5px;
  border: solid 1px ${lightBlueGrey};
  background-color: ${white};
  padding: 10px 11px;
`;

export const TextArea = styled.textarea`
  margin: 5px 0 0;
  border-radius: 5px;
  border: solid 1px ${lightBlueGrey};
  background-color: ${white};
  padding: 10px 11px;
  resize: none;
`;

export const CenterDiv = styled.div`
  align-items: center!important;
  justify-content: center!important;
  display: flex;
  flex-wrap: wrap;
`;

export const Line = styled.div`
  border-left: 0.1rem solid rgb(216, 216, 216);
  box-sizing: border-box;
  height: 0.1rem;
  width: 15rem;
  background-color: rgb(216, 216, 216);
`;

export const TextDiv = styled.div`
  font-family: Work Sans;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.32px;
  text-align: center;
  color: ${slateGrey};
`;

export const Select = styled.select`
  margin: 5px 0 0;
  padding: 11px 13px 10px 15px;
  border-radius: 5px;
  border: solid 1px ${lightBlueGrey};
  background-color: #ffffff;

  option {
    margin: 1px 8px 0 0;
    font-family: Work Sans;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.32px;
    color: ${slateGrey};
  }
`;

export const SubTitle = styled.div`
  font-family: Work Sans;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.29px;
  color: ${slateGrey};
  margin-bottom: 2rem;
`;