export const guid = () => {
  return 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};


export function validateRoute() {
  const { pathname } = window.location;
  const allowedUrl = ['subscribe', 'dashboard'];

  const [isAllowed] = allowedUrl.filter(item => {
    return pathname.indexOf(item) > 0;
  })

  if (isAllowed) {
    return true;
  }
  return false;
};

export function checkParameter(paramToCheck) {
  const queryParams = new URLSearchParams(window.location.search);
  const param = queryParams.get(paramToCheck);
  
  if (!param) {
    return null;
  }
  return param;
}
