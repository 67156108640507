import { connect } from 'react-redux';
import { fetchAudio, updateAudio, setNewAudio } from '../../actions/my-library-actions';
import AudioForm from './';

const mapStateToProps = state => ({
  audio: state.library.audio,
  newAudio: state.library.audio,
  audioFormError: state.library.audioFormError,
  isLoadingAudio: state.library.isLoadingAudio,
  formSubmitSuccess: state.library.formSubmitSuccess,
});

const mapDispatchToProps = {
  fetchAudio: audioId => fetchAudio(audioId),
  updateAudio: () => updateAudio(),
  setNewAudio: audio => setNewAudio(audio),
};

export default connect(mapStateToProps, mapDispatchToProps)(AudioForm);