import { handleActions } from 'redux-actions';
import { Record } from 'immutable';
import {
  setActiveTab,
  setAudioPlaying,
  requestAudioList,
  receiveAudioList,
  failAudioList,
  requestPlaylist,
  receivePlaylist,
  failPlaylist,
  requestGroup,
  receiveGroup,
  failGroup,
  requestAudio,
  receiveAudio,
  failAudio,
  sendAudio,
  responseAudio,
  setNewAudio,
  requestGroupDetail,
  receiveGroupDetail,
  failGroupDetail,
  sendGroup,
  responseGroup,
  setNewGroupDetail,
  requestGroupMembers,
  receiveGroupMembers,
  requestGroupCreate,
  receiveGroupCreate,
  failGroupCreate,
  requestPlaylistCreate,
  receivePlaylistCreate,
  failPlaylistCreate,
  setNewPlaylist,
  setPlaylist,
  setPlaylistOrder,
  setDeleteKey,
  setDeleteLabel,
  setDeleteType,
  requestDelete,
  receiveDelete,
  failDelete,
  requestPlaylistItem,
  receivePlaylistItem,
  failPlaylistItem,
  sendPlaylist,
  responsePlaylist,
  requestPlaylistAudios,
  receivePlaylistAudios,
  requestPlaylistPermissions,
  receivePlaylistPermission,
  sendGroupPermission,
  responseGroupPermission,
  setSelectedAudio,
  sendAudioToPlaylist,
  responseAudioToPlaylist,
  closeModal,
  sendInvitation,
  responseInvitation,
  invitationFail,
  requestAudioSharedWithMeList,
  receiveAudioSharedWithMeList,
  failAudioSharedWithMeList,
} from '../actions/my-library-actions';
import { AudioRecord } from '../../../redux/records/audio-record';
import { GroupRecord } from '../../../redux/records/group-record';
import { PlaylistRecord } from '../../../redux/records/playlist-record';

const MyLibraryRecord = Record({
  activeTab: 0,
  formSubmitSuccess: false,
  modalSubmitSuccess: false,
  isLoadingAudio: false,
  isLoadingAudioSharedWithMe: false,
  audios: [],
  audioPlaying: new AudioRecord(),
  audiosSharedWithMe: [],
  audioErrorMessage: '',
  audioSharedWithMeErrorMessage: '',
  isLoadingPlaylist: false,
  playlists: [],
  playlistErrorMessage: '',
  isLoadingGroup: false,
  groups: [],
  groupErrorMessage: '',
  audio: new AudioRecord(),
  newAudio: new AudioRecord(),
  audioFormError: '',
  groupDetail: new GroupRecord(),
  newGroupDetail: new GroupRecord(),
  groupFormError: '',
  isLoadingMembers: false,
  groupMembers: [],
  closeForm: false,
  isCreating: false,
  newPlaylist: new PlaylistRecord(),
  deleteRowKey: '',
  deleteRowLabel: '',
  deleteType: '',
  isDeleting: false,
  deleteErrorMessage: '',
  playlist: new PlaylistRecord(),
  playlistFormError: '',
  isLoadingPlaylistAudios: true,
  playlistAudios: [],
  isLoadingPlaylistPermissions: true,
  playlistPermissions: [],
  isLoadingGroupPermission: true,
  selectedAudioToPlaylist: null,
  isCreatingPlaylistAudio: true,
  isSendindInvitation: false,
  invitationError: '',
});

export const initialState = new MyLibraryRecord();

export default handleActions(
  {
    [setActiveTab]: (state, action) => state.set('activeTab', action.payload),
    [setAudioPlaying]: (state, action) => state.set('audioPlaying', action.payload),
    [requestAudioList]: state => state
      .delete('audioErrorMessage')
      .delete('formSubmitSuccess')
      .delete('modalSubmitSuccess')
      .delete('closeForm')
      .delete('deleteErrorMessage')
      .set('isLoadingAudio', true),
    [receiveAudioList]: (state, action) => state
      .set('audios', action.payload)
      .set('isLoadingAudio', false),
    [failAudioList]: (state, action) => state
      .set('audioErrorMessage', action.payload.message)
      .set('isLoadingAudio', false),

    [requestAudioSharedWithMeList]: state => state
      .delete('audioSharedWithMeErrorMessage')
      .set('isLoadingAudioSharedWithMe', true),
    [receiveAudioSharedWithMeList]: (state, action) => state
      .set('audiosSharedWithMe', action.payload)
      .set('isLoadingAudioSharedWithMe', false),
    [failAudioSharedWithMeList]: (state, action) => state
      .set('audioErrorMessage', action.payload.message)
      .set('isLoadingAudioSharedWithMe', false),

    [requestPlaylist]: state => state
      .delete('deleteErrorMessage')
      .delete('playlistErrorMessage')
      .delete('formSubmitSuccess')
      .delete('modalSubmitSuccess')
      .delete('closeForm')
      .delete('newPlaylist')
      .delete('playlistAudios')
      .delete('playlist')
      .set('isCreatingPlaylistAudio', true)
      .set('isLoadingPlaylist', true),
    [receivePlaylist]: (state, action) => state
      .set('playlists', action.payload)
      .set('isCreatingPlaylistAudio', false)
      .set('isLoadingPlaylist', false),
    [failPlaylist]: (state, action) => state
      .set('playlistErrorMessage', action.payload.message)
      .set('isCreatingPlaylistAudio', false)
      .set('isLoadingPlaylist', false),

    [requestGroup]: state => state
      .delete('groupErrorMessage')
      .delete('deleteErrorMessage')
      .delete('formSubmitSuccess')
      .delete('modalSubmitSuccess')
      .delete('closeForm')
      .delete('newGroupDetail')
      .set('isLoadingGroup', true),
    [receiveGroup]: (state, action) => state
      .set('groups', action.payload)
      .set('isLoadingGroupPermission', false)
      .set('isLoadingGroup', false),
    [failGroup]: (state, action) => state
      .set('groupErrorMessage', action.payload.message)
      .set('isLoadingGroupPermission', false)
      .set('isLoadingGroup', false),

    [requestAudio]: state => state
      .delete('audioFormError')
      .set('newAudio', new AudioRecord())
      .set('isLoadingAudio', true),
    [receiveAudio]: (state, action) => state
      .set('audio', action.payload)
      .set('isLoadingAudio', false),
    [failAudio]: (state, action) => state
      .set('audioFormError', action.payload.message)
      .set('isLoadingAudio', false),
    [sendAudio]: state => state
      .delete('audioFormError')
      .set('isLoadingAudio', true),
    [responseAudio]: state => state
      .set('formSubmitSuccess', true)
      .set('isLoadingAudio', false),
    [setNewAudio]: (state, action) => state
      .set('newAudio', action.payload),

    [requestGroupDetail]: state => state
      .delete('groupFormError')
      .set('newGroupDetail', new GroupRecord())
      .set('isLoadingGroup', true),
    [receiveGroupDetail]: (state, action) => state
      .set('groupDetail', action.payload)
      .set('isLoadingGroup', false),
    [failGroupDetail]: (state, action) => state
      .set('groupFormError', action.payload.message)
      .set('isLoadingGroup', false),
    [sendGroup]: state => state
      .delete('groupFormError')
      .set('isLoadingGroup', true),
    [responseGroup]: state => state
      .set('formSubmitSuccess', true)
      .set('isLoadingGroup', false),
    [setNewGroupDetail]: (state, action) => state
      .set('newGroupDetail', action.payload),
    [requestGroupMembers]: state => state
      .delete('isSendindInvitation')
      .delete('invitationError')
      .set('modalSubmitSuccess', false)
      .set('isLoadingMembers', true),
    [receiveGroupMembers]: (state, action) => state
      .set('groupMembers', action.payload)
      .set('isLoadingMembers', false),

    [requestGroupCreate]: state => state.set('isCreating', true),
    [receiveGroupCreate]: state => state
      .set('closeForm', true)
      .set('isCreating', false),
    [failGroupCreate]: (state, action) => state
      .set('groupFormError', action.payload.message)
      .set('isCreating', false),

    [requestPlaylistCreate]: state => state
      .set('isCreatingPlaylistAudio', true)
      .set('isCreating', true),
    [receivePlaylistCreate]: state => state
      .set('closeForm', true)
      .set('isCreating', false),
    [failPlaylistCreate]: (state, action) => state
      .set('playlistErrorMessage', action.payload.message)
      .set('isCreatingPlaylistAudio', false)
      .set('isCreating', false),
    [setNewPlaylist]: (state, action) => state.set('newPlaylist', action.payload),
    [setPlaylist]: (state, action) => state.set('playlist', action.payload),
    [setPlaylistOrder]: (state, action) => state.setIn(['playlist', 'itemIds'], action.payload),

    [setDeleteKey]: (state, action) => state.set('deleteRowKey', action.payload),
    [setDeleteLabel]: (state, action) => state.set('deleteRowLabel', action.payload),
    [setDeleteType]: (state, action) => state.set('deleteType', action.payload),

    [requestDelete]: state => state.set('isDeleting', true),
    [receiveDelete]: state => state
      .set('isDeleting', false)
      .set('modalSubmitSuccess', true),
    [failDelete]: (state, action) => state
      .set('isDeleting', false)
      .set('deleteErrorMessage', action.payload.message),

    [requestPlaylistItem]: state => state
      .delete('playlistFormError')
      .set('isLoadingPlaylist', true),
    [receivePlaylistItem]: (state, action) => state
      .set('isLoadingPlaylist', false)
      .set('playlist', action.payload),
    [failPlaylistItem]: (state, action) => state
      .set('isLoadingPlaylist', false)
      .set('isLoadingPlaylistAudios', false)
      .set('isLoadingPlaylistPermissions', false)
      .set('playlistFormError', action.payload.message),
    [sendPlaylist]: state => state
      .delete('playlistFormError')
      .set('isLoadingPlaylist', true),
    [responsePlaylist]: state => state
      .set('formSubmitSuccess', true)
      .set('isLoadingPlaylist', false),
    [requestPlaylistAudios]: state => state
      .set('modalSubmitSuccess', false)
      .set('isLoadingPlaylistAudios', true),
    [receivePlaylistAudios]: (state, action) => state
      .set('isLoadingPlaylistAudios', false)
      .set('playlistAudios', action.payload),
    [requestPlaylistPermissions]: state => state
      .set('modalSubmitSuccess', false)
      .set('isLoadingPlaylistPermissions', true),
    [receivePlaylistPermission]: (state, action) => state
      .set('playlistPermissions', action.payload)
      .set('isLoadingPlaylistPermissions', false),
    [sendGroupPermission]: state => state
      .delete('groupErrorMessage')
      .set('isLoadingGroupPermission', true),
    [responseGroupPermission]: state => state
      .set('modalSubmitSuccess', true)
      .set('isLoadingGroupPermission', false),

    [setSelectedAudio]: (state, action) => state
      .set('selectedAudioToPlaylist', action.payload),
    [sendAudioToPlaylist]: state => state
      .set('isCreatingPlaylistAudio', true),
    [responseAudioToPlaylist]: state => state
      .delete('playlistErrorMessage')
      .delete('selectedAudioToPlaylist')
      .set('modalSubmitSuccess', true)
      .set('isCreatingPlaylistAudio', false),
    [closeModal]: state => state.set('modalSubmitSuccess'. false),
    [sendInvitation]: state => state
      .set('isSendindInvitation', true),
    [responseInvitation]: state => state
      .set('isSendindInvitation', true)
      .set('modalSubmitSuccess', true),
    [invitationFail]: (state, action) => state
      .set('isSendindInvitation', false)
      .set('invitationError', action.payload.message),
  },
  initialState
);
