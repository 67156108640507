import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Icon, Text } from './styles';

const TitlePage = ({ children, iconCode }) => (
  <Wrapper>
    <Icon unicode={iconCode} />
    <Text>{children}</Text>
  </Wrapper>
);

TitlePage.propTypes = {
  iconCode: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

TitlePage.defaultProps = {
  iconCode: '',
  children: '',
};

export default memo(TitlePage);
