import React from 'react';
import { Container, FooterCopyright, Links } from './styles';
import { withRouter } from 'react-router-dom';

export const Footer = () => {
  //const isAuthenticated = auth0Client.isAuthenticated();
  
  return (
    <Container>
      <Links>
        <a href="/contact">Contact</a>
        <a href="/terms">Terms</a>
        <a href="/privacy-policy">Privacy Policy</a>
      </Links>
      <FooterCopyright>
        Copyrights © 2020 All Rights Reserved by Strengths Inc.
      </FooterCopyright>
    </Container>
  );
};

export default withRouter(Footer);
