import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, TitleWrapper, LinksDesktop, LinksMobile } from './styles';
import ButtonAlexa from './Buttons/Alexa';
import ButtonGoogle from './Buttons/Google';
import ButtonPlay from './Buttons/Play';
import ButtonShare from '../../../../../../../shared/basic/Share';
import ButtonPlayMobile from './Buttons/PlayMobile';
import Order from './Labels/Order';
import Title from './Labels/Title';
import Time from './Labels/Time';
import { urlAudioGenerator } from '../../../../../../../shared/helper/openPlaylistLink';
import { useSelector } from 'react-redux';
import { secondFormat } from '../../../../../../../shared/helper/Time';

const PlaylistListItem = ({ id, order, voicePhrase, title, audioSeconds, isLoading }) => {
  const { id: currentyAudioId } = useSelector(state => state.publicLibrary.currentyAudio);
  const isActive = currentyAudioId === id;
  const copySoar = urlAudioGenerator({id, voicePhrase}, 'soar');
  const shareConfig = {
    copyGoogle: urlAudioGenerator({id, voicePhrase}, 'googleAssistant'),
    copyAlexa: urlAudioGenerator({id, voicePhrase}, 'googleAssistant'),
    copySoar: copySoar,
    facebook: copySoar,
    twitter: copySoar,
    linkedin: copySoar,
    email: copySoar,
  };

  return (
    <Wrapper backgroundColor={isActive ? '#eef0ff' : '#fff'}>

      <TitleWrapper gridArea='title'>
        <Order text={order} />
        <Title text={title} isActive={isActive} />
      </TitleWrapper>

      <Time gridArea='time' text={secondFormat(audioSeconds)} />

      <LinksDesktop gridArea='links'>
        <ButtonAlexa id={id} isLoading={isLoading} />
        <ButtonGoogle id={id} isLoading={isLoading} />
        <ButtonPlay id={id} isLoading={isLoading} />
        <ButtonShare isLoading={isLoading} {...shareConfig} />
      </LinksDesktop>

      <LinksMobile gridArea='links'>
        <ButtonPlayMobile id={id} isLoading={isLoading} />
      </LinksMobile>

    </Wrapper>
  );
};

PlaylistListItem.propTypes = {
  id: PropTypes.any,
  order: PropTypes.string,  
  voicePhrase: PropTypes.string,
  title: PropTypes.string,
  audioSeconds: PropTypes.string,
  isLoading: PropTypes.bool,
};

PlaylistListItem.defaultProps = {
  id: '',
  order: '',
  voicePhrase: '',
  title: '',
  audioSeconds: '',
  isLoading: false,
};

export default PlaylistListItem;
