import React from 'react';
import { useSelector } from 'react-redux';

import Container from '../../../../../../shared/layout/Container';
import Section from '../../../../../../shared/layout/Section';
import { statusManager } from '../../../../shared/helpers/statusManager';
import AudioItem from './Item';
import { Wrapper, WrapperItems } from './styles';

const PlaylistDetailList = () => {
  const style = { paddingTop: '0', paddingBottom: '0' };
  const { items } = useSelector(state => state.publicLibrary.currentyPlaylist);
  const { isLoading, errorMessage } = useSelector(state => state.publicLibrary);
  const isEmpty = !items;

  const status = statusManager({
    component: AudioItem,
    isLoading,
    errorMessage: errorMessage,
    isEmpty,
    isList: true
  });

  return (
    <Section>
      <Container {...style}>
        <Wrapper>
          <WrapperItems>
            {status || items.map((item, index) => (
              <AudioItem
                key={item.id}
                id={item.id}
                order={String(index+1)}
                voicePhrase={item.voicePhrase}
                title={item.title}
                audioSeconds={String(item.audioSeconds)}
              />
            ))
            }
          </WrapperItems>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default PlaylistDetailList;