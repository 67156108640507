import React from 'react';
import withLayout from '../../shared/layout/hocs/withLayout';
import Container from '../../shared/layout/Container';
import { Title, SubTitle } from '../../shared/layout/CustomContent/styles';
import { Tile, Tiles, Price, Button } from './styles';
import { NEW_PLANS } from '../premium/constants';

const Terms = () => (
  <Container>
    <Title>Subscriptions</Title>
    <Tiles>
      {NEW_PLANS.map(item => (
        <Tile key={item.index}>
          <a href={`/subscribe/${item.link}/${item.period}`}>
            <SubTitle>{item.name} - {item.period}</SubTitle>
            <Price>${item.price}</Price>
            <Button tip="Subscribe">Subscribe</Button>
          </a>
        </Tile>
      ))}
    </Tiles>
  </Container>
);

export default withLayout(Terms);
