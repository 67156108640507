import React from "react";
import PropTypes from 'prop-types';
import { H2 } from './styles';

const PageSubtitle = ({ children }) => <H2>{children}</H2>;

PageSubtitle.propTypes = {
  children: PropTypes.any
};

PageSubtitle.defaultProps = {
  children: '',
};

export default PageSubtitle;