import { Record } from 'immutable';
import moment from 'moment';

export const CoachPlanRecord = Record({
  stripeSubscriptionId: '',
  name: '',
  dateCreated: '',
  amount: '',
  interval: '',
  intervalCount: '',
  start: '',
  end: '',
  cardDesc: '',
  cancelledOn: '',
  freeTrialStart: '',
  freeTrialEnd: '',
  freeTrial: false,
  active: false,
});

CoachPlanRecord.parse = coachSubscriptions => {
  if (coachSubscriptions.length <= 0) {
    return new CoachPlanRecord();
  }
  const [activeSubscription] = coachSubscriptions.filter(subscription => {
    const startDate = moment(subscription.start).format('YYYY-MM-DD');
    const endDate = moment(subscription.end).format('YYYY-MM-DD');

    return moment()
      .utc(0)
      .isBetween(startDate, endDate);
  });

  if (activeSubscription.length === 0) {
    return new CoachPlanRecord();
  }

  const {
    stripeSubscriptionId,
    name,
    dateCreated,
    amount,
    interval,
    intervalCount,
    start,
    end,
    cardDesc,
    cancelledOn,
    freeTrialStart,
    freeTrialEnd,
  } = activeSubscription;

  const freeTrial = moment(freeTrialEnd) >= moment();
  const active = true;

  return new CoachPlanRecord({
    stripeSubscriptionId,
    name,
    dateCreated,
    amount,
    interval,
    intervalCount,
    start,
    end,
    cardDesc,
    cancelledOn,
    active,
    freeTrialStart,
    freeTrialEnd,
    freeTrial,
  });
};
