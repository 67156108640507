import styled, { createGlobalStyle } from 'styled-components';
import { imgs } from '../../assets';
import { customMedia } from '../../styles/breakpoints';
import { white } from '../../styles/variables';

export const FlexBox = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${customMedia.greaterThan('lg')`
    margin-top: auto;
    flex-direction: row;
  `}
`;

export const DivContainer = styled.div`
  height: 100vh;

  ${customMedia.greaterThan('lg')`
    background: url(${imgs.sign_background}) no-repeat top right;
    background-size: cover;
    background-position-x: -5rem;
  `}

  ${customMedia.between('992px', '1020px')`
    padding-left: 1rem;
    background-position-x: -11rem;
  `}

  ${customMedia.between('1021px', '1050px')`
    padding-left: 1rem;
    background-position-x: -10rem;
  `}

  ${customMedia.between('1051px', '1069px')`
    background-position-x: -7rem;
  `}

  ${customMedia.between('1070px', '1100px')`
    background-position-x: -5rem;
  `}

  ${customMedia.between('1101px', '1199px')`
    background-position-x: -1rem;
  `}
`;

export const ItemsBox = styled.div`
  display: flex;
  padding-bottom: 2rem;

  &:first-child {
    padding-top: 2rem;
  }
`;

export const FlexBoxLogo = ItemsBox.withComponent('img');

export const FlexBoxWithImg = styled(ItemsBox)`
  img {
    width: 8.2rem;
    height: 8.2rem;
    object-fit: contain;
    margin: 0px 0.5rem 0px 0px;
  }
`;

export const DivForm = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  & > img {
    display: none;
  }

  ${customMedia.greaterThan('lg')`
    width: 50%;
    padding-right: 2.5rem;
    justify-content: flex-end;

    & img {
      display: none;
    }
  `}
`;

export const DivFeatures = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(${imgs.mobile_signup_bottom}) no-repeat;
  background-size: cover;
  padding-top: 6.74rem;
  width: 100%;

  & > img {
    display: none;
  }

  ${customMedia.greaterThan('sm')`
    padding-top: 10rem;
  `}

  ${customMedia.greaterThan('lg')`
    width: 50%;
    height: 100vh;
    justify-content: center;
    padding-top: 0;
    background: unset;
    margin: auto 0;

    & > img {
      display: block;
    }
  `}
`;

export const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${white};
  }

  .align-items-center {
    align-items: center!important;
  }

  .d-flex {
    display: flex !important;
  }

  .justify-content-center {
    justify-content: center!important;
  }
`;