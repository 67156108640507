import React from 'react';
import { Route } from 'react-router-dom';
import auth0Client from '../../helper/auth0';
import Loader from '../Loader';

const SecuredRoute = ({ component: Component, path, ...rest }) => {
  const isAuthenticated = auth0Client.isAuthenticated();

  if (!isAuthenticated) {
    sessionStorage.setItem('targetUrl', window.location.pathname);
    window.open('/signup?login=true', '_self');
  }

  return !isAuthenticated ? (
    <Loader />
  ) : (
    <Route
      path={path}
      {...rest}
      render={props => {
        return <Component {...props} />;
      }}
    />
  );
}

export default SecuredRoute;
