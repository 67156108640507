import { connect } from "react-redux";
import PlaylistList from "./playlistList";
import { fetchPlaylist } from '../../../../redux/actions/audio-actions';

const mapStateToProps = state => ({
  audioLoaded: state.publicAudio.isLoading,
  isLoading: state.publicAudio.isLoadingList,
  playlistList: state.publicAudio.playlistList,
});

const mapDispatchToProps = {
  fetchPlaylistByAudio: () => fetchPlaylist(),
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistList);