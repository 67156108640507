import React from 'react';
import { useSelector } from 'react-redux';
import withLayout from '../../shared/layout/hocs/withLayout';
import Grid from './Components/Grid';
import ThankYou from './Components/ThankYou';

const Pricing = () => {
  const successMessageSubscription = useSelector(state => state.pricePlans.successMessageSubscription);
  return successMessageSubscription ? <ThankYou /> : <Grid />;
};

export default withLayout(Pricing);
