import React from "react";
import PropTypes from 'prop-types';

import { Div } from './styles';

const Time = ({ text, ...props }) => <Div {...props}>{text}</Div>;

Time.propTypes = {
  text: PropTypes.string,
};

Time.defaultProps = {
  text: '',
};

export default Time;
