import { Record } from 'immutable';
import { DateTime } from 'luxon';

export const AudioRecord = Record({
  id: '',
  ownerUserId: '',
  description: '',
  source: '',
  lang: '',
  contentType: '',
  tags: [],
  ext: '',
  dateCreated: '',
  title: '',
  transcription: '',
  contentCategory: '',
  scope: 0,
  voicePhrase: '',
  globalVoicePhrase: '',
  audioSeconds: '',
  ownerInfo: {},
  sourceUrl: '',
  autoTags: '',
  subscriptions: '',
  groups: '',
  externalId: '',
  hasAudioTranscriptionEnabled: '',
  metadata: '',
  alexaAudioUrl: '',
});

AudioRecord.parse = audioObject => {
  const {
    id,
    ownerUserId,
    description,
    source,
    lang,
    contentType,
    tags,
    ext,
    dateCreated: date,
    title,
    transcription,
    contentCategory,
    scope,
    voicePhrase,
    globalVoicePhrase,
    audioSeconds,
    ownerInfo,
    sourceUrl,
    autoTags,
    subscriptions,
    groups,
    externalId,
    hasAudioTranscriptionEnabled,
    metadata,
    alexaAudioUrl,
  } = audioObject;

  const dateCreated = DateTime.fromISO(date);

  return new AudioRecord({
    id,
    ownerUserId,
    description,
    source,
    lang,
    contentType,
    tags,
    ext,
    dateCreated,
    title,
    transcription,
    contentCategory,
    scope,
    voicePhrase,
    globalVoicePhrase,
    audioSeconds,
    ownerInfo,
    sourceUrl,
    autoTags,
    subscriptions,
    groups,
    externalId,
    hasAudioTranscriptionEnabled,
    metadata,
    alexaAudioUrl,
  });
};
