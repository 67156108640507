import React from 'react';
import { Content, Loader } from './styles';

const ContentLoader = () => (
  <Content>
    <Loader />
    Loading ...
  </Content>
);

export default ContentLoader;
