import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';

import FormGroup from '../../../../../../../../shared/basic/Form';

import { CardCVCElement } from './styles';
import { setCurrentyCreditCard } from '../../../../../../actions/premium-actions';
import { Wrapper, Title, Form } from '../styles';

const Fields = ({ handleCreditCardCVCChange }) => {
  const dispatch = useDispatch();
  const currentyCreditCard = useSelector(state => state.accountSettings.currentyCreditCard);
  const savedCreditCards = useSelector(state => state.accountSettings.creditCards);

  const savedCreditCardsOptions = savedCreditCards.map(item => ({
    value: item.cardId,
    label: item.description,
  }));

  const handleCreditCardChange = value => {
    dispatch(setCurrentyCreditCard(value));
  };

  return (
    <Wrapper>
      <Title>EXISTENT PAYMENT CARD</Title>
      <Form>
        <FormGroup label="Saved Cards">
          <Select
            id="saved-credit-cards"
            options={savedCreditCardsOptions}
            value={currentyCreditCard}
            onChange={handleCreditCardChange}
          />
        </FormGroup>

        <FormGroup label="Confirm Security Code" inputId="confirm-security-code">
          <CardCVCElement
            id="confirm-security-code"
            name="securityCodeAttribute"
            onChange={handleCreditCardCVCChange}
          />
        </FormGroup>
      </Form>
    </Wrapper>
  );
};

Fields.propTypes = {
  handleCreditCardCVCChange: PropTypes.func.isRequired,
};
export default memo(Fields);
