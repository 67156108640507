import styled from 'styled-components';
import IconFontAnswer from '../../../../../../../../shared/basic/IconFontAnswer';

export const Wrapper = styled.div`
  display: grid;
  gap: .5rem;
  grid-template-columns: min-content max-content;
  height: min-content;
`;

export const Icon = styled(IconFontAnswer)`
  &::after {
    color: #5c5c5e;
  }
`;

export const Span = styled.span``;
