import React, { memo } from 'react';
import PropTypes from 'prop-types';

import CopyField from '..';
import IconAlexa from '../../../../icon/IconAlexa';

const CopyAlexa = ({ url }) => (
  <CopyField url={url} service="alexaSkills">
    <IconAlexa height="2.1rem" color="#57b4ef" />
  </CopyField>
);


CopyAlexa.propTypes = {
  url: PropTypes.string,
};

CopyAlexa.defaultProps = {
  url: '',
};

export default memo(CopyAlexa);
