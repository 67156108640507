import React from 'react';
import PropTypes from 'prop-types';
import withLayout from '../../../../shared/layout/hocs/withLayout';
import { Elements } from '@stripe/react-stripe-js';
import Title from '../../../../shared/basic/TitlePage';
import { Container, Section } from './styles';
import Box from './Box';

const stripePromise = new Promise(async resolve => {
  if (window.location.pathname.indexOf('/subscribe') > -1) {
    const { loadStripe } = await import('@stripe/stripe-js');
    resolve(loadStripe(process.env.REACT_APP_STRIPE_API_KEY));
  }
  resolve(null);
});

const Main = ({ match }) => (
  <Elements stripe={stripePromise}>
    <Container>
      <Section>
        <Title iconCode="f3a5">Subscription</Title>
        <Box plan={ match.params.plan } period={ match.params.period } />
      </Section>
    </Container>
  </Elements>
);

Main.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withLayout(Main);