import React from 'react';
import PropTypes from 'prop-types';
import ButtonShare from '../../../../../../../shared/basic/Share';

const SharePlaylist = props => {
  const {
    isLoading,
    errorMessage,
    urlGenerator,
  } = props;
  const copySoar = urlGenerator(props, 'soar');
  const links = {
    copyGoogle: urlGenerator(props, 'googleAssistant'),
    copyAlexa: urlGenerator(props, 'googleAssistant'),
    copySoar,
    facebook: copySoar,
    twitter: copySoar,
    linkedin: copySoar,
    email: copySoar,
  };

  return (
    <ButtonShare {...links} disabled={isLoading || !!errorMessage} />
  )
};

SharePlaylist.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  urlGenerator: PropTypes.func.isRequired,
  voicePhrase: PropTypes.string,
};

SharePlaylist.defaultProps = {
  voicePhrase: null,
};

export default SharePlaylist;