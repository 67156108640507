import styled from 'styled-components'
import * as variables from '../../../../styles/variables'

const mobile = 'screen and (max-width: 1000px)';
const small = 'screen and (max-width: 600px)';

export const TileContainer = styled.div`
  z-index: 2;
  margin: 3rem auto 4rem auto;
  width: 100%;
  max-width: 1450px;
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: auto auto auto auto;

  @media ${mobile} {
    grid-column-gap: 0;
    grid-template-columns: auto;
  }
`;

export const Tile = styled.div`
  padding: 0 16px;
  min-height: 240px;
  padding: 45px 45px 45px 45px;
  border-radius: 31px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  background-color: ${variables.white};
  margin: 30px auto 0px auto;
  
  @media ${mobile} {
    margin: 2rem;
    :nth-child(2) {
      margin: 2rem;
    }
  }
`;

export const TileImage = styled.div`
  text-align: center;
  padding-bottom: 2rem;
  display: grid;

  div {
    display: grid;
    margin: auto;

    @media ${small} {
      display: grid;
    }
  }

  img {
    margin: auto;
  }
`;

export const TileTitle = styled.div`
  margin-bottom: 30px;
  color: ${variables.greyTitle};
  font-size: 26px;
  font-weight: 600;
  letter-spacing: -0.17px;
    color: ${variables.darkGrey};
  }
`;

export const TileText = styled.div`
  opacity: 0.7;
  font-size: 18px;
  letter-spacing: -0.12px;
  color: #28323b;
`;