import { Record } from 'immutable';
import { PLAYLIST_LIST_ITEMS_PER_PAGE } from '../../shared/constants/page';

const PlaylistListRecord = Record({
  pageNum: 1,
  itemsPerPage: PLAYLIST_LIST_ITEMS_PER_PAGE,
  isLoadingLoadMore: false,
  totalCount: null,
  returnedCount: null,
  sort: 'datedesc',
  items: []
});

PlaylistListRecord.parse = values => {
  return new PlaylistListRecord({ ...values });
};

export default PlaylistListRecord;
