/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import auth0Client from '../../../helper/auth0';
import Loader from '../../../basic/LoaderBubble';

const isProduction = process.env.NODE_ENV === 'production';
const log = isProduction ? () => {} : console.info;

class Callback extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    fetchUserData: PropTypes.func.isRequired,
  };

  async componentDidMount() {
    const { fetchUserData, history } = this.props;

    try {
      let userId = await auth0Client.handleAuthenticationBackEnd();
      const targetUrl = sessionStorage.getItem('targetUrl') || '/my-library';

      log('finished handleAuthentication method, userId', userId); // del
      if (!userId) log('CompleteAuthorization userId: ', userId); // del
      if (!targetUrl) log('CompleteAuthorization targetUrl: ', targetUrl); // del

      if (!sessionStorage.getItem('socialMedia')) {
        sessionStorage.removeItem('targetUrl');
      }

      if (userId && sessionStorage.getItem('socialMedia')) {
        await fetchUserData(userId);
      }

      await history.replace(targetUrl);
    } catch (error) {
      // eslint-disable-next-line no-console
      log('Error CompleteAuthorization: ', error);
    }
  };

  render() {
    return <Loader />;
  }
}

Callback.propTypes = {
  history: PropTypes.object.isRequired,
  fetchUserData: PropTypes.func.isRequired,
};

export default Callback;
