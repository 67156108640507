import React from 'react';
import PropTypes from 'prop-types';
import { Button } from './styles';

const DefaultButton = props => {
  const { children, click } = props;
  return (
    <Button onClick={click} {...props}>{children}</Button>
  );
};

DefaultButton.propTypes = {
  inverted: PropTypes.bool,
  children: PropTypes.any,
  click: PropTypes.func,
};

DefaultButton.defaultProps = {
  inverted: false,
  children: null,
  click: () => {},
};

export default DefaultButton;