import styled from 'styled-components'
import { CommonLabel } from '../styles';
import media from "styled-media-query";

export const Date = styled(CommonLabel)`
  font-size: 1.4rem;
  letter-spacing: -.01rem;
  color: #666;
  min-width: 8.2rem;
  display: flex;
  justify-content: center;
  grid-area: date;

  ${media.lessThan("medium")`
    display: unset;
    justify-content: unset;
  `}
`;
