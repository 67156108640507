import { Record } from 'immutable';
import { DateTime } from 'luxon';

export const PlaylistRecord = Record({
  id: '',
  description: '',
  title: '',
  dateCreated: '',
  ownerUserId: '',
  sharing: '',
  audioCount: '',
  sharingDesc: '',
  itemIds: [],
});

PlaylistRecord.parse = audioObject => {
  const {
    id,
    description,
    title,
    dateCreated: date,
    ownerUserId,
    sharing,
    audioCount,
    sharingDesc,
    itemIds,
  } = audioObject;

  const dateCreated = DateTime.fromISO(date);

  return new PlaylistRecord({
    id,
    description,
    title,
    dateCreated,
    ownerUserId,
    sharing,
    audioCount,
    sharingDesc,
    itemIds,
  });
};
