import styled from 'styled-components';
import media from "styled-media-query";

export const Div = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-top: ${props => props.paddingTop};
  padding-bottom: ${props => props.paddingBottom};
  max-width: 68.06%;
  background-color: ${props => props.backgroundColor}

  ${media.lessThan("large")`
    max-width: 74%;
  `}
  ${media.lessThan("medium")`
    max-width: 84%;
  `}
  ${media.lessThan("small")`
    max-width: 90%;
  `}
`;
