export const uploadType = {
  FILE: 'file',
  TEXT: 'text',
  URL: 'url'
};

export const titleByContent = {
  file: {
    title: 'FILE SUCESSFULLY UPLOADED!',
    icon: 'f001',
  },
  text: {
    title: 'TEXT SUCESSFULLY CONVERTED!',
    icon: 'f893',
  },
  url: {
    title: 'YOUTUBE MEDIA SUCESSFULLY CONVERTED!',
    icon: 'f167',
  },
}