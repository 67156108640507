/* eslint-disable no-useless-escape */
export function convertTextToHTML(text) {
  if (!text) {
    return '';
  }

  const textareaSeparators = ['&#013;', '&#013;&#013;', '\r\n', '\r\n\r\n'];
  const separatorsRegExp = new RegExp(textareaSeparators.join('|'), 'g');
  const splittedText = text.split(separatorsRegExp);

  return splittedText.reduce(
    (resultText, currentText) => `${resultText}<p class="mb-2">${currentText}</p>`,
    ''
  );
}

export function verifyPlayer(urlVideoTmp) {
  return /(?:youtu\.be\/|youtube|(\w{11}))/g.exec(urlVideoTmp) ? 'youtube' : 'vimeo';
}

export function getVideoIdFromYouTubeUrl(videoUrl) {
  let videoId = null;

  if (videoUrl) {
    const regex = /=([\w-]{11})|\/([\w-]{11})/g;
    const regexResult = regex.exec(videoUrl);
    if (regexResult === null) {
      return null;
    }
    const [match1, match2] = regexResult.slice(1) || [];

    if (match1) {
      videoId = match1;
    } else if (match2) {
      videoId = match2;
    }
  }

  return videoId;
}

export function verifyURL(urlVideoTmp) {
  let newUrl = urlVideoTmp;

  if (urlVideoTmp && verifyPlayer(urlVideoTmp) === 'youtube') {
    const introVideoId = getVideoIdFromYouTubeUrl(urlVideoTmp);
    newUrl = `https://www.youtube.com/embed/${introVideoId}?rel=0&showinfo=0`;
  } else if (urlVideoTmp) {
    const replacedUrl = urlVideoTmp.replace(/\?.*/g, '');
    newUrl = `${replacedUrl}?byline=0&dnt=1&loop=1&title=0`;
  }

  return newUrl;
}
