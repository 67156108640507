import { Record } from 'immutable';
import { convertTextToHTML, verifyURL } from '../../shared/helper/converter';

export const CoachRecord = Record({
  id: null,
  vanity: '',
  stripeId: '',
  stripeExpressId: '',
  affiliateId: '',
  givenName: '',
  familyName: '',
  headline: '',
  description: '',
  shortBiography: '',
  isOnline: false,
  isPro: false,
  isBusiness: false,
  isBusinessPlus: false,
  isBusinessGrowth: false,
  isEvangelist: false,
  isSATeamSub: false,
  subscription: '',
  zoomUrl: '',
  meetingUrl: '',
  profilePictUrl: '',
  introVideoId: null,
  locality: '',
  region: '',
  regionCode: '',
  country: '',
  countryCode: '',
  phoneNumber: '',
  emailAddress: '',
  dateCreated: '',
  rating: 0,
  totalReviews: 0,
  strengths: [],
  typeOfCoach: [],
  coachSpecialtyId: 1,
  suggestedCoachSpecialty: '',
  campaign: 1,
  coachedAtCompanies: [],
  schedulingIds: {},
  coachCertifications: '',
  appointmentVanityUrl: [],
  realtimeVideo: true,
  appointmentScheduling: true,
  contactMe: true,
  facebookProfileUrl: '',
  LinkedinProfileUrl: '',
  twitterHandle: '',
  PersonalLinks: '',
  GallupCertified: 0,
  Headline: '',
  coachSubTitle: '',
  icfCertLevel: '',
  icfDigitalBadgeUrl: '',
  allowProBono: true,
  allowProBonoCareer: false,
  allowKeynoteSpeaker: false,
  covidVolunteerCoach: '',
  icfIndustries: '',
  icfClients: '',
  icfLanguages: '',
  IntroVideoUrl: '',
  gender: '',
  address: '',
  address2: '',
  zipCode: '',
  minimumLeadTimeAppointment: '0,0,0',
  additionalServices: '',
  corporateOrIndependantCoach: '',
  yearcoachingexperience: '',
  fullTimeCoach: '',
  virtualAssistant: false,
  vaYearsExperience: 0,
  vaEnglishCert: '',
  didFetchApi: false,
  isCoach: false,
  isCompany: false,
  hadFreeTrial: false,
  education: '',
  useNewLayout: false,
  connectedToZoom: false,
});

CoachRecord.parse = coach => {
  const {
    id,
    stripeId,
    stripeExpressId,
    affiliateId,
    givenName,
    familyName,
    isBusiness,
    isBusinessPlus,
    isBusinessGrowth,
    isPro,
    isEvangelist,
    isSATeamSub,
    subscription,
    addresses = [{}],
    phones = [{}],
    emails = [{}],
    rating,
    totalReviews,
    strengths,
    dateCreated,
    isCoach,
    isCompany,
  } = coach;
  const {
    hadFreeTrial,
    zoomMarketRefreshToken,
    AdditionalServices: additionalServices,
    CorporateOrIndependantCoach: corporateOrIndependantCoach,
    Gender: gender,
    zoomUrl,
    meetingUrl,
    coachSpecialtyId,
    suggestedCoachSpecialty,
    Headline: headline,
    AboutMe: aboutMe = '',
    ShortBio: shortBiography = '',
    ProfilePictUrl: profilePictUrl,
    IsOnlineToCoach: isOnlineToCoach,
    VanityUrl: vanity,
    TypeOfCoach: typeOfCoachString = '',
    Campaign: campaign = 1,
    clientCoachedAtCompanies = '[]',
    SchedulingIds: schedulingIdsString = '{}',
    CoachCertifications: coachCertifications = '',
    AppointmentVanityUrl: appointmentVanityUrlIsString = '[]',
    allowRealtimeVideo,
    allowAppointmentScheduling,
    allowContactMe,
    facebookProfileUrl,
    LinkedinProfileUrl,
    twitterHandle,
    GallupCertified,
    Headline,
    coachSubTitle,
    PersonalLinks,
    icfCertLevel,
    icfDigitalBadgeUrl,
    covidVolunteerCoach,
    icfIndustries,
    icfClients,
    icfLanguages,
    allowProBonoAppointments,
    IntroVideoUrl: introVideoUrl = '',
    vaYearsExperience,
    vaEnglishCert,
    minimumLeadTimeAppointment: minimumLeadTimeAppointmentString,
    YearsCoachExperience: yearcoachingexperience,
    FullOrPartTimeCoach: fullTimeCoach,
    Education: education = '',
    useNewLayout: coachUseNewLayout,
  } = coach.customFields || {};

  const IntroVideoUrl = verifyURL(introVideoUrl);
  const isOnline = isOnlineToCoach === '1';
  const description = convertTextToHTML(aboutMe);
  const {
    locality,
    region,
    regionCode,
    country,
    countryCode,
    address,
    address2,
    zipCode,
  } = addresses[0];
  const typeOfCoach = typeOfCoachString
    .split(',')
    .filter(type => Boolean(type))
    .map(specialty => specialty.trim());
  const { phoneNumber } = phones[0];
  const { emailAddress } = emails[0];
  const coachedAtCompanies = JSON.parse(clientCoachedAtCompanies);
  const introVideoId = verifyURL(introVideoUrl);
  const schedulingIds = JSON.parse(schedulingIdsString);
  const appointmentVanityUrl = JSON.parse(appointmentVanityUrlIsString);
  const realtimeVideo = allowRealtimeVideo !== 'False';
  const appointmentScheduling = allowAppointmentScheduling !== 'False';
  const contactMe = allowContactMe !== 'False';
  const allowProBono = allowProBonoAppointments !== 'False';
  const minimumLeadTimeAppointment = minimumLeadTimeAppointmentString || '0,0,0';
  const didFetchApi = true;
  const useNewLayout = coachUseNewLayout !== 'False';
  const connectedToZoom = Boolean(zoomMarketRefreshToken);

  return new CoachRecord({
    id,
    stripeId,
    stripeExpressId,
    affiliateId,
    vanity,
    givenName,
    familyName,
    headline,
    description,
    shortBiography,
    isOnline,
    isBusiness,
    isBusinessPlus,
    isBusinessGrowth,
    isPro,
    isEvangelist,
    subscription,
    zoomUrl,
    meetingUrl,
    profilePictUrl,
    introVideoId,
    locality,
    region,
    regionCode,
    country,
    countryCode,
    address,
    address2,
    zipCode,
    phoneNumber,
    emailAddress,
    dateCreated,
    rating,
    totalReviews,
    strengths,
    typeOfCoach,
    coachSpecialtyId,
    suggestedCoachSpecialty,
    campaign,
    coachedAtCompanies,
    schedulingIds,
    coachCertifications,
    appointmentVanityUrl,
    realtimeVideo,
    appointmentScheduling,
    contactMe,
    facebookProfileUrl,
    LinkedinProfileUrl,
    twitterHandle,
    GallupCertified,
    Headline,
    coachSubTitle,
    PersonalLinks,
    icfCertLevel,
    icfDigitalBadgeUrl,
    allowProBono,
    covidVolunteerCoach,
    icfIndustries,
    icfClients,
    icfLanguages,
    IntroVideoUrl,
    gender,
    minimumLeadTimeAppointment,
    additionalServices,
    corporateOrIndependantCoach,
    yearcoachingexperience,
    fullTimeCoach,
    vaYearsExperience,
    vaEnglishCert,
    didFetchApi,
    isCoach,
    isCompany,
    hadFreeTrial,
    education,
    useNewLayout,
    isSATeamSub,
    connectedToZoom,
  });
};

export const CoachPicture = coach => {
  const baseUrl = process.env.REACT_APP_AUTH0_PICT_URL;

  if (!(coach instanceof CoachRecord)) {
    return '';
  }

  if (coach.profilePictUrl) {
    return coach.profilePictUrl;
  }

  const { givenName, familyName, emailAddress } = coach;
  let firstLetters = givenName.length ? givenName.slice(0, 1) : emailAddress.slice(0, 1);
  firstLetters += familyName.length ? familyName.slice(0, 1) : emailAddress.slice(1, 2);

  return `${baseUrl}${firstLetters.toLowerCase()}.png?ssl=1`;
};

export const CoachResultPicture = (coach, withFallback = false) => {
  const baseUrl = process.env.REACT_APP_AUTH0_PICT_URL;

  if (coach.profilePictUrl && !withFallback) return coach.profilePictUrl;

  const { fullName } = coach;

  if (!fullName) return '';

  const [givenName, familyName] = fullName.split(' ');

  let firstLetters = givenName.length ? givenName.slice(0, 1) : '';

  if (firstLetters.length && familyName && familyName.length) {
    firstLetters += familyName.slice(0, 1);
  } else if (firstLetters.length) {
    firstLetters += givenName.slice(1, 2);
  }

  const coachPicture = {
    src: coach.profilePictUrl || '',
    fallbackSrc: firstLetters.length ? `${baseUrl}${firstLetters.toLowerCase()}.png?ssl=1` : '',
  };

  return withFallback ? coachPicture : coachPicture.fallbackSrc;
};
