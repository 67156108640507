import React from 'react';
import auth0Client from '../../../../../shared/helper/auth0';

import { goTo } from '../../../../../shared/helper/goTo';
import Container from '../../../../../shared/layout/Container';
import Section from '../../../../../shared/layout/Section';

import { 
  Wrapper,
  Highlighter,
  Title,
  Button
} from './styles';

const JoinSoar = () => {
  const isAuthenticated = auth0Client.isAuthenticated();
  return !isAuthenticated && (
    <Section>
      <Container paddingTop="0">
        <Wrapper>
          <Highlighter>GET STARTED</Highlighter>
          <Title>Create your account today!</Title>
          <Button onClick={() => goTo('/pricing')}>Join Soar Today</Button>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default JoinSoar;