import styled from 'styled-components';

export const Title = styled.h1`
  margin: 0 auto;
`;

export const SubTitle = styled.h2`
  margin: 0 auto;
`;

export const SubTitle3 = styled.h3`
  margin: 0 auto;
`;

export const Paragraph = styled.p`
  text-align: justify;
  font-size: 14px;
`;
