import React, { useState } from 'react';

import { DialogContainer } from '../../../dialog';
import PlaylistDetailsList from './List';
import { Wrapper, SessionTitle } from './styles';

const PlaylistDetails = ({ isReadOnly }) => {
  const [showDeleteAudio, setShowDeleteAudio] = useState(false);
  return (
    <>
    <Wrapper>
      <SessionTitle>PLAYLIST DETAILS</SessionTitle>
        <PlaylistDetailsList
          setShowDeleteAudio={setShowDeleteAudio}
          isReadOnly={isReadOnly}
        />
    </Wrapper>
    <DialogContainer showModal={showDeleteAudio} closeModal={() => setShowDeleteAudio(false)} label="audio" />
    </> 
  );
};

export default PlaylistDetails;
