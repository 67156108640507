import { connect } from 'react-redux';
import { fetchTopics } from '../../actions/dashboard-actions';
import Topics from './';

const mapStateToProps = state => ({
  topics: state.dashboard.topics,
  isLoading: state.dashboard.isLoadingTopics,
  error: state.dashboard.topicsError,
});

const mapDispatchToProps = {
  fetchTopics: () => fetchTopics(),
};

export default connect(mapStateToProps, mapDispatchToProps)(Topics);