import React from 'react';
import Status from '../../components/Status';

export const statusManager = props => {
  const { isLoading, errorMessage, isEmpty } = props;

  if (isLoading) return <Status {...props} />
  if (errorMessage) return <Status {...props} />
  if (isEmpty) return <Status {...props} />
  return false;
}
