import React from "react";
import PropTypes from 'prop-types';

import { Div } from './styles';

const Order = ({ text, ...props }) => <Div {...props}>{text}</Div>;

Order.propTypes = {
  text: PropTypes.string,
};

Order.defaultProps = {
  text: '',
};

export default Order;
