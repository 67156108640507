import styled from 'styled-components';
import {
  alertColor,
  alertBackgroundColor,
  alertBorderColor,
  successColor,
  successBackgroundColor,
  successBorderColor,
  infoColor,
  infoBackgroundColor,
  infoBorderColor,
} from '../../../styles/variables';

const type = {
  alert: {
    color: alertColor,
    background: alertBackgroundColor,
    borderColor: alertBorderColor,
  },
  success: {
    color: successColor,
    background: successBackgroundColor,
    borderColor: successBorderColor,
  },
  info: {
    color: infoColor,
    background: infoBackgroundColor,
    borderColor: infoBorderColor,
  },
};

export const Box = styled.div`
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;

  color: ${props => type[props.type]};
  background-color: ${props => type[props.type]};
  border-color: ${props => type[props.type]};
`;
