import React from 'react';
import PropTypes from 'prop-types';
import { TagInput, Tag } from './styles'

const TagView = ({ elements }) => (
  <TagInput>
    {elements.map((element, idx) => (
      <Tag key={`tag-${idx}`}>{element}</Tag>
    ))}
  </TagInput>
);

TagView.propTypes = {
  elements: PropTypes.array.isRequired,
};

export default TagView;