import styled from 'styled-components';
import media from 'styled-media-query';
import ImgByDensity from '../../../../../shared/basic/ImgByDensity';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
  background-color: white;
  padding: 6.5rem 5.7rem 7.2rem;
  border-radius: 0.5rem;

  ${media.lessThan('large')`
    padding: 2rem 2rem;
  `}
  ${media.lessThan('medium')`
    padding: 2rem 1rem;
  `}
`;

export const Grid = styled.div`
  display: grid;
  gap: 0.9rem;
  grid-template-columns: 1fr 1fr;

  ${media.lessThan('medium')`
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  `}
`;

export const H1 = styled.div`
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 1.1rem;
`;

export const H2 = styled.div`
  font-size: 1fr.8rem;
  margin-bottom: 4.8rem;
`;
export const B = styled.b``;

export const Img = styled(ImgByDensity)`
  height: auto;
  width: 76.96%;
  margin-bottom: 3.9rem;

  ${media.lessThan('medium')`
    width: 85%;
  `}
`;

export const Description = styled.div`
  line-height: 1.5;
  margin-bottom: 3.9rem;
  text-align: center;
`;

export const Badge = styled(ImgByDensity)`
  height: 5rem;
  width: auto;
  justify-self: center;
`;
