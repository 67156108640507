import { createAction } from 'redux-actions';
import { AudioRecord } from '../../../redux/records/audio-record';
import { GroupRecord } from '../../../redux/records/group-record';
import { PlaylistRecord } from '../../../redux/records/playlist-record';
import httpClient from '../../../shared/helper/request';

export const setPlaylist = createAction('LIBRARY/SET_PLAYLIST', playlist => PlaylistRecord.parse(playlist));
export const setPlaylistOrder = createAction('LIBRARY/SET_PLAYLIST_ORDER', itemIds => itemIds);

export const setActiveTab = createAction(
  'LIBRARY/SET_ACTIVE_TAB',
  tabIndex => tabIndex
);

export const setAudioPlaying = createAction('LIBRARY/SET_AUDIO_PLAYING',
  audio => new AudioRecord(audio)
);

export const requestAudioList = createAction('LIBRARY/REQUEST_AUDIO_LIST');
export const receiveAudioList = createAction('LIBRARY/RECEIVE_AUDIO_LIST', audios => audios);
export const failAudioList = createAction(
  'LIBRARY/FAIL_REQUEST_AUDIO_LIST',
  error => error
);

export const fetchAudios = () => async (dispatch, getState) => {
  dispatch(requestAudioList());

  try {
    const { authorizedUser } = getState();
    const { id } = authorizedUser;

    const { data } = await httpClient.get(`/SoarUplift/list?soarUserId=${id}&ownerUserId=${id}`);

    dispatch(receiveAudioList(data.items));
  } catch (error) {
    dispatch(failAudioList(error));
  }
};

export const requestAudioSharedWithMeList = createAction('LIBRARY/REQUEST_AUDIO_SHARED_WITH_ME_LIST');
export const receiveAudioSharedWithMeList = createAction(
  'LIBRARY/RECEIVE_AUDIO_SHARED_WITH_ME_LIST', audios => audios
);
export const failAudioSharedWithMeList = createAction(
  'LIBRARY/FAIL_REQUEST_AUDIO_SHARED_WITH_ME_LIST',
  error => error
);

export const fetchAudiosSharedWithMe = () => async (dispatch, getState) => {
  dispatch(requestAudioSharedWithMeList());

  try {
    const { authorizedUser } = getState();
    const { id } = authorizedUser;

    const { data } = await httpClient.get(`/SoarUplift/listShared?soarUserId=${id}`);

    dispatch(receiveAudioSharedWithMeList(data.items));
  } catch (error) {
    dispatch(failAudioSharedWithMeList(error));
  }
};

export const requestPlaylist = createAction('LIBRARY/REQUEST_PLAYLIST');
export const receivePlaylist = createAction('LIBRARY/RECEIVE_PLAYLIST', audios => audios);
export const failPlaylist = createAction(
  'LIBRARY/FAIL_REQUEST_PLAYLIST',
  error => error
);

export const fetchPlaylist = () => async (dispatch, getState) => {
  dispatch(requestPlaylist());

  try {
    const { authorizedUser } = getState();
    const { id } = authorizedUser;

    const { data } = await httpClient.get(`/SoarUpliftPlaylist/list/${id}`);

    dispatch(receivePlaylist(data));
  } catch (error) {
    dispatch(failPlaylist(error));
  }
};

export const requestGroup = createAction('LIBRARY/REQUEST_GROUP');
export const receiveGroup = createAction('LIBRARY/RECEIVE_GROUP', audios => audios);
export const failGroup = createAction(
  'LIBRARY/FAIL_REQUEST_GROUP',
  error => error
);

export const fetchGroups = () => async (dispatch, getState) => {
  dispatch(requestGroup());

  try {
    const { authorizedUser } = getState();
    const { id } = authorizedUser;

    const { data } = await httpClient.get(`/Group/list/${id}`);

    dispatch(receiveGroup(data));
  } catch (error) {
    dispatch(failGroup(error));
  }
};

export const requestAudio = createAction('LIBRARY/REQUEST_AUDIO');
export const receiveAudio = createAction('LIBRARY/RECEIVE_AUDIO', audioObject => AudioRecord.parse(audioObject));
export const failAudio = createAction(
  'LIBRARY/FAIL_REQUEST_AUDIO',
  error => error
);

export const fetchAudio = audioId => async dispatch => {
  dispatch(requestAudio());

  try {
    const { data } = await httpClient.get(`/SoarUplift/${audioId}`);

    dispatch(receiveAudio(data));
  } catch (error) {
    dispatch(failAudio(error));
  }
};

export const sendAudio = createAction('LIBRARY/SEND_AUDIO');
export const responseAudio = createAction('LIBRARY/RESPONSE_AUDIO');
export const setNewAudio = createAction('LIBRARY/SET_NEW_AUDIO', audioObject => AudioRecord.parse(audioObject));

export const updateAudio = () => async (dispatch, getState) => {
  dispatch(sendAudio());
  
  try {
    const { library } = getState();
    const { audio, newAudio } = library;
    const { tags, title, description, source, transcription, scope, voicePhrase } = newAudio;
    const audioData = {
      tags: tags.join(','),
      title,
      description,
      source,
      transcription,
      scope,
      voicePhrase
    };
    await httpClient.patch(`/SoarUplift/library/${audio.id}`, audioData);

    dispatch(responseAudio());
  } catch (error) {
    dispatch(failAudio(error));
  }
};

export const requestGroupDetail = createAction('LIBRARY/REQUEST_GROUP_DETAIL');
export const receiveGroupDetail = createAction('LIBRARY/RECEIVE_GROUP_DETAIL', groupDetail => GroupRecord.parse(groupDetail));
export const failGroupDetail = createAction('LIBRARY/FAIL_REQUEST_GROUP_DETAIL', error => error);

export const fetchGroupDetail = groupId => async dispatch => {
  dispatch(requestGroupDetail());

  try {
    const { data } = await httpClient.get(`/Group/${groupId}`);

    dispatch(receiveGroupDetail(data));
  } catch (error) {
    dispatch(failGroupDetail(error));
  }
};

export const sendGroup = createAction('LIBRARY/SEND_GROUP_DETAIL');
export const responseGroup = createAction('LIBRARY/RESPONSE_GROUP_DETAIL');
export const setNewGroupDetail = createAction('LIBRARY/SET_NEW_GROUP_DETAIL', groupObject => GroupRecord.parse(groupObject));

export const updateGroup = () => async (dispatch, getState) => {
  dispatch(sendGroup());
  
  try {
    const { library } = getState();
    const { groupDetail, newGroupDetail } = library;
    await httpClient.patch(`/Group/${groupDetail.id}`, newGroupDetail);

    dispatch(responseGroup());
  } catch (error) {
    dispatch(failGroupDetail(error));
  }
};

export const requestGroupMembers = createAction('LIBRARY/REQUEST_GROUP_MEMBER');
export const receiveGroupMembers = createAction('LIBRARY/RECEIVE_GROUP_MEMBER', members => members);

export const fetchGroupMembers = groupId => async dispatch => {
  dispatch(requestGroupMembers());

  try {
    const { data } = await httpClient.get(`/Group/users/list/${groupId}`);

    dispatch(receiveGroupMembers(data.users));
  } catch (error) {
    dispatch(failGroupDetail(error));
  }
};

export const requestGroupCreate = createAction('LIBRARY/REQUEST_GROUP_CREATE');
export const receiveGroupCreate = createAction('LIBRARY/RECEIVE_GROUP_CREATE');
export const failGroupCreate = createAction('LIBRARY/FAIL_REQUEST_GROUP_CREATE', error => error);

export const pushNewGroup = () => async (dispatch, getState) => {
  dispatch(requestGroupCreate());

  try {
    const { library, authorizedUser } = getState();
    const { newGroupDetail } = library;
    const { title, description } = newGroupDetail;
    const newGroup = {
      ownerUserId: authorizedUser.id,
      title,
      description,
    };

    await httpClient.post(`/Group`, newGroup);

    dispatch(receiveGroupCreate());
    dispatch(fetchGroups());
  } catch (error) {
    dispatch(failGroupCreate(error));
  }
};

export const requestPlaylistCreate = createAction('LIBRARY/REQUEST_PLAYLIST_CREATE');
export const receivePlaylistCreate = createAction('LIBRARY/RECEIVE_PLAYLIST_CREATE');
export const failPlaylistCreate = createAction('LIBRARY/FAIL_REQUEST_PLAYLIST_CREATE', error => error);
export const setNewPlaylist = createAction('LIBRARY/SET_NEW_PLAYLIST', playlist => PlaylistRecord.parse(playlist));

export const pushNewPlaylist = () => async (dispatch, getState) => {
  dispatch(requestPlaylistCreate());

  try {
    const { library, authorizedUser } = getState();
    const { newPlaylist } = library;
    const { title, description } = newPlaylist;
    const newPlaylistRecord = {
      ownerUserId: authorizedUser.id,
      title,
      description,
    };

    await httpClient.post(`/SoarUpliftPlaylist`, newPlaylistRecord);

    dispatch(receivePlaylistCreate());
    dispatch(fetchPlaylist());
  } catch (error) {
    dispatch(failPlaylistCreate(error));
  }
};

export const setDeleteKey = createAction('LIBRARY/SET_DELETE_ROW', key => key);
export const setDeleteLabel = createAction('LIBRARY/SET_DELETE_LABEL', label => label);
export const setDeleteType = createAction('LIBRARY/SET_DELETE_TYPE', type => type);

export const setDeleteOpts = opts => async dispatch => {
  const { key, label, type } = opts;
  dispatch(setDeleteKey(key));
  dispatch(setDeleteLabel(label));
  dispatch(setDeleteType(type));
};

export const requestDelete = createAction('LIBRARY/REQUEST_DELETE');
export const receiveDelete = createAction('LIBRARY/RECEIVE_DELETE');
export const failDelete = createAction('LIBRARY/FAIL_DELETE', error => error);

export const deleteAction = () => async (dispatch, getState) => {
  dispatch(requestDelete());

  try {
    const { library, authorizedUser } = getState();
    const { deleteRowKey, deleteType } = library;

    let deleteUrl = `/Group/${deleteRowKey}`;
    let reloadList = fetchGroups;
    if (deleteType === 'playlist') {
      deleteUrl = `/SoarUpliftPlaylist/${deleteRowKey}/${authorizedUser.id}`;
      reloadList = fetchPlaylist;
    } else if (deleteType === 'audio') {
      deleteUrl = `/SoarUplift/library/${deleteRowKey}/${authorizedUser.id}`;
      reloadList = fetchAudios;
    } else if (deleteType === 'playlist-audio') {
      const { playlist } = library;
      deleteUrl = `/SoarUpliftPlaylist/items/${playlist.id}/${deleteRowKey}`;
      reloadList = () => fetchPlaylistAudios(playlist.id);
    } else if (deleteType === 'playlist-permission') {
      const { playlist } = library;
      deleteUrl = `/Group/permissions/${deleteRowKey}/playlist/${playlist.id}`;
      reloadList = () => fetchPlaylistPermissions(playlist.id);
    } else if (deleteType === 'invitation') {
      const { groupDetail } = library;
      deleteUrl = `/Group/users/${groupDetail.id}/${deleteRowKey}`;
      reloadList = () => fetchGroupMembers(groupDetail.id);
    }

    await httpClient.delete(deleteUrl);

    dispatch(receiveDelete());
    dispatch(reloadList());
  } catch (error) {
    dispatch(failDelete(error));
  }
};

export const requestPlaylistItem = createAction('LIBRARY/REQUEST_PLAYLIST_ITEM');
export const receivePlaylistItem = createAction('LIBRARY/RECEIVE_PLAYLIST_ITEM', playlist => PlaylistRecord.parse(playlist));
export const failPlaylistItem = createAction(
  'LIBRARY/FAIL_REQUEST_PLAYLIST_ITEM',
  error => error
);

export const fetchPlaylistItem = playlistId => async (dispatch, getState) => {
  dispatch(requestPlaylistItem());
  try {
    const { authorizedUser } = getState();

    const { data } = await httpClient.get(`/SoarUpliftPlaylist/${playlistId}`);
    const { data: { itemIds } } = await httpClient.get(`/SoarUpliftPlaylist/items/${playlistId}/${authorizedUser.id}?expandAll=true`);

    const playList = { ...data, itemIds };

    dispatch(receivePlaylistItem(playList));
  } catch (error) {
    dispatch(failPlaylistItem(error));
  }
};

export const requestPlaylistAudios = createAction('LIBRARY/REQUEST_PLAYLIST_AUDIOS');
export const receivePlaylistAudios = createAction('LIBRARY/RECEIVE_PLAYLIST_AUDIOS', audios => audios);

export const fetchPlaylistAudios = playlistId => async (dispatch, getState) => {
  dispatch(requestPlaylistAudios());

  try {
    const { authorizedUser } = getState();
    const { data } = await httpClient.get(`/SoarUpliftPlaylist/items/${playlistId}/${authorizedUser.id}?expandAll=true`);

    let audiosFounded = data.items;
    if (!data.totalCount) audiosFounded = [];

    dispatch(receivePlaylistAudios(audiosFounded));
  } catch (error) {
    dispatch(failPlaylistItem(error));
  }
};

export const sendPlaylist = createAction('LIBRARY/SEND_PLAYLIST_DETAIL');
export const responsePlaylist = createAction('LIBRARY/RESPONSE_PLAYLIST_DETAIL');

export const updatePlaylistItem = () => async (dispatch, getState) => {
  dispatch(sendPlaylist());
  
  try {
    const { library } = getState();
    const { playlist } = library;
    const { title, description, sharing, itemIds } = playlist;
    const newPlaylistData = { title, description, sharing };
    await httpClient.patch(`/SoarUpliftPlaylist/${playlist.id}`, newPlaylistData);
    itemIds.legth && await httpClient.patch(
      `/SoarUpliftPlaylist/reorder/${playlist.id}/${itemIds.join(',')}`
    );
    dispatch(responsePlaylist());
  } catch (error) {
    dispatch(failPlaylistItem(error));
  }
};

export const requestPlaylistPermissions = createAction('LIBRARY/REQUEST_PLAYLIST_PERMISSIONS');
export const receivePlaylistPermission = createAction('LIBRARY/RECEIVE_PLAYLIST_PERMISSIONS', groups => groups);

export const fetchPlaylistPermissions = playlistId => async (dispatch, getState) => {
  dispatch(requestPlaylistPermissions());

  try {
    const { data } = await httpClient.get(`/SoarUpliftPlaylist/listGroupsWithPermissions/${playlistId}`);

    dispatch(receivePlaylistPermission(data));
  } catch (error) {
    dispatch(failPlaylistItem(error));
  }
};

export const sendGroupPermission = createAction('LIBRARY/SEND_GROUP_PERMISSION');
export const responseGroupPermission = createAction('LIBRARY/RESPONSE_GROUP_PERMISSION');

export const updateGroupPermission = groupId => async (dispatch, getState) => {
  dispatch(sendGroupPermission());
  
  try {
    const { library } = getState();
    const { playlist } = library;
    await httpClient.post(`Group/permissions/${groupId}/playlist/${playlist.id}`);

    dispatch(responseGroupPermission());
    dispatch(fetchPlaylistPermissions(playlist.id));
  } catch (error) {
    dispatch(failGroup(error));
  }
};

export const setSelectedAudio = createAction('LIBRARY/SET_SELECTED_AUDIO', audioId => audioId);
export const closeModal = createAction('LIBRARY/CLOSE_MODAL');

export const sendAudioToPlaylist = createAction('LIBRARY/SEND_AUDIO_TO_PLAYLIST');
export const responseAudioToPlaylist = createAction('LIBRARY/RESPONSE_AUDIO_TO_PLAYLIST');

export const pushAudioToPlaylist = playlistId => async (dispatch, getState) => {
  dispatch(sendAudioToPlaylist());
  
  try {
    const { library } = getState();
    const { selectedAudioToPlaylist } = library;
    await httpClient.post(`/SoarUpliftPlaylist/items/${playlistId}/${selectedAudioToPlaylist}`);

    dispatch(responseAudioToPlaylist());
  } catch (error) {
    dispatch(failPlaylistCreate(error));
  }
};

export const pushNewPlaylistWithAudio = () => async (dispatch, getState) => {
  dispatch(requestPlaylistCreate());

  try {
    const { library, authorizedUser } = getState();
    const { newPlaylist } = library;
    const { title, description } = newPlaylist;
    const newPlaylistRecord = {
      ownerUserId: authorizedUser.id,
      title,
      description,
    };

    const { data } = await httpClient.post(`/SoarUpliftPlaylist`, newPlaylistRecord);

    dispatch(pushAudioToPlaylist(data.id));
  } catch (error) {
    dispatch(failPlaylistCreate(error));
  }
};

export const sendInvitation = createAction('LIBRARY/SEND_INVITATION');
export const responseInvitation = createAction('LIBRARY/RESPONSE_AUDIO_TO_PLAYLIST');
export const invitationFail = createAction('LIBRARY/FAIL_INVITATION', error => error);

export const sendNewInvitation = invitationData => async (dispatch, getState) => {
  dispatch(sendInvitation());
  
  try {
    const { authorizedUser, library } = getState();
    const { groupDetail } = library;
    const { givenName, familyName, email } = invitationData;
    const urlData = encodeURI(`givenName=${givenName}&familyName=${familyName}&email=${encodeURIComponent(email)}`);
    await httpClient.post(`/Group/users/invite/${authorizedUser.id}/${groupDetail.id}?${urlData}`);

    dispatch(responseInvitation());
    dispatch(fetchGroupMembers(groupDetail.id));
  } catch (error) {
    dispatch(invitationFail(error));
  }
};
