import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8.7rem; 
`;

export const WrapperItems = styled.div`
  padding: 0;
  margin: 0 0 2rem 0;
`;
