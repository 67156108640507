import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import WebFont from 'webfontloader';
import '@fortawesome/fontawesome-pro/css/all.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './redux/containers/app-container';
import createStore from './redux/store';

WebFont.load({
  google: {
    families: ['Work Sans:100,200,300,400,500,600,700,800,900', 'sans-serif'],
  },
});

window.UPLIFT_APP = {};
window.UPLIFT_APP.history = createBrowserHistory();

const store = createStore();

ReactDOM.render(
  <Provider store={store}>
    <Router history={window.UPLIFT_APP.history} />
    <App />
  </Provider>,
  document.getElementById('root')
);
