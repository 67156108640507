import styled from 'styled-components';
import { FontAwesomeIcon } from '../../../../shared/basic/IconFontAnswer/styles';
import { lightBlueGrey } from '../../../../styles/variables';

export const Table = styled.table`
  width: 100%;
  margin-top: 5rem;
  border-radius: 5px;
  border: solid 1px ${lightBlueGrey};
  border-collapse: collapse;

  thead {
    background-color: #ebecf2;
    text-align: left;
    font-family: Work Sans;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.09px;
    color: #000000;

    tr {
      height: 6.4rem;
      border: solid 1px ${lightBlueGrey};

      th:first-child {
        padding-left: 2rem;
      }
    }
  }

  tbody {
    font-family: Work Sans;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.1px;
    color: #000000;

    tr {
      height: 6.4rem;

      &:not(:last-child){
        border-bottom: 1px solid ${lightBlueGrey};
      }

      td:first-child {
        padding-left: 2rem;
      }

      td:last-of-type {
        display: flex;
        padding-right: 2rem;
      }

      td {
        margin: 2rem 0;

        a:not(:first-child) {
          padding-left: 2rem;
        }
      }

      td:not(:first-child) {
        color: #666666;
        align-items: baseline;
      }

      & a {
        text-decoration: none;
      }
    }
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  cursor: pointer;

  &:not(:first-child) {
    padding-left: 2rem;
  }
`;