import React, { memo } from "react";
import PropTypes from 'prop-types';
import { BtnShort } from './styles';

const ButtonShort = ({ name, bgColor, color, children, disabled, onClick, dataTip, dataFor }) =>
  <BtnShort
    data-tip={dataTip}
    data-for={dataFor}
    data-event="click"
    name={name}
    bgColor={bgColor}
    color={color}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </BtnShort>;

ButtonShort.propTypes = {
  name: PropTypes.string,
  children: PropTypes.any,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  dataTip: PropTypes.string,
  dataFor: PropTypes.string,
};

ButtonShort.defaultProps = {
  name: '',
  children: '',
  bgColor: '',
  color: '',
  disabled: false,
  onClick: () => null,
  dataTip: '',
  dataFor: '',
};

export default memo(ButtonShort);
