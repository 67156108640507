import styled from 'styled-components';
import media from 'styled-media-query';
import ImgByDensity from '../../../../../../../shared/basic/ImgByDensity';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  text-decoration: ${props => props.disabled && 'line-through'};
  font-size: 1.3rem;
  font-weight: 500;
  display: grid;
  gap: .5rem;
  grid-template-columns: min-content auto;
  border-radius: 5px;
  border: solid 1px ${props => props.checked ? '#ff7654' : '#caccd8'};
  padding: 1.3rem;
`;

export const Flags = styled.div`
  display: grid;
  gap: .5rem;
  grid-template-columns: repeat(${props => props.nItems || 1}, 1fr);
  position: absolute;
  justify-self: end;
  align-self: center;
  margin-right: 1rem;
  
  ${media.lessThan('medium')`
    display: none;
  `}
`;

export const Img = styled(ImgByDensity)`
  height: 2.2rem;
`;
