import React, { useEffect, useState } from 'react';
import {
  FlexBox,
  GlobalStyles,
  FlexBoxLogo,
  DivFeatures,
  DivForm,
  DivContainer,
} from './styles';
import { imgs } from '../../assets';
import { Constants } from './constants';
import ItemList from './components/ItemList';
import Form from './components/form';

const Sign = () => {
  const url = new URL(window.location.href);
  const [isLogin] = useState(url.searchParams.get('login') === 'true');

  return (
    <>
      <GlobalStyles />
      <DivContainer>
        <FlexBox>
          <DivFeatures>
            <FlexBoxLogo src={imgs.logo_white} />
            {Constants.items.map(item => (
              <ItemList key={item.index} {...item} />
            ))}
          </DivFeatures>
          <DivForm>
            <Form isLogin={isLogin}>
              <FlexBoxLogo src={imgs.logo_thin} />
            </Form>
          </DivForm>
        </FlexBox>
      </DivContainer>
    </>
  )
};

export default Sign;