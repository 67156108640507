import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Div, Img, Title, Description, Price, Value, Period } from './styles';

const Channel = ({ img, name, description, price, period }) => {
  return (
    <Wrapper>
      {!img ? null : (
        <Div bgColor="#f4f4f4">
          <Img src={img} alt={name} />
        </Div>
      )}
      <Div>
        <Title>{name}</Title>
        {!description ? null : (<Description>{description}</Description>)}
        <Price>
          <Value>{price}</Value>
          <Period>{period}</Period>
        </Price>
      </Div>
    </Wrapper>
  );
};

Channel.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
  period: PropTypes.string,
};

Channel.defaultProps = {
  img: '',
  name: '',
  description: '',
  price: 0,
  period: '',
};

export default Channel;
