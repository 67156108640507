import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import {
  setCardHolderName,
  setCardNumberAttribute,
  setCountry,
  setExpirationDateAttribute,
  setZipCode
} from '../../../../../../actions/premium-actions';
import FormGroup from '../../../../../../../../shared/basic/Form';
import {  
  Grid,
  CardNumberField,
  CardExpiryField,
  CardCvcField,
  CardNameElement,
  ZipCodeElement,
} from './styles';
import { Wrapper, Title, Form } from '../styles';

const Fields = ({
  handleCreditCardCVCChange,
}) => {
  const dispatch = useDispatch();
  const selectedCountry = useSelector(state => state.authorizedUser.countryCode);
  const countries = useSelector(state => state.generalInfo.countries);
  const country = useSelector(state => state.accountSettings.country);
  const cardHolderName = useSelector(state => state.accountSettings.cardHolderName);
  const zipCode = useSelector(state => state.accountSettings.zipCode);
  
  const countriesOptions = countries.map(({ id, name }) => ({ value: id, label: name }));
  const selectedCountryOption = countriesOptions.find(option => option.value === country);

  useEffect(() => { dispatch(setCountry(selectedCountry)) }, [dispatch, selectedCountry]);

  const handleZipCodeChange = event => {
    const zipCodeRegexp = /^[a-z0-9]+$/i;
    const targetValue = event.target.value.slice(0, 10);

    if (zipCodeRegexp.test(targetValue) || targetValue === '') {
      dispatch(setZipCode(targetValue));
    }
  };

  const handleCardHolderNameChange = event => {
    dispatch(setCardHolderName(event.target.value));
  };

  const handleCountryChange = element => {
    dispatch(setCountry(element.value));
  };

  const handleCreditCardExpirationDateChange = element => {
    if (!element.empty && element.complete) dispatch(setExpirationDateAttribute(true));
  };

  const handleCreditCardNumberChange = element => {
    if (!element.empty && element.complete) dispatch(setCardNumberAttribute(true));
  };

  return (
    <Wrapper>
      <Title>NEW PAYMENT CARD</Title>
      <Form>
        <FormGroup label="Full Name">
          <CardNameElement
            name="name-on-card"
            value={cardHolderName}
            onChange={handleCardHolderNameChange}
          />
        </FormGroup>

        <Grid>
          <FormGroup label="Country">
            <Select
              options={countriesOptions}
              value={selectedCountryOption}
              onChange={handleCountryChange}
            />
          </FormGroup>

          <FormGroup label="Zip Code">
            <ZipCodeElement
              value={zipCode}
              onChange={handleZipCodeChange}
            />
          </FormGroup>
        </Grid>

        <FormGroup label="Card Number">
          <CardNumberField>
            <CardNumberElement
              onChange={handleCreditCardNumberChange}
            />
          </CardNumberField>
        </FormGroup>

        <Grid>
          <FormGroup label="Expiration Date">
            <CardExpiryField>
              <CardExpiryElement
                onChange={handleCreditCardExpirationDateChange}
              />
            </CardExpiryField>
          </FormGroup>

          <FormGroup label="Security Code">
            <CardCvcField>
              <CardCvcElement 
                onChange={handleCreditCardCVCChange}
              />
            </CardCvcField>
          </FormGroup>
        </Grid>
      </Form>
    </Wrapper>
  );
};

Fields.propTypes = {
  handleCreditCardCVCChange: PropTypes.func.isRequired,
};

export default memo(Fields);
