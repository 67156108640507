import React from 'react';
import withLayout from '../../shared/layout/hocs/withLayout';
import { UploadContainer } from './components/upload';
import Announcement from './components/announcement';
import Premium from './components/premium';
import Widget from './components/widget';
import { TopicsContainer } from './components/topics';
import {
  DashboardContainer,
  FirstPart,
  SecondPart,
} from './styles';

const text = 'Audio clips less than 4 minutes long give the best listening experience and play inside the Soar UPLIFT Library.';

const Dashboard = () => (
  <DashboardContainer>
    <FirstPart>
      <Widget dashed={true} footer={text}>
        <UploadContainer />
      </Widget>
      <Widget title="Topics">
        <TopicsContainer />
      </Widget>
    </FirstPart>
    <SecondPart>
      <Widget shadow>
        <Announcement />
      </Widget>
      {/* <Widget shadow>
        <Premium />
      </Widget> */}
    </SecondPart>
  </DashboardContainer>
);

export default withLayout(Dashboard);