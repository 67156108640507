import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Header from './Header';
import Player from './Player';
import List from './List';

import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrentPlaylist } from '../../../redux/actions/playlist-actions';
import withLayout from '../../../../../shared/layout/hocs/withLayout';
import JoinSoar from '../../../shared/components/JoinSoar';
import { setCurrentyAudio } from '../../../redux/actions/library-actions';

const PlaylistDetail = ({ match }) => {
  const dispatch = useDispatch();
  const items = useSelector(state => state.publicLibrary.currentyPlaylist.items);

  useEffect(() => {
    const { params: { playlistId } } = match;
    if (playlistId) dispatch(fetchCurrentPlaylist(playlistId))
  }, [dispatch, match]);

  useEffect(() => {
    if (items.length) dispatch(setCurrentyAudio(items[0]));
  }, [dispatch, items]);  

  return (
    <>
      <Header />
      <Player />
      <List />
      <JoinSoar />
    </>
  );
};

PlaylistDetail.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withLayout(PlaylistDetail);