import React from 'react';

import { Container, Input, Checkmark, Label } from './styles';

const Radio = ({ id, label, checked, onChange, value, disabled }) => (
  <Container>
    <Label htmlFor={id} disabled={disabled}>
      {label}
      <Input
        onChange={!disabled ? onChange : undefined}
        checked={checked}
        disabled={disabled}
        value={value}
        id={id}
      />
      <Checkmark  />
    </Label>
  </Container>
);

export default Radio;