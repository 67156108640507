import { connect } from 'react-redux';
import {
  pushNewGroup,
  setNewGroupDetail,
} from '../../actions/my-library-actions';
import GroupForm from './';

const mapStateToProps = state => ({
  newGroupDetail: state.library.newGroupDetail,
  groupFormError: state.library.groupFormError,
  isCreating: state.library.isCreating,
  closeForm: state.library.closeForm,
});

const mapDispatchToProps = {
  pushNewGroup: () => pushNewGroup(),
  setNewGroupDetail: group => setNewGroupDetail(group),
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupForm);