const appHost = process.env.REACT_APP_HOSTNAME;

export const urlPlaylistGenerator = (id, service) => {
  let url = '#';
  switch (service) {
    case 'alexaSkills':
      url = `https://alexa-skills.amazon.com/apis/custom/skills/amzn1.ask.skill.1be65940-84e8-44f1-886e-7c6ac189ebf1/tasks/GetPlaylist/versions/1?playlistId=${id}`;
      break;
    case 'googleAssistant':
      url = `https://assistant.google.com/services/invoke/uid/000000850f4057ae?intent=DialogflowGetPlaylistByIdIntent&param.playlistId=${id}&hl=en`;
      break;
    case 'soar':
      url = `${appHost}/playlist/detail/${id}`;
      break;  
    default:
      break;
  }
  return url;
};

export const openPlaylistLink = (service = null, id) => {
  if (!id) return
  if (service) window.open(urlPlaylistGenerator(id, service), '_blank');
  else window.open(`/playlist/audio/list/${id}`, '_self');
};

export const urlAudioGenerator = (object, service) => {
  let url = '#';
  const { id, voicePhrase } = object;
  switch (service) {
    case 'alexaSkills':
      url = `https://alexa-skills.amazon.com/apis/custom/skills/amzn1.ask.skill.fb16296e-0805-4565-a16f-d5768de17112/tasks/GetAudio/versions/1?audioId=${id}`;
      break;
    case 'googleAssistant':
      url = `https://assistant.google.com/services/invoke/uid/000000850f4057ae?intent=DialogflowGetPlaylistByIdIntent&param.audioId=${id}&hl=en`;
      break;
    case 'soar':
      url = `${appHost}/audio/${String(voicePhrase).replaceAll(' ', '-')}`;
      break;
    default:
      break;
  }
  return url;
};

export const openAudioLink = (service = null, id) => {
  if (!id) return
  if (service) window.open(urlAudioGenerator({ id, voicePhrase: null }, service), '_blank');
  else window.open(`/playlist/audio/list/${id}`, '_self');
};
