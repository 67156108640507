import { DateTime } from 'luxon';
import styled from 'styled-components';
import Icon from '../../../../../../../../../shared/basic/IconFontAnswer';
import { secondFormat } from '../../../../../../../../../shared/helper/Time';
import { melon } from '../../../../../../../../../styles/variables';


export const Row = styled.div`
  display: grid;
  gap: 1.6rem;
  grid-template-columns: min-content auto min-content;
  border-radius: 0.5rem;
  border: solid 0.1rem #caccd8;
  padding: 1rem;
  align-items: center;
`;

export const WrapperTitle = styled.div`
  display: grid;
  gap: 0.5rem;
  font-size: 1.4rem;
  grid-template-columns: 1fr;
`;

export const WrapperDetails = styled.div`
  display: grid;
  gap: 1.3rem;
  grid-template-columns: max-content auto;
  font-size: 1.3rem;
  color: #666666;
`;

export const Title = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const GripIcon = styled(Icon).attrs(() => ({
  unicode: 'f58e',
  iconSolid: true,
}))`
  :after {
    font-size: 1.5rem;
    color: #999999;
    cursor: pointer;
  }
`;

export const Duration = styled.td.attrs(props => ({
  children: secondFormat(props.children)
}))``;

export const Date = styled.td.attrs(props => ({
  children: DateTime.fromISO(props.children).toISODate()
}))``;

export const Action = styled.div`
  width: 100%;
  justify-items: end;
  display: grid;
  grid-template-columns: repeat(3, min-content);
  gap: 1.5rem;
`;

export const CommonIcon = styled(Icon)`
  :after {
    color: ${melon};
    cursor: pointer;
  }
`;

export const EyeIcon = styled(CommonIcon).attrs(() => ({
  unicode: 'f06e',
}))``;

export const PencilIcon = styled(CommonIcon).attrs(() => ({
  unicode: 'f044',
}))``;

export const TrashIcon = styled(CommonIcon).attrs(() => ({
  unicode: 'f2ed',
}))``;
