import styled from 'styled-components';
import media from 'styled-media-query';

export const DashboardContainer = styled.div `
  margin: 2.9rem;
  max-width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  display: flex;
  gap: unset;

  ${media.lessThan('medium')`
    display: grid;
    gap: 3rem;
  `}
`;

export const FirstPart = styled.div `
  width: 66%;
  ${media.lessThan('medium')`
    width: 100%;
  `}
`;

export const SecondPart = styled.div `
  margin-left: 3rem;
  width: 33%;
  ${media.lessThan('medium')`
    width: 100%;
    margin-left: 0rem;
  `}
`;
