import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  width: 33.5vw;
  padding: 0 0 9.1rem;
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;

  ${media.lessThan('large')`
    width: 55vw;
  `}
  ${media.lessThan('medium')`
    width: 75vw;
  `}
  ${media.lessThan('small')`
    width: 98vw;
  `}
`;

export const Title = styled.div`
  font-size: 2.4rem;
  font-weight: bold;
  letter-spacing: -0.16px;
  text-align: center;
  color: #000000;
  margin-bottom: 3.1rem;
`;
