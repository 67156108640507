import { createAction } from 'redux-actions';
import httpClient from '../../../shared/helper/request';
import { CoachPlanRecord } from '../../../redux/records/coach-plan-record';
import { PLANS } from '../constants';

export const setPrice = createAction('SUBSCRIPTION/SET_PRICE_PLAN', price => price);
export const setPeriod = createAction('SUBSCRIPTION/SET_PERIOD', period => period);
export const setSuccessMessage = createAction('PAYMENT/SET_SUCCESS_MESSAGE', message => message);
export const setIsLoading = createAction('SUBSCRIPTION/SET_IS_LOADING', boolean => boolean);

export const creditCardsRequestData = createAction('PAYMENT/CREDIT_CARDS_REQUEST_DATA');
export const creditCardsReceiveData = createAction(
  'PAYMENT/CREDIT_CARDS_RECEIVE_DATA',
  creditCards => creditCards
);
export const creditCardsFailLoadData = createAction(
  'PAYMENT/CREDIT_CARDS_FAIL_LOAD_DATA',
  error => error
);

export const savePaymentMethod = createAction(
  'PAYMENT/SAVE_PAYMENT_METHOD',
  (paymentMethod, zipCode) => ({ paymentMethod, zipCode })
);

export const fetchCreditCards = () => async (dispatch, getState) => {
  dispatch(creditCardsRequestData());
  try {
    const { authorizedUser } = getState();
    const url = `/user/creditCards/${authorizedUser.id}`;
    const { data: creditCards } = await httpClient.get(url);
    dispatch(creditCardsReceiveData(creditCards));
  } catch (error) {
    dispatch(creditCardsFailLoadData(error));
  }
};

export const coachPlansRequestData = createAction('PAYMENT/COACH_PLANS_REQUEST_DATA');
export const coachPlansReceiveData = createAction('PAYMENT/COACH_PLANS_RECEIVE_DATA', coachPlans =>
  CoachPlanRecord.parse(coachPlans)
);
export const coachPlansFailLoadData = createAction(
  'PAYMENT/COACH_PLANS_FAIL_LOAD_DATA',
  error => error
);

export const fetchCoachPlans = () => async (dispatch, getState) => {
  dispatch(coachPlansRequestData());
  try {
    const { authorizedUser } = getState();
    const url = `/Subscription/${authorizedUser.id}`;
    const { data: coachPlans } = await httpClient.get(url);
    dispatch(coachPlansReceiveData(coachPlans));
  } catch (error) {
    dispatch(coachPlansFailLoadData(error));
  }
};

export const setTypePlan = createAction(
  'SUBSCRIPTION/SET_TYPE_PLAN',
  subscriptionPlan => subscriptionPlan
);

export const setTypeSubscriptionLabel = createAction(
  'SUBSCRIPTION/SET_SUBSCRIPTION_LABEL',
  typeSubscriptionLabel => typeSubscriptionLabel
);

export const requestAlterPlan = createAction('PAYMENT/ALTER_PLAN_REQUEST');
export const receiveAlterPlan = createAction('PAYMENT/ALTER_PLAN_RECEIVE', data => data);
export const failAlterPlan = createAction('PAYMENT/ALTER_PLAN_FAIL', error => error);

export const alterPlan = stripeCardId => async (dispatch, getState) => {
  dispatch(requestAlterPlan());
  const { SINGLE } = PLANS;

  try {
    const { authorizedUser, accountSettings } = getState();
    const { period, coachPlans } = accountSettings;
    const userId = authorizedUser.id;
    const newPlanId = SINGLE[period].id;

    let response = '';
    if (
      coachPlans.stripeSubscriptionId &&
      coachPlans.cancelledOn === null
    ) {
      const subscriptionId = coachPlans.stripeSubscriptionId;
      response = await httpClient.patch(
        `/Subscription/updatePlan/${userId}/${subscriptionId}/${newPlanId}`
      );
    } else {
      if (coachPlans.stripeSubscriptionId) {
        await httpClient.patch(
          `/Subscription/cancel/${coachPlans.stripeSubscriptionId}/now`
        );
      }
      response = await httpClient.post(
        `/Subscription/${userId}/${newPlanId}/${stripeCardId}`
      );
    }

    const { data } = response;

    if (response.status !== 200) {
      const error = new Error(`Error: ${data}`);
      dispatch(failAlterPlan(error));
      return;
    }
    dispatch(setSuccessMessage('Success in alter plan.'));
    dispatch(receiveAlterPlan(data));
  } catch (error) {
    dispatch(failAlterPlan(error));
  }
};

export const billingAddressRequestData = createAction('PAYMENT/ADDRESS_REQUEST_DATA');
export const billingAddressReceiveData = createAction(
  'PAYMENT/ADDRESS_RECEIVE_DATA',
  billingAddress => billingAddress
);
export const billingAddressFailLoadData = createAction(
  'PAYMENT/ADDRESS_FAIL_LOAD_DATA',
  error => error
);

export const fetchBillingAddress = () => async (dispatch, getState) => {
  dispatch(billingAddressRequestData());
  try {
    const { authorizedUser } = getState();
    const { data: billingAddress } = await httpClient.get(`/user/${authorizedUser.id}`);
    dispatch(billingAddressReceiveData(billingAddress));
  } catch (error) {
    dispatch(billingAddressFailLoadData(error));
  }
};

export const requestProratePricePlan = createAction('PLAN/REQUEST_PRORATED_PRICE_PLAN');
export const receiveProratePricePlan = createAction(
  'PLAN/RECEIVE_PRORATED_PRICE_PLAN',
  proratedPricePlan => proratedPricePlan
);
export const failProratePricePlan = createAction(
  'PLAN/FAIL_PRORATED_PRICE_PLAN',
  errorMessage => errorMessage
);

export const getProratedPricePlan = period => async (dispatch, getState) => {
  dispatch(requestProratePricePlan());
  try {
    const { accountSettings, authorizedUser } = getState();
    const { coachPlans } = accountSettings;
    const { id: userId } = authorizedUser;
    const { stripeSubscriptionId } = coachPlans;
    if (stripeSubscriptionId) {
      let newPlanId = '';

      if (period === 360) {
        newPlanId = process.env.REACT_APP_PLAN_BUSINESS_GROWTH_ANUAL;
      }
      if (period === 30) {
        newPlanId = process.env.REACT_APP_PLAN_BUSINESS_GROWTH_MONTLY;
      }

      const response = await httpClient.get(
        `Subscription/proratedAmount/${userId}/${stripeSubscriptionId}/${newPlanId}`
      );
      const { data } = response;

      dispatch(receiveProratePricePlan(data));
    }
  } catch (error) {
    dispatch(failProratePricePlan(error));
  }
};

export const setPromoCode = createAction('SUBSCRIPTION/SET_PROMO_CODE', promoCode => promoCode);

export const failApplyPromoCode = createAction(
  'PLAN/FAIL_SET_PROMO_CODE',
  errorMessage => errorMessage
);

export const applyPromoCode = (promoCode, plan, period) => async (dispatch, getState) => {
  try {
    dispatch(setIsLoading(true));
    // const { BUSINESSICF, ICF, PLUS, PROMO_PLUS, GROWTH } = TYPE_PLAN[0];
    // const { authorizedUser } = getState();
    // let newPlanId;
    // const userId = authorizedUser.id;

    // if (plan === PLUS.plan) {
    //   newPlanId = period === 360 ? PLUS.annualPlanId : PLUS.monthlyPlanId;
    // }
    // if (plan === GROWTH.plan) {
    //   newPlanId = period === 360 ? GROWTH.annualPlanId : GROWTH.monthlyPlanId;
    // }
    // if (plan === ICF.plan) {
    //   newPlanId = period === 360 ? ICF.annualPlanId : ICF.monthlyPlanId;
    // }
    // if (plan === BUSINESSICF.plan) {
    //   newPlanId = period === 360 ? BUSINESSICF.annualPlanId : BUSINESSICF.monthlyPlanId;
    // }
    // if (plan === PROMO_PLUS.plan) {
    //   newPlanId = period === 360 ? PROMO_PLUS.annualPlanId : PROMO_PLUS.monthlyPlanId;
    // }

    // const response = await httpClient.get(
    //   `Subscription/prePurchaseAmount/${userId}/${newPlanId}?couponName=${promoCode}`
    // );
    // const { data } = response;

    // dispatch(setPrice(data));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(failApplyPromoCode(error.response.data));
  }
};

export const failLocalToken = createAction(
  'PLAN/FAIL_LOCAL_TOKEN',
  errorMessage => errorMessage
);

export const setCurrentyCreditCard = createAction(
  'PLAN/SET_CURRENTY_CREDIT_CARD',
  creditCard => creditCard
);

export const setSecurityCodeAttribute = createAction(
  'PLAN/SET_SECURITY_CODE_ATTRIBUTE',
  securityCode => securityCode
);

export const setCvcElement = createAction(
  'PLAN/SET_CVC_ELEMENT',
  cvc => cvc
);

export const setCardNumberAttribute = createAction(
  'PLAN/SET_CARD_NUMBER_ATTRIBUTE',
  cardNumber => cardNumber
);

export const setExpirationDateAttribute = createAction(
  'PLAN/SET_EXPIRATION_DATE_ATTRIBUTE',
  expirationDate => expirationDate
);

export const setCardHolderName = createAction(
  'PLAN/SET_CARD_HOLDER_NAME',
  holderName => holderName
);

export const setCountry = createAction(
  'PLAN/SET_COUNTRY',
  country => country
);

export const setZipCode = createAction(
  'PLAN/SET_ZIP_CODE',
  zipCode => zipCode
);

export const setMode = createAction(
  'PLAN/SET_MODE',
  mode => mode
);

export const setStripe = createAction(
  'PLAN/SET_STRIPE',
  stripe => stripe
);

export const setIsSubscriptionPlan = createAction(
  'PLAN/SET_IS_SUBSCRIPTION_PLAN',
  isSubscriptionPlan => isSubscriptionPlan
);

export const setSelectedPaymentOption = createAction(
  'PLAN/SET_SELECTED_PAYMENT_OPTION',
  paymentOption => paymentOption
);

export const setDisabledOptions = createAction(
  'PLAN/SET_DISABLE_OPTION',
  disableOption => disableOption
);

export const setPromotionalCode = createAction(
  'PLAN/SET_PROMO_CODE',
  code => code
);
