import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import FormGroup from '../../../../shared/basic/FormGroup';
import TitlePage from '../../../../shared/basic/TitlePage';
import withLayout from '../../../../shared/layout/hocs/withLayout';
import DefaultButton from '../../../../shared/basic/DefaultButton';
import { Keywords, GlobalStyles } from '../audioForm/styles';
// import { helperText } from '../../constants';
import { DialogContainer } from '../dialog';
import ContentLoader from '../../../../shared/basic/ContentLoader';
import BoxMessage from '../../../../shared/basic/BoxMessage';
// import TagView from '../../../../shared/basic/TagView';
import { PlaylistRecord } from '../../../../redux/records/playlist-record';
import { PermissionDialogContainer } from '../permissionDialog';
import {
  Container,
  Section,
} from '../../styles';
import {
  Input,
  Content,
  Back,
  TextArea,
  DivFlex,
  SubTitle,
  TrashIcon,
  Table,
  NewContentContainer,
  DivBlock,
  DivBack,
  PlusIcon,
  Button,
  DivTitle,
} from './styles';
import PrivacyPermissions from './Session/PrivacyPermissions';
import PlaylistDetails from './Session/PlaylistDetails';
import withWindowResize from '../../../../shared/hocs/withWindowResize';
import { MEDIUM } from '../../../../shared/constants/breakpoint-constants';

const Tr = ({children}) => (<tr>{children}</tr>);

const PlaylistDetailForm = props => {
  const {
    formSubmitSuccess,
    isLoadingPlaylist,
    playlistFormError,
    playlist,
    match,
    history,
    fetchPlaylistItem,
    setPlaylist,
    updatePlaylistItem,
    fetchPlaylistAudios,
    isLoadingPlaylistPermissions,
    playlistPermissions,
    fetchPlaylistPermissions,
    setDeleteOpts,
    width,
  } = props;
  const { title, description, sharing } = playlist;
  const [showDeletePermission, setShowDeletePermission] = useState(false);
  const [showAddPermission, setShowAddPermission] = useState(false);
  const [isPrivate, setIsPrivate] = useState(sharing < 2);
  // const [selected] = useState(["ACA", "speech"]);
  const isReadOnly = /\/(view)\//g.test(match.path);

  useEffect(() => { setIsPrivate(sharing < 2) }, [sharing]);

  useEffect(() => {
    if (formSubmitSuccess) {
      history.push('/my-library');
    }
  }, [formSubmitSuccess]);

  useEffect(() => {
    async function fetchPlaylist() {
      const { params } = match;
      await fetchPlaylistItem(params.id);
    }
    async function fetchAudios() {
      const { params } = match;
      await fetchPlaylistAudios(params.id);
    }
    async function fetchPermissions() {
      const { params } = match;
      await fetchPlaylistPermissions(params.id);
    }

    fetchPlaylist();
    fetchAudios();
    fetchPermissions();
  }, [fetchPlaylistItem, fetchPlaylistAudios, fetchPlaylistPermissions]);

  function changeValue(field, value) {
    setPlaylist(playlist.set(field, value));
  }

  const onChangeIsPrivate = () => {
    if (isReadOnly) return;
    const newValue = !isPrivate;
    setIsPrivate(newValue);
    setPlaylist(playlist.set('sharing', newValue ? 1 : 2));
  }

  // function onChangeTags(tagsChanged, allTags, action) {
  //   console.log('onChangeTags', tagsChanged, allTags, action);
  // }

  async function handlerSubmit() {
    await updatePlaylistItem();
  }

  function deletePermissionHandle(event) {
    setDeleteOpts({
      key: event.target.dataset.key,
      label: event.target.dataset.label,
      type: 'playlist-permission',
    });

    setShowDeletePermission(true);
  }

  return isLoadingPlaylist ? <ContentLoader /> : (
    <Container>
      <GlobalStyles />
      <Section>
        <TitlePage iconCode="f07c">MY LIBRARY</TitlePage>
        <NewContentContainer>
          <DivBlock>
            <DivBack>
              <Link to="/my-library" className="back">
                <Back unicode="f053" /> BACK TO PLAYLISTS
              </Link>
            </DivBack>
            {playlistFormError && <BoxMessage message={playlistFormError} />}
            <DivFlex>
              <Content>
                <DivBlock>
                  <SubTitle><DivTitle>PLAYLIST INFORMATION</DivTitle></SubTitle>
                  <FormGroup title="Full Title">
                    <Input
                      onChange={event => changeValue('title', event.target.value)}
                      defaultValue={title}
                      readOnly={isReadOnly}
                    />
                  </FormGroup>
                  <FormGroup title="Description">
                    <TextArea
                      rows="2"
                      defaultValue={description}
                      onChange={event => changeValue('description', event.target.value)}
                      readOnly={isReadOnly}
                    />
                  </FormGroup>
                  {/* <FormGroup title="Keywords" helper={helperText.keywords}>
                    {isReadOnly ? <TagView elements={selected} /> : (
                      <Keywords
                        tags={tags}
                        delimiters={[13, ',']}
                        onChange={onChangeTags}
                      />
                    )}
                  </FormGroup> */}
                </DivBlock>
                <DivFlex>
                  {!isReadOnly && (
                    <>
                      <DefaultButton onClick={handlerSubmit}>SAVE CHANGES</DefaultButton>
                      <Link to="/my-library">CANCEL</Link>
                    </>                  
                  )}
                </DivFlex>
              </Content>
              <Content>
                {!isReadOnly && (
                  <SubTitle>
                    <DivTitle>
                      PLAYLIST PERMISSIONS
                    </DivTitle>
                    {isPrivate && (
                      <Button click={() => setShowAddPermission(true)}>
                        <PlusIcon unicode="f067" />
                        {!width.lessThan(MEDIUM) && <>ADD PERMISSION</>}
                      </Button>
                    )}
                  </SubTitle>
                )}
                <PrivacyPermissions onChange={onChangeIsPrivate} isPrivate={isPrivate}/>
                {isPrivate && (
                  isLoadingPlaylistPermissions ? <ContentLoader /> : (
                    <Table width="100%">
                      <thead>
                        <tr>
                          <th>Group</th>
                          <th>Members</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {playlistPermissions.map(permission => (
                          <Tr key={permission.id}>
                            <td>{permission.title}</td>
                            <td>{permission.memberCount}</td>
                            <td>
                              {!isReadOnly && (
                                <TrashIcon
                                  key={`trash-${permission.id}`}
                                  data-key={permission.id}
                                  data-label={permission.title}
                                  unicode="f2ed"
                                  onClick={deletePermissionHandle}
                                />
                              )}
                            </td>
                          </Tr>
                        ))}
                      </tbody>
                    </Table>
                  )
                )}
              </Content>
            </DivFlex>
            <PlaylistDetails isReadOnly={isReadOnly} />            
          </DivBlock>
        </NewContentContainer>
      </Section>
      <DialogContainer showModal={showDeletePermission} closeModal={() => setShowDeletePermission(false)} label="group" />
      <PermissionDialogContainer showModal={showAddPermission} closeModal={() => setShowAddPermission(false)} />
    </Container>
  );
};

PlaylistDetailForm.propTypes = {
  formSubmitSuccess: PropTypes.bool.isRequired,
  isLoadingPlaylist: PropTypes.bool.isRequired,
  isLoadingPlaylistPermissions: PropTypes.bool.isRequired,
  playlistFormError: PropTypes.string.isRequired,
  playlist: PropTypes.instanceOf(PlaylistRecord).isRequired,
  playlistPermissions: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  fetchPlaylistItem: PropTypes.func.isRequired,
  setPlaylist: PropTypes.func.isRequired,
  updatePlaylistItem: PropTypes.func.isRequired,
  fetchPlaylistAudios: PropTypes.func.isRequired,
  fetchPlaylistPermissions: PropTypes.func.isRequired,
  setDeleteOpts: PropTypes.func.isRequired,
  width: PropTypes.object.isRequired,
};

PlaylistDetailForm.defaultProps = {
  history: {},
}

export default withLayout(withWindowResize(PlaylistDetailForm));
