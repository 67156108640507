import { Record } from 'immutable';

export const PlanHeaderRecord = Record({
  name: '',
  price: '',
  priceCurrency: '',
  description: '',
  button: {
    description: '',
    url: '',
    css: '',
  },
});
