import styled from 'styled-components';
import {
  black,
  lightGreyBlue,
  white
} from '../../../../styles/variables';

export const Widget = styled.div`
  width: 100%;
  border-radius: 5px;
  border: ${
    props => props.dashed ? `dashed 2px ${lightGreyBlue}` : props.border ? `solid 2px ${lightGreyBlue}` : 'unset'
  };
  box-shadow: ${
    props => props.shadow ? '0 0 4px 0 rgba(0, 0, 0, 0.1)' : 'unset'
  };
  background-color: ${white};

  &:not(:first-child) {
    margin-top: 2rem;
  }
`;

export const Title = styled.div`
  margin: 3rem 0 0 0;
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.34px;
  color: ${black};
`;

export const Footer = styled.div`
  display: flex;
  margin-top: 1.7rem;
  font-family: Work Sans;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.29px;
  text-align: center;
  color: ${black};
  justify-content: center;

  & div {
    margin-right: .6rem;
  }
`;