import styled, { createGlobalStyle } from 'styled-components';
import { black, lightBlueGrey, melon, white } from '../../../../styles/variables';
import Icon from '../../../../shared/basic/IconFontAnswer';
import TagEditor from "react-tageditor";

export const GlobalStyles = createGlobalStyle`
  .tag input, .tag-editor, .tag-editor-placeholder, .tag-editor-mirror {
    font-size: 1.1rem;
    font-family: Work Sans;
  }

  .tag input, .tag-editor-placeholder {
    padding: .5rem 2rem .5rem 1rem;
    line-height: 1.8;
  }

  .tag {
    display: inline-block;
    margin-right: .5rem;
    position: relative;

    & input {
      margin: 0;
      border: 0;
      box-sizing: content-box;
      color: ${white};
      background-color: ${melon};
      min-width: 1em;
      border-radius: 4px;

      &:focus {
        outline: 0;
      }

      &:focus + a {
        display: none;
      }
    }

    a {
      position: absolute;
      top: 50%;
      right: 0.1em;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      cursor: pointer;
      color: ${white};
      line-height: 1;
      font-size: 1.8rem;
      font-weight: 600;
      width: 1em;
      height: 1em;
      text-align: center;

      &:hover {
        color: ${lightBlueGrey};
      }
    }

    &.has-error input {
      background-color: #f2c9c9;
    }

    &.has-error input, &.has-error a {
      transition: color 1s;
      color: #5c0909;
    }
  }

  .tag-editor {
    padding: .8rem .7rem;
    border-radius: .5rem;
    border: solid 1px ${lightBlueGrey};
    background-color: ${white};
    cursor: text;

    &.is-active {
      border-color: ${lightBlueGrey};
    }
  }

  .tag-editor-placeholder {
    color: #777;
    margin-bottom: .5em;
  }

  .tag-editor-mirror {
    position: absolute;
    top: -9999px;
    left: -9999px;
    width: auto;
    visibility: hidden;
  }
`;

export const Keywords = styled(TagEditor)``;

export const DivFlex = styled.div`
  display: flex;

  a {
    margin-left: 2rem;
    padding: 13px 2rem 12px 2rem;
    text-decoration: none;
    font-family: Work Sans;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.32px;
    text-align: center;
    color: ${melon};
  }
`;

export const Input = styled.input.attrs(props => ({
  type: props?.type ? props.type : 'text',
}))`
  display: block;
  font-size: 1.3rem;
  color: ${black};
  padding: 1.1rem 1.6rem;
  border-radius: .5rem;
  border: solid 0.1rem #caccd8;
  background-color: ${white};
`;

export const TextArea = styled.textarea`
  padding: 9px 192px 43px 10px;
  border-radius: 5px;
  border: solid 1px ${lightBlueGrey};
  background-color: ${white};
  resize: none;
`;

export const Select = styled.select`
  padding: 11px 13px 10px 11px;
  border-radius: 5px;
  border: solid 1px ${lightBlueGrey};
  background-color: #ffffff;
`;

export const Content = styled.div`
  max-width: 60rem;
  font-family: Work Sans;

  & .back {
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.32px;
    text-align: right;
    color: ${melon};
    display: flex;
    text-decoration: none;
    margin: auto 0 3rem auto;
  }
`;

export const Back = styled(Icon)`
  font-size: 13px;
  font-weight: 600;
  margin-right: 1rem;

  :after {
    color: ${melon};
  }
`;