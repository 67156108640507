import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../dashboard/components/upload/components/modal';
import {
  Close,
  Header,
  Content,
  Footer,
  Input,
} from './styles';
import DefaultButton from '../../../../shared/basic/DefaultButton';
import FormGroup from '../../../../shared/basic/FormGroup';
import ContentLoader from '../../../../shared/basic/ContentLoader';
import BoxMessage from '../../../../shared/basic/BoxMessage';
import { useDispatch } from 'react-redux';

const InvitationDialog = props => {
  const {
    showModal,
    isSendindInvitation,
    modalSubmitSuccess,
    invitationError,
    sendNewInvitation,
    invitationFail,
    closeModal,
  } = props;
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [givenName, setGivenName] = useState('');
  const [familyName, setFamilyName] = useState('');

  useEffect(() => {
    if (modalSubmitSuccess) {
      setEmail('');
      setGivenName('');
      setFamilyName('');
      closeModal();
    }
  }, [modalSubmitSuccess]);

  async function handlerSubmit() {
    if (!givenName || !email || !familyName) {
      dispatch(invitationFail({message: 'All fields are required'}));
      return;
    }

    const regExp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/g;
    if (!regExp.test(email)) {
      dispatch(invitationFail({message: 'Use a valid email'}));
      return;
    }

    await sendNewInvitation({
      givenName,
      familyName,
      email,
    });
  }

  return (
    <Modal showModal={showModal}>
      <Header>
        ADD PERMISSION
        <Close unicode="f00d" onClick={closeModal} />
      </Header>
      <Content>
        {isSendindInvitation ? <ContentLoader /> : (
          <>
            {invitationError && <BoxMessage message={invitationError} />}
            <FormGroup title="Given name">
              <Input defaultValue={givenName} onChange={event => setGivenName(event.target.value)} />
            </FormGroup>
            <FormGroup title="Family name">
              <Input defaultValue={familyName} onChange={event => setFamilyName(event.target.value)} />
            </FormGroup>
            <FormGroup title="Email">
              <Input type="email" defaultValue={email} onChange={event => setEmail(event.target.value)} />
            </FormGroup>
          </>
        )}
      </Content>
      {!isSendindInvitation && (
        <Footer>
          <DefaultButton click={() => handlerSubmit()}>SEND INVITATION</DefaultButton>
          <DefaultButton onClick={closeModal} inverted>CANCEL</DefaultButton>
        </Footer>
      )}
    </Modal>
  );
};

InvitationDialog.propTypes = {
  showModal: PropTypes.bool.isRequired,
  isSendindInvitation: PropTypes.bool.isRequired,
  modalSubmitSuccess: PropTypes.bool.isRequired,
  invitationError: PropTypes.string.isRequired,
  sendNewInvitation: PropTypes.func.isRequired,
  invitationFail: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default InvitationDialog;
