import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Loading from './Loading';
import Error from './Error';
import Empty from './Empty';

const Status = ({ isLoading, errorMessage, isEmpty, ...props }) => {
  if (isLoading) return <Loading {...props} />;
  else if (errorMessage) return <Error {...props} />;
  else if (isEmpty) return <Empty {...props} />;
  return <></>;
};

Status.propTypes = {
  component: PropTypes.any,
  isLoading: PropTypes.bool,
  isEmpty: PropTypes.bool,
  errorMessage: PropTypes.string,
};

Status.defaultProps = {
  component: <Fragment />,
  isLoading: false,
  isEmpty: false,
  errorMessage: '',
};


export default Status;