import { createAction } from 'redux-actions';
import httpClient from '../../shared/helper/request';

export const countriesRequestData = createAction('PERSONALIZE/COUNTRIES_REQUEST_DATA');

export const countriesReceiveData = createAction(
  'PERSONALIZE/COUNTRIES_RECEIVE_DATA',
  countries => countries
);

export const countriesFailLoadData = createAction(
  'PERSONALIZE/COUNTRIES_FAIL_LOAD_DATA',
  error => error
);

export const fetchCountries = () => async dispatch => {
  dispatch(countriesRequestData());
  try {
    const { data: countries } = await httpClient.get('/Authority/countries');
    dispatch(countriesReceiveData(countries));
  } catch (error) {
    dispatch(countriesFailLoadData(error));
  }
};
