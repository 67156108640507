import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Constants } from '../../constants';
import { signUp } from '../../../helper/sign';
import {
  CenterBox,
  Title,
  Label,
  Input,
  Button,
  Signup,
  ExtraData,
  Error,
  Div,
} from './styles';

const Form = ({ isLogin, children }) => {
  const data = isLogin ? Constants.form.login : Constants.form.signup;
  const [loading, setLoading] = useState(false);
  const [givenName, setGivenName] = useState('');
  const [familyName, setFamilyName] = useState('');  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');


  async function createUser() {
    if(!isLogin && (!isValidEmail() || !isValidPassword())){
      return;
    }
    signUp({
      isLogin,
      givenName,
      familyName,
      email,
      password,
      callback: value => setLoading(value),
      errorCallback: value => setError(value),
    });
  };

  const isValidEmail = () =>{
    const reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,253}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,253}[a-zA-Z0-9])?)*$/;
        if(!email || reg.test(email) === false){
            setEmailError("Email is Invalid");
            return false;
        }else {
          setEmailError('');
          return true;
        }
        
  }

  const isValidPassword = () =>{
    const reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        if(!password || reg.test(password) === false){
          setPasswordError("Password is Invalid, should have atleast 1 number, special character, lowercase and uppercase letter");
            return false;
        }else {
          setPasswordError('');
          return true;
        }
  }
  

  const onKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      createUser();
    }
  }

  return (
    <CenterBox>
      {children}
      <div className="align-items-center justify-content-center">
        <Title>{data.formTitle}</Title>
      </div>
      {error ? <Error>{error}</Error> : null}
      <div className="align-items-center justify-content-center">
      {data.extraData && (
        <>
        <Div>
              <Label>First Name</Label>
              <Input 
                id="inpGivenName"
                data-cy="auth-email"
                type="text"
                placeholder="Enter First Name"
                name="givenName"
                value={givenName}
                onChange={e => setGivenName(e.target.value)}
                onKeyDown={e => onKeyDown(e)}
              />
            </Div>
          <Div>
              <Label>Last Name</Label>
              <Input 
                id="inpFamilyName"
                data-cy="auth-email"
                type="text"
                placeholder="Enter Last Name"
                name="familyName"
                value={familyName}
                onChange={e => setFamilyName(e.target.value)}
                onKeyDown={e => onKeyDown(e)}
              />
            </Div>
          </>
          )}
          <Div>
            <Label>Email *</Label>
            <Input 
              id="inpEmail"
              data-cy="auth-email"
              type="text"
              placeholder="Enter Email"
              name="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              onKeyDown={e => onKeyDown(e)}
            />
            <div className="text-danger">{emailError}</div>
          </Div>
          <Div>
            <Label>Password *</Label>
            <Input
              id="inpPassword"
              data-cy="auth-password"
              type="password"
              placeholder="Enter Password"
              name="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              onKeyDown={e => onKeyDown(e)}
            />
            <div className="text-danger">{passwordError}</div>
          </Div>
          <div className="d-flex justify-content-center align-items-center">
            <Button
              type="submit"
              data-cy="auth-submit"
              onClick={e => createUser(e)}
              disabled={loading}
              autoFocus
            >
              {loading ? 'Loading ...' : data.buttonText}
            </Button>
          </div>
          <Signup className="d-flex justify-content-center align-items-center">
            {data.question}&nbsp;<a href={data.questionLink}>{data.link}</a>
          </Signup>
          {data.extraData && (
            <ExtraData>
              By signing up, you agree to Soar&apos;s{' '}
              <a href="https://get.soar.com/terms" target="_blank" rel="noopener noreferrer">Terms of Use</a>{' '}
              and acknowledge you have read the{' '}
              <a href="https://get.soar.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
            </ExtraData>
          )}
      </div>
    </CenterBox>
  );
};

Form.propTypes = {
  isLogin: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
};

export default Form;
