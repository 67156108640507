import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  background-color: ${props => props.backgroundColor};
  padding: 1.9rem 2.0rem;
  display: grid;
  grid-template-columns: max-content repeat(3, auto) repeat(4, max-content);
  grid-template-areas: 
    "title title title title time links links links";
  column-gap: 3.8rem;
  align-items: center;
  border-radius: 5px;
  border: solid .1rem #ecedf3;
  margin-bottom: -.1rem;
  div { cursor: pointer; }

  ${media.lessThan("large")`
    padding: 1.2rem 1.0rem;
    column-gap: 3.088rem;
    grid-template-areas: 
      "title title title title links links links links"
      "time time time . links links links links";
  `}
  ${media.lessThan("medium")`    
    column-gap: 2.027rem;
    grid-template-areas: 
      "title title title title links links links links"
      "time time time . links links links links";
  `}
  ${media.lessThan("small")`
    column-gap: 1.188rem;
    grid-template-areas: 
      "title title title title title links links links"
      "time time time time links links links links";
  `}
`;

export const TitleWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(2, max-content);
  column-gap: 1.3rem;
  justify-self: start;
  grid-area: ${props => props.gridArea || ''};
`;

export const LinksDesktop = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(4, max-content);
  column-gap: .5rem;
  justify-self: end;
  grid-area: ${props => props.gridArea || ''};

  ${media.lessThan("medium")`display: none;`}
`;

export const LinksMobile = styled.div`
  display: inline-grid;
  justify-self: end;
  grid-area: ${props => props.gridArea || ''};
  display: none;

  ${media.lessThan("medium")`display: unset;`}
`;
