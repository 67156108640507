import { connect } from 'react-redux';
import {
  setActiveTab,
  fetchAudios,
  fetchAudiosSharedWithMe,
  fetchPlaylist,
  fetchGroups,
  setDeleteOpts,
  setSelectedAudio,
  closeModal,
} from './actions/my-library-actions';
import MyLibrary from './';

const mapStateToProps = state => ({
  activeTab: state.library.activeTab,
  isLoadingAudio: state.library.isLoadingAudio,
  isLoadingAudioSharedWithMe: state.library.isLoadingAudioSharedWithMe,
  
  audioErrorMessage: state.library.audioErrorMessage,
  audioSharedWithMeErrorMessage: state.library.audioSharedWithMeErrorMessage,

  audios: state.library.audios,
  audiosSharedWithMe: state.library.audiosSharedWithMe,

  isLoadingPlaylist: state.library.isLoadingPlaylist,
  playlistErrorMessage: state.library.playlistErrorMessage,
  playlists: state.library.playlists,

  isLoadingGroup: state.library.isLoadingGroup,
  groupErrorMessage: state.library.groupErrorMessage,
  groups: state.library.groups,

  authorizedUser: state.authorizedUser,
  isSATeamSub: state.authorizedUser.isSATeamSub,
});

const mapDispatchToProps = {
  setActiveTab: index => setActiveTab(index),
  fetchAudios: () => fetchAudios(),
  fetchAudiosSharedWithMe: () => fetchAudiosSharedWithMe(),
  fetchPlaylist: () => fetchPlaylist(),
  fetchGroups: () => fetchGroups(),
  setDeleteOpts: opts => setDeleteOpts(opts),
  setSelectedAudio: audioId => setSelectedAudio(audioId),
  closeModal: () => closeModal(),
};

export default connect(mapStateToProps, mapDispatchToProps)(MyLibrary);