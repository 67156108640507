import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, LinksDesktop, LinksMobile, GapDetails } from './styles';
import ButtonAlexa from './Buttons/Alexa';
import ButtonGoogle from './Buttons/Google';
import ButtonPlay from './Buttons/Play';
import ButtonPlayMobile from './Buttons/PlayMobile';
import AudioTitle from './Labels/Title';
import AudioSpeaker from './Labels/Speaker';
import AudioDate from './Labels/Date';
import AudioTime from './Labels/Time';
import Tooltip from '../../../../../../shared/basic/Tooltip';

const AudioListItem = props => {
  const {
    title,
    dateCreated,
    audioSeconds,
    id,
    backgroundColor,
    isLoading,
    speaker,
    globalVoicePhrase,
  } = props;

  return (
    <Wrapper backgroundColor={backgroundColor}>

      <AudioTitle title={title} />

      <GapDetails>
        <AudioSpeaker speaker={speaker} />
        <AudioDate dateCreated={dateCreated} />
        <AudioTime audioSeconds={audioSeconds} />
      </GapDetails>

      <LinksDesktop>
        <Tooltip effect='solid' /> 
        <ButtonAlexa id={!!Number(audioSeconds) && id} isLoading={isLoading} />
        <ButtonGoogle id={!!Number(audioSeconds) && id} isLoading={isLoading} />
        <ButtonPlay id={!!Number(audioSeconds) && globalVoicePhrase} isLoading={isLoading} />
      </LinksDesktop>
      
      <LinksMobile>
        <ButtonPlayMobile
          id={!!Number(audioSeconds) && id}
          isLoading={isLoading}
          globalVoicePhrase={globalVoicePhrase}
        />
      </LinksMobile>

    </Wrapper>
  );
};

AudioListItem.propTypes = {
  isLoading: PropTypes.bool,
  globalVoicePhrase: PropTypes.string,
  title: PropTypes.string,
  dateCreated: PropTypes.string,
  audioSeconds: PropTypes.string,
  backgroundColor: PropTypes.string,
  speaker: PropTypes.string,
  id: PropTypes.any,
  children: PropTypes.any,
};

AudioListItem.defaultProps = {
  id: null,
  globalVoicePhrase: '',
  children: '',
  title: '',
  dateCreated: '',
  audioSeconds: '',
  speaker: '',
  backgroundColor: '#FFFFFF',
  isLoading: false,
};

export default AudioListItem;
