import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Container from '../../../../../shared/layout/Container';
import Section from '../../../../../shared/layout/Section';
import AudioPlayer from './AudioPlayer';
import VideoPlayer from "./VideoPlayer"

import { Wrapper, Highlighter, Title } from './styles';
import AudioRecord from '../../../redux/records/audio-record';

function CheckVideoType(contentType, sourceUrl){

  return (['zoomrecording', 'video'].includes(contentType.toLowerCase()) ||
      ('url'.includes(contentType.toLowerCase()) &&
          sourceUrl.isNotEmpty &&
          sourceUrl.includes('youtu')));
}

function urlBuilder(alexaAudioUrl) {
  return alexaAudioUrl.substring(0,alexaAudioUrl.lastIndexOf("/")) + '/orig.mp4';
}

const PlaylistDetailPlayer = ({ audio }) => {

  const { id, voicePhrase, alexaAudioUrl, contentType, sourceUrl, ext } = audio;
  const isVideo = CheckVideoType(contentType, sourceUrl);
  const url = isVideo ? urlBuilder(alexaAudioUrl) : alexaAudioUrl;

  const style = { paddingTop: '0', paddingBottom: '0' };

  return id && (
    <Section marginBottom="1rem" backgroundColor="white">
      <Container {...style}>
        <Wrapper>
          {/* <Highlighter>YOU ARE LISTENING TO:</Highlighter> */}
          {/* <Title>{voicePhrase || '...'}</Title> */}
          {isVideo ? <VideoPlayer url={url} /> : <AudioPlayer url={url} />}
           {/* <AudioPlayer url={alexaAudioUrl} /> */}
        </Wrapper>
      </Container>
    </Section>
  )
};

PlaylistDetailPlayer.propTypes = {
  audio: PropTypes.instanceOf(AudioRecord).isRequired,
};

export default PlaylistDetailPlayer;