import { handleActions } from 'redux-actions';
import { Record } from 'immutable';
import {
  countriesRequestData,
  countriesReceiveData,
  countriesFailLoadData,
} from '../actions/general-actions';
import { setIsLoading, unsetIsLoading } from '../actions/page-actions';

const GeneralRecord = Record({
  countries: [],
  isLoading: false,
  errorMessage: '',
  successMessage: '',
  isLoadingPage: true,
});

export const initialState = new GeneralRecord();

export default handleActions(
  {
    [countriesRequestData]: state => state.set('isLoading', true).delete('errorMessage'),
    [countriesReceiveData]: (state, action) =>
      state
        .set('countries', action.payload)
        .set('isLoading', false)
        .delete('errorMessage'),
    [countriesFailLoadData]: (state, action) =>
      state.set('errorMessage', action.payload.message).set('isLoading', false),
    [setIsLoading]: state => state.set('isLoadingPage', true),
    [unsetIsLoading]: state => state.set('isLoadingPage', false),
  },
  initialState
);
