import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Item from './Item';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from '../../../../../shared/typography/PageTitle';
import PageSubtitle from '../../../../../shared/typography/PageSubtitle';
import ButtonLoadMore from '../../../shared/components/LoadMore'

import { Wrapper, WrapperItems } from './styles';
import { statusManager } from '../../../shared/helpers/statusManager';
import { fetchAudioList } from '../../../redux/actions/library-actions';
import withLayout from '../../../../../shared/layout/hocs/withLayout';
import withWindowResize from '../../../../../shared/hocs/withWindowResize';
import { SMALL } from '../../../../../shared/constants/breakpoint-constants';
import JoinSoar from '../../../shared/components/JoinSoar';
import Container from '../../../../../shared/layout/Container';
import Section from '../../../../../shared/layout/Section';
import { setAudiosPageNum } from '../../../redux/actions/audio-list-actions';

const AudiosList = ({ width }) => {
  const dispatch = useDispatch();
  const style = width.lessThan(SMALL)
    ? { paddingTop: '1.7rem', paddingBottom: '0' }
    : { paddingBottom: '0' };
  const {
    pageNum, items, isLoadingLoadMore, totalCount
  } = useSelector(state => state.publicLibrary.listAudios);
  const isLoading = useSelector(state => state.publicLibrary.isLoading);
  const errorMessage = useSelector(state => state.publicLibrary.errorMessage);

  useEffect(() => {
    dispatch(fetchAudioList());
  }, [dispatch]);

  const handleLoadMoreClick = () => {
    dispatch(setAudiosPageNum(pageNum+1));
    dispatch(fetchAudioList());
  }

  const status = statusManager({ component: Item, isLoading, errorMessage, isList: true });

  return (
    <Section>
      <Container {...style}>
        <Wrapper>
          <PageTitle title="Browse all Audio" />
          <PageSubtitle>
            Browse among hundreds of curated audios, featuring the best audio content you can find online:
          </PageSubtitle>
          <WrapperItems>
          {status || items.map(item => (
            <Item
              key={item.id}
              title={item.title}
              dateCreated={item.dateCreated}
              audioSeconds={String(item.audioSeconds)}
              speaker={item.source}
              id={item.id}
              globalVoicePhrase={item.globalVoicePhrase}
            />))
          }
          </WrapperItems>
          {items.length < totalCount &&
            <ButtonLoadMore text="Load more audios" isLoading={isLoadingLoadMore} onClick={handleLoadMoreClick}/>
          }
        </Wrapper>
        <JoinSoar />
      </Container>
    </Section>
  )
};

AudiosList.propTypes = {
  width: PropTypes.object.isRequired,
};

export default withLayout(withWindowResize(AudiosList));