import { combineReducers } from 'redux';

import authorizedUser from './authorized-user-reducer';
import generalInfo from './general-reducer';
import accountSettings from '../../components/premium/reducers/premium-reducer';
import publicLibrary from '../../components/PublicLibrary/redux/reducers/library-reducer';
import publicAudio from '../../components/PublicLibrary/redux/reducers/audio-reducer';
import dashboard from '../../components/dashboard/reducers/dashboard-reducer';
import library from '../../components/MyLibrary/reducers/my-library-reducer';
import pricePlans from '../../components/Pricing/Redux/reducers/price-plans-reducer';

export default combineReducers({
  authorizedUser,
  accountSettings,
  generalInfo,
  dashboard,
  publicLibrary,
  publicAudio,
  library,
  pricePlans,
});
