import { handleActions } from 'redux-actions';
import {
  setPlansHeader,
  setPlansAccess,
  setPeriod,
  setPlan,
  setPlanObj
} from '../actions/price-plans-actions';
import {
  setErrorMessageSubscription,
  setSuccessMessageSubscription,
  setIsLoadingSubscription,

  setFieldEmail,
  setFieldEmailIsNewUser,
  setFieldEmailWasVerified,
  setFieldEmailIsValid,

  setFieldPassword,
  setFieldPasswordWasVerified,
  setFieldPasswordIsValid,

  setFieldCardIsValid
} from '../actions/subscription-actions';
import { PricePlansRecord } from '../records/price-plans-record';

export const initialState = new PricePlansRecord();

export default handleActions(
  {
    [setPlan]: (state, action) => state.set('plan', action.payload),
    [setPlanObj]: (state, action) => state.set('planObj', action.payload),
    [setPeriod]: (state, action) => state.set('period', action.payload),
    [setPlansAccess]: (state, action) => state.set('plansAccess', action.payload),
    [setPlansHeader]: (state, action) => state.set('plansHeader', action.payload),

    [setFieldEmail]: (state, action) => state.set('fieldEmail', action.payload),
    [setFieldEmailWasVerified]: (state, action) => state.set('fieldEmailWasVerified', action.payload),
    [setFieldEmailIsValid]: (state, action) => state.set('fieldEmailIsValid', action.payload),

    [setFieldPassword]: (state, action) => state.set('fieldPassword', action.payload),
    [setFieldPasswordWasVerified]: (state, action) => state.set('fieldPasswordWasVerified', action.payload),
    [setFieldPasswordIsValid]: (state, action) => state.set('fieldPasswordIsValid', action.payload),

    [setFieldCardIsValid]: (state, action) => state.set('fieldCardIsValid', action.payload),
    [setIsLoadingSubscription]: (state, action) => state.set('isLoadingSubscription', action.payload),
    [setErrorMessageSubscription]: (state, action) => state.set('errorMessageSubscription', action.payload),
    [setSuccessMessageSubscription]: (state, action) => state.set('successMessageSubscription', action.payload),
  },
  initialState
);
