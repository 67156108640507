import React from 'react';
import PropTypes from 'prop-types';
import Player from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import './styles.css';

const AudioPlayer = ({ url }) => {
  return (
    <Player
      src={url}
      autoPlay
      layout="horizontal-reverse"
      customAdditionalControls={[]}
      customVolumeControls={[]}
    />
  )
};

AudioPlayer.propTypes = {
  url: PropTypes.string,
};

AudioPlayer.defaultProps = {
  url: '',
};

export default AudioPlayer;