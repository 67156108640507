import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Container from '../../../../../../shared/layout/Container';
import Section from '../../../../../../shared/layout/Section';
import { statusManager } from '../../../../shared/helpers/statusManager';
import AudioItem from './Item';
import { Wrapper, WrapperTitle, WrapperItems } from './styles';
import ContentLoader from '../../../../../../shared/basic/ContentLoader';

const PlaylistList = props => {
  const {
    audioLoaded,
    isLoading,
    playlistList,
    fetchPlaylistByAudio,
  } = props;
  const style = { paddingTop: '0', paddingBottom: '0' };
  const isEmpty = !playlistList.length;
  const status = statusManager({
    component: AudioItem,
    isLoading,
    errorMessage: '',
    isEmpty,
    isList: true
  });

  useEffect(() => {
    async function fetchPlaylist() {
      await fetchPlaylistByAudio();
    }
    fetchPlaylist();
  }, [fetchPlaylistByAudio]);

  return audioLoaded ? <ContentLoader /> : (
    <Section>
      <Container {...style}>
        <Wrapper>
          <WrapperTitle>FEATURED IN {playlistList.length} PLAYLISTS</WrapperTitle>
          <WrapperItems>
            {status || playlistList.map(item => (
              <AudioItem
                key={item.id}
                id={item.id}
                title={item.title}
                date={String(item.dateCreated)}
                audioCount={item.audioCount}
              />
            ))
            }
          </WrapperItems>
        </Wrapper>
      </Container>
    </Section>
  );
};

PlaylistList.propTypes = {
  audioLoaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  playlistList: PropTypes.array.isRequired,
  fetchPlaylistByAudio: PropTypes.func.isRequired,
};

export default PlaylistList;