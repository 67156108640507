import styled from 'styled-components';
import {
  black,
  kellyGreen,
  lightBlueGrey,
  melon,
  slateGrey,
  white
} from '../../../../../../styles/variables';
import { uploadImages } from '../../../../../../assets';
import { pixelRatio } from '../../../../../../shared/helper/screen';
import Icon from '../../../../../../shared/basic/IconFontAnswer';

export const Image = styled.img.attrs(() => {
  const ration = pixelRatio();

  let src = uploadImages.illustration;
  if (ration == '@2x') {
    src = uploadImages.illustration2x;
  } else if (ration == '@3x') {
    src = uploadImages.illustration3x;
  }

  return ({
    src,
  });
})`
  max-width: 33.8rem;
`;

export const Div = styled.div`
  margin: 1.8rem 0;
  width: 100%;
  text-align: center;
`;

export const Gear = styled(Icon)`
  :after {
    color: ${white};
  }
`;

export const Close = styled(Icon)`
  :after {
    color: ${black};
  }
`;

export const SwitchDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(3, max-content);
  gap: 1rem;
  margin: auto 0;
  
  .switch-text {
    font-family: Work Sans;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.32px;
    color: ${slateGrey};
  }
`;

export const FormFile = styled.form`
  display: block;
`;

export const FormBody = styled.div`
  padding: 0 2rem;
`;

export const FormHeader = styled.div`
  margin: 0 0 28px;
  padding: 22px 30px;
  font-family: Work Sans;
  font-size: 1.4rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.34px;
  color: ${black};
  display: flex;
  border-bottom: solid 1px ${lightBlueGrey};

  & div {
    position: absolute;
    right: 3rem;
    margin-top: auto;
    margin-bottom: auto;

    :after {
      color: ${black};
    }
    &:hover {
      cursor: pointer;
    }
  }
`;

export const AudioInfo = styled.span`
  display: flex;
  color: ${black};
  width: auto;
  margin: 19px 0 0 1px;
  padding: 13px 16px 13px 35px;
  border-radius: 8px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
  background-color: ${white};

  & div {
    position: absolute;
  }

  & .left {
    left: 4rem;
  }
  & .right {
    right: 4rem;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

export const Helper = styled.small`
  display: block;
  margin: 10px 12px 0 1px;
  font-family: Work Sans;
  font-size: 1.2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.29px;
  color: ${slateGrey};
`;

export const Label = styled.label`
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: .5rem;
  letter-spacing: .032rem;
`;

export const Input = styled.input.attrs(props => ({
  type: props?.type ? props.type : 'text',
}))`
  display: block;
  font-size: 1.3rem;
  color: ${black};
  padding: 1.1rem 1.6rem;
  border-radius: .5rem;
  border: solid 0.1rem #caccd8;
  background-color: ${white};
`;

export const Textarea = styled.textarea`
  display: block;
  font-size: 1.3rem;
  color: ${black};
  padding: 1.1rem 1.6rem;
  border-radius: .5rem;
  border: solid 0.1rem #caccd8;
  background-color: ${white};
  resize: none;
`;

export const FormFooter = styled(FormBody)`
  display: flex;
  margin-bottom: 3rem;
`;

export const SubmitButton = styled.button`
  color: ${white};
  margin: 0 23px 0 0;
  padding: 1.3rem 1.4rem;
  border-radius: 5px;
  background-color: ${melon};
  font-family: Work Sans;
  font-size: 1.3rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.32px;
  text-align: center;
  border: unset;
  display: flex;

  &:hover {
    cursor: pointer;
  }

  div {
    margin-right: 1rem;
  }
`;

export const CancelButton = styled(SubmitButton)`
  background-color: ${white};
  color: ${melon};

  &:hover {
    cursor: pointer;
  }
`;
