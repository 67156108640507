import { handleActions } from 'redux-actions';
import { Record } from 'immutable';
import {
  setSuccessMessage,
  creditCardsRequestData,
  creditCardsReceiveData,
  creditCardsFailLoadData,
  billingAddressRequestData,
  billingAddressReceiveData,
  billingAddressFailLoadData,
  coachPlansRequestData,
  coachPlansReceiveData,
  coachPlansFailLoadData,
  savePaymentMethod,
  requestAlterPlan,
  receiveAlterPlan,
  failAlterPlan,
  setTypePlan,
  setPrice,
  setTypeSubscriptionLabel,
  requestProratePricePlan,
  receiveProratePricePlan,
  failProratePricePlan,
  setPromoCode,
  setIsLoading,
  failApplyPromoCode,
  failLocalToken,
  setPeriod,
  setCurrentyCreditCard,
  setSecurityCodeAttribute,
  setCvcElement,
  setCardNumberAttribute,
  setExpirationDateAttribute,
  setCardHolderName,
  setCountry,
  setZipCode,
  setMode,
  setStripe,
  setIsSubscriptionPlan,
  setSelectedPaymentOption,
  setDisabledOptions,
  setPromotionalCode,
} from '../actions/premium-actions';

const CheckoutRecord = Record({
  currentyCreditCard: '',
  securityCodeAttribute: false,
  cvcElement: false,
  cardNumberAttribute: '',
  expirationDateAttribute: '',
  cardHolderName: '',
  country: '',
  mode: '',
  stripe: {},
  isSubscriptionPlan: false,
  promotionalCode: '',

  selectedPaymentOption: 'existingCard',
  disabledOptions: [],

  creditCards: [],
  paymentMethod: '',
  proratedPricePlan: '',
  billingAddress: [],
  subscriptionPlan: {},
  coachPlans: {},
  price: '',
  zipCode: '',
  typeSubscriptionLabel: '',
  isLoading: false,
  isLoadingCoachPlans: false,
  isLoadingPaymentMethod: false,
  isLoadingBillingAddress: false,
  isLoadingCancelRequest: false,
  isLoadingProratedPricePlan: false,
  successMessage: '',
  errorMessage: '',
  errorMessageLocalToken: '',
  errorMessagePromoCode: '',
  downgrading: false,
  promoCode: '',
  period: 'MONTHLY',
});

export const initialState = new CheckoutRecord();

export default handleActions(
  {
    [setPeriod]: (state, action) => state.set('period', action.payload),
    [setSuccessMessage]: (state, action) => state.set('successMessage', action.payload),
    [creditCardsRequestData]: state =>
      state
        .set('isLoadingPaymentMethod', true)
        .set('isLoading', true)
        .delete('errorMessage'),
    [creditCardsReceiveData]: (state, action) =>
      state
        .set('creditCards', action.payload)
        .set('isLoadingPaymentMethod', false)
        .set('isLoading', false)
        .delete('errorMessage'),
    [creditCardsFailLoadData]: (state, action) =>
      state
        .set('errorMessage', action.payload.message)
        .set('isLoadingPaymentMethod', false)
        .set('isLoading', false),
    [billingAddressRequestData]: state =>
      state.set('isLoadingBillingAddress', true).delete('errorMessage'),
    [billingAddressReceiveData]: (state, action) =>
      state
        .set('billingAddress', action.payload.addresses)
        .set('isLoadingBillingAddress', false)
        .delete('errorMessage'),
    [billingAddressFailLoadData]: (state, action) =>
      state.set('errorMessage', action.payload.message).set('isLoadingBillingAddress', false),
    [coachPlansRequestData]: state => state.set('isLoadingCoachPlans', true).delete('errorMessage'),
    [coachPlansReceiveData]: (state, action) =>
      state
        .set('coachPlans', action.payload)
        .set('isLoadingCoachPlans', false)
        .delete('errorMessage'),
    [coachPlansFailLoadData]: (state, action) =>
      state.set('errorMessage', action.payload.message).set('isLoadingCoachPlans', false),
    [requestAlterPlan]: state => state.set('isLoading', true).delete('errorMessage'),
    [receiveAlterPlan]: state => state.set('isLoading', false).delete('errorMessage'),
    [failAlterPlan]: (state, action) =>
      state.set('errorMessage', action.payload.message).set('isLoading', false),
    [savePaymentMethod]: (state, action) =>
      state
        .set('paymentMethod', action.payload.paymentMethod)
        .set('zipCode', action.payload.zipCode),
    [setTypePlan]: (state, action) => state.set('subscriptionPlan', action.payload),
    [setPrice]: (state, action) => state.set('price', action.payload),
    [requestProratePricePlan]: state =>
      state.set('isLoadingProratedPricePlan', true).delete('errorMessage'),
    [receiveProratePricePlan]: (state, action) =>
      state
        .set('proratedPricePlan', action.payload)
        .set('isLoadingProratedPricePlan', false)
        .delete('errorMessage'),
    [failProratePricePlan]: (state, action) =>
      state.set('errorMessage', action.payload.message).set('isLoadingProratedPricePlan', false),
    [setTypeSubscriptionLabel]: (state, action) =>
      state.set('typeSubscriptionLabel', action.payload),
    [setPromoCode]: (state, action) => state.set('promoCode', action.payload),
    [setIsLoading]: (state, action) => state.set('isLoading', action.payload),
    [failApplyPromoCode]: (state, action) =>
      state.set('errorMessagePromoCode', action.payload).set('isLoading', false),
    [failLocalToken]: (state, action) =>
      state.set('errorMessageLocalToken', action.payload).set('isLoading', false),
    [setCurrentyCreditCard]: (state, action) => state.set('currentyCreditCard', action.payload),
    [setSecurityCodeAttribute]: (state, action) => state.set('securityCodeAttribute', action.payload),
    [setCvcElement]: (state, action) => state.set('cvcElement', action.payload),
    [setCardNumberAttribute]: (state, action) => state.set('cardNumberAttribute', action.payload),
    [setExpirationDateAttribute]: (state, action) => state.set('expirationDateAttribute', action.payload),
    [setCardHolderName]: (state, action) => state.set('cardHolderName', action.payload),
    [setCountry]: (state, action) => state.set('country', action.payload),
    [setZipCode]: (state, action) => state.set('zipCode', action.payload),
    [setMode]: (state, action) => state.set('mode', action.payload),
    [setStripe]: (state, action) => state.set('stripe', action.payload),
    [setIsSubscriptionPlan]: (state, action) => state.set('isSubscriptionPlan', action.payload),
    [setSelectedPaymentOption]: (state, action) => state.set('selectedPaymentOption', action.payload),
    [setDisabledOptions]: (state, action) => state.set('disabledOptions', action.payload),
    [setPromotionalCode]: (state, action) => state.set('promotionalCode', action.payload),
  },
  initialState
);
