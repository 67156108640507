import { Record } from 'immutable';

const PlaylistRecord = Record({
  id: null,
  description: '',
  title: '',
  dateCreated: '',
  ownerUserId: null,
  ownerUserObj: {},
  sharing: null,
  audioCount: null,
  sharingDesc: '',
  itemIds: [],
  items: [],
});

PlaylistRecord.parse = values => {
  return new PlaylistRecord({ ...values });
};

export default PlaylistRecord;
