import styled from 'styled-components';
import { melon, white } from '../../../styles/variables';

export const Button = styled.button`
  margin: 0 23px 0 0;
  padding: 13px 2rem 12px 2rem;
  border-radius: 5px;
  background-color: ${props => props.inverted ? white : melon};
  border: none;
  font-family: Work Sans;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.32px;
  text-align: center;
  color: ${props => props.inverted ? melon : white};
  cursor: pointer;
  text-transform: uppercase;
`;