import styled from 'styled-components';
import {
  white,
  black,
  lightBlueGrey,
  BLUE_VIOLET,
  darkGrey2
} from '../../../../styles/variables';
import { customMedia } from '../../../../styles/breakpoints';

export const CenterBox = styled.div`
  font-family: Work Sans;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${black};
  width: 90vw;

  & > img {
    margin: auto;
  }
  
  ${customMedia.greaterThan('sm')`
    width: 60vw;
  `}

  ${customMedia.greaterThan('lg')`
    margin-top: auto;
    margin-bottom: auto;
    max-width: 35rem;
  `}

  ${customMedia.greaterThan('xl')`
    margin: auto;
  `}
`;

export const Title = styled(CenterBox)`
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;

  ${customMedia.greaterThan('sm')`
    font-size: 2rem;
  `}
`;

export const Div = styled.div`
  width: 100%;
`;

export const Error = styled(CenterBox)`
  font-size: 1.5rem;
  text-align: center;
  border: 1px solid #ebccd1;
  border-radius: 4px;
  padding: 15px;
  color: #a94442;
  background-color: #f2dede;
  word-break: break-all;
  margin: 1rem 0;
  max-width: fit-content;
`;

export const Label = styled(CenterBox)`
  margin: 3rem 0 0.5rem 0;
  font-size: 1.4rem;
  font-weight: 500;
  
  ${customMedia.greaterThan('sm')`
    margin: 3rem 0 0.5rem 0;
    font-size: 1.6rem;
  `}
`;

export const ExtraData = styled(CenterBox)`
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 1.5;
  text-align: center;
  color: ${darkGrey2};
  margin: auto;
  padding-top: 3rem;

  & > a {
    color: ${darkGrey2};
    font-weight: 600;
  }
`;

export const Signup = styled(CenterBox)`
  margin: 3rem 0 0 0;
  font-size: 1.6rem;
  color: ${black};

  & a {
    text-decoration: none;
    font-weight: 600;
    color: ${BLUE_VIOLET};
  }
`;

export const Input = styled.input`
  height: 4.5rem;
  margin: 0.5rem 0 0 0.1rem;
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  border: solid 0.1rem ${lightBlueGrey};
  background-color: ${white};
  width: -webkit-fill-available;
`;

export const Button = styled.button`
  width: 13.7rem;
  border: 0;
  margin: 3rem 0 0 0;
  padding: 1.5rem 0.6rem 1.5rem 0.7rem;
  border-radius: 2.4rem;
  background-color: ${BLUE_VIOLET};
  font-family: Work Sans;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: -0.09px;
  text-align: center;
  color: ${white};
  cursor: pointer;

  &[disabled] {
    background-color: ${darkGrey2};
    cursor: wait;
  }
`;