import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { DndProvider } from 'react-dnd'

import ContentLoader from '../../../../../../../shared/basic/ContentLoader';
import PlaylistDetailsItemMobile from './Item/MobileItem';
import PlaylistDetailsItem from './Item';
import { Table } from './styles';
import { setPlaylistOrder } from '../../../../../actions/my-library-actions';
import withWindowResize from '../../../../../../../shared/hocs/withWindowResize';
import { MEDIUM } from '../../../../../../../shared/constants/breakpoint-constants';

const PlaylistDetailsList = ({ setShowDeleteAudio, isReadOnly, width }) => {
  const dispatch = useDispatch();
  const isLoadingPlaylistAudios = useSelector(state => state.library.isLoadingPlaylistAudios);
  const itemIds = useSelector(state => state.library.playlist.itemIds);
  const playlistAudios = useSelector(state => state.library.playlistAudios);
  const [items, setItems] = useState(itemIds.map(id => playlistAudios.find(i => +i.id === id)));

  const moveItem = useCallback((dragIndex, hoverIndex) => {
    const dragCard = itemIds[dragIndex];
    const newItems = [ ...itemIds ];    
    newItems.splice(dragIndex, 1);
    newItems.splice(hoverIndex, 0, dragCard);
    dispatch(setPlaylistOrder(newItems));
  }, [items]);

  const renderItem = (item, index) => (
    width.lessThan(MEDIUM) 
      ? <PlaylistDetailsItemMobile
        key={item.id}
        {...item}
        index={index}
        setShowDeleteAudio={setShowDeleteAudio}
        moveItem={moveItem}
        isReadOnly={isReadOnly}
      />
      : <PlaylistDetailsItem
        key={item.id}
        {...item}
        index={index}
        setShowDeleteAudio={setShowDeleteAudio}
        moveItem={moveItem}
        isReadOnly={isReadOnly}
      />
  );

  useEffect(() => {
    setItems(itemIds.map(id => playlistAudios.find(i => +i.id === id)))
  }, [itemIds])

  return isLoadingPlaylistAudios ? <ContentLoader /> : (
    <Table>
      {!width.lessThan(MEDIUM) && (
        <thead><tr><th>Title</th><th>Duration</th><th>Date</th><th /></tr></thead>
      )}
      <DndProvider backend={width.lessThan(MEDIUM) ? TouchBackend : HTML5Backend}>
        <tbody>
          {items.map((item, index) => renderItem(item, index))}
        </tbody>
      </DndProvider>
    </Table>
  )
};

PlaylistDetailsList.propTypes = {
  setShowDeleteAudio: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

PlaylistDetailsList.defaultProps = {
  setShowDeleteAudio: () => {},
  isReadOnly: true,
}

export default withWindowResize(PlaylistDetailsList);
