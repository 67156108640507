import styled from 'styled-components';
import media from 'styled-media-query';
import { black, lightBlueGrey, melon, white } from '../../../../styles/variables';
import Icon from '../../../../shared/basic/IconFontAnswer';
import { customMedia } from '../../../../styles/breakpoints';
import { ContentContainer } from '../../styles';
import DefaultButton from '../../../../shared/basic/DefaultButton';

export const DivBlock = styled.div`
  display: block;
  width: 100%;

  .playlist-info {
    margin: 4rem 0 2rem;
    font-family: Work Sans;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.34px;
    color: ${black};
  }
`;

export const DivBack = styled.div`
  margin-bottom: 3rem;

  a {
    display: flex;
    text-decoration: none;
    font-family: Work Sans;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.32px;
    text-align: center;
    color: ${melon};
  }
`;

export const Button = styled(DefaultButton)`
  margin: 0;
  padding: .7rem 2rem;
  display: flex;
  position: absolute;
  right: 0;
`;

export const DivTitle = styled.div`
  margin: auto 0;
`;

export const DivFlex = styled.div`
  display: flex;

  a {
    display: flex;
    text-decoration: none;
    font-family: Work Sans;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.32px;
    text-align: center;
    color: ${melon};
    padding: .6rem 2rem;
    margin: auto 0;
  }

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`;

export const NewContentContainer = styled(ContentContainer)`
  display: flex;
  flex-direction: column;

  ${customMedia.greaterThan('md')`
    flex-direction: row;
  `}
`;

export const Input = styled.input.attrs(props => ({
  type: props?.type ? props.type : 'text',
}))`
  display: block;
  font-size: 1.3rem;
  color: ${black};
  padding: 1.1rem 1.6rem;
  border-radius: .5rem;
  border: solid 0.1rem #caccd8;
  background-color: ${white};
`;

export const TextArea = styled.textarea`
  padding: 10px 10px 43px 10px;
  border-radius: 5px;
  border: solid 1px ${lightBlueGrey};
  background-color: ${white};
  resize: none;
`;

export const Select = styled.select`
  padding: 11px 13px 10px 11px;
  border-radius: 5px;
  border: solid 1px ${lightBlueGrey};
  background-color: #ffffff;
`;

export const Content = styled.div`
  width: 50%;
  font-family: Work Sans;
  display: block;

  & .back {
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.32px;
    text-align: right;
    color: ${melon};
    display: flex;
    text-decoration: none;
    margin: auto 0 3rem auto;
  }

  :last-child {
    margin-left: 5rem;

    ${media.lessThan('medium')`
      margin-left: 0;
      margin-top: 5rem;
    `}
  }

  ${media.lessThan('medium')`
    width: 100%;
  `}
`;

export const Back = styled(Icon)`
  font-size: 13px;
  font-weight: 600;
  margin-right: 1rem;

  :after {
    color: ${melon};
  }
`;

export const SubTitle = styled.div`
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.34px;
  color: ${black};
  margin-bottom: 1rem;
  position: relative;
  display: flex;
  height: 3rem;
`;

export const TrashIcon = styled(Icon)`
  :after {
    margin-right: 1rem;
    color: ${melon};
    cursor: pointer;
  }
`;

export const PlusIcon = styled(Icon)`
  :after {
    margin-right: 1rem;
    color: ${white};

    ${media.lessThan('medium')`
      margin-right: 0;
    `}
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  margin-top: 2rem;

  thead {
    background-color: ${lightBlueGrey};
    font-size: 1.4rem;

    th {
      padding: 1.6rem 1.4rem;
      text-align: left;
    }
  }

  tr {
    height: 4rem;
  }

  tbody {
    font-size: 1.5rem;
    tr {
      border: 1px solid ${lightBlueGrey};

      td {
        padding: 1.6rem 1.4rem;
      }
    }
  }
`;