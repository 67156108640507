import styled from 'styled-components'

import {
  melon
} from '../../../styles/variables';

export const Btn = styled.div`
  border: ${props => props.border || 'none'};
  padding: ${props => props.padding || '.5rem 1.0rem'};
  margin: ${props => props.margin};
  border-radius: ${props => props.borderRadius || '1.1rem'};
  color: ${props => props.color || '#ffffff'};
  background-color: ${props => props.bgColor || melon};
  font-size: ${props => props.fontSize || '1.2rem'};
  font-weight: ${props => props.fontWeight || '600'};
  letter-spacing: -0.08px;
  display: ${props => props.display || 'flex'};
  text-transform: uppercase;
  align-items: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'} !important;
  opacity: ${props => props.disabled && '0.3'};

  &:hover {
    opacity: ${props => !props.disabled && '0.6'};
  }
`;
