import React from 'react';
import { useSelector } from 'react-redux';

import { urlPlaylistGenerator } from '../../../../../../../../shared/helper/openPlaylistLink';
import ButtonShare from '../../../../../../../../shared/basic/Share';

const SharePlaylist = () => {
  const { id, isLoading, errorMessage } = useSelector(state => state.publicLibrary.currentyPlaylist);
  const copySoar = urlPlaylistGenerator(id, 'soar');
  const links = {
    copyGoogle: urlPlaylistGenerator(id, 'googleAssistant'),
    copyAlexa: urlPlaylistGenerator(id, 'googleAssistant'),
    copySoar,
    facebook: copySoar,
    twitter: copySoar,
    linkedin: copySoar,
    email: copySoar,
  };

  return (
    <ButtonShare {...links} disabled={isLoading || !!errorMessage} />
  )
};

export default SharePlaylist;