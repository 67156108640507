import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { CardNumberElement } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Btn } from './styles';
import {
  alterPlan,
  failLocalToken,
  savePaymentMethod
} from '../../../../../../actions/premium-actions';

const Buttons = ({ history }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const isLoading = useSelector(state => state.accountSettings.isLoading);
  const currentyCreditCard = useSelector(state => state.accountSettings.currentyCreditCard);
  const cvcElement = useSelector(state => state.accountSettings.cvcElement);
  const cardNumberAttribute = useSelector(state => state.accountSettings.cardNumberAttribute);
  const expirationDateAttribute = useSelector(state => state.accountSettings.expirationDateAttribute);
  const cardHolderName = useSelector(state => state.accountSettings.cardHolderName);
  const country = useSelector(state => state.accountSettings.country);
  const zipCode = useSelector(state => state.accountSettings.zipCode);
  const mode = useSelector(state => state.accountSettings.mode);

  function handleBack() {
    history.push('/subscriptions');
  }

  const handleCheckoutButtonClick = async (stripeCardId, paymentMethod, zipCode) => {
    await dispatch(alterPlan(stripeCardId));
    await dispatch(savePaymentMethod(paymentMethod, zipCode));
  };

  const onNewCheckoutButtonClick = async event => {
    event.preventDefault();

    const cardElement = elements.getElement(CardNumberElement);
    const { token } = await stripe.createToken(cardElement, {
      name: cardHolderName,
      address_country: country.label,
      address_zip: zipCode,
    });

    if (token && token.id && token.card) {
      dispatch(failLocalToken(''));
      await handleCheckoutButtonClick(
        token.id,
        `${token.card.brand} ending in ${token.card.last4}`,
        zipCode
      );
    } else {
      dispatch(failLocalToken('Error: Can`t process the payment.'));
    }
  };

  const onExistingCheckoutButtonClick = async event => {
    event.preventDefault();

    if (currentyCreditCard && cvcElement) {
      dispatch(failLocalToken(''));
      await handleCheckoutButtonClick(currentyCreditCard.value, currentyCreditCard.label, zipCode);
    } else {
      dispatch(failLocalToken('Error: You need to fill correct all fields.'));
    }
  };

  const isNewPaymentCardValid = Boolean(
    cardNumberAttribute &&
    expirationDateAttribute &&
    cvcElement &&
    cardHolderName &&
    country &&
    zipCode
  );
  const isExistingPaymentCardValid = Boolean(currentyCreditCard && cvcElement);
  const content = {};

  switch (mode) {
    case 'existing': {
      content.isLoading = isLoading;
      content.onClick = onExistingCheckoutButtonClick;
      content.isValid = isExistingPaymentCardValid;
      content.text = 'SUBSCRIBE';
      break;
    }
    default: {
      content.isLoading = isLoading;
      content.onClick = onNewCheckoutButtonClick;
      content.isValid = isNewPaymentCardValid;
      content.text = 'SUBSCRIBE';
      break;
    }
  }

  return (
    <Grid paddingBottom="2rem">
      <Btn
        onClick={content.onClick}
        disabled={(content.isLoading || !content.isValid)}
        minWidth="14rem"
      >
        {content.isLoading ? 'Loading...' : content.text}
      </Btn>
      <Btn reverse onClick={handleBack}>CANCEL</Btn>
    </Grid>
  );
}; 

Buttons.propTypes = {
  history: PropTypes.object,
};

Buttons.defaultValues = {
  history: {},
};

export default memo(Buttons);
