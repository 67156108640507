import styled from 'styled-components';
import media from 'styled-media-query';
import { darkGrey2 } from '../../../../../../../../styles/variables';

import Button from '../../../../../../../../shared/basic/Button';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 1.5rem;
  margin-bottom: 3rem;

  ${media.lessThan('medium')`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows> 1fr 1fr;
    gap: 1.5rem;
  `}
`;

export const Btn = styled(Button)`
  background-color: ${props => props.reverse ? '#ffffff' : '#ff7654'};
  color: ${props => props.reverse ? '#ff7654' : '#ffffff'};
  padding: 1.3rem;
  border-radius: .5rem;
  display: inline-flex;
  justify-content: center;
  min-width: ${props => props.minWidth};

  &[disabled] {
    background-color: ${darkGrey2};
  }

  ${media.lessThan('medium')`
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  `}
`;
