import styled from 'styled-components';

export const WrapperOptions = styled.div`
  text-decoration: ${props => props.isDisabled && 'line-through'};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

export const WrapperForms = styled.div`
  display: grid;
  gap: 0;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
`;
