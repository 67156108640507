import React from 'react';
import withLayout from '../../shared/layout/hocs/withLayout';
import Logo from '../../shared/basic/Logo';
import ImgPresenter from '../../assets/images/presenter.png';
import ImgRevolution from '../../assets/images/join-revolution.png';
import Tiles from './components/tiles';
import Video from './components/video';
import ReactPlayer from "react-player";
import {
  LogoContainer,
  StyledLanding,
  StyledContainer,
  ContentView,
  ContentContainer,
  CaptureContainer,
  OverflowImage,
  TopBlock,
  Title1,
  SubText1,
  SubText2,
  SpanQuestion,
  CenterTitle,
  MiddleForm,
  JoinTheRevolution,
  JoinImage,
  AppButtons,
  CTAButton,
  CenterTitle2,
  CaptureContainer2,
} from './styles';
import {
  VideoContainerRows,
  VideoPlayers,
  VideoPlayer,
} from './components/video/styles';

const Landing = () => (
  <StyledLanding>
    <OverflowImage>
      <img src={ImgPresenter} alt="Be the First!" />
    </OverflowImage>
    <ContentView>
      <StyledContainer>
        <ContentContainer>
          <LogoContainer>
            <Logo />
            <Title1>Turn your conferences into AI-powered learning libraries</Title1>
          </LogoContainer>
          <TopBlock pt="3">
            <SubText2 pt="3" pb="1">
              Give your members access to event highlights and on-demand learning - anytime, anywhere and on any device.
            </SubText2>
            <SubText2 pt="0" pb="1">
              Event planners spend months selecting speakers, choosing topics and producing amazing events. This average participant
                experiences only 1-2% of the event live, and recalls very little within days.
            </SubText2>
            <SubText2 pt="0" pb="3">
              Now the team that brought you Ancestry.com, brings you Soar to unlock millions of hours of world class content. Offering the 
              latest advances in voice-computing, AI and mobile streaming to power dynamic learning experiences.
            </SubText2>
            <AppButtons>
              <CTAButton href="https://app.soar.com/clint?isSchedule=true">Schedule a Call to Learn More</CTAButton>
            </AppButtons>
          </TopBlock>
        </ContentContainer>
      </StyledContainer>
      <Tiles />
      <MiddleForm>
        <StyledContainer>
            <CaptureContainer direction="column">
              <SpanQuestion>WHY SOAR AUDIO?</SpanQuestion>
              <CenterTitle>
                With Soar Audio, you can stream your recorded conference content to viewers anytime, anywhere on any device. Turn your 
                event archives into impactful AI-powered learning libraries.
              </CenterTitle>
              <SubText1 pt="3" pb="3" width="69" align="center">
                Make a lasting impression on your attendees, and give then an experience they'll never forget.
              </SubText1>
              <CTAButton href="https://app.soar.com/clint?isSchedule=true">Schedule a conversation with Soar</CTAButton>
            </CaptureContainer>
        </StyledContainer>
      </MiddleForm>
    </ContentView>
    <Video />
    <ContentView> 
      <JoinTheRevolution>
        <VideoPlayers>
          <VideoPlayer>
            <ReactPlayer url="https://youtu.be/qaQF40FQM2I" width="100%" height="100%" />
          </VideoPlayer>
          <VideoPlayer>
            <ReactPlayer url="https://youtu.be/pCOj5NonhFs" width="100%" height="100%" />
          </VideoPlayer>
        </VideoPlayers>
        {/* <VideoContainerRows>
        </VideoContainerRows> */}
        <JoinImage>
          <CaptureContainer>
            <CenterTitle2>Try out Soar with your own private Zoom recordings</CenterTitle2>
          </CaptureContainer>
          <CaptureContainer2>
            <CTAButton href="/pricing">Sign Up</CTAButton>
          </CaptureContainer2>
          <img src={ImgRevolution} alt="Join the revolution" />
        </JoinImage>
      </JoinTheRevolution>
    </ContentView>
  </StyledLanding>
);

export default withLayout(Landing);
