import styled from 'styled-components';

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.marginBottom || '2rem'};
`;

export const Label = styled.label`
  font-size: 1.3rem;
  font-weight: 500;
  display: inline-block;
  margin-bottom: .5rem;
  line-height: 1;
  color: #000000;
`;