import { connect } from 'react-redux';
import { fetchUserPathInfo, receiveFile } from '../../actions/dashboard-actions';
import Upload from './upload';

const mapStateToProps = state => ({
  isUploadinUserPath: state.dashboard.isUploadinUserPath,
  userPathErrorMessage: state.dashboard.userPathErrorMessage,
  userPathInfo: state.dashboard.userPathInfo,
});

const mapDispatchToProps = {
  fetchUserPathInfo: () => fetchUserPathInfo(),
  receiveFileInfo: fileInfo => receiveFile(fileInfo),
};

export default connect(mapStateToProps, mapDispatchToProps)(Upload);