import styled from 'styled-components';
import media from 'styled-media-query';
import {
  black,
  slateGrey,
  melon,
  white,
  greyAlpha,
} from '../../../../styles/variables';
import { FontAwesomeIcon } from '../../../../shared/basic/IconFontAnswer/styles';

export const Div = styled.div`
  padding: 2.2rem;
  display: block;
  align-items: center;
  font-family: Work Sans;
`;

export const Dropzone = styled(Div)`
  padding: unset;
  cursor: ${props => props.cursor ? props.cursor : 'default'};

  & .highlight {
    background-color: ${greyAlpha};
  }
`;

export const DivButtons = styled.div`
  font-family: Work Sans;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;

  ${media.greaterThan('small')`
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const Title = styled.div`
  width: 100%;
  margin: 0 0 9px;
  font-size: 1.6rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.39px;
  text-align: center;
  color: ${black};

  & > span {
    font-size: 1.2rem;
    letter-spacing: 0.29px;
  }
`;

export const ConvertText = styled(Title)`
  font-size: 1.3rem;
  letter-spacing: 0.32px;
`;

export const Content = styled.div`
  max-width: 28.5rem;
  height: 40px;
  margin: 9px auto;
  font-size: 1.3rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 0.32px;
  text-align: center;
  color: ${slateGrey};
`;

export const Icon = styled(FontAwesomeIcon)`
  margin: 1.8rem auto;
  font-size: 4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.97px;
  text-align: center;
`

export const Button = styled.button`
  display: flex;
  height: fit-content;
  margin: 12px 10px 0 0;
  padding: 13px 15px;
  border-radius: 5px;
  background-color: ${melon};
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.32px;
  color: ${white};
  border: unset;

  & > div {
    padding-right: 1rem;
  }

  & > i {
    padding-right: 1rem;
  }
`;

export const FileInput = styled.input.attrs(() => ({
  type: 'file',
  accept: '.txt, .md, .mp3, .aif, .m4a, .mp4, .mov',
  multiple: false,
}))`
  display: none;
`;

export const ProgressWrapper = styled.div`
  margin-left: 32px;
  align-items: flex-start;
  justify-items: flex-start;
  flex: 1;
  overflow-y: auto;
`;
