import styled from 'styled-components';
import Icon from '../../../../shared/basic/IconFontAnswer';
import { black, lightBlueGrey, white } from '../../../../styles/variables';

export const Header = styled.div`
  display: flex;
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.34px;
  color: ${black};
  border-bottom: 1px solid ${lightBlueGrey};
  padding: 2rem 3rem;

  div {
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
    right: 3rem;
  }
`;

export const Close = styled(Icon)`
  cursor: pointer;

  &::after {
    color: ${black};
  }
`;

export const Content = styled.div`
  font-family: Work Sans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.34px;
  color: ${black};
  padding: 3rem 3rem 0 3rem;

  span {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.34px;
  }
`;

export const Footer = styled.div`
  padding: 0 3rem 3rem 3rem;
  margin-top: 3rem;
  display: flex;
`;

export const Input = styled.input.attrs(props => ({
  type: props?.type ? props.type : "text",
}))`
  margin: 5px 0 0;
  border-radius: 5px;
  border: solid 1px ${lightBlueGrey};
  background-color: ${white};
  padding: 10px 11px;
`;
