import arrowup from './images/combined-shape.svg';
import headphone from './images/combined-shape_2.svg';
import stripes from './images/combined-shape_3.svg';
import diamond from './images/combined-shape_4.svg';
import mobileSignupBottom from './images/mobile-signup-bottom.svg';
import signBackground from './images/background.jpg';
import logoWhite from './images/logo_white.png';
import logoThin from './images/logo_thin.png';
import logoWithoutText from './images/logo_without_text.png';
import americanExpressIcon from './payment-systems-logos/american-express-icon.png';
import discoverIcon from './payment-systems-logos/discover-icon.png';
import mastercardIcon from './payment-systems-logos/mastercard-icon.png';
import visaIcon from './payment-systems-logos/visa-icon.png';
import bannerTop from './images/BannerTop.png';
import illustration from './images/illustration.png';
import illustration2x from './images/illustration@2x.png';
import illustration3x from './images/illustration@3x.png';
import appleStoreBadge from './dashboard/apple-store-badge.png';
import appleStoreBadge2x from './dashboard/apple-store-badge@2x.png';
import appleStoreBadge3x from './dashboard/apple-store-badge@3x.png';
import googlePlayBadge from './dashboard/google-play-badge.png';
import googlePlayBadge2x from './dashboard/google-play-badge@2x.png';
import googlePlayBadge3x from './dashboard/google-play-badge@3x.png';
import widgetCover from './dashboard/image.png';
import widgetCover2x from './dashboard/image@2x.png';
import widgetCover3x from './dashboard/image@3x.png';
import premiumCover from './dashboard/plan.png';
import premiumCover2x from './dashboard/plan@2x.png';
import premiumCover3x from './dashboard/plan@3x.png';

export const imgs = {
  arrowup,
  headphone,
  stripes,
  diamond,
  sign_background: signBackground,
  logo_white: logoWhite,
  mobile_signup_bottom: mobileSignupBottom,
  logo_thin: logoThin,
  logoWithoutText,
  bannerTop,
};

export const paymentLogos = {
  americanExpressIcon,
  discoverIcon,
  mastercardIcon,
  visaIcon,
};

export const uploadImages = {
  illustration,
  illustration2x,
  illustration3x,
};
export const dashboard = {
  appleStoreBadge,
  appleStoreBadge2x,
  appleStoreBadge3x,
  googlePlayBadge,
  googlePlayBadge2x,
  googlePlayBadge3x,
  widgetCover,
  widgetCover2x,
  widgetCover3x,
  premiumCover,
  premiumCover2x,
  premiumCover3x,
};
