import React, { memo } from 'react';
import PropTypes from 'prop-types';

import LoaderBubble from '../../../../../shared/basic/LoaderBubble';

import { Button } from './styles';

const ButtonLoadMore = ({ onClick, text, isLoading }) => {
  return <Button onClick={onClick}>
    {isLoading && <LoaderBubble />}
    {text}
  </Button>;
};

ButtonLoadMore.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
};

ButtonLoadMore.defaultProps = {
  text: '',
  onClick: () => {},
  isLoading: false,
};

export default memo(ButtonLoadMore);
