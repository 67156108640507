import axios from 'axios';
import cookies from 'browser-cookies';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_SOAR_API_HOST,
});

export default httpClient;

export function setTokenApi(accessToken) {
  httpClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  sessionStorage.setItem('token', accessToken);
}

export function clearTokenApi() {
  httpClient.defaults.headers.common.Authorization = undefined;
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('tokenTMP');
  sessionStorage.removeItem('token_API_audience_TMP');
  sessionStorage.removeItem('refreshToken');
  sessionStorage.removeItem('SoarID');
  cookies.erase('socialMedia');
}

export function getTokenApi() {
  return sessionStorage.getItem('token');
}

export async function suggestCoachSpecialty(url, payload, successCallback, failureCallback) {
  try {
    const response = await httpClient.patch(url, payload);

    if (response.status === 200) {
      successCallback();
    } else {
      throw new Error(response.data);
    }
  } catch (error) {
    failureCallback(error);
  }
}

export async function sendMessage(url, payload, successCallback, failureCallback) {
  try {
    const response = await httpClient.post(url, payload);
    const { data } = response;

    if (response.status === 200 && data === 'Success') {
      successCallback();
    } else {
      throw new Error(data);
    }
  } catch (error) {
    failureCallback(error);
  }
}

export async function verifyEmailSoar(email) {
  try {
    const response = await httpClient.get(`/user/byEmail?email=${email}`);
    if (response) {
      if (response.status === 200) {
        return response;
      }
    }
    return false;
  } catch (e) {
    return false;
  }
}

export const redirectLoginSocialMedia = connection => {
  const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientID = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirect = process.env.REACT_APP_HOSTNAME;

  const redirectLogin = `https://${auth0Domain}/authorize
?response_type=code
&client_id=${clientID}
&scope=offline_access
&redirect_uri=${redirect}
&connection=${connection === 1 ? 'facebook' : 'google-oauth2'}`;
  window.open(redirectLogin, '_self');
};

export const redirectAuthCallback = (response, site = true, redirect = true) => {
  const appHost = process.env.REACT_APP_HOSTNAME;
  setTokenApi(response.access_token);
  const redirectLogin = `${appHost}/auth/callback#
access_token=${response.access_token}
&scope=openid%20profile
&expires_in=${response.expires_in}
&token_type=Bearer
&id_token=${response.id_token}`;
  redirect && window.open(redirectLogin, site === true ? '_self' : '');
};

export const forgotPassword = async email => {
  const nodeBackEnd = process.env.REACT_APP_BACKEND_NODE;
  const url = `${nodeBackEnd}/auth/forgotpassword`;
  try {
    const response = await httpClient.post(url, { email });
    if (response.data.execute) {
      return response.data.message;
    }
    return false;
  } catch (e) {
    return e.data.message;
  }
};
