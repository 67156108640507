import { Record } from 'immutable';
import { uploadType } from '../../components/dashboard/constants';
import { AudioRecord } from './audio-record';

export const DashboardRecord = Record({
  fileInfo: [{}],
  fileId: 'NoFile',
  isUploadinUserPath: false,
  userPathErrorMessage: '',
  userPathInfo: '',
  isSedingFile: false,
  sendFileError: '',
  isFileInfoLoading: false,
  fileInfoError: '',
  textToTranscription: '',
  sourceUrl: '',
  contentType: uploadType.FILE,
  scope: 0,
  audio: new AudioRecord(),
  isLoadingTopics: true,
  topicsError: '',
  topics: [],
});
