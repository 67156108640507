import styled from 'styled-components'

export const H2 = styled.h2 `
  font-size: 1.7rem;
  letter-spacing: -.011rem;
  color: #000000;
  margin-block-start: 0;
  margin-block-end: 3.6rem;
  font-weight: normal;
`;
