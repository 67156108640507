import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, WrapperSwitch, Switch, Title, SubTitle } from './styles';

const SUB_TITLE_PRIVATE = 'This is a Private playlist, manage which groups have permission below:';
const SUB_TITLE_PUBLIC = 'This playlist is Public to all users.';

const PrivacyPermissions = ({ onChange, isPrivate}) => (
  <Wrapper>
    <WrapperSwitch>
      <Switch onChange={onChange} checked={isPrivate}/>
      <Title>{isPrivate ? 'Private' : 'Public'} Playlist</Title>
    </WrapperSwitch>
    <SubTitle>{isPrivate ? SUB_TITLE_PRIVATE : SUB_TITLE_PUBLIC}</SubTitle>
  </Wrapper>
);

PrivacyPermissions.propTypes = {
  onChange: PropTypes.func,
  isPrivate: PropTypes.bool,
};

PrivacyPermissions.defaultProps = {
  onChange: {},
  access: false,
}

export default PrivacyPermissions;
