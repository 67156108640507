import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ContentLoader from '../../../../shared/basic/ContentLoader';
import { Div, Row } from './styles';

const Topics = ({ isLoading, error, fetchTopics, topics }) => {
  useEffect(() => {
    async function fetch() {
      await fetchTopics();
    }

    fetch();
  }, [fetchTopics]);

  return isLoading ? <ContentLoader /> : (
    <Div>
      {topics.map(topic => {
        return (
          <Row key={topic.id}>
            <span className="name">{topic.title}</span>
            <span className="amount">({topic.entryCount})</span>
          </Row>
        );
      })}
    </Div>
  );
};

Topics.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  topics: PropTypes.array.isRequired,
  error: PropTypes.string.isRequired,
  fetchTopics: PropTypes.func.isRequired,
};

export default Topics;
