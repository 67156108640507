import styled from 'styled-components';
import { slateGrey } from '../../../styles/variables';

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

export const Helper = styled.small`
  display: block;
  margin: 10px 12px 0 1px;
  font-family: Work Sans;
  font-size: 1.2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.29px;
  color: ${slateGrey};
`;

export const Label = styled.label`
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: .5rem;
  letter-spacing: .032rem;
`;
