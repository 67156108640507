import React from "react";
import { StyledLogo } from './styles';
import LogoImg from '../../../assets/images/logo.png';

const Logo = () =>
  <StyledLogo src={LogoImg}/>;

Logo.propTypes = {};
Logo.defaultProps = {};

export default Logo;
