import styled from 'styled-components'

export const Button = styled.div`
  position: relative;
  overflow: hidden;
  width: max-content;
  color: #ff7654;
  font-size: 1.43rem;
  font-weight: bold;
  line-height: 1;
  padding: 1.1rem 4.4rem;
  background-color: transparent;
  border-radius: 50vmax;
  border: solid 0.2rem #ff7654;
  align-self: center;

  :hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;
