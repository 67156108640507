import React from 'react';
import PropTypes from 'prop-types';

import { Count } from './styles';

const PlaylistCount = ({ audioCount }) => (
  <Count>{audioCount && `${audioCount} audios`}</Count>
);

PlaylistCount.propTypes = {
  audioCount: PropTypes.string,
};

PlaylistCount.defaultProps = {
  audioCount: '',
};

export default PlaylistCount;
