import React from 'react';
import PropTypes from 'prop-types';

import ErrorMessage from '../../../../../../shared/basic/ErrorMessage';
import { Wrapper } from './styles'
import { PLAYLIST_LIST_ITEMS_PER_PAGE as ITEMS_PER_PAGE } from '../../../constants/page';

const Error = ({ isList, message, ...props }) => (
  <Wrapper>
    <ErrorMessage message={message} />
    {isList
      ? Array.from({ length: ITEMS_PER_PAGE }).map((_, index) => <props.component key={index} isLoading />)
      : <props.component isLoading />
    }
  </Wrapper>
);

Error.propTypes = {
  component: PropTypes.any,
  message: PropTypes.string,
  isList: PropTypes.bool,
};

Error.defaultProps = {
  message: 'Oops! there was an unexpected error, please try again late.',
  component: <div />,
  isList: false,
};

export default Error;