import React from 'react';
import PropTypes from 'prop-types';

import Subscription from './Subscription';
import Payment from './Payment';
import {
  Wrapper,
  Grid,
  FirstColumn,
  SecondColumn,
  Div,
  Img,
  Divisor,
} from './styles';

const Box = ({ plan, period }) => (
  <Wrapper>
    <Grid>
      <FirstColumn>
        <Div>
          <Subscription plan={plan} period={period} />
          <Divisor />
          <Payment />
        </Div>
      </FirstColumn>
      <SecondColumn>
        <Img src="/img/checkout/premium.png" alt="Premium checkout" />
      </SecondColumn>
    </Grid>
  </Wrapper>
);

Box.propTypes = {
  plan: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
};

export default Box;