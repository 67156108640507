import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import LargePlay from '../../../../shared/components/Button/Play/Large';
import { setAudioPlaying } from '../../../../actions/my-library-actions';

const ButtonPlay = ({ id, isLoading, bgColor, color, items, ...props }) => {
  const dispatch = useDispatch();
  const { id: audioPlayingId } = useSelector(state => state.library.audioPlaying);
  const isActive = id === audioPlayingId;
  const handleClick = () => {
    dispatch(setAudioPlaying(isActive ? {} : items.find(i => i.id === id)));
  };

  const attributes = {
    id: +id,
    isActive: isActive,
    setAudio: handleClick,
    isLoading,
    bgColor,
    color,
    ...props
  };

  return <LargePlay {...attributes} />
};

ButtonPlay.propTypes = {
  id: PropTypes.any,
  isLoading: PropTypes.bool,
  items: PropTypes.array,
};

ButtonPlay.defaultProps = {
  id: null,
  isLoading: false,
  items: [],
};

export default ButtonPlay;
