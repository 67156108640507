import React from "react";
import PropTypes from 'prop-types';
import { Div } from './styles';

const Container = ({ children, backgroundColor, paddingTop, paddingBottom }) => 
  <Div
    backgroundColor={backgroundColor}
    paddingTop={paddingTop}
    paddingBottom={paddingBottom}
  >
    {children}
  </Div>;

Container.propTypes = {
  children: PropTypes.any,
  backgroundColor: PropTypes.string,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
};

Container.defaultProps = {
  children: {},
  backgroundColor: '',
  paddingTop: '5.4rem',
  paddingBottom: '1rem',
};

export default Container;