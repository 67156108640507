import styled from 'styled-components';
import ReactSwitch from 'react-switch';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
`;

export const WrapperSwitch = styled.div`
  display: flex;
  margin: auto 0;
  align-items: center;
`;

export const Switch = styled(ReactSwitch).attrs(() => ({
  uncheckedIcon: false,
  checkedIcon: false,
  height: 18,
  width: 33,
  onColor: '#00b634',
}))``;

export const Title = styled.div`
  font-size: 1.3rem;
  font-weight: 600;
  color: #000000;
  margin-left: 1rem;
`;

export const SubTitle = styled.div`
  font-size: 1.3rem;
  color: #5c5c5e;
`;
