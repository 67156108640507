import styled from 'styled-components';
import media from 'styled-media-query';
import {
  black,
  kellyGreen,
  melon,
  slateGrey,
  white
} from '../../../../../../styles/variables';

export const FormFile = styled.div`
  display: block;
`;

export const FormBody = styled.div`
  flex-direction: column;
  padding: 0 2rem;
`;

export const ModalTitle = styled.div`
  display: flex;

  & div {
    position: absolute;
    right: 3rem;
    margin-top: auto;
    margin-bottom: auto;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const FormHeader = styled.div`
  margin: 0 0 28px;
  padding: 25px 30px 30px 29px;
  background-color: ${kellyGreen};
  font-family: Work Sans;
  font-size: 1.4rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.34px;
  color: ${white};
`;

export const AudioInfo = styled.span`
  display: flex;
  color: ${black};
  width: auto;
  margin: 19px 0 0 1px;
  padding: 13px 35px;
  border-radius: 8px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
  background-color: ${white};

  & div {
    position: absolute;
  }

  & .left {
    left: 4.5rem;
  }
  & .right {
    right: 4.5rem;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

export const Helper = styled.small`
  display: block;
  margin: 10px 12px 0 1px;
  font-family: Work Sans;
  font-size: 1.2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.29px;
  color: ${slateGrey};
`;

export const Label = styled.label`
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: .5rem;
  letter-spacing: .032rem;

  & span {
    padding-left: .3rem;
    color: ${melon};
  }
`;

export const Input = styled.input.attrs(props => ({
  type: props?.type ? props.type : 'text',
}))`
  display: block;
  font-size: 1.3rem;
  color: ${black};
  padding: 1.1rem 1.6rem;
  border-radius: .5rem;
  border: solid 0.1rem #caccd8;
  background-color: ${white};
`;

export const Textarea = styled.textarea`
  display: block;
  font-size: 1.3rem;
  color: ${black};
  padding: 1.1rem 1.6rem;
  border-radius: .5rem;
  border: solid 0.1rem #caccd8;
  background-color: ${white};
  resize: none;
`;

export const FormFooter = styled(FormBody)`
  margin-bottom: 3rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0.5rem;

  ${media.greaterThan('small')`
    display: block;
    gap: unset;
  `}
`;

export const SubmitButton = styled.button`
  color: ${white};
  margin: 0 23px 0 0;
  padding: 1.5rem 4rem;
  border-radius: 5px;
  background-color: ${melon};
  font-family: Work Sans;
  font-size: 1.3rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.32px;
  text-align: center;
  border: unset;
  width: 100%;

  ${media.greaterThan('small')`
    width: unset;
  `}
`;

export const CancelButton = styled(SubmitButton)`
  background-color: ${white};
  color: ${melon};
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  ${media.greaterThan('small')`
    width: unset;
  `}
`;
