import React from 'react';
import { dashboard } from '../../../../assets';
import { Div, Cover, SubText, Title, Footer, Img } from './styles'

const Announcement = () => (
  <Div>
    <Title>
      DOWNLOAD OUR APP
    </Title>
    <SubText>
      Get the Soar Uplift app for your phone or tablet today.
    </SubText>
    <div>
      <Cover alt="Soar Cover"/>
    </div>
    <Footer>
      <a href="https://apps.apple.com/us/app/soar-audio/id1502457902">
        <Img src={dashboard.appleStoreBadge} />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.soar.soarlibrary">
        <Img src={dashboard.googlePlayBadge} />
      </a>
    </Footer>
  </Div>
);

export default Announcement;