import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import PropTypes from 'prop-types';
import {
  FormFile,
  FormGroup,
  Label,
  FormBody,
  FormHeader,
  Input,
  FormFooter,
  SubmitButton,
  CancelButton,
  Image,
  Div,
  SwitchDiv,
  Gear,
  Close,
  Helper,
} from './styles'
import Loader from '../../../../../../shared/basic/ContentLoader';
import ErrorMessage from '../../../../../../shared/basic/BoxMessage';

const FormUrl = props => {
  const {
    closeModal,
    openAudioDetail,
    handleFormSubmit,
  } = props;

  const [url, setUrl] = useState('');
  const [privacy, setPrivacy] = useState(false);
  const [formError, setFormError] = useState('');

  async function submitForm(event) {
    event.preventDefault();
    if (!url) {
      setFormError('The URL field is required.');
      return;
    }

    const rg = /(?:youtu\.be\/|youtube\.com\/)/g;
    if (!rg.test(url)) {
      setFormError('Use only YouTube valid URL, as shown in the example below the field.');
      return;
    }

    function callback() {
      closeModal();
      openAudioDetail();
    };

    const formData = { url, privacy };
    await handleFormSubmit(formData, callback);
  };

  return (
    <FormFile onSubmit={submitForm}>
      <FormHeader>
        CONVERT YOUTUBE VIDEO TO AUDIO
        <Close unicode={'f00d'} onClick={closeModal} />
      </FormHeader>
      <FormBody>
        {formError ? <ErrorMessage message={formError} /> : null}
        <Div>
          <Image />
        </Div>
        <FormGroup>
          <Label>Youtube video url</Label>
          <Input value={url} onChange={event => setUrl(event.target.value)} />
          <Helper>Example: https://www.youtube.com/watch?v=12345</Helper>
        </FormGroup>
        <FormGroup>
          <Label>Privacy</Label>
          <SwitchDiv>
            <div className="switch-text">Private</div>
            <Switch
              onChange={setPrivacy}
              checked={privacy}
              uncheckedIcon={false}
              checkedIcon={false}
              height={18}
              width={36}
            />
            <div className="switch-text">Public</div>
          </SwitchDiv>
        </FormGroup>
      </FormBody>
      <FormFooter>
        <SubmitButton type="submit"><Gear unicode="f013" /> CONVERT TO AUDIO</SubmitButton>
        <CancelButton onClick={closeModal}>Cancel</CancelButton>
      </FormFooter>
    </FormFile>
  );
};

FormUrl.propTypes = {
  closeModal: PropTypes.func.isRequired,
  openAudioDetail: PropTypes.func.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
}

export default FormUrl;
