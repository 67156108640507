import { createAction } from 'redux-actions';
import httpClient from '../../shared/helper/request';
import { CoachRecord } from '../records/coach-record';
import { userFailLoadData, setIsLoading, unsetIsLoading } from './page-actions';

export const setAuthorizedUser = createAction(
  'AUTHORIZED_USER/SET_AUTHORIZED_USER',
  authorizedUser => authorizedUser
);

export const setProfilePictUrl = createAction(
  'AUTHORIZED_USER/SET_PROFILE_PICT_URL',
  profilePictUrl => profilePictUrl
);

export const userRequestData = createAction('AUTHORIZED_USER/USER_REQUEST_DATA');
export const userReceiveData = createAction('AUTHORIZED_USER/USER_RECEIVE_DATA', user =>
  CoachRecord.parse(user)
);

export const fetchUserData = userId => async dispatch => {
  dispatch(userRequestData());
  dispatch(setIsLoading());

  if (!userId) {
    const error = new Error('Not found user id');
    dispatch(userFailLoadData(error));
    return;
  }

  try {
    const { data: user } = await httpClient.get(`/user/${userId}`);

    dispatch(
      userReceiveData({ ...user })
    );
    dispatch(unsetIsLoading());
    // eslint-disable-next-line consistent-return
    return CoachRecord.parse(user);
  } catch (error) {
    dispatch(userFailLoadData(error));
  }
};

export const fetchCurrentUser = () => async (dispatch, getState) => {
  const { authorizedUser } = getState();
  await dispatch(fetchUserData(authorizedUser.id));
};

export const requestUpdateAuthorizedUser = createAction(
  'AUTHORIZED_USER/UPDATE_AUTHORIZED_USER_REQUEST'
);
export const receiveUpdateAuthorizedUser = createAction(
  'AUTHORIZED_USER/UPDATE_AUTHORIZED_USER_RECEIVE',
  user => {
    if (user instanceof CoachRecord) {
      return user;
    }

    return CoachRecord.parse(user);
  }
);
export const failUpdateAuthorizedUser = createAction(
  'AUTHORIZED_USER/UPDATE_AUTHORIZED_USER_RECEIVE_FAIL',
  error => error
);

export const updateAuthorizedUser = user => async (dispatch, getState) => {
  dispatch(requestUpdateAuthorizedUser());
  try {
    const { authorizedUser } = getState();
    const response = await httpClient.patch(`/user/${authorizedUser.id}`, user);
    const { data: updatedUser } = response;

    if (response.status !== 200) {
      const error = new Error(
        `Something went wrong. Try the operation again. Error: ${updatedUser}`
      );
      dispatch(failUpdateAuthorizedUser(error));
      return;
    }

    dispatch(receiveUpdateAuthorizedUser(updatedUser));
  } catch (error) {
    dispatch(failUpdateAuthorizedUser(error));
  }
};

export const fetchReloadUser = () => async (dispatch, getState) => {
  dispatch(userRequestData());

  const { authorizedUser } = getState();
  const { id: userId } = authorizedUser;

  if (!userId) {
    const error = new Error('Not found user id');
    dispatch(userFailLoadData(error));
    return;
  }

  try {
    const { data: user } = await httpClient.get(`/user/${userId}`);
    const { data: rating } = await httpClient.get(`/review/list/user/${user.id}/average`);

    dispatch(
      userReceiveData({
        ...user,
        rating: rating.average,
        totalReviews: rating.totalCount,
      })
    );
    // eslint-disable-next-line consistent-return
    return CoachRecord.parse(user);
  } catch (error) {
    dispatch(userFailLoadData(error));
  }
};
