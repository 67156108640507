import React, { memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tabs, TabPanel } from 'react-tabs';
import { useDispatch, useSelector } from 'react-redux';

import TitlePage from '../../shared/basic/TitlePage';
import withLayout from '../../shared/layout/hocs/withLayout';
import { UploadContainer } from '../dashboard/components/upload';
import Widget from '../dashboard/components/widget';
import Table from './components/table';
import Icon from '../../shared/basic/IconFontAnswer';
import { GroupFormContainer } from './components/groupForm';
import { PlaylistFormContainer } from './components/playlistForm';
import { AddPlaylistFormContainer } from './components/addPlaylistForm';
import ContentLoader from '../../shared/basic/ContentLoader';
import ErrorMessage from '../../shared/basic/BoxMessage';
import { DialogContainer } from './components/dialog';
import { CoachRecord } from '../../redux/records/coach-record';
import {
  columns as defaultColumns,
  actions,
  widgetHelperText,
} from './constants';
import {
  ContentContainer,
  Container,
  Section,
  Tab,
  TabList,
  CreateButton,
  DivPlanRequired,
  ZoomLinks,
  ScrollDiv,
  WrapperPlayer,
  HighlighterPlayer,
  TitlePlayer
} from './styles';
import AudioPlayer from '../PublicLibrary/components/Playlist/Detail/Player/AudioPlayer';
import VideoPlayer from '../PublicLibrary/shared/components/Player/VideoPlayer';
import { setAudioPlaying } from './actions/my-library-actions';
import withWindowResize from '../../shared/hocs/withWindowResize';
import { MEDIUM } from '../../shared/constants/breakpoint-constants';

const appHost = process.env.REACT_APP_HOSTNAME;

const renderButton = (buttonType, handleClick = () => {}) => (
  <CreateButton onClick={handleClick}>
    {buttonType === 1 
    ? <><Icon unicode="f550" /> CREATE PLAYLIST</>
    : <><Icon unicode="f0c0" /> CREATE GROUP</>}
  </CreateButton>
)

const GroupTable = props => {
  const { isSATeamSub, isLoadingGroup } = props;

  if (!isSATeamSub) {
    return null;
  }

  return (isLoadingGroup ? <ContentLoader /> : (
    <Table
      key="group"
      recordType="group"
      editUrl="/group/edit"
      {...props}
    />
  ));
}

const ZoomConnectionButton = props => {
  const { connectedToZoom, id, emailAddress } = props.user;
  if (connectedToZoom) {
    return (<span>Connected to zoom</span>);
  } else {
    const encodedParam = btoa(`${emailAddress}:${id}`);
    return (
      <div>
        <a href={`${appHost}/zoom-mkp?state=${encodedParam}`}>Connect with zoom</a> OR <a href={`${appHost}/zoom-mkp-teams?state=${encodedParam}`}>Connect with zoom teams</a>
      </div>
    );
  }
}

const ZoomConnection = props => {
  return (
    <ZoomLinks>
      <ZoomConnectionButton {...props} />
    </ZoomLinks>
  );
}

const Library = props => {
  const {
    activeTab,
    setActiveTab,
    fetchAudios,
    fetchAudiosSharedWithMe,
    isLoadingAudio,
    isLoadingAudioSharedWithMe,
    audioErrorMessage,
    audioSharedWithMeErrorMessage,
    audios,
    audiosSharedWithMe,
    isLoadingPlaylist,
    playlistErrorMessage,
    playlists,
    fetchPlaylist,
    isLoadingGroup,
    groupErrorMessage,
    groups,
    fetchGroups,
    setDeleteOpts,
    setSelectedAudio,
    closeModal,
    isSATeamSub,
    authorizedUser,
    width,
  } = props;
  const [columns, setColumns] = useState(defaultColumns[activeTab]);
  const [button, setButton] = useState(null);
  const [showGroupForm, setShowGroupForm] = useState(false);
  const [showPlaylistForm, setshowPlaylistForm] = useState(false);
  const [showAddPlaylist, setShowAddPlaylist] = useState(false);
  const [audioDeleteModal, setAudioDeleteModal] = useState(false);
  const [playlistDeleteModal, setPlaylistDeleteModal] = useState(false);
  const [groupDeleteModal, setGroupDeleteModal] = useState(false);
  const [audioActions, playlistActions, groupActions, audioSharedWithMeActions] = actions;
  const audioPlaying = useSelector(state => state.library.audioPlaying);
  const dispatch = useDispatch();

  const isVideo = CheckVideoType(audioPlaying.contentType, audioPlaying.sourceUrl);
  const url = isVideo ? urlBuilder(audioPlaying.alexaAudioUrl) : audioPlaying.alexaAudioUrl;

  useEffect(() => {
    dispatch(setAudioPlaying({}));

    async function getAudios() {
      await fetchAudios();
    }

    async function getPlaylists() {
      await fetchPlaylist();
    }

    async function getGroups() {
      await fetchGroups();
    }

    async function getAudiosSharedWithMe() {
      await fetchAudiosSharedWithMe();
    }

    if (activeTab === 0) {
      getAudios();
      setButton(null);
    } else if (activeTab === 1) {
      getPlaylists();
      setButton(renderButton(activeTab, () => setshowPlaylistForm(true)));
    } else if (activeTab === 2) {
      if (isSATeamSub) {
        getGroups();
        setButton(renderButton(activeTab, () => setShowGroupForm(true)));
      }
    } else if (activeTab === 3) {
      getAudiosSharedWithMe();
      setButton(null);
    }
  }, [activeTab]);

  function onSelectTab(index) {
    const [newColumns] = defaultColumns.filter(column => {
      return column.key === index;
    });

    setColumns(newColumns);
    setActiveTab(index);
  };

  function deleteAudioHandle(event) {
    setDeleteOpts({
      key: event.target.dataset.key,
      label: event.target.dataset.label,
      type: event.target.dataset.type,
    });

    setAudioDeleteModal(true);
  }

  function deletePlaylistHandle(event) {
    setDeleteOpts({
      key: event.target.dataset.key,
      label: event.target.dataset.label,
      type: event.target.dataset.type,
    });

    setPlaylistDeleteModal(true);
  }

  function deleteGroupHandle(event) {
    setDeleteOpts({
      key: event.target.dataset.key,
      label: event.target.dataset.label,
      type: event.target.dataset.type,
    });

    setGroupDeleteModal(true);
  }

  function playlistHandle(event) {
    setSelectedAudio(event.target.dataset.key);
    dispatch(closeModal());
    setShowAddPlaylist(true);
  }

  function CheckVideoType(contentType, sourceUrl){

    return (['zoomrecording', 'video'].includes(contentType.toLowerCase()) ||
        ('url'.includes(contentType.toLowerCase()) &&
            sourceUrl.isNotEmpty &&
            sourceUrl.includes('youtu')));
  }
  
  function urlBuilder(alexaAudioUrl) {
    return alexaAudioUrl.substring(0,alexaAudioUrl.lastIndexOf("/")) + '/orig.mp4';
  }

  return (
    <Container>
      <Section>
        <TitlePage iconCode="f07c">MY LIBRARY</TitlePage>
        <ContentContainer>
          <ZoomConnection user={authorizedUser} />
          <Tabs onSelect={onSelectTab} selectedIndex={activeTab}>
            <TabList>
              <Tab>Audio</Tab>
              <Tab>Playlist</Tab>
              <Tab>Groups</Tab>
              <Tab>Shared with me</Tab>
              {!width.lessThan(MEDIUM) && button}
            </TabList>

            <TabPanel>
              {audioErrorMessage && <ErrorMessage message={audioErrorMessage} />}
              {isLoadingAudio ? <ContentLoader /> : (
                <>
                  <Widget dashed footer={widgetHelperText}>
                    <UploadContainer showBlogButton={true} showTextButton={false} isMyLibrary />
                  </Widget>
                  {activeTab === 0 && (
                    <WrapperPlayer opacity={!audioPlaying.id && '0.2'}>
                      {/* <HighlighterPlayer>YOU ARE LISTENING TO:</HighlighterPlayer> */}
                      <TitlePlayer>{audioPlaying.voicePhrase || '...'}</TitlePlayer>
                      {isVideo ? <VideoPlayer url={url} /> : <AudioPlayer url={url} isDisabled={!audioPlaying.id} />}
                      {/* <AudioPlayer url={audioPlaying.alexaAudioUrl} isDisabled={!audioPlaying.id} /> */}                    </WrapperPlayer>
                  )}
                  <ScrollDiv>
                    <Table
                      key="audio"
                      recordType="audio"
                      actions={audioActions}
                      columns={columns}
                      rows={audios}
                      handleDelete={deleteAudioHandle}
                      handlePlaylist={playlistHandle}
                      editUrl="/audio/edit"
                      viewUrl="/audio"
                    />
                  </ScrollDiv>
                </>
              )}
            </TabPanel>
            <TabPanel>
              {playlistErrorMessage && <ErrorMessage message={playlistErrorMessage} />}
              {isLoadingPlaylist ? <ContentLoader /> : (
                <ScrollDiv>
                  {width.lessThan(MEDIUM) && renderButton(activeTab, () => setshowPlaylistForm(true))}
                  <Table
                    key="playlist"
                    recordType="playlist"
                    actions={playlistActions}
                    handleDelete={deletePlaylistHandle}
                    columns={columns}
                    rows={playlists}
                    editUrl="/playlist/edit"
                    viewUrl="/playlist/view"
                  />
                </ScrollDiv>
              )}
            </TabPanel>
            <TabPanel>
              {groupErrorMessage && <ErrorMessage message={groupErrorMessage} />}
              <ScrollDiv>
                {width.lessThan(MEDIUM) && renderButton(activeTab, () => setShowGroupForm(true))}
                <GroupTable
                  isSATeamSub={isSATeamSub}
                  isLoadingGroup={isLoadingGroup}
                  handleDelete={deleteGroupHandle}
                  actions={groupActions}
                  columns={columns}
                  rows={groups}
                />
                {!isSATeamSub && (
                  <DivPlanRequired type="info">
                    Want to create your own groups? Subscribe to teams <Link to="/pricing">here</Link>
                  </DivPlanRequired>
                )}
              </ScrollDiv>
            </TabPanel>
            {activeTab === 3 && (
              <WrapperPlayer opacity={!audioPlaying.id && '0.2'}>
                {/* <HighlighterPlayer>YOU ARE LISTENING TO:</HighlighterPlayer> */}
                <TitlePlayer>{audioPlaying.voicePhrase || '...'}</TitlePlayer>
                {isVideo ? <VideoPlayer url={url} /> : <AudioPlayer url={url} isDisabled={!audioPlaying.id} />}
                {/* <AudioPlayer url={audioPlaying.alexaAudioUrl} isDisabled={!audioPlaying.id} /> */}              </WrapperPlayer>
            )}
            <TabPanel>
              {audioSharedWithMeErrorMessage && <ErrorMessage message={audioSharedWithMeErrorMessage} />}
              {isLoadingAudioSharedWithMe ? <ContentLoader /> : (
                <>
                  <Table
                    recordType="audioSharedWithMe"
                    actions={audioSharedWithMeActions}
                    columns={columns}
                    rows={audiosSharedWithMe}
                    handlePlaylist={() => {}}
                    viewUrl="/audio"
                  />
                </>
              )}
            </TabPanel>
          </Tabs>
        </ContentContainer>
      </Section>

      <GroupFormContainer showModal={showGroupForm} closeModal={() => setShowGroupForm(false)} />
      <PlaylistFormContainer showModal={showPlaylistForm} closeModal={() => setshowPlaylistForm(false)} />
      <AddPlaylistFormContainer showModal={showAddPlaylist} closeModal={() => setShowAddPlaylist(false)} />
      <DialogContainer showModal={audioDeleteModal} closeModal={() => setAudioDeleteModal(false)} label="audio" />
      <DialogContainer showModal={playlistDeleteModal} closeModal={() => setPlaylistDeleteModal(false)} label="playlist" />
      <DialogContainer showModal={groupDeleteModal} closeModal={() => setGroupDeleteModal(false)} label="group" />
    </Container>
  );
};

Library.propTypes = {
  isSATeamSub: PropTypes.bool.isRequired,
  isLoadingAudio: PropTypes.bool.isRequired,
  isLoadingPlaylist: PropTypes.bool.isRequired,
  isLoadingGroup: PropTypes.bool.isRequired,
  audioErrorMessage: PropTypes.string.isRequired,
  playlistErrorMessage: PropTypes.string.isRequired,
  groupErrorMessage: PropTypes.string.isRequired,
  activeTab: PropTypes.number.isRequired,
  audios: PropTypes.array.isRequired,
  playlists: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  authorizedUser: PropTypes.instanceOf(CoachRecord).isRequired,
  setActiveTab: PropTypes.func.isRequired,
  fetchAudios: PropTypes.func.isRequired,
  fetchPlaylist: PropTypes.func.isRequired,
  fetchGroups: PropTypes.func.isRequired,
  setDeleteOpts: PropTypes.func.isRequired,
  setSelectedAudio: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  width: PropTypes.object.isRequired,
};

export default withLayout(memo(withWindowResize(Library)));
