import React from 'react';

import { Wrapper, Grid, H1, H2, B, Description, Img, Badge } from './styles';

const Box = () => (
  <Wrapper>
    <H1>You’re all set!</H1>
    <H2>Thanks for subscribing to <B>SHRM</B>.</H2>
    <Img src="/img/checkout/thank-you.png" alt="Thank you" />
    <Description>
      Start exploring your new content using our Soar Uplift app, you can also explore it using your Alexa and Google Assistant devices.
    </Description>
    <Grid>
      <Badge src="/img/checkout/apple-store-badge.png" alt="Apple Store" />
      <Badge src="/img/checkout/google-play-badge.png" alt="Google Play" />
    </Grid>
  </Wrapper>
);

export default Box
