import cookies from 'browser-cookies'
import { authorizedUser, createAccount, createUserSoarApi, generateJWT } from '../account';
import auth0Client from '../auth0';
import { redirectAuthCallback } from '../request';

export const login = async (params) => {
  const { email, password, callback, errorCallback, redirect = true } = params;
  const successCallBack = response => {
    sessionStorage.setItem('token_API_audience_TMP', response.message.access_token);
    sessionStorage.setItem('refreshToken', response.message.refresh_token);
    auth0Client.setSession(response.message);
    redirectAuthCallback(response.message, true, redirect);
  };

  const failureCallback = response => {
    // email is not verified... "Please verify your email or reset password to continue."
    errorCallback && errorCallback(response.message.error_description);
    callback && callback(false);
  };

  callback && callback(true);
  cookies.set('soarUserExist', 'true');
  await authorizedUser(email, password, successCallBack, failureCallback);
};

function cleanLocalStorage() {
  sessionStorage.removeItem('userTMP');
  sessionStorage.removeItem('tokenTMP');
  sessionStorage.removeItem('idAuth0TMP');
};

async function accountAuth0(params) {
  const { email, password, givenName, familyName, callback, errorCallback } = params;
  const dataAuth0 = {
    given_name: givenName,
    family_name: familyName,
    email,
    password,
    name: `${email} ${''}`,
    connection: 'Username-Password-Authentication',
  };

  const successCallbackUser = () => {
    cleanLocalStorage();
    login(params);
  };

  const failureCallbackUser = () => {
    errorCallback && errorCallback('Something went wrong when trying to create a new account! Try again later.');
    callback && callback(false);
  };

  createAccount(dataAuth0, successCallbackUser, failureCallbackUser);
};

async function createSoarUser(params) {
  const { data, callback, errorCallback } = params;
  const header = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token_API_audience_TMP')}` } };

  try {
    const responseCreateUserSoar = await createUserSoarApi(header, data);
    const { status: statusCreateUser } = responseCreateUserSoar;
    if (statusCreateUser === 200 || statusCreateUser === 201) {
      const soarID = responseCreateUserSoar.data.id;

      sessionStorage.setItem('SoarID', soarID);

      accountAuth0(params);
      return responseCreateUserSoar.data;
    }
    throw new Error();
  } catch {
    errorCallback && errorCallback('Something went wrong when trying to create a new account! Try again later.');
    callback && callback(false);
    return false;
  }
};

export async function signUp(params) {
  const { isLogin, givenName, familyName, email, callback } = params;
  if (!isLogin) {
    const failureCallback = () => {
      callback && callback(false);
    };
    callback && callback(true);

    await generateJWT(failureCallback);

    const data = {
      givenName: givenName,
      familyName: familyName,
      emails: [{ emailAddress: email, type: 'EMAIL1' }],
      signupCode: 9,
      gsnStatus: 110,
      isCoach: false,
    };

    return createSoarUser({data, ...params});
  } else {
    await login(params);
  }
};
