import Axios from 'axios';
import { createAction } from 'redux-actions';
import auth0Client, { getSoarId } from '../../../../shared/helper/auth0';
import httpClient from '../../../../shared/helper/request';
import AudioRecord from '../records/audio-record';

export const setIsLoading = createAction('AUDIO/SET_IS_LOADING', value => value);

export const setLoadionPlaylist = createAction('AUDIO/SET_LOADING_PLAYLIST', value => value);

export const setErrorMessage = createAction('AUDIO/SET_ERROR_MESSAGE', value => value);

export const setAudioInfo = createAction('AUDIO/SET_AUDIO_DATA', value => AudioRecord.parse(value));

export const setPlaylistData = createAction('AUDIO/SET_PLAYLIST_DATA', playlist => playlist);

export const fetchAudioData = voicePhrase => async dispatch => {  
  dispatch(setIsLoading(true));
  const axios = Axios.create({
    baseURL: process.env.REACT_APP_SOAR_API_HOST,
  });
  try {
    const isAuthenticated = auth0Client.isAuthenticated();
    const userId = getSoarId(auth0Client.getProfile());

    const privateUri = [`/SoarUplift/getByVoicePhrase/${userId}/${voicePhrase}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token_API_audience_TMP')}`
    }}];
    const publicUri = [`/SoarUplift/getByVoicePhrase/public/${voicePhrase}`];
    const uri = isAuthenticated ? privateUri : publicUri;

    const { data } = await axios.get(...uri);

    dispatch(setAudioInfo(data.item));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setErrorMessage('This audio is private or does not exists anymore.'));
    dispatch(setIsLoading(false));
  }
};

export const fetchPlaylist = () => async (dispatch, getState) => {
  dispatch(setLoadionPlaylist(true));
  const { publicAudio } = getState();
  const { audio } = publicAudio;

  try {
    const { data } = await httpClient.get(`/SoarUpliftPlaylist/listIncludingItem/${audio.id}`);

    dispatch(setPlaylistData(data));
    dispatch(setLoadionPlaylist(false));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
    dispatch(setLoadionPlaylist(false));
  }
};
