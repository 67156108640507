import React, { memo } from "react";
import PropTypes from 'prop-types';
import { Btn } from './styles';

const Button = ({ children, ...props }) => <Btn {...props}>{children}</Btn>;

Button.propTypes = {
  children: PropTypes.any,
};

Button.defaultProps = {
  children: '',
};

export default memo(Button);
