import React from 'react';

import withLayout from '../../../../shared/layout/hocs/withLayout';
import Title from '../../../../shared/basic/TitlePage';
import { Container, Section } from './styles';
import Box from './Box';

const ThankYou = () => {
  return (
    <Container>
      <Section>
        <Title iconCode="f3a5">Subscription</Title>
        <Box />
      </Section>
    </Container>
  );
};

export default withLayout(ThankYou);