import styled from 'styled-components';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';

export const FormTooltip = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  justify-items: end;
  padding: 1.3rem 0;
  cursor: default !important;
`;

export const FooterLinks = styled.div`
  padding-top: 0.5rem;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(4, max-content);
  justify-self: center;
`;

export const Circle = styled.div`
  width: 2.7rem;
  height: 2.7rem;
  background-color: ${props => props.backgroundColor || '#ff7654'};
  color: #ffffff;
  border-radius: 50vmax;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FacebookShare = styled(FacebookShareButton)`
  :hover:not(:active) { opacity: 0.8; }
`;

export const LinkedinShare = styled(LinkedinShareButton)`
  :hover:not(:active) { opacity: 0.8; }
`;

export const TwitterShare = styled(TwitterShareButton)`
  :hover:not(:active) { opacity: 0.8; }
`;

export const EmailShare = styled(EmailShareButton)`
  :hover:not(:active) { opacity: 0.8; }
`;
