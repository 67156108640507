import styled from 'styled-components';
import media from 'styled-media-query';
import ImgByDensity from '../../../../../shared/basic/ImgByDensity';

export const Wrapper = styled.div`
  margin-top: 1.5rem;
  background-color: white;
  padding: 3.9rem 3.1rem 3.0rem;
  border-radius: 0.5rem;

  ${media.lessThan('medium')`
    padding: 2rem 1rem;
  `}
`;

export const Title = styled.div`
  font-size: ${props => props.secondary ? '1.6rem' : '1.4rem'};
  font-weight: ${props => props.secondary ? 'unset' : '600'};
  margin-bottom: ${props => props.secondary ? '0.2rem' : '2rem'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${props => props.secondary ? '#5c5c5e' : 'inherit'};
`;

export const Grid = styled.div`
  display: grid;
  gap: 3.1rem;
  grid-template-columns: 11fr 14fr;

  ${media.lessThan('large')`
    display: block;
    gap: unset;
    grid-template-columns: unset;
  `}
`;

const Column = styled.div`
  display: flex;
  justify-content: center;
`;

export const FirstColumn = styled(Column)``;
export const SecondColumn = styled(Column)`
  ${media.lessThan('large')`
    display: none;
  `}
`;

export const Div = styled.div`
  width: 100%;
`;

export const Img = styled(ImgByDensity)`
  width: 35rem;
  height: auto;
  align-self: flex-end;
  margin-bottom: 8.4rem;
`;

export const Divisor = styled.div`
  display: block;
  height: 0.1rem;
  margin: 2.3rem 0;
  padding: 0;
  background-color: #caccd8;
`;

export const ErrorMessage = styled.span`
  font-size: 1.4rem;
  font-weight: 600;
`;
