import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useDrag, useDrop } from 'react-dnd';

import { setDeleteOpts } from '../../../../../../actions/my-library-actions';
import { goTo } from '../../../../../../../../shared/helper/goTo';

import {
  Row,
  Title,
  Duration,
  Date,
  Action,
  GripIcon,
  EyeIcon,
  PencilIcon,
  TrashIcon
} from './styles';
import { urlAudioGenerator } from '../../../../../../../../shared/helper/openPlaylistLink';

const appHost = process.env.REACT_APP_HOSTNAME;

const PlaylistDetailsItem = ({
  id,
  index,
  title,
  voicePhrase,
  audioSeconds,
  dateCreated,
  setShowDeleteAudio,
  moveItem,
  isReadOnly
}) => {
  const dispatch = useDispatch();
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: 'item',
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: 'item', id, index },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const style = isDragging && { opacity: 0, border: 'none' };
  
  drag(drop(ref));

  const deleteItem = () => {
    dispatch(setDeleteOpts({ key: `icon-${id}`, label: title, type: 'playlist-audio' }));
    setShowDeleteAudio(true);
  };

  const editItem = () => {
    goTo(`${appHost}/audio/edit/${id}`);
  };

  const ViewItem = () => {
    goTo(urlAudioGenerator({ id, voicePhrase }, 'soar'));
  };

  return (
    <Row ref={ref} style={{ ...style }}>
      <Title><GripIcon />{title}</Title>
      <Duration>{audioSeconds}</Duration>
      <Date>{dateCreated}</Date>
      <Action>
        <EyeIcon onClick={ViewItem} />
        {!isReadOnly && (
          <>
            <PencilIcon onClick={editItem} />
            <TrashIcon onClick={deleteItem} />
          </>
        )}
      </Action>
    </Row>
  )
};

PlaylistDetailsItem.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  title: PropTypes.string,
  voicePhrase: PropTypes.string,
  audioSeconds: PropTypes.number,
  dateCreated: PropTypes.string,
  moveItem: PropTypes.func,
  setShowDeleteAudio: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

PlaylistDetailsItem.defaultProps = {
  id: '',
  index: null,
  title: '',
  voicePhrase: '',
  audioSeconds: null,
  dateCreated: '',
  moveItem: () => { },
  setShowDeleteAudio: () => { },
  isReadOnly: true,
}

export default PlaylistDetailsItem;
