import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Box } from './styles';

const ErrorMessage = ({ message }) => <Wrapper><Box>{message}</Box></Wrapper>;

ErrorMessage.propTypes = {
  message: PropTypes.string,
};

ErrorMessage.defaultProps = {
  message: '',
};

export default ErrorMessage;