import styled from 'styled-components';
import media from 'styled-media-query';

import Icon from "../../../../../shared/basic/IconFontAnswer";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
`;

export const Highlighter = styled.div`
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 1.5rem;
  color: #5c5c5e;

  ${media.lessThan('small')`align-self: center;`}
`;

export const WrapperTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1.2rem;

  ${media.lessThan('small')`
    display: flex;
    justify-content: center;
    text-align: center;
    grid-template-columns: unset;
    gap: unset;
    margin-bottom: 0.8rem;
  `}
`;

export const Title = styled.div`
  font-size: 3.2rem;
  font-weight: bold;
  line-height: 1;

  min-height: 3.2rem;
  min-width: 3.2rem;
  
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  ${media.lessThan('small')`
    font-size: 2.1rem;
    min-height: 2.1rem;
    min-width: 2.1rem;
  `}
`;

export const MobileGoBack = styled.div`
  font-size: 1.3rem;
  font-weight: 600;
  color: #ff7654;
  margin-bottom: 2.5rem;
  display: none;
  :hover { cursor: pointer; }

  ${media.lessThan('small')`display: block;`}
`;

export const DesktopGoBack = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 600;
  color: #ff7654;
  display: block;
  :hover { cursor: pointer; }

  ${media.lessThan('small')`display: none;`}
`;

export const ArrowLeft = styled(Icon).attrs(() => ({
  unicode: 'f060',
  iconSolid: true,
  color: '#ff7654'
}))`
  font-size: 1.3rem;
  margin-right: 1.1rem;
  display: unset;
`;

export const WrapperSubtitle = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
  gap: 1rem;
  font-size: 1.5rem;
  align-items: baseline;

  ${media.lessThan('small')`
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    justify-items: center;
  `}
`;

export const Subtitle = styled.div`  
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 0.5rem;
`;

export const Author = styled.div`
  font-weight: 600;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const WrapperShare = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
