import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Item from './Item';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from '../../../../../shared/typography/PageTitle';
import PageSubtitle from '../../../../../shared/typography/PageSubtitle';
import ButtonLoadMore from '../../../shared/components/LoadMore'
import { setPlaylistsPageNum } from '../../../redux/actions/playlist-list-actions';

import { Wrapper, WrapperItems } from './styles';
import { statusManager } from '../../../shared/helpers/statusManager';
import { fetchPlaylistList } from '../../../redux/actions/library-actions';
import withLayout from '../../../../../shared/layout/hocs/withLayout';
import withWindowResize from '../../../../../shared/hocs/withWindowResize';
import { SMALL } from '../../../../../shared/constants/breakpoint-constants';
import JoinSoar from '../../../shared/components/JoinSoar';
import Container from '../../../../../shared/layout/Container';
import Section from '../../../../../shared/layout/Section';

const PlaylistList = ({ width }) => {
  const dispatch = useDispatch();
  const style = width.lessThan(SMALL)
    ? { paddingTop: '1.7rem', paddingBottom: '0' }
    : { paddingBottom: '0' };
  const {
    pageNum, items, isLoadingLoadMore, totalCount
  } = useSelector(state => state.publicLibrary.listPlaylists);
  const isLoading = useSelector(state => state.publicLibrary.isLoading);
  const errorMessage = useSelector(state => state.publicLibrary.errorMessage);

  useEffect(() => {
    dispatch(fetchPlaylistList());
  }, [dispatch]);

  const handleLoadMoreClick = () => {
    dispatch(setPlaylistsPageNum(pageNum+1));
    dispatch(fetchPlaylistList());
  }

  const status = statusManager({ component: Item, isLoading, errorMessage, isList: true });

  return (
    <Section>
      <Container {...style}>
        <Wrapper>
          <PageTitle title="Playlists" />
          <PageSubtitle>
            Browse among hundreds of curated playlists, featuring the best audio content you can find online:
          </PageSubtitle>
          <WrapperItems>
          {status || items.map(item => (
              <Item
                key={item.id}
                title={item.title}
                dateCreated={item.dateCreated}
                audioCount={String(item.audioCount)}
                id={item.id}
              />
            ))
          }
          </WrapperItems>
          {items.length < totalCount &&
            <ButtonLoadMore text="Load more playlists" isLoading={isLoadingLoadMore} onClick={handleLoadMoreClick}/>
          }
        </Wrapper>
        <JoinSoar />
      </Container>
    </Section>
  )
};

PlaylistList.propTypes = {
  width: PropTypes.object.isRequired,
};

export default withLayout(withWindowResize(PlaylistList));