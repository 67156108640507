import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../basic/Button';
import IconShare from '../../icon/Share';
import Tooltip from '../../basic/Tooltip';

import CopyGoogle from './CopyField/Google';
import CopyAlexa from './CopyField/Alexa';
import CopySoar from './CopyField/Soar';
import IconEnvelope from '../../icon/Envelope';

import {
  FormTooltip,
  FooterLinks,
  Circle,
  FacebookShare,
  LinkedinShare,
  TwitterShare,
  EmailShare,
 } from './styles';
import IconFacebook from '../../icon/Facebook';
import IconTwitter from '../../icon/Twitter';
import IconLinkedin from '../../icon/Linkedin';

const ButtonShare = ({
  isLoading,
  copyGoogle,
  copyAlexa,
  copySoar,
  facebook,
  twitter,
  linkedin,
  email,
  disabled,
  margin,
}) => {
  const [tooltipisShow, setTooltipisShow] = useState(false);
  const toogleTooltip = () => setTooltipisShow(!tooltipisShow);

  const configTolltip = {
    bgColor: '#ffffff',
    id: `${copySoar}-audioTip`,
    place: 'top',
    effect: 'solid',
    globalEventOff: 'click',
    arrowColor: '#ffffff',
    afterShow: toogleTooltip,
    afterHide: toogleTooltip,
    opacity: 1,
    padding: '0.3rem',
    borderRadius: '2rem',
    boxShadow: '0 10px 19px -13px rgba(0, 0, 0, 1.1)',
    clickable: true,
  };

  return (
    <>
      <Button
        data-for={`${copySoar}-audioTip`}
        data-tip="Audio"
        data-event="click"
        disabled={disabled}
        bgColor={'transparent'}
        color={isLoading ? 'transparent' : ''}
        fontSize={'1.8rem'}
        padding={'0 0 0 0.5rem'}
        margin={margin}
      >
        <IconShare color='#7909ea' />
      </Button>
      
      {!disabled &&
        <Tooltip {...configTolltip} >
          <FormTooltip>
            {copyGoogle && <CopyGoogle url={copyGoogle} />}
            {copyAlexa && <CopyAlexa url={copyAlexa} />}
            {copySoar && <CopySoar url={copySoar} />}
            <FooterLinks>
              {facebook && <FacebookShare url={facebook}>
                <Circle backgroundColor="#3a5997"><IconFacebook /></Circle>
              </FacebookShare>}
              {twitter && <TwitterShare url={twitter}>
                <Circle backgroundColor="#01aced"><IconTwitter /></Circle>
              </TwitterShare>}
              {linkedin && <LinkedinShare url={linkedin}>
                <Circle backgroundColor="#007fb1"><IconLinkedin /></Circle>
              </LinkedinShare>}
              {email && <EmailShare url={email} body="">
                <Circle><IconEnvelope /></Circle>
              </EmailShare>}
            </FooterLinks>
          </FormTooltip>
        </Tooltip>
      }
    </>
  );
};

ButtonShare.propTypes = {
  isLoading: PropTypes.bool,
  copyGoogle: PropTypes.string,
  copyAlexa: PropTypes.string,
  copySoar: PropTypes.string,
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  linkedin: PropTypes.string,
  email: PropTypes.string,
  disabled: PropTypes.bool,
  margin: PropTypes.string,
};

ButtonShare.defaultProps = {
  isLoading: false,
  copyGoogle: '',
  copyAlexa: '',
  copySoar: '',
  facebook: '',
  twitter: '',
  linkedin: '',
  email: '',
  disabled: false,
  margin: '',
};

export default memo(ButtonShare);
