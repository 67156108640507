import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Date } from './styles';

const PlaylistDate = ({ dateCreated }) => (
  <Date>
    {dateCreated && moment(dateCreated).format('YYYY-MM-DD')}
  </Date>
);

PlaylistDate.propTypes = {
  dateCreated: PropTypes.string,
};

PlaylistDate.defaultProps = {
  dateCreated: '',
};

export default PlaylistDate;
