import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: inline-grid;
  grid-template-columns: max-content auto;
  column-gap: 1.5rem;
  align-items: center;
  cursor: default !important;
`;

export const CopyContainer = styled.div`
  width: 30rem;
  display: inline-grid;
  grid-template-columns: auto max-content;
  column-gap: 3.4rem;
  padding: 1.25rem 1.5rem;
  border-radius: 0.5rem;
  border: solid 1px #dfe1ea;
  background-color: #f8f8f8;
  line-height: 1;

  ${media.lessThan('medium')`width: 25rem;`}
  ${media.lessThan('small')`
    width: 8rem;
    column-gap: 1rem;
  `}
`;

export const Link = styled.div.attrs(props => ({
  'data-for': 'copy',
  'data-tip': 'Copied to clipboard!',
  'data-event': 'click focus',
  'data-delay-hide': '3000',
  'ref': props.ref,
  'style': {pointerEvents: 'none'},
}))`
  font-size: 1.3rem;
  color: #5c5c5e;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Tooltip = styled(ReactTooltip).attrs(props => ({
  id: 'copy',
  effect: 'solid',
  globalEventOff: 'click',
  arrowColor: 'transparent'
}))``;
