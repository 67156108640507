import { connect } from 'react-redux';
import {
  fetchGroups,
  updateGroupPermission,
  failGroup,
} from '../../actions/my-library-actions';
import PermissionForm from './';

const mapStateToProps = state => ({
  isLoadingGroupPermission: state.library.isLoadingGroupPermission,
  groups: state.library.groups,
  modalSubmitSuccess: state.library.modalSubmitSuccess,
  groupErrorMessage: state.library.groupErrorMessage,
});

const mapDispatchToProps = {
  fetchGroups: () => fetchGroups(),
  updateGroupPermission: group => updateGroupPermission(group),
  failGroup: message => failGroup(message),
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionForm);