import React from "react";
import PropTypes from 'prop-types';
import { Div } from './styles';

const Section = ({ children, ...props }) => 
  <Div {...props}>
    {children}
  </Div>;

Section.propTypes = {
  children: PropTypes.element,
};

Section.defaultProps = {
  children: <></>,
};

export default Section;