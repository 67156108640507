import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Highlighter = styled.div`
  color: #5c5c5e;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 5rem;
`;

export const Title = styled.div`
  color: #000000;
  font-size: 2.1rem;
  line-height: 1;
  margin-bottom: 5rem;
`;

export const Button = styled.div`
  width: max-content;
  color: #ffffff;
  font-size: 1.9rem;
  font-weight: bold;
  line-height: 1;
  padding: 2rem 2.65rem;
  background-color: #ff7654;
  border-radius: 50vmax;

  :hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;
