import styled from 'styled-components'

export const H1 = styled.h1 `
  font-size: 3.2rem;
  font-weight: bold;
  letter-spacing: -.021rem;
  color: '#000000';
  margin-block-start: 0;
  margin-block-end: 1.2rem;
`;
