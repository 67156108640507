import styled from 'styled-components';
import { black, darkGrey, lightBlueGrey } from '../../../styles/variables';

export const Container = styled.div`
  text-align:center;
}
`;

export const FooterCopyright = styled.div`
  padding: 2rem 2rem;
  font-size: 14px;
  text-align:center;
  color: ${darkGrey};
}
`;

export const Links = styled.div`
  margin-top: 2rem;
  padding: 2rem 2rem;
  border-top: solid 1px ${lightBlueGrey};
  a {
    padding: 1rem 2rem;
    text-decoration: none;
    color: ${black};
    font-size: 14px;
    font-weight: bold;
  }
}
`;
