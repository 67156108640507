import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import PropTypes from 'prop-types';
import {
  FormFile,
  FormGroup,
  Label,
  FormBody,
  FormHeader,
  Textarea,
  FormFooter,
  SubmitButton,
  CancelButton,
  Image,
  Div,
  SwitchDiv,
  Gear,
  Close,
} from './styles'
import Loader from '../../../../../../shared/basic/ContentLoader';
import ErrorMessage from '../../../../../../shared/basic/BoxMessage';

const FormText = props => {
  const {
    closeModal,
    handleFormSubmit,
    openAudioDetail,
  } = props;

  const [text, setText] = useState('');
  const [privacy, setPrivacy] = useState(false);
  const [formError, setFormError] = useState('');

  async function submitForm(event) {
    event.preventDefault();
    if (!text) {
      setFormError('The Text field is required.');
      return;
    }

    function callback() {
      closeModal();
      openAudioDetail();
    };

    const formData = { text, privacy };
    await handleFormSubmit(formData, callback);
  };

  return (
    <FormFile onSubmit={submitForm}>
      <FormHeader>
        CONVERT TEXT TO AUDIO
        <Close unicode={'f00d'} onClick={closeModal} />
      </FormHeader>
      <FormBody>
        {formError ? <ErrorMessage message={formError} /> : null}
        <Div>
          <Image />
        </Div>
        <FormGroup>
          <Label>Type or paste your text</Label>
          <Textarea value={text} rows="6" onChange={event => setText(event.target.value)} />
        </FormGroup>
        <FormGroup>
          <Label>Privacy</Label>
          <SwitchDiv>
            <div className="switch-text">Private</div>
            <Switch
              onChange={setPrivacy}
              checked={privacy}
              uncheckedIcon={false}
              checkedIcon={false}
              height={18}
              width={36}
            />
            <div className="switch-text">Public</div>
          </SwitchDiv>
        </FormGroup>
      </FormBody>
      <FormFooter>
        <SubmitButton type="submit"><Gear unicode="f013" /> CONVERT TO AUDIO</SubmitButton>
        <CancelButton onClick={closeModal}>Cancel</CancelButton>
      </FormFooter>
    </FormFile>
  );
};

FormText.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  openAudioDetail: PropTypes.func.isRequired,
}

export default FormText;
