import styled from 'styled-components';

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

export const Label = styled.label`
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: .5rem;
  letter-spacing: .032rem;
`;

export const Input = styled.input.attrs(props => ({
  type: props?.type ? 'text' : props.type,
}))`
  display: block;  
  font-size: 1.3rem;
  color: #5c5c5e;
  padding: 1.1rem 1.6rem;
  border-radius: .5rem;
  border: solid 0.1rem #caccd8;
  background-color: #f1f1f1;
`;

export const Select = styled.select`
  display: block;
  font-size: 1.3rem;
  color: #5c5c5e;
  padding: 1.2rem 1.5rem;
  border-radius: .5rem;
  border: solid 0.1rem #caccd8;
  background-color: #ffffff;
`;

export const Option = styled.option``;

export const Total = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 0.5rem;
  align-items: baseline;
  font-size: 1.3rem;
  font-weight: 500;
  color: #5c5c5e;
`;

export const Value = styled.div`
  font-size: 2.8rem;
  font-weight: bold;
  color: #000000;
`;
