import cookies from 'browser-cookies';
import httpClient from '../request';

export async function generateJWT(failureCallback) {
  try {
    const body = JSON.stringify({
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      client_secret: process.env.REACT_APP_AUTH0_SECRET_KEY,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      grant_type: 'client_credentials',
    });

    const apiAudienceBody = JSON.stringify({
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      client_secret: process.env.REACT_APP_AUTH0_SECRET_KEY,
      audience: 'https://api.soar.com',
      grant_type: 'client_credentials',
    });

    const response = await httpClient.post(
      `https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`,
      body,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    const { data } = response;

    const responseApiAudience = await httpClient.post(
      `https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`,
      apiAudienceBody,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      sessionStorage.setItem('tokenTMP', data.access_token);
      sessionStorage.setItem('token_API_audience_TMP', responseApiAudience.data.access_token);
    } else {
      failureCallback(data);
    }
  } catch (error) {
    failureCallback(error);
  }
}

export const getDataCreateUser = auth => {
  const {
    user_id: userId,
    given_name: givenName,
    family_name: familyName,
    email,
    picture,
  } = auth.data;
  const data = {
    auth0Id: userId,
    givenName,
    familyName,
    emails: [{ emailAddress: email, type: 'EMAIL1' }],
    signupCode: 1,
    gsnStatus: 110,
    isCoach: true,
    referredByAffiliateId: cookies.get('referredByAffiliateId'),
    customFields: {
      ProfilePictUrl: picture,
    },
  };
  return data;
};

export const createUserSoarApi = async (header, data) => {
  const userData = await httpClient.post('/user', data, header);
  return userData;
};

export const updateUserSoarApi = async (header, data) => {
  const storedSoarId = sessionStorage.getItem('SoarID');
  sessionStorage.removeItem('SoarID');
  const coachData = await httpClient.patch(`/user/${storedSoarId}`, data, header);
  return coachData;
};

export const linkAuth0IDSoarID = async coachID => {
  const header = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token_API_audience_TMP')}` },
  };
  const response = await httpClient.patch(
    `/user/sendIdToAuth0/${coachID}`,
    { userID: coachID },
    header
  );
  return response;
};

export const userExistCreateAccount = async (header, auth0, user) => {
  const userId = user[0].id;
  await httpClient.patch(`/user/${userId}`, { auth0Id: auth0 }, header);
  await linkAuth0IDSoarID(userId);
  sessionStorage.removeItem('tokenTMP');
  return userId;
};

export const userSubscription = async (header, userId, planID, days) => {
  const freeTrialDays = days ? `/?freeTrialDays=${days}` : '';
  const response = await httpClient.post(
    `/Subscription/${userId}/${planID}/none${freeTrialDays}`,
    {},
    header
  );
  return response;
};

export async function createAccount(payload, successCallback, failureCallback) {
  const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const soarID = sessionStorage.getItem('SoarID');
  const idAuth0TMP = sessionStorage.getItem('idAuth0TMP');
  const header = { headers: { Authorization: `Bearer ${sessionStorage.getItem('tokenTMP')}` } };
  try {
    if (!idAuth0TMP) {
      const response = await httpClient.post(
        `https://${auth0Domain}/api/v2/users`,
        payload,
        header
      );

      if (response.status === 200 || response.status === 201) {
        sessionStorage.setItem('idAuth0TMP', response.data.user_id);
        const responseUser = await updateUserSoarApi(header, { auth0Id: response.data.user_id });
        if (responseUser.status === 200) {
          await linkAuth0IDSoarID(soarID);
          await successCallback();
        }
      }
    } else {
      const responseUser = await updateUserSoarApi(header, { auth0Id: idAuth0TMP });
      if (responseUser.status === 200) {
        await linkAuth0IDSoarID(soarID);
        await successCallback();
      }
    }
  } catch (e) {
    failureCallback(e);
  }
}

export async function getObjectUserAuth0(idAuth0) {
  const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
  try {
    const teste = `https://${auth0Domain}/api/v2/users/${idAuth0}`;
    const response = await httpClient.get(teste, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('tokenTMP')}`,
      },
    });
    if (response.status === 200 || response.status === 201) {
      return response;
    }
    return '';
  } catch (e) {
    return e;
  }
}

export const isEmailVerified = async (userId, accessToken) => {
  debugger;
  let emailVerified = false;
  const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
  try {
    const teste = `https://${auth0Domain}/api/v2/users/${userId}`;
    const response = await httpClient.get(teste, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    debugger;
    if (response.status === 200 || response.status === 201) {
      if(response.data.email_verified){
        emailVerified = true;
      }
    }
    return emailVerified;
  } catch (e) {
    debugger;
    return e;
  }
}

export async function authorizedUser(login, password, successCallback, failureCallback) {
  const nodeBackEnd = process.env.REACT_APP_BACKEND_NODE;
  try {
    const url = `${nodeBackEnd}/auth/login`;
    const response = await httpClient.post(
      url,
      {
        login,
        password,
      },
      {
        withCredentials: true,
      }
    );
    if (response.data.execute) {
      successCallback(response.data);
    } else {
      failureCallback(response.data);
    }
  } catch (e) {
    failureCallback(e);
  }
}

export async function infoUser() {
  const nodeBackEnd = process.env.REACT_APP_BACKEND_NODE;
  const url = `${nodeBackEnd}/auth/user`;
  httpClient.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('token')}`;
  const response = await httpClient.post(url);
  if (response.data.execute) {
    return response.data.message;
  }
  sessionStorage.removeItem('token');
  if (!window.location.pathname.includes('signup')) {
    window.open('/signup?login=true', '_self');
  }
  return '';
}

export async function refreshToken(isSocialMedia, accessToken = null) {
  const nodeBackEnd = process.env.REACT_APP_BACKEND_NODE;

  let url = '';
  let body = {};

  if (isSocialMedia) {
    url = `${nodeBackEnd}/auth/setSocialMediaToken`;
    if (accessToken) {
      body = {
        access_token: accessToken,
      };
    }
  } else {
    url = `${nodeBackEnd}/auth/refreshtoken`;
  }

  const response = await httpClient.post(url, body, {
    withCredentials: true,
  });
  if (response.data.execute) {
    sessionStorage.removeItem('token');
    sessionStorage.setItem('token', response.data.message.access_token);
  }
  return response.data.execute;
}
