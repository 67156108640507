import { handleActions } from 'redux-actions';

import LibraryRecord from '../records/library-record';

import {
  setErrorMessage,
  setIsLoading,
  setItemsPlaylists,
  setItemsAudios,
  setSuccessMessage,
  setCurrentyPlaylist,
  setCurrentyAudio,
  setPlaylists,
  setAudios,
} from '../actions/library-actions';
import {
  setPlaylistItems,
  setPlaylistItemIds,
  setOwnerUserObj
} from '../actions/playlist-actions';
import { setPlaylistsPageNum } from '../actions/playlist-list-actions';
import { setAudiosPageNum } from '../actions/audio-list-actions';

export const initialState = new LibraryRecord();

export default handleActions(
  {
    [setIsLoading]: (state, action) => state.set('isLoading', action.payload),
    [setErrorMessage]: (state, action) => state.set('errorMessage', action.payload),
    [setSuccessMessage]: (state, action) => state.set('successMessage', action.payload),

    [setAudios]: (state, action) => state.set('listAudios', action.payload),
    [setItemsAudios]: (state, action) => state.setIn(['listAudios', 'items'], action.payload),
    [setAudiosPageNum]: (state, action) => state.setIn(['listAudios', 'pageNum'], action.payload),

    [setPlaylists]: (state, action) => state.set('listPlaylists', action.payload),
    [setItemsPlaylists]: (state, action) => state.setIn(['listPlaylists', 'items'], action.payload),
    [setPlaylistsPageNum]: (state, action) => state.setIn(['listPlaylists', 'pageNum'], action.payload),

    [setCurrentyAudio]: (state, action) => state.set('currentyAudio', action.payload),
    
    [setCurrentyPlaylist]: (state, action) => state.set('currentyPlaylist', action.payload),
    [setPlaylistItems]: (state, action) => state.setIn(['currentyPlaylist', 'items'], action.payload),
    [setPlaylistItemIds]: (state, action) => state.setIn(['currentyPlaylist', 'itemIds'], action.payload),
    [setOwnerUserObj]: (state, action) => state.setIn(['currentyPlaylist', 'ownerUserObj'], action.payload),
  },
  initialState
);
