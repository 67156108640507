import styled from 'styled-components';
import media from 'styled-media-query';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;

  ${media.lessThan('medium')`
    display: block;
    grid-template-columns: unset;
    gap: unset;
  `}
`;

export const CardNameElement = styled.input.attrs(() => ({
  type: 'text',
  required: true,
}))`
  padding: 1.1rem 1.6rem;
  border-radius: .5rem;
  border: solid .1rem #caccd8;
`;

export const ZipCodeElement = styled.input.attrs(() => ({
  type: 'text',
  required: true,
}))`
  padding: 1.1rem 1.6rem;
  border-radius: .5rem;
  border: solid .1rem #caccd8;
`;

export const CardNumberField = styled.div`
  padding: 1.1rem 1.6rem;
  border-radius: .5rem;
  border: solid .1rem #caccd8;
`;

export const CardExpiryField = styled.div`
  padding: 1.1rem 1.6rem;
  border-radius: .5rem;
  border: solid .1rem #caccd8;
`;

export const CardCvcField = styled.div`
  padding: 1.1rem 1.6rem;
  border-radius: .5rem;
  border: solid .1rem #caccd8;
`;
