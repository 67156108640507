import styled from 'styled-components'
import media from "styled-media-query";

export const Wrapper = styled.div`
  background-color: ${props => props.backgroundColor};
  padding: 1.9rem 2.0rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr) repeat(4, min-content);
  grid-template-areas: 
    "title title title title title detail detail links";
  column-gap: 3.8rem;
  align-items: center;
  border-radius: 5px;
  border: solid .1rem #ecedf3;
  margin-bottom: -.1rem;
  div {
    cursor: pointer;
  }

  ${media.lessThan("large")`
    padding: 1.2rem 1.0rem;
    column-gap: 3.088rem;
    grid-template-areas: 
      "title title title title title links links links"
      "detail detail detail detail . links links links";
  `}
  ${media.lessThan("medium")`    
    column-gap: 2.027rem;
    grid-template-areas: 
      "title title title title title links links links"
      "detail detail detail detail . links links links";
  `}
  ${media.lessThan("small")`
    column-gap: 1.188rem;
    grid-template-areas: 
      "title title title title title title links links"
      "detail detail detail detail detail detail links links";
  `}
`;

export const GapDetails = styled.div`
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "date count";
  column-gap: 3.8rem;
  grid-area: detail;
  justify-self: end;
  max-width: fit-content;

  ${media.lessThan("large")`
    column-gap: 3.088rem;
    justify-self: start;
  `}
  ${media.lessThan("medium")`
    column-gap: 2.027rem;
  `}
  ${media.lessThan("small")`
    column-gap: 1.5rem;
  `}
`;

export const LinksDesktop = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto auto;
  column-gap: .5rem;
  width: 17.059rem;
  justify-self: end;
  grid-area: links;

  ${media.lessThan("medium")`
    display: none;
  `}
`;

export const LinksMobile = styled.div`
  display: inline-grid;
  justify-self: end;
  grid-area: links;
  display: none;

  ${media.lessThan("medium")`
    display: unset;
  `}
`;
