import styled from 'styled-components';

import IconFontAnswer from '../../basic/IconFontAnswer';

export const Icon = styled(IconFontAnswer).attrs(props => ({
  unicode: 'f0c1',
  color: props.color,
}))`
  font-size: ${props => props.fontSize}
`;
