import { connect } from 'react-redux';
import {
  pushNewPlaylist,
  setNewPlaylist,
} from '../../actions/my-library-actions';
import GroupForm from './';

const mapStateToProps = state => ({
  newPlaylist: state.library.newPlaylist,
  playlistErrorMessage: state.library.playlistErrorMessage,
  isCreating: state.library.isCreating,
  closeForm: state.library.closeForm,
});

const mapDispatchToProps = {
  pushNewPlaylist: () => pushNewPlaylist(),
  setNewPlaylist: playlist => setNewPlaylist(playlist),
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupForm);