import React from 'react';
import PropTypes from 'prop-types';

import DefaultMessage from '../../../../../../shared/basic/DefaultMessage';
import { Wrapper } from './styles'

const Empty = ({ message }) => (
  <Wrapper>
    <DefaultMessage message={message} />
  </Wrapper>
);

Empty.propTypes = {
  message: PropTypes.string,
};

Empty.defaultProps = {
  message: 'Oops! The list is empty.',
};

export default Empty;