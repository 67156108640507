import styled from 'styled-components';
import { dashboard } from '../../../../assets';
import { black, slateGrey } from '../../../../styles/variables';
import { customMedia } from '../../../../styles/breakpoints';
import { pixelRatio } from '../../../../shared/helper/screen';

export const Div = styled.div`
  display: block;
  padding: 2rem 0;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;

  *:last-child {
    margin-left: .9rem
  }
`;

export const Cover = styled.img.attrs(() => {
  const ratio = pixelRatio();
  let img = dashboard.widgetCover;

  if (ratio == '@2x') {
    img = dashboard.widgetCover2x;
  } else if (ratio == '@3x') {
    img = dashboard.widgetCover3x;
  }

  return ({
    src: img,
  });
})`
  margin: 1.9rem 0;
  height: auto;
  width: 100%;
`;

export const SubText = styled.div`
  margin: .9rem 0 0 2rem;
  font-family: Work Sans;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${slateGrey};
`;

export const Title = styled.div`
  margin-left: 2rem;
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.34px;
  color: ${black};
`;

export const Img = styled.img`
  width: 100%;

  ${customMedia.lessThan('xl')`
    width: 90%;
  `}
`;