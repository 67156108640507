/* eslint-disable no-console */
import auth0 from 'auth0-js';
import httpClient, {
  setTokenApi,
  clearTokenApi,
  verifyEmailSoar,
  forgotPassword,
} from '../request';
import {
  generateJWT,
  getObjectUserAuth0,
  getDataCreateUser,
  userExistCreateAccount,
  createUserSoarApi,
  updateUserSoarApi,
  linkAuth0IDSoarID,
  userSubscription,
  infoUser,
} from '../account';

const isProduction = process.env.NODE_ENV === 'production';
const log = isProduction ? () => {} : console.info;
class Auth {
  constructor() {
    this.expiresAt = null;
    this.auth0 = new auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      // audience: 'https://api.soar.com',
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
      responseType: 'token id_token code',
      scope: 'openid profile offline_access',
    });
  }

  setProfile = async () => {
    this.profile = await infoUser();
  };

  getProfile = () => this.profile;

  getIdToken = () => this.idToken;

  isAuthenticated = () => sessionStorage.getItem('token_API_audience_TMP') !== null;

  login = callBack => {
    const cb = response => {
      callBack(response);
    };
    this.auth0.login(
      {
        realm: 'Username-Password-Authentication',
        username: sessionStorage.getItem('loginTmp'),
        password: sessionStorage.getItem('passwordTmp'),
        redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
      },
      cb
    );
    sessionStorage.removeItem('loginTmp');
    sessionStorage.removeItem('passwordTmp');
  };

  signIn = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const intent = urlParams.get('noAuthRedirect') === 'signup' ? 'signUp' : 'login';
    const loginHint = urlParams.get('loginHint') ? urlParams.get('loginHint') : '';

    this.auth0.authorize({ intent, login_hint: loginHint });
  };

  handleAuthentication = () => {
    log('call handleAuthentication'); // del
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line consistent-return
      this.auth0.parseHash(async (err, authResult) => {
        log('handleAuthentication authResult', authResult); // del
        if (!authResult) {
          return reject();
        }
        const soarId = await getSoarId(authResult.idTokenPayload);
        let userId = '';

        log('handleAuthentication soarId:', soarId); // del
        if (!soarId) {
          const failureCallback = () => {
            log('error generate token...');
            return err;
          };
          await generateJWT(failureCallback);

          const auth0Data = await getObjectUserAuth0(authResult.idTokenPayload.sub);
          const { data: dataUserExist } = await verifyEmailSoar(auth0Data.data.email);
          const storedSoarId = sessionStorage.getItem('SoarID');
          const header = { headers: { Authorization: `Bearer ${authResult.accessToken}` } };
          if (dataUserExist) {
            const response = await userExistCreateAccount(
              header,
              authResult.idTokenPayload.sub,
              dataUserExist
            );
            userId = response;
            log('updated Auth0 user metadata', response); // del
          } else {
            log('creating an user...');

            const data = getDataCreateUser(auth0Data); // return object for create a user
            if (sessionStorage.getItem('directories')) {
              data.customFields.searchDirectory = sessionStorage.getItem('directories');
            }

            let user = {};
            if (storedSoarId) {
              const response = await updateUserSoarApi(header, data);
              user = response.data;
            } else {
              const response = await createUserSoarApi(header, data);
              user = response.data;
            }
            log(`created the user with id ${user.id}:`, user); // del

            log('updating Auth0 metadata...'); // del
            userId = user.id;
            const response = await linkAuth0IDSoarID(header, userId);
            if (sessionStorage.getItem('register') && sessionStorage.getItem('freeTrialDays')) {
              const planID = process.env.REACT_APP_PLAN_BUSINESS_PLUS_MONTLY;
              await userSubscription(header, userId, planID, sessionStorage.getItem('freeTrialDays'));
              sessionStorage.removeItem('register');
              sessionStorage.removeItem('freeTrialDays');
            }
            log('response', response); // del
            log('updated Auth0 user metadata'); // del
          }
          this.setSession(authResult);
          sessionStorage.setItem('SoarID', userId);
          resolve(userId);
        } else {
          this.setSession(authResult);
          sessionStorage.setItem('SoarID', userId);
          resolve(soarId);
        }
      });
    });
  };

  handleAuthenticationBackEnd = async () => {
    log('call handleAuthenticationBackEnd'); // del
    await this.setProfile();
    const soarId = await getSoarId(this.profile);
    log('handleAuthenticationBackEnd soarId:', soarId); // del
    return soarId;
  };

  setSession = authResult => {
    this.idToken = authResult.id_token;
    this.accessToken = authResult.access_token;
    this.profile = authResult.idTokenPayload;
    // set the time that the id token will expire at
    this.expiresAt = authResult.expires_in * 1000;

    setTokenApi(authResult.accessToken);
  };

  signOut = async () => {
    const nodeBackEnd = process.env.REACT_APP_BACKEND_NODE;
    const url = `${nodeBackEnd}/auth/logout`;

    const response = await httpClient.post(
      url,
      {},
      {
        withCredentials: true,
      }
    );
    if (response.data.execute) {
      clearTokenApi();
    }
  };

  silentAuth = () =>
    new Promise((resolve, reject) => {
      // eslint-disable-next-line consistent-return
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) return reject(err);
        this.setSession(authResult);
        resolve();
      });
    });

  loginWithGoogle = () => {
    const audience = process.env.REACT_APP_SOAR_AUDIENCE;
    this.auth0.authorize(
      {
        connection: 'google-oauth2',
        scope: 'openid profile offline_access',
        audience,
      },
      err => {
        if (err) console.log(err, 'login');
      }
    );
  };

  loginWithFacebook = () => {
    const audience = process.env.REACT_APP_SOAR_AUDIENCE;
    this.auth0.authorize(
      {
        connection: 'facebook',
        scope: 'openid profile offline_access',
        audience,
      },
      err => {
        if (err) console.log(err, 'login');
      }
    );
  };

  forgotPassword = async email => {
    if (!email) {
      return 'There is no email to change the password, fill in the email field.';
    }
    const response = await forgotPassword(email);
    return (
      `${response}` +
      `\n` +
      ` If you did not receive the message, it's possible that you still haven't created an account.`
    );
  };

  loginWithLinkedin = () => {
    const audience = process.env.REACT_APP_SOAR_AUDIENCE;
    this.auth0.authorize(
      {
        connection: 'linkedin',
        scope: 'openid profile offline_access',
        audience,
      },
      err => {
        if (err) console.log(err, 'login');
      }
    );
  };
}

export function getSoarId(profile) {
  let soarId;

  if (
    profile &&
    profile['https://www.soar.com.app_metadata'] &&
    profile['https://www.soar.com.app_metadata'].soar_id
  ) {
    soarId = profile['https://www.soar.com.app_metadata'].soar_id;
  }

  if (
    profile &&
    profile['https://www.soar.com.user_metadata'] &&
    profile['https://www.soar.com.user_metadata'].soar_id
  ) {
    soarId = profile['https://www.soar.com.user_metadata'].soar_id;
  }

  if (profile && profile.app_metadata && profile.app_metadata.soar_id) {
    soarId = profile.app_metadata.soar_id;
  }

  return soarId;
}

const auth0Client = new Auth();

export default auth0Client;
