import styled from 'styled-components'
import * as variables from '../../../../styles/variables'

const mobile = 'screen and (max-width: 1000px)';
const small = 'screen and (max-width: 704px)';

export const VideoContainer = styled.div`
  margin: 10px auto;
  width: 100%;
  max-width: 1275px;
  display: grid;
  grid-template-columns: 50% auto;

  @media ${mobile} {
    grid-template-columns: auto;
  }
`;

export const VideoContainerRows = styled.div`
  margin: 10px auto;
  width: 100%;
  max-width: 1275px;
  display: grid;
  grid-template-rows: 50% auto;
  /* margin-left: 5rem;
  margin-top: 2.5rem; */

  @media ${mobile} {
    grid-template-columns: auto;
  }
`;

export const VideoPlayers = styled.div`
  margin: auto 4rem auto;

  @media ${small} {
    margin: 0;
  }
`;

export const VideoPlayer = styled.div`
  /* width: 100%; */
  max-width: 548px;
  height: 308px;
  /* margin: 0 auto 40px auto; */

  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

export const VideoText = styled.div`
  
  font-size: 14px;

  @media ${mobile} {
    padding: 2rem;
  }
`;

export const VideoTextTitle = styled.div`
  margin-bottom: 30px;
  color: ${variables.greyTitle};
  font-weight: 500;
  font-size: 2.8rem;
`;

export const BenefitsContainer = styled.div`
  margin-bottom: 5rem;
  max-width: 550px;
`;

export const BenefitItem = styled.div`
  display: grid;
  line-height: 26px;
  grid-template-columns: 20px auto;
  margin-bottom: 3rem;
`;

export const BenefitIndex = styled.div`
  color: ${variables.brightOrange};
`;

export const BenefitText = styled.div`
color: ${variables.darkGrey};
  font-size: 17px;
`;
