import React from 'react';
import PropTypes from 'prop-types';

import { Box } from './styles';

const BoxMessage = ({ message, type }) => <Box type={type}>{message}</Box>;

BoxMessage.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
};

BoxMessage.defaultProps = {
  message: '',
  type: 'alert',
};

export default BoxMessage;