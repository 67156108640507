import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  opacity: 0.9;
  font-size: 1.6rem;
  color: red;
`;

export const Box = styled.div`
  border: 0.1rem solid red;
  background-color: white;
  border-radius: 1.0rem;
  margin: 2.0rem;
  padding: 2.0rem;
`;
