import { connect } from 'react-redux';
import {
  invitationFail,
  sendNewInvitation,
} from '../../actions/my-library-actions';
import InvitationDialog from './';

const mapStateToProps = state => ({
  isSendindInvitation: state.library.isSendindInvitation,
  modalSubmitSuccess: state.library.modalSubmitSuccess,
  invitationError: state.library.invitationError,
});

const mapDispatchToProps = {
  sendNewInvitation: invitationData => sendNewInvitation(invitationData),
  invitationFail: message => invitationFail(message),
};

export default connect(mapStateToProps, mapDispatchToProps)(InvitationDialog);